import { type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
// Skillmore UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
// local imports
import { PATH_SKILL } from '../config/paths';
import { getRoleId, getSkillId } from '../helpers/routerParams';

type RedirectToNewPathProps = {
  path: string;
}

const RedirectToNewPathPropTypes = {
  // attributes
  path: PropTypes.string.isRequired
};

const RedirectToNewPath: FunctionComponent<RedirectToNewPathProps> = ({
  path
}) => {
  const isSkill = path === PATH_SKILL;
  const id = (isSkill ? getSkillId : getRoleId)(useParams());
  return <Navigate to={injectParams(path, isSkill ? { skill_id: id } : { role_id: id })}/>;
};

RedirectToNewPath.propTypes = RedirectToNewPathPropTypes;

export default RedirectToNewPath;
