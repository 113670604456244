import { memo, useState, useEffect, useCallback, type FunctionComponent, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
// local imports
import CloseIconButton from './CloseIconButton';
import { type Values, ValuesPropType } from '../helpers/intl';
// SCSS imports
import { dialogActions, dialogActionsCompact, text, okButton } from './ActionSucceededMessage.module.scss';

const contentSx = { pb: 1 };

const WIDTHS = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

type ActionSucceededMessageProps = {
  maxWidth?: typeof WIDTHS[number];
  compact?: boolean;
  withClose?: boolean;
  disableTypography?: boolean;
  onOK?: () => void;
  title?: string | null;
  message: string;
  values?: Values;
  button?: string | null;
  link?: string | null;
  open?: boolean;
};

const ActionSucceededMessagePropTypes = {
  // attributes
  maxWidth: PropTypes.oneOf(WIDTHS),
  compact: PropTypes.bool,
  withClose: PropTypes.bool,
  disableTypography: PropTypes.bool,
  onOK: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  button: PropTypes.string,
  link: PropTypes.string,
  values: ValuesPropType,
  open: PropTypes.bool
};

const ActionSucceededMessage: FunctionComponent<ActionSucceededMessageProps> = ({
  maxWidth,
  compact,
  withClose,
  disableTypography,
  onOK,
  title,
  message,
  values,
  button,
  link,
  open = false
}) => {
  const { formatMessage } = useIntl();

  const [messageOpen, setMessageOpen] = useState(false);

  const translatedLink = useMemo(
    () => link ? formatMessage({ id: link, defaultMessage: link }) : undefined,
    [formatMessage, link]
  );

  useEffect(() => {
    if (open) setMessageOpen(true);
  }, [open]);

  const handleClose = useCallback(() => {
    setMessageOpen(false);
    if (onOK) onOK();
  }, [onOK]);

  const titleSx = useMemo(() => ({ pt: compact ? 5 : 8, pb: 2, px: 6, textAlign: 'center' }), [compact]);

  return (
    <Dialog
        disableEnforceFocus
        maxWidth={maxWidth}
        open={messageOpen}
        onClose={onOK ? undefined : handleClose}
    >
      {withClose ? <CloseIconButton onClick={handleClose}/> : undefined}
      {title ? (
        <DialogTitle sx={titleSx}>
          <Typography variant="h3" component="span">
            <FormattedMessage id={title} values={values} defaultMessage={title}/>
          </Typography>
        </DialogTitle>
      ) : undefined}
      <DialogContent sx={contentSx}>
        <Box
            pt={title ? undefined : (compact && 3) || 6}
            pb={compact || onOK ? 2 : 6}
            px={6}
        >
          {disableTypography ? (
            <FormattedMessage id={message} values={values} defaultMessage={message}/>
          ) : (
            <Typography variant="inherit" paragraph align="center" className={text}>
              <FormattedMessage id={message} values={values} defaultMessage={message}/>
            </Typography>
          )}
        </Box>
        {onOK && compact ? <Divider/> : undefined}
      </DialogContent>
      {onOK ? (
        <DialogActions disableSpacing className={compact ? dialogActionsCompact : dialogActions}>
          <Button
              color="primary"
              variant="contained"
              disableElevation
              size="large"
              className={okButton}
              onClick={handleClose}
              component="a"
              href={translatedLink}
              target={link ? '_blank' : undefined}
              rel={link ? 'noopener noreferrer' : undefined}
          >
            <FormattedMessage id={button || 'common.big_ok'} values={values} defaultMessage={button || undefined}/>
          </Button>
        </DialogActions>
      ) : undefined}
    </Dialog>
  );
};

ActionSucceededMessage.propTypes = ActionSucceededMessagePropTypes;

export default memo(ActionSucceededMessage);
