import { memo, useCallback, useMemo, forwardRef, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import reject from 'lodash/reject';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import toString from 'lodash/toString';
import { useIntl } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// local imports
import { LookupItem } from '../models/lookupItem';
import { DEFAULT_MENU_PROPS } from '../helpers/menus';
// SCSS imports
import { root, shortRoot, placeholder } from '../styles/modules/Lookup.module.scss';

type FilterSelectorProps = {
  type: string;
  choices?: LookupItem[] | null;
  error?: boolean;
  helperText?: string;
  value: number;
  onChange: (id: number) => void;
  disabled?: boolean | null;
  required?: boolean | null;
  short?: boolean | null;
  myId?: number;
};

const FilterSelectorPropTypes = {
  // attributes
  type: PropTypes.string.isRequired,
  choices: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  short: PropTypes.bool,
  myId: PropTypes.number
};

const FilterSelector = forwardRef<HTMLDivElement, FilterSelectorProps>(({
  type,
  choices,
  error,
  helperText,
  value,
  onChange,
  disabled = false,
  required = false,
  short = false,
  myId
}, ref) => {
  const { formatMessage } = useIntl();

  const handleChange = useCallback((event: SelectChangeEvent<number>) => onChange(
    toSafeInteger(event.target.value)
  ), [onChange]);

  const displayChoices = useMemo(() => myId ? reject(choices, ['id', myId]) : choices, [choices, myId]);

  const names: Record<string, string> = useMemo(
    () => transform(choices || [], (result, { id, name, title }) => {
      result[toString(id)] = myId
        ? formatMessage({ id: `filter.${type}.name` }, { name: name || title || '', is_my: myId === id })
        : name || title || '';
    }, {} as Record<string, string>),
    [choices, formatMessage, type, myId]
  );

  const [choice, all] = useMemo(() => [
    formatMessage({ id: `filter.${type}.label` }),
    required ? undefined : formatMessage({ id: `filter.${type}.all` })
  ], [type, required, formatMessage]);

  const renderValue = useCallback(
    (val: unknown): ReactNode => val === 0 || val === '' ? choice : names[toString(val)],
    [choice, names]
  );

  return (
    <FormControl
        ref={ref}
        variant="outlined"
        size="small"
        error={error}
        disabled={disabled || size(choices) < 1}
        className={short ? shortRoot : root}
    >
      <Select
          id={`${type}-select`}
          value={(required && value === 0) || !choices ? '' : value}
          displayEmpty
          renderValue={renderValue}
          onChange={handleChange}
          MenuProps={DEFAULT_MENU_PROPS}
          className={value === 0 ? placeholder : undefined}
      >
        {all ? (
          <MenuItem value={0}>
            <Typography variant="h5">
              {all}
            </Typography>
          </MenuItem>
        ) : undefined}
        {myId ? (
          <MenuItem value={myId}>
            {renderValue(myId)}
          </MenuItem>
        ) : undefined}
        {displayChoices ? map(displayChoices, ({ id, name, title }) => (
          <MenuItem key={id} value={id}>
            {name || title}
          </MenuItem>
        )) : undefined}
      </Select>
      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : undefined}
    </FormControl>
  );
});

FilterSelector.displayName = 'FilterSelector';

FilterSelector.propTypes = FilterSelectorPropTypes;

export default memo(FilterSelector);
