import { memo, type FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// Skillmore UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import TalentFinder from '../panels/TalentFinder';

const SupervisorTalentFinderScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({ manager: true });
  return content || (
    <Screen withSettings>
      <ContentCard>
        <CardTitle title="hr.talentfinder" withDivider/>
        <DndProvider backend={HTML5Backend} context={window}>
          <TalentFinder supervisor/>
        </DndProvider>
      </ContentCard>
    </Screen>
  );
};

export default memo(SupervisorTalentFinderScreen);
