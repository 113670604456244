import { memo, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import transform from 'lodash/transform';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import isUndefined from 'lodash/isUndefined';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// Material Icon imports
import PlaceIcon from '@mui/icons-material/Place';
import HourglassTop from '@mui/icons-material/HourglassTop';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
// Skillmore UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import { defaultDateOptions, shortDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
// local imports
import { OpportunitySkillStatus, Opportunity, OpportunityStatus } from '../graphql/types';
import { SkillLevel } from '../models/skill';
import { PATH_SKILL } from '../config/paths';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';

type OpportunityDetailsContentProps = {
  supervisor?: boolean;
  opportunity?: Opportunity | null;
  withoutSkills?: boolean;
  disabled?: boolean | null;
  isNew?: boolean | null;
  matchRate?: number | null;
  growthRate?: number | null;
  actions?: ReactNode | ReactNode[];
}

const OpportunityDetailsContentPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  opportunity: PropTypes.object as Validator<Opportunity>,
  withoutSkills: PropTypes.bool,
  disabled: PropTypes.bool,
  isNew: PropTypes.bool,
  matchRate: PropTypes.number,
  growthRate: PropTypes.number,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>
};

// eslint-disable-next-line complexity
const OpportunityDetailsContent: FunctionComponent<OpportunityDetailsContentProps> = ({
  supervisor = false,
  opportunity,
  withoutSkills = false,
  disabled = false,
  isNew,
  matchRate,
  growthRate,
  actions
}) => {
  const { getLocationStr } = useModels();
  const {
    title, status, owner, location, duration_value, duration_unit, sidegig, onsite, timezone_minutes, start_date, description,
    updated_at, timestamp, skills
  } = opportunity || {};

  const [requiredSkills, skillsGrowth] = useMemo(() => skills
    ? transform(skills, (result, skill) => {
        result[skill.status === OpportunitySkillStatus.required ? 0 : 1].push(skill);
      }, [[], []] as (typeof skills)[])
    : [undefined, undefined], [skills]);

  return (
    <>
      <CardSection compact={!supervisor} top={supervisor}>
        <Box display="flex" alignItems="center" pb={1.5}>
          <Box flex="1 1 0" pr={2} display="flex" flexDirection="column">
            {supervisor || isUndefined(isNew) ? undefined : <SkillNewTag active={isNew}/>}
            <BoxTypography variant="subtitle1" pb={1}>
              {title}
            </BoxTypography>
            <BoxTypography
                variant="body2"
                color="info.caption"
                fontStyle="italic"
                display="block"
            >
              <FormattedMessage
                  id="opportunities.timestamp"
                  values={{
                    status,
                    date: timestamp ? (
                      <FormattedDate
                          value={getJsDateFromISO(timestamp)}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...supervisor ? defaultDateOptions : shortDateOptions}
                      />
                    ) : null,
                    update: supervisor && updated_at && status && status !== OpportunityStatus.draft ? (
                      <FormattedDate
                          value={getJsDateFromISO(updated_at)}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...defaultDateOptions}
                      />
                    ) : null,
                    owner: supervisor ? null : (owner && (
                      <EmployeeName
                          employee={owner}
                          manager
                          disabled={disabled ? true : undefined}
                      />
                    )) || '—'
                  }}
              />
            </BoxTypography>
          </Box>
          {actions || (!supervisor && !isUndefined(matchRate)) ? (
            <Box display="flex" flexDirection={actions ? undefined : 'column'} pl={1.5} py={0.5}>
              {actions || <MatchIndicator value={toSafeInteger(matchRate)}/>}
            </Box>
          ) : undefined}
          {!supervisor && !isUndefined(growthRate) ? (
            <Box display="flex" flexDirection="column" pl={2.5} py={0.5}>
              <MatchIndicator
                  value={toSafeInteger(growthRate)}
                  variant="planned"
                  label="opportunities.growth_rate_value"
              />
            </Box>
          ) : undefined}
        </Box>
      </CardSection>
      <CardSection bottom>
        <Divider/>
        <Box display="flex" alignItems="center" flexWrap="wrap" py={2}>
          <Box flex="1 1 0" display="flex" alignItems="center" justifyContent="flex-start" pr={1}>
            <PlaceIcon color="primary"/>
            <BoxTypography variant="body2" pl={1}>
              {getLocationStr(location) || <FormattedMessage id="opportunities.location_not_specified"/>}
              <FormattedMessage
                  id="opportunities.location_options"
                  values={{ onsite, timezone: isSafeInteger(timezone_minutes) ? timezone_minutes : null, style: 'dash' }}
              />
            </BoxTypography>
          </Box>
          <Box flex="1 1 0" display="flex" alignItems="center" justifyContent="center" px={1}>
            <HourglassTop color="primary"/>
            <BoxTypography variant="body2" pl={1}>
              <FormattedMessage
                  id="opportunities.duration"
                  values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: true }}
              />
            </BoxTypography>
          </Box>
          <Box flex="1 1 0" display="flex" alignItems="center" justifyContent="flex-end" pl={1}>
            <CalendarMonth color="primary"/>
            <BoxTypography variant="body2" pl={1}>
              <FormattedMessage
                  id="opportunities.start_date"
                  values={{ date: start_date ? getJsDateFromISO(start_date) : null, withTitle: null }}
              />
            </BoxTypography>
          </Box>
        </Box>
        <Divider/>
      </CardSection>
      <CardSection compact={!supervisor} bottom={supervisor}>
        <BoxTypography variant="body1" pb={3}>
          {description}
        </BoxTypography>
        <Divider/>
        {!withoutSkills && (requiredSkills?.length || skillsGrowth?.length) ? [
          ...requiredSkills?.length ? [{ header: 'opportunities.skills.required', skls: requiredSkills }] : [],
          ...skillsGrowth?.length ? [{ header: 'opportunities.skills.growth', skls: skillsGrowth }] : []
        ].map(({ header, skls }) => (
          <Box key={header} pt={2}>
            <BoxTypography variant="h4" pb={1}>
              <FormattedMessage id={header}/>
            </BoxTypography>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {map(skls, (skill) => (
                <Box
                    key={skill.id}
                    my={1}
                    mr={2.5}
                    px={2}
                    py={0.5}
                    bgcolor="background.card"
                    borderRadius={shape.tinyBorderRadius}
                >
                  <SkillName
                      skill={skill}
                      level={skill.skill_proficiency_level as SkillLevel}
                      skillPath={PATH_SKILL}
                      variant="body1"
                      maxLines={1}
                      disabled={disabled}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )) : undefined}
      </CardSection>
    </>
  );
};

OpportunityDetailsContent.propTypes = OpportunityDetailsContentPropTypes;

export default memo(OpportunityDetailsContent);
