import { memo, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// Skillmore UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { getDefaultLeaderId } from '../models/user';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import EmployeesWithSkill from './EmployeesWithSkill';

type EmployeesWithSkillCardProps = {
  skillId?: string | null;
  isEmployee?: boolean;
  supervisor?: boolean;
  hrbp?: boolean;
  reducedUI?: boolean;
  isInternational?: boolean | null;
  resetFilters?: boolean;
}

const EmployeesWithSkillCardPropTypes = {
  skillId: PropTypes.string,
  isEmployee: PropTypes.bool,
  supervisor: PropTypes.bool,
  hrbp: PropTypes.bool,
  reducedUI: PropTypes.bool,
  isInternational: PropTypes.bool,
  resetFilters: PropTypes.bool
};

const EmployeesWithSkillCard: FunctionComponent<EmployeesWithSkillCardProps> = ({
  skillId,
  isEmployee = false,
  supervisor = false,
  hrbp = false,
  reducedUI = false,
  isInternational = false,
  resetFilters = false
}) => {
  const { user: { data: user } } = useContext(GlobalContext);
  const { skill: { failed } } = useContext(DataContext);

  const uid = useMemo(
    () => (user && (hrbp ? getDefaultLeaderId(user) : supervisor && user.code)) || undefined,
    [hrbp, supervisor, user]
  );

  return failed ? null : (
    <ContentCard>
      <CardTitle
          title={supervisor ? 'supervisor.skill_supply' : 'skill.employees_with_skill'}
          withDivider={supervisor || !reducedUI}
      />
      <EmployeesWithSkill
          skillId={skillId}
          isEmployee={isEmployee}
          supervisor={supervisor}
          hrbp={hrbp}
          uid={uid}
          isInternational={isInternational}
          reducedUI={!supervisor && reducedUI}
          resetFilters={resetFilters}
      />
    </ContentCard>
  );
};

EmployeesWithSkillCard.propTypes = EmployeesWithSkillCardPropTypes;

export default memo(EmployeesWithSkillCard);
