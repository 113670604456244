import { forwardRef, memo, useCallback, type Component } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// Material Icon imports
import BubbleChart from '@mui/icons-material/BubbleChart';
import DonutLargeRounded from '@mui/icons-material/DonutLargeRounded';
import AppsRounded from '@mui/icons-material/AppsRounded';
// local imports
import FormatListBulletedRounded from '../icons/FormatListBulletedRounded';
// SCSS imports
import { viewButton, icon, iconPie, divider } from './ViewSwitch.module.scss';

export const TABLE_VIEW = 'table' as const;
export const TILES_VIEW = 'tiles' as const;
export const CHART_VIEW = 'chart' as const;

const VIEW_TYPES = [TABLE_VIEW, TILES_VIEW, CHART_VIEW] as const;

export type ViewType = typeof VIEW_TYPES[number];

export type ChartStyleType = 'pie' | 'bubble';

type ViewSwitchProps = {
  chart?: boolean;
  chartStyle?: ChartStyleType;
  tiles?: boolean;
  disabled?: boolean;
  value: string;
  onChange: (id: ViewType) => void;
};

const ViewSwitchPropTypes = {
  chart: PropTypes.bool,
  chartStyle: PropTypes.oneOf(['pie', 'bubble']) as Validator<ChartStyleType>,
  tiles: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ViewSwitch = forwardRef<Component<BoxProps>, ViewSwitchProps>(({
  chart = false,
  chartStyle = 'bubble',
  tiles = false,
  disabled = false,
  value,
  onChange
}, ref) => {
  const { formatMessage } = useIntl();

  const handleChart = useCallback(() => {
    if (value !== CHART_VIEW) onChange(CHART_VIEW);
  }, [value, onChange]);

  const handleTiles = useCallback(() => {
    if (value !== TILES_VIEW) onChange(TILES_VIEW);
  }, [value, onChange]);

  const handleTable = useCallback(() => {
    if (value !== TABLE_VIEW) onChange(TABLE_VIEW);
  }, [value, onChange]);

  const isChart = value === CHART_VIEW;
  const isTiles = value === TILES_VIEW;
  const isTable = value === TABLE_VIEW;

  return (
    <Box
        ref={ref}
        pl={3}
        color="greys.switch"
        display="flex"
        alignItems="center"
    >
      {chart ? (
        <>
          <Button
              disabled={disabled}
              color={isChart ? 'inherit' : 'info'}
              variant={isChart ? 'outlined' : 'contained'}
              aria-label={formatMessage({ id: 'common.view.chart' }, { selected: isChart })}
              onClick={handleChart}
              className={viewButton}
          >
            {chartStyle === 'pie'
              ? <DonutLargeRounded color={isChart ? 'inherit' : 'primary'} className={iconPie}/>
              : <BubbleChart color={isChart ? 'inherit' : 'primary'} className={icon}/>}
          </Button>
          <Divider light flexItem orientation="vertical" className={divider}/>
        </>
      ) : undefined}
      {tiles ? (
        <>
          <Button
              disabled={disabled}
              color={isTiles ? 'inherit' : 'info'}
              variant={isTiles ? 'outlined' : 'contained'}
              aria-label={formatMessage({ id: 'common.view.tiles' }, { selected: isTiles })}
              onClick={handleTiles}
              className={viewButton}
          >
            <AppsRounded color={isTiles ? 'inherit' : 'primary'} className={icon}/>
          </Button>
          <Divider light flexItem orientation="vertical" className={divider}/>
        </>
      ) : undefined}
      <Button
          disabled={disabled}
          color={isTable ? 'inherit' : 'info'}
          variant={isTable ? 'outlined' : 'contained'}
          aria-label={formatMessage({ id: 'common.view.list' }, { selected: isTable })}
          onClick={handleTable}
          className={viewButton}
      >
        <FormatListBulletedRounded color={isTable ? 'inherit' : 'primary'} className={icon}/>
      </Button>
    </Box>
  );
});

ViewSwitch.displayName = 'ViewSwitch';

ViewSwitch.propTypes = ViewSwitchPropTypes;

export default memo(ViewSwitch);
