import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import useCustomerSettings, { OtherResources } from '../config/customer';
import ExploreOtherResourcesSection from './ExploreOtherResourcesSection';
// SCSS imports
import { bg, privacyNotice } from './ExploreOtherResources.module.scss';

type ExploreOtherResourcesProps = {
  isInternational?: boolean;
  noTopMargin?: boolean;
  otherResources?: OtherResources;
};

const ExploreOtherResourcesPropTypes = {
  isInternational: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  otherResources: PropTypes.object as Validator<OtherResources>
};

const ExploreOtherResources: FunctionComponent<ExploreOtherResourcesProps> = ({
  isInternational = false,
  noTopMargin = false,
  otherResources
}) => {
  const { CUSTOMER_OTHER_RESOURCES, PRIVACY_NOTICE_URL } = useCustomerSettings();
  const { support, resources } = otherResources || CUSTOMER_OTHER_RESOURCES;
  const vertical = size(support) >= 1;
  return (
    <>
      <Box
          flexGrow={1}
          mt={noTopMargin ? 0 : 7}
          mb={PRIVACY_NOTICE_URL ? 1 : 3}
          pt={3.5}
          display="flex"
          flexDirection={vertical ? undefined : 'column'}
          justifyContent="strech"
          flexWrap={vertical ? 'wrap' : undefined}
          className={bg}
      >
        {vertical ? (
          <>
            <Box flex="1 1 0">
              <ExploreOtherResourcesSection
                  title="board.support"
                  vertical
                  isInternational={isInternational}
                  resources={support}
              />
            </Box>
            <Box flex="1 1 0">
              <ExploreOtherResourcesSection
                  title="board.other_resources"
                  vertical
                  isInternational={isInternational}
                  resources={resources}
              />
            </Box>
          </>
        ) : (
          <ExploreOtherResourcesSection
              title="board.other_resources"
              isInternational={isInternational}
              resources={resources}
          />
        )}
      </Box>
      {PRIVACY_NOTICE_URL ? (
        <Box mb={2} textAlign="center">
          <StandardLink
              text="welcome.privacy_notice.title"
              href={PRIVACY_NOTICE_URL}
              className={privacyNotice}
          />
        </Box>
      ) : undefined}
    </>
  );
};

ExploreOtherResources.propTypes = ExploreOtherResourcesPropTypes;

export default memo(ExploreOtherResources);
