import { memo, useCallback, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// Skillmore UI Components
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import { Employee } from '../models/employee';
import useCustomerSettings from '../config/customer';
import useModels from '../helpers/models';

type EmployeeDetailsBandProps = {
  fullName?: string | null;
  employee?: Employee | null;
  onJobClick?: (roleId: string) => void | null;
};

const EmployeeDetailsBandPropTypes = {
  fullName: PropTypes.string,
  employee: PropTypes.object as Validator<Employee>,
  onJobClick: PropTypes.func
};

const EmployeeDetailsBand: FunctionComponent<EmployeeDetailsBandProps> = ({
  fullName,
  employee,
  onJobClick
}) => {
  const { getEmployeeContactUrl } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();
  const { getEmployeeLocationStr } = useModels();
  const showMatchRate = showNonReducedUI(employee);
  const { email, current_job, location } = employee || {};
  const { code, title, match_rate, location: jobLocation } = current_job || {};

  const href = useMemo(() => getEmployeeContactUrl(code, email), [code, email, getEmployeeContactUrl]);
  const locationStr = useMemo(
    () => getEmployeeLocationStr(jobLocation, location),
    [jobLocation, location, getEmployeeLocationStr]
  );

  const handleJobClick = useCallback(() => {
    if (code && onJobClick) onJobClick(code);
  }, [code, onJobClick]);

  return (
    <ContentCard pending={!employee}>
      <CardSection compact flex>
        <Box
            py={1}
            flexGrow={1}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
        >
          <Box
              flexGrow={1}
              flexShrink={0}
              py={2}
              display="flex"
              alignItems="center"
          >
            <AccountCircleAlt fontSize="large" color="primary"/>
            <BoxTypography pl={1} flexGrow={1} variant="subtitle2">
              <TruncatedTextLink variant="inherit" maxLines={1} href={href} text={fullName}/>
            </BoxTypography>
          </Box>
          <Box alignSelf="stretch" p={1.5}>
            <Divider
                orientation="vertical"
                variant="middle"
            />
          </Box>
          <BoxTypography
              flexGrow={1}
              flexShrink={0}
              py={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              align="center"
              variant="body1"
          >
            {locationStr || <FormattedMessage id="employees.not_available"/>}
          </BoxTypography>
          <Box alignSelf="stretch" p={1.5}>
            <Divider
                orientation="vertical"
                variant="middle"
            />
          </Box>
          <Box
              flexGrow={1}
              flexShrink={0}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
          >
            <Box
                flexGrow={1}
                flexShrink={0}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
            >
              <TruncatedTextLink variant="subtitle2" text={title} onClick={code && onJobClick ? handleJobClick : undefined}/>
            </Box>
            {showMatchRate ? (
              <Box pl={2} display="flex" alignItems="center">
                <MatchIndicator value={toSafeInteger(match_rate)}/>
              </Box>
            ) : undefined}
          </Box>
        </Box>
      </CardSection>
    </ContentCard>
  );
};

EmployeeDetailsBand.propTypes = EmployeeDetailsBandPropTypes;

export default memo(EmployeeDetailsBand);
