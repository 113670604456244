import { memo, useState, useEffect, useLayoutEffect, useMemo, useCallback, useReducer, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import zod from 'zod';
import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, type ApolloCache } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import { isEmptyString, isValidEmail } from '@empathco/ui-components/src/helpers/strings';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import YesNoButtonGroup from '@empathco/ui-components/src/elements/YesNoButtonGroup';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import {
  AdminUser,
  NewAdminUserDocument, NewAdminUserMutationVariables,
  UpdateAdminUserDocument, UpdateAdminUserMutationVariables,
  NewAdminUserInviteDocument, DeleteAdminUserDocument
} from '../graphql/types';
import { NEW_ADMIN_USER } from '../graphql/NewAdminUser';
import { UPDATE_ADMIN_USER } from '../graphql/UpdateAdminUser';
import { DELETE_ADMIN_USER } from '../graphql/DeleteAdminUser';
import { NEW_ADMIN_USER_INVITE } from '../graphql/NewAdminUserInvite';
import { toggleReducer } from '../helpers/reducers';

const updateAdminUsers = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'adminUsers' });
};

type AdminUserDialogProps = {
  user?: AdminUser | null;
  onClose: () => void;
  // for Strorybook only
  testAction?: number;
  testUser?: AdminUser;
}

const AdminUserDialogPropTypes = {
  // attributes
  user: PropTypes.object as Validator<AdminUser>,
  onClose: PropTypes.func.isRequired,
  // for Strorybook only
  testAction: PropTypes.number,
  testUser: PropTypes.object as Validator<AdminUser>
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const AdminUserDialog: FunctionComponent<AdminUserDialogProps> = ({
  user,
  onClose,
  testAction,
  testUser
}) => {
  const { formatMessage } = useIntl();

  const userId = user?.id;

  // this dialog
  const isOpen = Boolean(user || testUser);
  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);
  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [invite, toggleInvite] = useReducer(toggleReducer, Boolean(testUser));
  const [closeOnOK, setCloseOnOK] = useState(true);

  const newUser = useMutationMethod({
    // TODO: key: 'newAdminUser', -- restore when backend responds with `success: true`
    mutation: useMutation(NEW_ADMIN_USER as typeof NewAdminUserDocument)
  });
  const updateUser = useMutationMethod({
    // TODO: key: 'updateAdminUser', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_ADMIN_USER as typeof UpdateAdminUserDocument)
  });
  const { mutate: saveAdminUser, loading: savePending, failed: saveFailed } = userId
    ? updateUser : newUser;

  const { mutate: inviteUser, loading: invitePending, failed: inviteFailed, succeeded: inviteSucceeded } = useMutationMethod({
    // TODO: key: 'newAdminUserInvite', -- restore when backend responds with `success: true`
    mutation: useMutation(NEW_ADMIN_USER_INVITE as typeof NewAdminUserInviteDocument)
  });

  const { mutate: deleteAdminUser, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_ADMIN_USER as typeof DeleteAdminUserDocument)
  });

  const onDelete = useCallback(() => {
    if (userId) deleteAdminUser?.({
      variables: { user_id: userId },
      update: updateAdminUsers,
      onCompleted: () => onClose()
    });
  }, [userId, onClose, deleteAdminUser]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleDelete,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(onDelete);

  const pending = savePending || invitePending || deletePending;

  // Admin User Editor form
  const schema = useMemo(() => zod.object({
    username: zod.string().min(1, { message: formatMessage({ id: 'admin.users.input.username.required' }) }),
    first_name: zod.string().optional(),
    last_name: zod.string().optional(),
    email: zod.union([
      zod.literal(''),
      zod.string().email({ message: formatMessage({ id: 'admin.users.input.email.invalid' }) })
    ]),
    is_active: zod.boolean()
  }), [formatMessage]);

  type Schema = zod.infer<typeof schema>;

  const formParams = useMemo(() => ({
    resolver: zodResolver(schema),
    defaultValues: {
      username: (user || testUser)?.username || '',
      first_name: (user || testUser)?.first_name || '',
      last_name: (user || testUser)?.last_name || '',
      email: (user || testUser)?.email || '',
      is_active: Boolean((user || testUser)?.is_active)
    }
  } as Parameters<typeof useForm<Schema>>[0]), [schema, user, testUser]);

  const { control, reset, watch, handleSubmit } = useForm<Schema>(formParams);

  const username = watch('username');
  const firstName = watch('first_name');
  const lastName = watch('last_name');
  const email = watch('email');
  const isActive = watch('is_active');

  const canInvite = Boolean(email) && !isEmptyString(email) && isValidEmail(email) && isActive;
  const canInviteWithoutSaving = Boolean(userId && email && user?.email && email === user.email);
  const canSave = !userId || Boolean(
    (username && username !== (user?.username || '')) ||
    firstName !== (user?.first_name || '') ||
    lastName !== (user?.last_name || '') ||
    email !== (user?.email || '') ||
    isActive !== Boolean(user?.is_active)
  );

  const msgValues = useMemo(() => ({ bold, username: user?.username || '?', email }), [email, user]);

  const handleInvite = useCallback(() => {
    if (canInviteWithoutSaving && email) {
      setCloseOnOK(false);
      inviteUser?.({ variables: { input: { email } } });
    }
  }, [email, canInviteWithoutSaving, inviteUser]);

  useLayoutEffect(() => {
    reset(formParams?.defaultValues as Schema);
  }, [formParams, reset]);

  const onSubmit: SubmitHandler<Schema> = useCallback((formData: Schema) => {
    const inviteUserArgs: Parameters<typeof inviteUser>[0] | undefined = invite && formData.email ? {
      variables: { input: { email: formData.email } }
    } : undefined;
    setCloseOnOK(true);
    saveAdminUser?.({
      variables: {
        ...userId ? { user_id: userId } : {},
        input: {
          ...formData,
          is_staff: true,
          is_superuser: true
        }
      } as NewAdminUserMutationVariables & UpdateAdminUserMutationVariables,
      update: updateAdminUsers,
      onCompleted: () => {
        if (inviteUserArgs) inviteUser?.(inviteUserArgs);
        else onClose();
      }
    });
  }, [invite, userId, onClose, saveAdminUser, inviteUser]);

  const labels = useMemo(() => ({
    username: formatMessage({ id: 'admin.users.input.username' }),
    first_name: formatMessage({ id: 'admin.users.input.first_name' }),
    last_name: formatMessage({ id: 'admin.users.input.last_name' }),
    email: formatMessage({ id: 'admin.users.input.email' })
  }), [formatMessage]);

  const renderStringField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<
    Schema, 'username' | 'first_name' | 'last_name' | 'email'
  >>['render']>[0]) => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        label={labels[field.name]}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={pending}
    />
  ), [pending, labels]);

  const renderBooleanField = useCallback(({ field }: Parameters<GetComponentProps<
    typeof Controller<Schema, 'is_active'>
  >['render']>[0]) => (
    <YesNoButtonGroup
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        small
        yesLabel={`admin.users.input.${field.name}.true`}
        noLabel={`admin.users.input.${field.name}.false`}
        disabled={pending}
    />
  ), [pending]);

  useEffect(() => {
    if (testAction === 1) saveAdminUser?.({ variables: {
      input: { username: 'test123', is_active: true, is_staff: true, is_superuser: true }
    } as NewAdminUserMutationVariables & UpdateAdminUserMutationVariables });
    else if (testAction === 2) saveAdminUser?.({ variables: {
      user_id: 123, input: { username: 'test123', is_active: true, is_staff: true, is_superuser: true }
    } });
    else if (testAction === 4) inviteUser?.({ variables: { input: { email: 'test123@example.com' } } });
    else if (testAction === 3) deleteAdminUser?.({ variables: { user_id: 123 } });
  }, [testAction, deleteAdminUser, saveAdminUser, inviteUser]);

  const disabled = pending ? true : undefined;

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="md"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={disabled ? undefined : handleClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={handleClose}/>
        <CardTitle title={userId ? 'admin.users.edit' : 'admin.users.new'} withDivider/>
        <CardSection>
          <Grid container>
            <Grid container item xs={12} sm={6} pr={3} alignItems="center">
              <Controller
                  name="username"
                  control={control}
                  render={renderStringField}
              />
            </Grid>
            <Grid container item xs={12} sm={6} pr={3} pt={1.25} pb={4}>
              <Controller
                  name="is_active"
                  control={control}
                  render={renderBooleanField}
              />
            </Grid>
            <Grid container item xs={12} sm={6} pr={3} alignItems="center">
              <Controller
                  name="first_name"
                  control={control}
                  render={renderStringField}
              />
            </Grid>
            <Grid container item xs={12} sm={6} pr={3} alignItems="center">
              <Controller
                  name="last_name"
                  control={control}
                  render={renderStringField}
              />
            </Grid>
            <Grid container item xs={12} sm={6} pr={3} alignItems="center">
              <Controller
                  name="email"
                  control={control}
                  render={renderStringField}
              />
            </Grid>
            <Grid
                container direction="row" item
                xs={12} sm={6} pr={3}
                pt={canInviteWithoutSaving ? 1.5 : 1}
                alignItems="flex-start"
            >
              {canInviteWithoutSaving ? (
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    disableElevation
                    startIcon={invitePending ? <CircularProgress size={16} color="inherit"/> : undefined}
                    disabled={!canInvite || disabled}
                    onClick={handleInvite}
                >
                  <FormattedMessage id="admin.users.button.invite"/>
                </Button>
              ) : (
                <FormControlLabel
                    control={(
                      <CheckboxButton
                          small
                          label="admin.users.button.invite"
                          checked={canInvite ? invite : false}
                          onChange={toggleInvite}
                          disabled={!canInvite || disabled}
                      />
                    )}
                    label={(
                      <Box
                          pl={1.25}
                          color={!canInvite || disabled ? 'action.disabled' : 'secondary.text'}
                          fontWeight={fontWeightMedium}
                      >
                        <FormattedMessage id="admin.users.button.invite"/>
                      </Box>
                    )}
                />
              )}
            </Grid>
          </Grid>
        </CardSection>
        <CardFooter withDivider>
          <Button
              color="primary"
              variant="outlined"
              disabled={pending}
              onClick={handleClose}
          >
            <FormattedMessage id="common.button.cancel"/>
          </Button>
          <Box width="5%"/>
          <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              startIcon={savePending ? <CircularProgress size={18} color="inherit"/> : undefined}
              disabled={!canSave || pending}
              onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id={userId ? 'admin.users.button.save' : 'admin.users.button.create'}/>
          </Button>
          {userId ? (
            <>
              <Box width="5%"/>
              <Button
                  color="error"
                  variant="outlined"
                  startIcon={deletePending ? <CircularProgress size={18} color="inherit"/> : undefined}
                  disabled={disabled}
                  onClick={handleDelete}
              >
                <FormattedMessage id="admin.users.button.delete"/>
              </Button>
            </>
          ) : undefined}
        </CardFooter>
      </Dialog>
      {userId && confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="admin.users.delete.title"
            text="admin.users.delete.question"
            values={msgValues}
            withCancelButton
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <ActionFailedAlert
          message={userId ? 'admin.users.update.error' : 'admin.users.new.error'}
          open={saveFailed}
      />
      {canInvite ? (
        <ActionFailedAlert
            message="admin.users.invite.error"
            open={inviteFailed}
        />
      ) : undefined}
      {canInvite ? (
        <ActionSucceededMessage
            message="admin.users.invite.success"
            open={inviteSucceeded}
            values={msgValues}
            onOK={closeOnOK ? onClose : undefined}
        />
      ) : undefined}
      {userId ? (
        <ActionFailedAlert
            message="admin.users.delete.error"
            open={deleteFailed}
        />
      ) : undefined}
    </>
  ) : null;
};

AdminUserDialog.propTypes = AdminUserDialogPropTypes;

export default memo(AdminUserDialog);
