import {
  memo, useState, useCallback, useEffect, useContext, useMemo, type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import { HIERARCHY_INTERNAL_QUERY } from '../graphql/HierarchyInternal';
import { HIERARCHY_QUERY } from '../graphql/Hierarchy';
import { COUNTRIES_QUERY } from '../graphql/Countries';
import { STATES_QUERY } from '../graphql/States';
import { EMPLOYEE_SKILL_ADVISORS_QUERY } from '../graphql/EmployeeSkillAdvisors';
import { SEND_MENTORSHIP_REQUEST } from '../graphql/SendMentorshipRequest';
import {
  LookupItem, State, SkillAdvisor, Manager,
  CountriesDocument, StatesDocument,
  EmployeeSkillAdvisorsDocument, EmployeeSkillAdvisorsQueryVariables,
  SendMentorshipRequestDocument
} from '../graphql/types';
import { Job } from '../models/job';
import { Skill } from '../models/skill';
import { Employee } from '../models/employee';
import { getLeaderId } from '../models/user';
import { MAX_COUNTRIES_OPTIONS } from '../config/params';
import { HEIRARCHY_OPTIONS } from '../helpers/graphql';
import useModels, { sanitizeLookup } from '../helpers/models';
import { getSettingsBoolValue, getSettingsIntValue, getSettingsStrValue } from '../helpers/context';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import { getJobAnalyticsData, useMixpanel } from '../context/analytics';
import PaginationControls from '../v3/PaginationControls';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';
// SCSS imports
import { dialogContent } from './EmployeesPopup.module.scss';

const NO_MANAGERS = [] as Manager[];

type EmployeesPopupProps = {
  advisors?: boolean;
  role?: Job | null;
  skill?: Skill | null;
  isInternational?: boolean | null;
  reducedUI?: boolean;
  hrbp?: boolean;
  supervisor?: boolean;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const EmployeesPopupPropTypes = {
  // attributes
  advisors: PropTypes.bool,
  role: PropTypes.object as Validator<Job>,
  skill: PropTypes.object as Validator<Skill>,
  isInternational: PropTypes.bool,
  reducedUI: PropTypes.bool,
  hrbp: PropTypes.bool,
  supervisor: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const EmployeesPopup: FunctionComponent<EmployeesPopupProps> = ({
  advisors = false,
  role,
  skill,
  isInternational = false,
  reducedUI = false,
  hrbp = false,
  supervisor = false,
  isOpen = false,
  onClose
}) => {
  const { getDomesticCountryId, isDomesticCountryId } = useModels();
  const mixpanel = useMixpanel();
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings,
    employeesToConnect: {
      count: employeesCount, data: employeesToConnect,
      pending: pendingEmployees, failed: failedEmployees, params: paramsEmployees
    }, requireEmployeesToConnect
  } = useContext(DataContext);

  const {
    query: getHierarchyInternal, pending: pendingHierarchyInt, failed: failedHierarchyInt, results: hierarchyInt
  } = useQueryObject({
    data: undefined,
    key: 'hierarchyInternal',
    flatResults: true,
    lazyQuery: useLazyQuery(HIERARCHY_INTERNAL_QUERY)
  });
  const { query: getHierarchy, pending: pendingHierarchy, failed: failedHierarchy, results: hierarchy } = useQueryObject({
    data: undefined as unknown as Manager[],
    key: 'readHierarchy',
    flatResults: true,
    lazyQuery: useLazyQuery(HIERARCHY_QUERY, HEIRARCHY_OPTIONS)
  });

  // lazy load countires
  const { query: getCountries, pending: pendingCountries, failed: failedCountries, results: countriesData } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'countries',
    lazyQuery: useLazyQuery(COUNTRIES_QUERY as typeof CountriesDocument)
  });
  // lazy load states
  const { query: getStates, pending: pendingStates, results: statesData } = useQueryCounted({
    data: undefined as unknown as State,
    key: 'states',
    lazyQuery: useLazyQuery(STATES_QUERY as typeof StatesDocument)
  });

  // lazy load skill advisors
  const {
    query: getAdvisors, pending: pendingAdvisors, failed: failedAdvisors,
    count: advisorsCount, results: skillAdvisors, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as SkillAdvisor,
    key: 'employeeSkillAdvisors',
    lazyQuery: useLazyQuery(EMPLOYEE_SKILL_ADVISORS_QUERY as typeof EmployeeSkillAdvisorsDocument)
  });

  const { mutate: sendMentorshipRequest, loading: requestPending, failed: requestFailed } = useMutationMethod({
    key: 'sendMentorshipRequest',
    mutation: useMutation(SEND_MENTORSHIP_REQUEST as typeof SendMentorshipRequestDocument)
  });

  const pending = advisors ? pendingAdvisors : pendingEmployees;
  const failed = advisors ? failedAdvisors : failedEmployees;
  const employees = (advisors ? skillAdvisors : employeesToConnect) as (Employee | SkillAdvisor)[];
  const count = advisors ? advisorsCount : employeesCount;
  const params = advisors ? prevVars : paramsEmployees;

  const withMyOrg = supervisor || (advisors && Boolean(user?.org?.id));
  const isAdminOnly = Boolean(user?.is_admin_only);

  const settingsId = isAdminOnly ? undefined : (advisors && 'employee_advisors') || 'employees_to_connect';
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));
  const settings = settingsId && settingsLoaded ? settingsData : null;

  const settingsCountry = settingsId ? getSettingsIntValue(settings, `${settingsId}__country`) : 0;
  const settingsState = settingsId ? getSettingsIntValue(settings, `${settingsId}__state`) : 0;
  const nameMyOnlySetting = settingsId ? `${settingsId}${advisors ? '__my_org_only' : '__my_team_only'}` : undefined;
  const settingsMyOnly = nameMyOnlySetting ? getSettingsBoolValue(settings, nameMyOnlySetting) : false;
  const settingsManager = settingsId ? getSettingsStrValue(settings, `${settingsId}__manager`) : '';

  const countries = pendingCountries ? null : countriesData;
  const states = pendingStates ? null : statesData;

  const [country, setCountry] = useState<number | undefined>(reducedUI
    ? getDomesticCountryId(countries)
    : sanitizeLookup(settingsCountry, countries)
  );
  const [state, setState] = useState<number>(sanitizeLookup(settingsState, states));
  const [myOnly, setMyOnly] = useState<boolean>(withMyOrg && Boolean(settingsMyOnly));

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();
  const [mounted, setMounted] = useState(isOpen);

  const isDomestic = useMemo(() => isDomesticCountryId(country, countries), [countries, country, isDomesticCountryId]);
  const local = reducedUI || (!hrbp && !isInternational);

  const { id: jsId, title } = role || skill || {};

  const managers = ((advisors || failedHierarchyInt || failedHierarchy) && NO_MANAGERS) ||
    (!pendingHierarchyInt && !pendingHierarchy && hierarchy) || null;
  const defaultManager = settingsManager || '0';
  const [manager, setManager] = useState(defaultManager);

  const [pendingId, setPendingId] = useState<number>();

  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  useEffect(() => {
    if (jsId) {
      // reset Country and State filters if job has changed
      setCountry(0);
      setState(0);
    }
  }, [jsId]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      if (reducedUI) setCountry(getDomesticCountryId(countries));
      else if (settingsId) {
        setCountry(sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__country`), countries));
        setState(sanitizeLookup(getSettingsIntValue(settings, `${settingsId}__state`), states));
        if (nameMyOnlySetting) setMyOnly(withMyOrg && Boolean(getSettingsBoolValue(settings, nameMyOnlySetting)));
        if (!advisors) setManager(getSettingsStrValue(settings, `${settingsId}__manager`) || '0');
      }
    }
  }, [
    settingsLoaded, pendingSettingsUpdate, nameMyOnlySetting,
    advisors, supervisor, withMyOrg, countries, states, settings, reducedUI, settingsId,
    getDomesticCountryId
  ]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && jsId && (advisors ? getAdvisors : requireEmployeesToConnect) && !isNil(pageSize)) {
      const manager_id = (!withMyOrg || !myOnly) && manager && manager !== '0' ? manager : undefined;
      const newParams = {
        [advisors ? 'skill_id' : 'job_id']: jsId,
        ...isDomestic && state ? { state_id: state } : {},
        ...(isDomestic && state) || !country ? {} : { country_id: country },
        ...isNil(manager_id) ? {} : { manager_id },
        ...withMyOrg && myOnly ? { [advisors ? 'my_org_only' : 'my_team_only']: true } : {},
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(params, newParams)) {
        curPage = 1;
        setCurrentPage(1);
      }
      if (advisors) getAdvisors?.({ variables: {
        ...newParams,
        offset: pageSize * (curPage - 1)
      } as EmployeeSkillAdvisorsQueryVariables });
      else requireEmployeesToConnect?.({
        ...newParams,
        offset: pageSize * (curPage - 1)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // do not monitor `params` since it is updated by `getAdvisors`/`requireEmployeesToConnect`
    jsId, isDomestic, state, country, myOnly, manager, pageSize, currentPage, settingsLoaded, withMyOrg, advisors,
    requireEmployeesToConnect, getAdvisors
  ]);

  useEffect(() => {
    getCountries?.({ variables: { ...local ? { local: true } : {}, limit: MAX_COUNTRIES_OPTIONS } });
  }, [local, getCountries]);

  useEffect(() => {
    if (isDomestic && country) getStates?.({ variables: {
      country_id: country,
      limit: MAX_COUNTRIES_OPTIONS
    } });
  }, [country, isDomestic, getStates]);

  useEffect(() => {
    if (!advisors && isOpen && role) mixpanel.track('JobEmployees', getJobAnalyticsData(role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisors, isOpen, mixpanel]); // do not track `role` changes

  useEffect(() => {
    if (!advisors) getHierarchyInternal?.();
  }, [advisors, getHierarchyInternal]);
  useEffect(() => {
    if (!advisors && hierarchyInt) getHierarchy?.();
  }, [advisors, hierarchyInt, getHierarchy]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setCountry(val);
    if (settingsId && settingsCountry !== val) updateSettings?.({ [`${settingsId}__country`]: val });
  }, [countries, settingsCountry, updateSettings, settingsId]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setState(val);
    if (settingsId && settingsState !== val) updateSettings?.({ [`${settingsId}__state`]: val });
  }, [states, settingsState, updateSettings, settingsId]);

  const handleMyOnly = useCallback((value: boolean) => {
    const val = Boolean(value);
    setMyOnly(val);
    if (nameMyOnlySetting && Boolean(settingsMyOnly) !== val) updateSettings?.({ [nameMyOnlySetting]: val });
  }, [settingsMyOnly, updateSettings, nameMyOnlySetting]);

  const handleManager = useCallback((val: string) => {
    setManager(val);
    if (settingsId && settingsManager !== val) updateSettings?.({ [`${settingsId}__manager`]: val });
  }, [settingsManager, updateSettings, settingsId]);

  const handleAction = useMemo(() => advisors ? (id: number) => {
    if (id && jsId && sendMentorshipRequest) {
      setPendingId(id);
      sendMentorshipRequest({
        variables: { input: { skill_id: jsId, mentor_id: id } },
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkillAdvisors' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeAdvisors' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
        },
        onCompleted: () => setPendingId(undefined),
        onError: () => setPendingId(undefined)
      });
    }
  } : undefined, [jsId, advisors, sendMentorshipRequest]);

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> | undefined = useMemo(() => advisors ? {
    avatarVariant: 'advisor',
    withSkills: true,
    withCompletedState: true,
    actionLabel: 'skill.advisors.button.request',
    actionFinishedLabel: 'skill.advisors.button.requested',
    onAction: handleAction,
    actionPendingId: pendingId
  } : undefined, [pendingId, handleAction, advisors]);

  const disabled = pending || failed || !employees || requestPending;
  const disabledFilters = disabled || !settingsLoaded || pendingSettingsUpdate;

  const pagination = (
    <PaginationControls
        settingsId={settingsId}
        loaded={Boolean(mounted && employees)}
        pending={mounted && pending ? true : undefined}
        loading={pending}
        total={count}
        currentPage={currentPage}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={disabled}
    />
  );

  const filters = mounted ? (
    <Box
        pb={failed ? undefined : 3}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-start"
    >
      <Box pr={2}>
        <FilterSelector
            type="country"
            choices={countries}
            value={country || 0}
            onChange={handleCountry}
            disabled={disabledFilters || failedCountries}
        />
      </Box>
      <Box pr={2}>
        <FilterSelector
            type="state"
            choices={isDomestic ? states : undefined}
            value={isDomestic ? state : 0}
            onChange={handleState}
            disabled={disabledFilters || failedCountries || !isDomestic}
        />
      </Box>
      {advisors ? undefined : (
        <Box pr={2}>
          <ChainOfCommandSelector
              uid={defaultManager}
              me={user?.code}
              leader={getLeaderId(user)}
              expandable
              managers={managers}
              value={manager}
              onChange={handleManager}
              disabled={disabledFilters || pendingHierarchyInt || pendingHierarchy || failedHierarchyInt || failedHierarchy ||
                (withMyOrg && myOnly)}
          />
        </Box>
      )}
      {withMyOrg ? (
        <Box py={1} pl={1.5}>
          <OnOffSwitch
              label={advisors ? 'skill.my_org_only' : 'supervisor.my_team_only'}
              value={Boolean(myOnly)}
              onChange={handleMyOnly}
              disabled={disabledFilters ? true : undefined}
          />
        </Box>
      ) : undefined}
    </Box>
  ) : undefined;

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={onClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose}/>
        <CardTitle
            flex
            wrap
            title={(
              <Box mt={2.5} flexGrow="1" display="flex" alignItems="center">
                <FormattedMessage
                    id={(advisors && 'skill.advisors.title') ||
                      (hrbp && 'hr.employees_in_this_title') ||
                      'employees.connect_others_title'}
                    values={{ title }}
                />
              </Box>
            )}
            withDivider
        />
        <DialogContent className={dialogContent}>
          <CardsGrid
              variant="shady"
              items={employees}
              pending={pending}
              failed={failed}
              filters={filters}
              pagination={pagination}
              notFoundMessage={advisors ? 'skill.advisors.not_found' : undefined}
              withoutTopPadding
              component={EmployeeCard}
              ComponentProps={componentProps}
          />
        </DialogContent>
      </Dialog>
      {advisors ? (
        <ActionFailedAlert
            message="skill.advisors.request_error"
            open={requestFailed}
        />
      ) : undefined}
    </>
  ) : pagination;
};

EmployeesPopup.propTypes = EmployeesPopupPropTypes;

export default memo(EmployeesPopup);
