import { memo, useCallback, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
// Skillmore UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import { OPPORTUNITY_MATCHES_QUERY } from '../graphql/OpportunityMatches';
import { OPPORTUNITY_BOOKINGS_QUERY } from '../graphql/OpportunityBookings';
import {
  Opportunity, OpportunityMatch, OpportunityBookingsSort,
  OpportunityBookingsDocument, OpportunityBookingsQueryVariables,
  OpportunityMatchesDocument, OpportunityMatchesQueryVariables
} from '../graphql/types';
import { PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS, PATH_SUPERVISOR_OPPORTUNITY_MATCHES } from '../config/paths';
import { getDefaultLeaderId } from '../models/user';
import { FilterValues } from '../hooks/useFilters';
import { GlobalContext } from '../context/global';
import { managerDashboardParams } from '../context/supervisor';
import { DataContext } from '../context';
import DashboardFilters from '../v3/DashboardFilters';
import OpportunityMatchesPanel from '../panels/OpportunityMatchesPanel';
import OpportunityBookingPanel from '../panels/OpportunityBookingPanel';
// SCSS imports
import { topCaption } from './OpportunityBookingsDialog.module.scss';

type OpportunityBookingsDialogProps = {
  opportunity?: Opportunity | null;
  onClose: () => void;
  readOnly?: boolean;
  // for Strorybook only
  testPending?: boolean;
}

const OpportunityBookingsDialogPropTypes = {
  // attributes
  opportunity: PropTypes.object as Validator<Opportunity>,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  // for Strorybook only
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements
const OpportunityBookingsDialog: FunctionComponent<OpportunityBookingsDialogProps> = ({
  opportunity,
  onClose,
  readOnly = false,
  testPending
}) => {
  const { pathname } = useLocation();
  const { id, title } = opportunity || {};

  const {
    query: getMatches, pending: matchesPending, failed: matchesFailed, count: matchesCount, results: matches,
    variables: matchesVars
  } = useQueryCounted({
    data: undefined as unknown as OpportunityMatch,
    key: 'opportunityMatches',
    lazyQuery: useLazyQuery(OPPORTUNITY_MATCHES_QUERY as typeof OpportunityMatchesDocument)
  });

  const {
    query: getBookings, pending: bookingsPending, failed: bookingsFailed, count: bookingsCount, results: bookings,
    variables: bookingsVars
  } = useQueryCounted({
    data: undefined as unknown as OpportunityMatch,
    key: 'opportunityBookings',
    lazyQuery: useLazyQuery(OPPORTUNITY_BOOKINGS_QUERY as typeof OpportunityBookingsDocument)
  });

  const { user: { data: user } } = useContext(GlobalContext);
  const { settingsUpdate: { pending: pendingSettingsUpdate } } = useContext(DataContext);

  const [matchesPage, setMatchesPage] = useState(1);
  const [matchesPageSize, setMatchesPageSize] = useState<number>();
  const [hideShortlisted, setHideShortlisted] = useState(false);
  const [filters, setFilters] = useState<FilterValues>();

  const [bookingPage, setBookingPage] = useState(1);
  const [bookingPageSize, setBookingPageSize] = useState<number>();

  const uid = useMemo(() => getDefaultLeaderId(user), [user]);

  const managerDashboardParamsFunc = useMemo(managerDashboardParams, []);

  const [matchesLink, bookingsLink] = useMemo(() => [
    (id && injectParams(PATH_SUPERVISOR_OPPORTUNITY_MATCHES, { opp_id: id })) || undefined,
    (id && injectParams(PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS, { opp_id: id })) || undefined
  ], [id]);

  const isMatchesTab = pathname === matchesLink;
  const isBookingsTab = pathname === bookingsLink;

  useEffect(() => {
    if (id && isMatchesTab && matchesPageSize && filters && getMatches) {
      const variables: OpportunityMatchesQueryVariables = {
        opportunity_id: id,
        pathBuilder: pathBuilder as unknown as string,
        input: {
          ...pick(managerDashboardParamsFunc(filters), ['manager_id', 'country_id', 'state_id', 'job_levels']),
          ...hideShortlisted ? { hide_shortlist: true } : {},
          limit: matchesPageSize
        }
      };
      let curPage = matchesPage;
      if (paramsDiffer(matchesVars?.input, variables?.input)) {
        curPage = 1;
        setMatchesPage(1);
      }
      variables.input.offset = matchesPageSize * (curPage - 1);
      getMatches({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `matchesVars` changes:
    id, isMatchesTab, hideShortlisted, filters, matchesPage, matchesPageSize,
    getMatches, managerDashboardParamsFunc
  ]);

  useEffect(() => {
    if (id && isBookingsTab && bookingPageSize && getBookings) {
      const variables: OpportunityBookingsQueryVariables = {
        opportunity_id: id,
        pathBuilder: pathBuilder as unknown as string,
        input: {
          sort_by: OpportunityBookingsSort.match_rate,
          limit: bookingPageSize
        }
      };
      let curPage = bookingPage;
      if (paramsDiffer(bookingsVars?.input, variables?.input)) {
        curPage = 1;
        setBookingPage(1);
      }
      variables.input.offset = bookingPageSize * (curPage - 1);
      getBookings({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, bookingPage, bookingPageSize, isBookingsTab, getBookings]); // ignoring `bookingVars` changes

  const isOpen = isMatchesTab || isBookingsTab;

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const handleFilters = useCallback((newFilters: FilterValues) => setFilters((prevFilters) => {
    if (isEqual(prevFilters, newFilters)) return prevFilters;
    return newFilters;
  }), []);

  const filtersReady = Boolean(filters);
  // const exportDisabled = !filtersReady;
  const disabled = /* exportDisabled || */ pendingSettingsUpdate ||
    (isMatchesTab && matchesPending) || (isBookingsTab && bookingsPending) ||
    testPending ? true : undefined;

  const booking = (
    <OpportunityBookingPanel
        supervisor
        readOnly={readOnly}
        opportunity={opportunity}
        disabled={disabled}
        bookings={bookings}
        count={bookingsCount}
        pending={bookingsPending || testPending}
        failed={bookingsFailed}
        currentPage={bookingPage}
        onPageSelected={setBookingPage}
        onPageSize={setBookingPageSize}
    />
  );

  const items = useMemo(() => readOnly ? undefined : [
    {
      title: 'opportunities.matches.title',
      link: matchesLink
    },
    {
      title: 'opportunities.booking.title',
      link: bookingsLink
    }
  ], [matchesLink, bookingsLink, readOnly]);

  return mounted ? (
    <Dialog
        disableEnforceFocus
        maxWidth="xl"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={disabled ? undefined : onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose} disabled={disabled}/>
      <CardSection top className={topCaption}>
        {title}
      </CardSection>
      {items ? (
        <CardTabbar withoutScroll items={items}>
          <>
            <CardSection top>
              <DashboardFilters
                  settingsId="opp_matches"
                  withLevels
                  withReset
                  onChange={handleFilters}
                  uid={uid}
                  disabled={disabled}
                  // action={ ... exportDisabled ...}
                  extraFilters={
                    <Box pl={2} pb={1} display="flex" justifyContent="flex-start">
                      <OnOffSwitch
                          label="opportunities.matches.hide_shortlist"
                          value={Boolean(hideShortlisted)}
                          onChange={setHideShortlisted}
                          disabled={disabled}
                      />
                    </Box>
                  }
              />
            </CardSection>
            <OpportunityMatchesPanel
                opportunity={opportunity}
                disabled={disabled}
                matches={matches}
                count={matchesCount}
                pending={!filtersReady || matchesPending || testPending}
                failed={matchesFailed}
                currentPage={matchesPage}
                onPageSelected={setMatchesPage}
                onPageSize={setMatchesPageSize}
            />
          </>
          {booking}
        </CardTabbar>
      ) : (
        <>
          <CardTitle title="opportunities.booking.title" withDivider/>
          {booking}
        </>
      )}
    </Dialog>
  ) : null;
};

OpportunityBookingsDialog.propTypes = OpportunityBookingsDialogPropTypes;

export default memo(OpportunityBookingsDialog);
