import { forwardRef, memo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// Skillmore UI Components
import { bold } from '@empathco/ui-components/src/helpers/intl';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
// local imports
import { DevPlanProgress, DevPlanDetails, EmployeeDevPlanProgress, DevPlanTarget } from '../graphql/customTypes';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import EmployeeProgressChart from '../widgets/EmployeeProgressChart';

type EmployeeProgressDetailsProps = {
  target: DevPlanTarget | null;
  individual?: boolean;
  isMyPlan?: boolean;
  active?: boolean | null;
  employee: DevPlanProgress | EmployeeDevPlanProgress;
  devplan: DevPlanDetails;
  route?: string | null;
}

const EmployeeProgressDetailsPropTypes = {
  target: PropTypes.string as Validator<DevPlanTarget>,
  individual: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  active: PropTypes.bool,
  employee: PropTypes.object.isRequired as Validator<DevPlanProgress>,
  devplan: PropTypes.object as Validator<DevPlanDetails>,
  route: PropTypes.string
};

// eslint-disable-next-line complexity
const EmployeeProgressDetails = forwardRef<Component<BoxProps>, EmployeeProgressDetailsProps>(({
  target,
  individual = false,
  isMyPlan = false,
  active = false,
  employee,
  devplan,
  route
}, ref) => {
  const { getEmployeeLocationStr } = useModels();
  const isActive = Boolean(!individual || active);
  const chart = (
    <Box
        ref={individual || isMyPlan ? ref : undefined}
        flexGrow={1}
        py={individual ? 2 : undefined}
    >
      <BoxTypography
          variant="h6"
          pl={individual ? 1 : 8.5}
          pr={individual ? undefined : 5}
          pb={individual ? 2 : undefined}
      >
        <FormattedMessage
            id={isActive ? 'hr.dev_plan.chart.title' : 'hr.dev_plan.chart.inactive'}
            values={{ target, title: devplan?.job?.title || devplan?.opportunity?.title || '' }}
        />
      </BoxTypography>
      <EmployeeProgressChart
          initial={isActive
            ? employee?.initial_match_rate
            : employee?.current_match_rate || employee?.initial_match_rate}
          current={isActive
            ? employee?.current_match_rate
            : null}
      />
    </Box>
  );

  if (individual || isMyPlan) return chart;

  // here we are only working with HRBP Dev Plan data:
  const { current_job, location, is_devplan_active } = (employee as DevPlanProgress) || {};
  const { title } = current_job || {};
  const { title: devplanTitle, cohort } = devplan || {};
  const { title: cohortTitle } = cohort || {};
  return (
    <Box
        ref={ref}
        pt={individual ? 4 : undefined}
        pb={1}
        display="flex"
        alignItems="flex-start"
        flexWrap="wrap"
    >
      <Box color="misc.selectedBorder">
        <AccountCircleAlt fontSize="large" color="inherit"/>
      </Box>
      <Box flexGrow={1} p={0.75}>
        <EmployeeName
            variant="h5"
            employee={employee as DevPlanProgress}
            route={route}
            manager
        />
        <BoxTypography variant="body2">
          {title}
        </BoxTypography>
        <BoxTypography variant="body2" pb={2}>
          {getEmployeeLocationStr(current_job?.location, location) || <FormattedMessage id="employees.not_available"/>}
        </BoxTypography>
        {cohortTitle ? (
          <BoxTypography variant="body2" pb={0.5}>
            <FormattedMessage
                id="hr.dev_plan.cohort"
                values={{ title: cohortTitle, bold }}
            />
          </BoxTypography>
        ) : undefined}
        {devplanTitle ? (
          <BoxTypography variant="body2" pb={0.5}>
            <FormattedMessage
                id="hr.dev_plan.dev_plan"
                values={{ title: devplanTitle, bold }}
            />
          </BoxTypography>
        ) : undefined}
        <BoxTypography
            pb={0.5}
            variant="body2"
            display="flex"
            alignItems="center"
        >
          <FormattedMessage
              id="hr.dev_plan.status"
              values={{ status: <TagLabel variant={is_devplan_active ? 'active' : 'inactive'} small/> }}
          />
        </BoxTypography>
      </Box>
      {chart}
    </Box>
  );
});

EmployeeProgressDetails.displayName = 'EmployeeProgressDetails';

EmployeeProgressDetails.propTypes = EmployeeProgressDetailsPropTypes;

export default memo(EmployeeProgressDetails);
