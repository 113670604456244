import { memo, useLayoutEffect, useMemo, type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// Skillmore UI Components
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
// local imports
import { getDelegator } from '../models/user';
import { REDEPLOYMENT_PLAN_QUERY } from '../graphql/RedeploymentPlan';
import { RedeploymentPlanDocument } from '../graphql/types';
import { RedeploymentDetails } from '../graphql/customTypes';
import { getPlanId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import LeaderHeader from '../elements/LeaderHeader';
import Screen from '../v3/Screen';
import RedeploymentPlansPanel from '../panels/RedeploymentPlansPanel';
import RedeploymentPlanPanel from '../panels/RedeploymentPlanPanel';

const HRRedeploymentScreen: FunctionComponent = () => {
  const params = useParams();
  const plan_id = getPlanId(params);

  const { content, user } = useVerifyUser({ hr: true });
  const leader = getDelegator(user);

  // lazy load redeployment plan
  const { query: getRedeploymentPlan, pending, failed, results: plan } = useQueryObject({
    data: undefined as unknown as RedeploymentDetails,
    key: 'redeploymentPlan',
    flatResults: true,
    lazyQuery: useLazyQuery(REDEPLOYMENT_PLAN_QUERY as typeof RedeploymentPlanDocument)
  });

  useLayoutEffect(() => {
    if (!content && plan_id) getRedeploymentPlan?.({ variables: { plan_id } });
  }, [content, plan_id, getRedeploymentPlan]);

  const loadedPlan = Boolean(plan_id) && plan_id === plan?.id;

  const breadcrumbs = useMemo(() => loadedPlan ? [{ title: plan.title }] : undefined, [loadedPlan, plan]);

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      {leader ? <LeaderHeader leader={leader}/> : undefined}
      <ContentCard withoutTopMargin={Boolean(leader)}>
        {plan_id ? (
          <RedeploymentPlanPanel
              plan={plan}
              pending={pending}
              failed={failed}
          />
        ) : (
          <RedeploymentPlansPanel/>
        )}
      </ContentCard>
    </Screen>
  );
};

export default memo(HRRedeploymentScreen);
