import { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import last from 'lodash/last';
import range from 'lodash/range';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
// Skillmore UI Components
import { getCurrentQuarter, getFormattedQuarterFrom } from '@empathco/ui-components/src/helpers/datetime';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { setEChartInstanceOption } from '@empathco/ui-components/src/helpers/echarts';
import { svgPath as RadioButtonFilled } from '@empathco/ui-components/src/icons/RadioButtonFilled';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { SKILL_LEVEL_MIN, SKILL_LEVEL_MAX, SkillLevel } from '../models/skill';
import { SKILL_DEV_PLAN_QUARTERS, GlobalEChartsStyles } from '../config/params';
import Chart from '../elements/Chart';
// SCSS imports
import { chart } from './SkillGrowthChart.module.scss';

function getGrowthData(
  level: SkillLevel,
  organic?: (number | null | undefined)[] | null,
  accelerated?: (number | null | undefined)[] | null
) {
  return transform(range(SKILL_LEVEL_MIN, SKILL_LEVEL_MAX), (result, index) => {
    const lvl = index + 1;
    if (lvl > level) {
      const acceleration = toSafeInteger(accelerated?.[index]);
      if (acceleration >= 1) {
        result.hasAcceleration = true;
        result.isCourse[result.data.length] = true;
      }
      const value = acceleration || toSafeInteger(organic?.[index]);
      if (value >= 1) {
        result.sum += value;
        result.data.push([result.sum, lvl]);
      }
    }
  }, {
    data: [[0, level]],
    sum: 0,
    hasAcceleration: false,
    isCourse: [] as (boolean | undefined)[]
  });
}

// eslint-disable-next-line max-params
function getMarkers(
  level: SkillLevel,
  data: number[][],
  bgMarkerStyle: unknown,
  fgMarkerStyle: unknown,
  dkMarkerStyle: unknown,
  outerMarkerStyle?: unknown,
  isCourse?: (boolean | undefined)[]
) {
  return transform(data, (result, coord, index) => {
    if (index < 1 || (coord[0] <= SKILL_DEV_PLAN_QUARTERS && coord[1] > level)) {
      if (isCourse?.[index]) {
        result.push({
          coord,
          symbolSize: 36,
          symbol: RadioButtonFilled,
          itemStyle: outerMarkerStyle
        });
        result.push({
          coord,
          symbolSize: 20,
          symbol: RadioButtonFilled,
          itemStyle: bgMarkerStyle
        });
        result.push({
          coord,
          symbolSize: 10,
          symbol: RadioButtonFilled,
          itemStyle: dkMarkerStyle
        });
      } else if (index === 0) {
        result.push({
          coord,
          symbolSize: 13,
          symbol: RadioButtonFilled,
          itemStyle: fgMarkerStyle
        });
        result.push({
          coord,
          symbolSize: 9,
          symbol: RadioButtonFilled,
          itemStyle: bgMarkerStyle
        });
      } else result.push({
        coord,
        symbolSize: 10,
        symbol: 'circle'
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [] as any[]);
}

type SkillGrowthChartProps = {
  level: SkillLevel;
  selectedCourse?: string | null;
  accelerated?: (number | null | undefined)[] | null;
  organic?: (number | null | undefined)[] | null;
};

const SkillGrowthChartPropTypes = {
  level: PropTypes.number.isRequired as Validator<SkillLevel>,
  selectedCourse: PropTypes.string,
  accelerated: PropTypes.array,
  organic: PropTypes.array
};

const SkillGrowthChart = forwardRef<EChartsReactCore, SkillGrowthChartProps>(({
  level,
  selectedCourse,
  accelerated,
  organic
}, ref) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [levelText, timelineText, organicText, quarterFormat] = useMemo(() => [
    formatMessage({ id: 'skill.growth.level' }),
    formatMessage({ id: 'skill.growth.timeline' }),
    formatMessage({ id: 'skill.growth.organic' }),
    formatMessage({ id: 'skill.growth.quarter_format' })
  ], [formatMessage]);

  const timelineLabels = useMemo(() => {
    const currentQuarter = getCurrentQuarter();
    return map(range(SKILL_DEV_PLAN_QUARTERS + 1),
      (quarter) => getFormattedQuarterFrom(currentQuarter, quarter, quarterFormat));
  }, [quarterFormat]);

  const acceleratedText = useMemo(() => formatMessage({
    id: selectedCourse ? 'skill.growth.accelerated.one' : 'skill.growth.accelerated.many'
  }, {
    course: selectedCourse
  }), [formatMessage, selectedCourse]);

  const [organicData, organicMarkers, isAccelerated, acceleratedData, acceleratedMarkers] = useMemo(() => {
    const { data } = getGrowthData(level, organic);
    if (size(data) <= 1) data.push([SKILL_DEV_PLAN_QUARTERS, level]);
    const getOrganicMarkers = () => getMarkers(
      level,
      data,
      { color: theme.palette.background.paper },
      { color: theme.palette.primary.light },
      { color: theme.palette.chart.primaryDot }
    );
    if (!accelerated) return [data, getOrganicMarkers(), false, [], []];
    const { data: accelData, hasAcceleration, isCourse } = getGrowthData(level, organic, accelerated);
    if (!hasAcceleration || size(accelData) <= 1) return [data, getOrganicMarkers(), false, [], []];

    const lastPoint = last(accelData);
    if ((lastPoint?.[0] || 0) < SKILL_DEV_PLAN_QUARTERS) {
      accelData.push([SKILL_DEV_PLAN_QUARTERS, lastPoint?.[1] || level]);
    }
    return [
      data,
      getMarkers(
        level,
        data,
        { color: theme.palette.background.paper },
        { color: theme.palette.primary.light },
        { color: theme.palette.chart.primaryDot },
        { color: theme.palette.chart.primaryLucid },
        isCourse
      ),
      true,
      accelData,
      getMarkers(
        level,
        accelData,
        { color: theme.palette.background.paper },
        { color: theme.palette.info.light },
        { color: theme.palette.chart.infoDot },
        { color: theme.palette.chart.infoLucid },
        isCourse
      )
    ];
  }, [level, accelerated, organic, theme]);

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    const nonZeroFinal = (last(organicData)?.[1] || 0) >= 1;

    setEChartInstanceOption(echartInstance, {
      ...GlobalEChartsStyles,
      grid: {
        show: true,
        top: 30,
        bottom: 68,
        left: 40,
        right: 25,
        borderColor: 'transparent',
        backgroundColor: theme.palette.background.card
      },
      xAxis: {
        show: true,
        type: 'value',
        min: 0,
        max: SKILL_DEV_PLAN_QUARTERS,
        minInterval: 0.5,
        maxInterval: 0.5,
        interval: 0.5,
        name: timelineText,
        nameLocation: 'end',
        nameTextStyle: {
          align: 'right',
          verticalAlign: 'top',
          padding: [15, 25, 0, 0],
          fontSize: 16,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.text.label
        },
        axisTick: false,
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: (value: number) => value >= SKILL_DEV_PLAN_QUARTERS - 1 ? '' : timelineLabels[value - 0.5],
          margin: 15,
          fontSize: 16
        },
        splitLine: {
          show: true,
          interval: 0,
          lineStyle: {
            width: theme.shape.thickBorderWidth,
            color: [
              theme.palette.background.paper,
              'transparent'
            ]
          }
        }
      },
      yAxis: {
        show: true,
        type: 'value',
        min: SKILL_LEVEL_MIN,
        max: SKILL_LEVEL_MAX + 1,
        name: levelText,
        nameLocation: 'end',
        nameTextStyle: {
          padding: [0, -55, -10, 100],
          fontSize: 16,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.text.label
        },
        axisTick: false,
        axisLine: {
          show: false
        },
        axisLabel: {
          showMaxLabel: false,
          padding: [0, 5, 0, 0],
          fontSize: 16
        },
        splitLine: {
          show: true,
          interval: 0,
          lineStyle: {
            width: theme.shape.thickBorderWidth,
            color: theme.palette.background.paper
          }
        }
      },
      series: [
        ...isAccelerated ? [
          {
            silent: true,
            name: 'accelerated',
            type: 'line',
            data: acceleratedData,
            itemStyle: {
              color: theme.palette.info.light
            },
            lineStyle: {
              type: [spacing(0.75), spacing(0.25)],
              dashOffset: spacing(0.325)
            },
            markPoint: {
              symbolSize: 20,
              symbolKeepAspect: true,
              data: acceleratedMarkers
            },
            endLabel: {
              show: true,
              formatter: acceleratedText,
              color: theme.palette.info.light,
              align: 'right',
              verticalAlign: 'bottom',
              padding: 16,
              fontSize: 16,
              fontWeight: theme.typography.fontWeightMedium
            }
          }
        ] : [],
        {
          silent: true,
          name: 'organic',
          type: 'line',
          data: organicData,
          itemStyle: {
            color: theme.palette.primary.main
          },
          markPoint: {
            symbolSize: 20,
            symbolKeepAspect: true,
            data: organicMarkers
          },
          endLabel: {
            show: true,
            formatter: organicText,
            color: theme.palette.secondary.main,
            align: 'right',
            verticalAlign: nonZeroFinal ? 'top' : 'bottom',
            padding: [30, 16, 16, 16],
            fontSize: 16,
            fontWeight: theme.typography.fontWeightMedium
          }
        }
      ]
    });
  }, [
    acceleratedData, acceleratedMarkers, isAccelerated, organicData, organicMarkers,
    timelineLabels, acceleratedText, levelText, organicText, timelineText, theme
  ]);

  return <Chart ref={chartRef} option={GlobalEChartsStyles} className={chart}/>;
});

SkillGrowthChart.displayName = 'SkillGrowthChart';

SkillGrowthChart.propTypes = SkillGrowthChartPropTypes;

export default memo(SkillGrowthChart);
