/* eslint-disable max-lines */
import { memo, useCallback, useEffect, useMemo, useState, useContext, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
// Skillmore UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
// local imports
import { DA_SKILL_SUPPLY_QUERY } from '../graphql/DASkillSupply';
import { DA_EMPLOYEE_SKILLS_QUERY } from '../graphql/DAEmployeeSkills';
import { DA_SKILLS_GROWTH_QUERY } from '../graphql/DASkillsGrowth';
import { DA_SKILLS_DEVELOPMENT_QUERY } from '../graphql/DASkillsDevelopment';
import { DA_UPSKILLING_VELOCITY_QUERY } from '../graphql/DAUpskillingVelocity';
import { DA_EFFECTIVE_COURSES_QUERY } from '../graphql/DAMostEffectiveCourses';
import { DA_COURSE_COMPLETION_QUERY } from '../graphql/DACourseCompletion';
import { DA_EFFECTIVE_ADVISORS_QUERY } from '../graphql/DAMostEffectiveAdvisors';
import { DA_SKILL_COURSE_MAPPING_QUERY } from '../graphql/DASkillCourseMapping';
import {
  SkillType, SkillOrCourse, SQLSortDirection,
  DASkillSupplyDocument, DASkillSupplyQuery,
  DAEmployeeSkillsDocument, DAEmployeeSkillsQuery,
  DASkillsGrowthDocument, DASkillsGrowthQuery,
  DASkillsDevelopmentDocument, DASkillsDevelopmentQuery,
  DAUpskillingVelocityDocument, DAUpskillingVelocityQuery,
  DAMostEffectiveCoursesDocument, DAMostEffectiveCoursesQuery,
  DACourseCompletionDocument, DACourseCompletionQuery,
  DAMostEffectiveAdvisorsDocument, DAMostEffectiveAdvisorsQuery,
  DASkillCourseMappingDocument, DASkillCourseMappingQuery
} from '../graphql/types';
import { COURSE_COMPLETION_DIRCTION, SKILL_OR_COURSE } from '../graphql/customTypes';
import { CONST_MIN_SKILLS, toValidConst } from '../constants/constValues';
import { SkillsDevelopmentMethod, SKILLS_DEVELOPMENT_METHODS } from '../constants/skillsDevelopment';
import {
  DA_EFFECTIVE_COURSES, DA_COURSE_COMPLETION, DA_EFFECTIVE_ADVISORS, DA_EMPLOYEE_SKILLS, DA_SKILLS_DEVELOPMENT,
  DA_UPSKILLING_VELOCITY, DA_SKILLS_GROWTH, DA_SKILL_COURSE_MAPPING, DA_SKILL_SUPPLY
} from '../constants/dashboardAnalytics';
import useCustomerSettings from '../config/customer';
import {
  API_DA_EFFECTIVE_COURSES_EXPORT, API_DA_COURSE_COMPLETION_EXPORT, API_DA_EFFECTIVE_ADVISORS_EXPORT,
  API_DA_EMPLOYEE_SKILLS_EXPORT, API_DA_SKILLS_DEVELOPMENT_EXPORT, // API_DA_UPSKILLING_VELOCITY_EXPORT,
  API_DA_SKILLS_GROWTH_EXPORT, API_DA_SKILL_COURSE_EXPORT, API_DA_SKILL_SUPPLY_EXPORT
} from '../config/api';
import { PATH_SKILL } from '../config/paths';
import { FilterValues } from '../hooks/useFilters';
import { GlobalContext } from '../context/global';
import ConstSelector from '../elements/ConstSelector';
import SortSelector from '../elements/SortSelector';
import TopChart, { TopChartProps } from '../widgets/TopChart';

type DashboardAnalyticsSkillsProps = {
  chartId: number;
  filters?: FilterValues;
  skillType: SkillType;
  years: number;
  pending?: boolean | null;
  disabled?: boolean | null;
  onEmployeeCountChange?: (value: number | null | undefined) => void;
  pinned?: number[];
  onPin: (value: number) => void;
  pinning?: boolean;
}

const DashboardAnalyticsSkillsPropTypes = {
  chartId: PropTypes.number.isRequired,
  filters: PropTypes.object as Validator<FilterValues>,
  skillType: PropTypes.string.isRequired as Validator<SkillType>,
  years: PropTypes.number.isRequired,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  onEmployeeCountChange: PropTypes.func,
  pinned: PropTypes.array,
  onPin: PropTypes.func.isRequired,
  pinning: PropTypes.bool
};

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const DashboardAnalyticsSkills: FunctionComponent<DashboardAnalyticsSkillsProps> = ({
  chartId,
  filters,
  skillType,
  years,
  pending: parentPending = false,
  disabled = false,
  onEmployeeCountChange,
  pinned,
  onPin,
  pinning = false
}) => {
  const { DA_TABS, HAS_MENTORING, HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { fontsLoaded, token } = useContext(GlobalContext);
  const pending = parentPending || !fontsLoaded;

  // lazy load Skill Supply/Demand data
  const {
    query: getSkillSupply, pending: pendingSkillSupply, failed: failedSkillSupply, results: skillSupply
  } = useQueryObject({
    data: undefined as unknown as DASkillSupplyQuery['daSkillSupply'],
    key: 'daSkillSupply',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILL_SUPPLY_QUERY as typeof DASkillSupplyDocument)
  });

  // lazy load Employee Skills data
  const {
    query: getEmployeeSkills, pending: pendingEmployeeSkills, failed: failedEmployeeSkills, results: employeeSkills
  } = useQueryObject({
    data: undefined as unknown as DAEmployeeSkillsQuery['daEmployeeSkills'],
    key: 'daEmployeeSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_EMPLOYEE_SKILLS_QUERY as typeof DAEmployeeSkillsDocument)
  });

  // lazy load Skills Growth data
  const {
    query: getSkillsGrowth, pending: pendingSkillsGrowth, failed: failedSkillsGrowth, results: skillsGrowth
  } = useQueryObject({
    data: undefined as unknown as DASkillsGrowthQuery['daSkillsGrowth'],
    key: 'daSkillsGrowth',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILLS_GROWTH_QUERY as typeof DASkillsGrowthDocument)
  });

  // lazy load Skills Development data
  const {
    query: getSkillsDevelopment, pending: pendingSkillsDevelopment, failed: failedSkillsDevelopment, results: skillsDevelopment
  } = useQueryObject({
    data: undefined as unknown as DASkillsDevelopmentQuery['daSkillsDevelopment'],
    key: 'daSkillsDevelopment',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILLS_DEVELOPMENT_QUERY as typeof DASkillsDevelopmentDocument)
  });

  // lazy load Upskilling Velocity data
  const {
    query: getUpskilling, pending: pendingUpskilling, failed: failedUpskilling, results: upskilling
  } = useQueryObject({
    data: undefined as unknown as DAUpskillingVelocityQuery['daUpskillingVelocity'],
    key: 'daUpskillingVelocity',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_UPSKILLING_VELOCITY_QUERY as typeof DAUpskillingVelocityDocument)
  });

  // lazy load Most Effective Courses data
  const {
    query: getEffectiveCourses, pending: pendingEffectiveCourses, failed: failedEffectiveCourses, results: effectiveCourses
  } = useQueryObject({
    data: undefined as unknown as DAMostEffectiveCoursesQuery['daMostEffectiveCourses'],
    key: 'daMostEffectiveCourses',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_EFFECTIVE_COURSES_QUERY as typeof DAMostEffectiveCoursesDocument)
  });

  // lazy load Course Completion data
  const {
    query: getCourseCompletion, pending: pendingCourseCompletion, failed: failedCourseCompletion, results: courseCompletion
  } = useQueryObject({
    data: undefined as unknown as DACourseCompletionQuery['daCourseCompletion'],
    key: 'daCourseCompletion',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_COURSE_COMPLETION_QUERY as typeof DACourseCompletionDocument)
  });

  // lazy load Most Effective Advisors data
  const {
    query: getEffectiveAdvisors, pending: pendingEffectiveAdvisors, failed: failedEffectiveAdvisors, results: effectiveAdvisors
  } = useQueryObject({
    data: undefined as unknown as DAMostEffectiveAdvisorsQuery['daMostEffectiveAdvisors'],
    key: 'daMostEffectiveAdvisors',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_EFFECTIVE_ADVISORS_QUERY as typeof DAMostEffectiveAdvisorsDocument)
  });

  // lazy load Skill-Course Mapping data
  const {
    query: getSkillCourse, pending: pendingSkillCourse, failed: failedSkillCourse, results: skillCourse
  } = useQueryObject({
    data: undefined as unknown as DASkillCourseMappingQuery['daSkillCourseMapping'],
    key: 'daSkillCourseMapping',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILL_COURSE_MAPPING_QUERY as typeof DASkillCourseMappingDocument)
  });

  const [confirmedOnly, setConfirmedOnly] = useState(false);
  const [minSkills, setMinSkills] = useState<number>(CONST_MIN_SKILLS[1]);
  // TODO: employee_threshold for Most Effective Courses?
  const [method, setMethod] = useState<SkillsDevelopmentMethod>(SKILLS_DEVELOPMENT_METHODS[HAS_MENTORING ? 0 : 1]);
  const [skillOrCourse, setSkillOrCourse] = useState(SKILL_OR_COURSE[0]);
  const [completionDirection, setCompletionDirection] = useState(COURSE_COMPLETION_DIRCTION[0]);

  const handleMinSkillsChange = useCallback((event: SelectChangeEvent<number>) =>
    setMinSkills(toValidConst(event?.target?.value, CONST_MIN_SKILLS[1], CONST_MIN_SKILLS)), []);

  useEffect(() => {
    onEmployeeCountChange?.({
      [DA_SKILL_SUPPLY]: !failedSkillSupply && isNil(skillSupply?.total_employees) ? null : skillSupply?.total_employees,
      [DA_EMPLOYEE_SKILLS]: !failedEmployeeSkills && isNil(employeeSkills?.total_employees)
        ? null : employeeSkills?.total_employees,
      [DA_SKILLS_GROWTH]: !failedSkillsGrowth && isNil(skillsGrowth?.total_employees) ? null : skillsGrowth?.total_employees,
      [DA_SKILLS_DEVELOPMENT]: !failedSkillsDevelopment && isNil(skillsDevelopment?.total_employees)
        ? null : skillsDevelopment?.total_employees,
      [DA_EFFECTIVE_COURSES]: !failedEffectiveCourses && isNil(effectiveCourses?.total_employees)
        ? null : effectiveCourses?.total_employees,
      [DA_COURSE_COMPLETION]: !failedCourseCompletion && isNil(courseCompletion?.total_employees)
        ? null : courseCompletion?.total_employees,
      [DA_EFFECTIVE_ADVISORS]: !failedEffectiveAdvisors && isNil(effectiveAdvisors?.total_employees)
        ? null : effectiveAdvisors?.total_employees,
      [DA_UPSKILLING_VELOCITY]: !failedUpskilling && isNil(upskilling?.total_employees) ? null : upskilling?.total_employees
      }[chartId]);
  }, [
    chartId,
    skillSupply?.total_employees, failedSkillSupply,
    employeeSkills?.total_employees, failedEmployeeSkills,
    skillsGrowth?.total_employees, failedSkillsGrowth,
    skillsDevelopment?.total_employees, failedSkillsDevelopment,
    effectiveCourses?.total_employees, failedEffectiveCourses,
    courseCompletion?.total_employees, failedCourseCompletion,
    effectiveAdvisors?.total_employees, failedEffectiveAdvisors,
    upskilling?.total_employees, failedUpskilling,
    onEmployeeCountChange
  ]);

  const employeeSkillsParams = useMemo(() => ({ years }), [years]);

  const optionalParams: TopChartProps['optionalParams'] = useMemo(() => ({ withoutInDemandSkills: !HAS_INDEMAND_SKILLS }),
    [HAS_INDEMAND_SKILLS]);

  const [
    exportParams,
    skillSupplyExportParams,
    employeeSkillsExportParams,
    skillsGrowthDevExportParams,
    // upskillingExportParams,
    effectiveCoursesExportParams,
    courseCompletionExportParams,
    skillCourseExportParams
  ] = useMemo(() => {
    if (!filters || !token || !isString(token)) return [null, null, null, null];
    const params: FilterValues & { token: string; } = { ...filters, token };
    return [
      // Most Effective Advisors
      params,
      // Skill Supply/Demand
      { ...params,
        skill_type: skillType, ...confirmedOnly && skillType === SkillType.indemand ? { confirmed_only: true } : {} },
      // Employee Skills
      { ...params, skill_threshold: minSkills },
      // Skills Growth + Skills Development
      { ...params, skill_type: skillType, duration: 12 * years },
      // Upskilling Velocity
      // { ...params, skill_type: skillType },
      // Most Effective Courses
      { ...params, skill_type: skillType },
      // Course Completion
      {
        ...params,
        skill_type: skillType,
        ...completionDirection === SQLSortDirection.ASC ? { direction: SQLSortDirection.ASC } : {}
      },
      // Skill To Course Mapping
      { ...params, skill_or_course: skillOrCourse }
    ];
  }, [confirmedOnly, minSkills, skillOrCourse, completionDirection, years, filters, skillType, token]);

  // load Skill Supply/Demand
  useEffect(() => {
    if (chartId === DA_SKILL_SUPPLY && DA_TABS?.skill_supply_demand && filters?.org_id) getSkillSupply?.({ variables: {
      input: {
        ...filters,
        skill_type: skillType,
        ...confirmedOnly && skillType === SkillType.indemand ? { confirmed_only: true } : {}
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillType, confirmedOnly, getSkillSupply, DA_TABS?.skill_supply_demand]);

  // load Employee Skills
  useEffect(() => {
    if (
      chartId === DA_EMPLOYEE_SKILLS && DA_TABS?.employees_skills && filters && minSkills
    ) getEmployeeSkills?.({ variables: {
      input: {
        ...filters,
        skill_threshold: minSkills
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, minSkills, getEmployeeSkills, DA_TABS?.employees_skills]);

  // load Skills Growth
  useEffect(() => {
    if (
      chartId === DA_SKILLS_GROWTH && HAS_INDEMAND_SKILLS && DA_TABS?.skills_growth && filters
    ) getSkillsGrowth?.({ variables: {
      input: {
        ...filters,
        skill_type: skillType,
        duration: 12 * years
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillType, years, getSkillsGrowth, HAS_INDEMAND_SKILLS, DA_TABS?.skills_growth]);

  // load Skills Development
  useEffect(() => {
    if (
      chartId === DA_SKILLS_DEVELOPMENT && HAS_INDEMAND_SKILLS && (HAS_MENTORING || HAS_COURSES_READONLY) &&
      DA_TABS?.skills_development && filters
    ) getSkillsDevelopment?.({ variables: {
      input: {
        ...filters,
        skill_type: skillType,
        duration: 12 * years
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [
    chartId, filters, skillType, years, getSkillsDevelopment,
    HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING, DA_TABS?.skills_development
  ]);

  // load Upskilling Velocity
  useEffect(() => {
    if (chartId === DA_UPSKILLING_VELOCITY && DA_TABS?.upskilling_velocity && filters) getUpskilling?.({ variables: {
      input: { ...filters, skill_type: skillType },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillType, getUpskilling, DA_TABS?.upskilling_velocity]);

  // load Most Effective Courses
  useEffect(() => {
    if (
      chartId === DA_EFFECTIVE_COURSES && HAS_COURSES_READONLY && DA_TABS?.most_effective_courses && filters
    ) getEffectiveCourses?.({ variables: {
      input: {
        ...filters,
        skill_type: skillType
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillType, getEffectiveCourses, HAS_COURSES_READONLY, DA_TABS?.most_effective_courses]);

  // load Course Completion
  useEffect(() => {
    if (
      chartId === DA_COURSE_COMPLETION && HAS_COURSES_READONLY && DA_TABS?.course_completion && filters
    ) getCourseCompletion?.({ variables: {
      input: {
        ...filters as Omit<FilterValues, 'direction'>,
        skill_type: skillType,
        ...completionDirection === SQLSortDirection.ASC ? { direction: SQLSortDirection.ASC } : {}
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillType, completionDirection, getCourseCompletion, HAS_COURSES_READONLY, DA_TABS?.course_completion]);

  // load Most Effective Advisors
  useEffect(() => {
    if (
      chartId === DA_EFFECTIVE_ADVISORS && HAS_MENTORING && DA_TABS?.most_effective_advisors && filters
    ) getEffectiveAdvisors?.({ variables: {
      input: filters,
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, getEffectiveAdvisors, HAS_MENTORING, DA_TABS?.most_effective_advisors]);

  // load Skill To Course Mapping
  useEffect(() => {
    if (
      chartId === DA_SKILL_COURSE_MAPPING && HAS_COURSES_READONLY && DA_TABS?.skill_course_mapping && filters
    ) getSkillCourse?.({ variables: {
      input: {
        ...filters,
        skill_or_course: skillOrCourse
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, skillOrCourse, getSkillCourse, HAS_COURSES_READONLY, DA_TABS?.skill_course_mapping]);

  const handlePinSkillSupply = useCallback(() => onPin(DA_SKILL_SUPPLY), [onPin]);
  const handlePinEmployeeSkills = useCallback(() => onPin(DA_EMPLOYEE_SKILLS), [onPin]);
  const handlePinSkillsGrowth = useCallback(() => onPin(DA_SKILLS_GROWTH), [onPin]);
  const handlePinSkillsDevelopment = useCallback(() => onPin(DA_SKILLS_DEVELOPMENT), [onPin]);
  const handlePinEffectiveCourses = useCallback(() => onPin(DA_EFFECTIVE_COURSES), [onPin]);
  const handlePinCourseCompletion = useCallback(() => onPin(DA_COURSE_COMPLETION), [onPin]);
  const handlePinEffectiveAdvisors = useCallback(() => onPin(DA_EFFECTIVE_ADVISORS), [onPin]);
  const handlePinSkillCourse = useCallback(() => onPin(DA_SKILL_COURSE_MAPPING), [onPin]);
  const handlePinUpskilling = useCallback(() => onPin(DA_UPSKILLING_VELOCITY), [onPin]);

  const exportDisabled = disabled || pending || !filters;

  return (
    <>
      {chartId === DA_SKILL_SUPPLY && DA_TABS?.skill_supply_demand ? (
        <TopChart
            variant="supply_demand"
            layout="full"
            info="hr.dashboard.supply_demand.info"
            withInfoButton
            hrbp
            data={skillSupply?.skills || (filters?.org_id || pendingSkillSupply ? null : [])}
            totalEmployees={skillSupply?.total_employees}
            path={PATH_SKILL}
            pending={pendingSkillSupply || pending}
            failed={failedSkillSupply}
            optionalParams={optionalParams}
            action={skillType === SkillType.indemand ? (
              <OnOffSwitch
                  label="hr.dashboard.confirmed_skills_only"
                  italic
                  value={confirmedOnly}
                  onChange={setConfirmedOnly}
                  disabled={disabled || pendingSkillSupply ? true : undefined}
              />
            ) : undefined}
            pinned={includes(pinned, DA_SKILL_SUPPLY)}
            onPin={disabled ? undefined : handlePinSkillSupply}
            pinning={pinning}
            exportEndpoint={API_DA_SKILL_SUPPLY_EXPORT}
            exportParams={skillSupplyExportParams}
            exportDisabled={exportDisabled || pendingSkillSupply || failedSkillSupply || size(skillSupply?.skills) < 1}
        />
      ) : undefined}
      {chartId === DA_EMPLOYEE_SKILLS && DA_TABS?.employees_skills ? (
        <TopChart
            variant="employee_skills"
            layout="full"
            info="hr.dashboard.employees_skills.info"
            withInfoButton
            hrbp
            data={employeeSkills?.results}
            totalEmployees={employeeSkills?.total_employees}
            pending={pendingEmployeeSkills || pending}
            failed={failedEmployeeSkills}
            optionalParams={employeeSkillsParams}
            action={(
              <ConstSelector
                  variant="skills"
                  value={minSkills}
                  onChange={handleMinSkillsChange}
                  disabled={disabled || pendingEmployeeSkills ? true : undefined}
              />
            )}
            pinned={includes(pinned, DA_EMPLOYEE_SKILLS)}
            onPin={disabled ? undefined : handlePinEmployeeSkills}
            pinning={pinning}
            exportEndpoint={API_DA_EMPLOYEE_SKILLS_EXPORT}
            exportParams={employeeSkillsExportParams}
            exportDisabled={exportDisabled || pendingEmployeeSkills || failedEmployeeSkills ||
              size(employeeSkills?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_SKILLS_GROWTH && HAS_INDEMAND_SKILLS && DA_TABS?.skills_growth ? (
        <TopChart
            variant="skills_growth"
            info="hr.dashboard.skills_growth.info"
            withInfoButton
            hrbp
            layout="full"
            data={skillsGrowth?.skills}
            totalEmployees={skillsGrowth?.total_employees}
            path={PATH_SKILL}
            pending={pendingSkillsGrowth || pending}
            failed={failedSkillsGrowth}
            pinned={includes(pinned, DA_SKILLS_GROWTH)}
            onPin={disabled ? undefined : handlePinSkillsGrowth}
            pinning={pinning}
            exportEndpoint={API_DA_SKILLS_GROWTH_EXPORT}
            exportParams={skillsGrowthDevExportParams}
            exportDisabled={exportDisabled || pendingSkillsGrowth || failedSkillsGrowth || size(skillsGrowth?.skills) < 1}
        />
      ) : undefined}
      {chartId === DA_SKILLS_DEVELOPMENT && HAS_INDEMAND_SKILLS && (HAS_MENTORING || HAS_COURSES_READONLY) &&
      DA_TABS?.skills_development ? (
        <TopChart
            variant={(HAS_MENTORING && method === 'advisors' && 'skill_advisors') ||
              (HAS_COURSES_READONLY && 'skill_courses') || 'skills_development'}
            layout="full"
            info="hr.dashboard.skills_development.info"
            withInfoButton
            hrbp
            data={skillsDevelopment?.skills}
            totalEmployees={skillsDevelopment?.total_employees}
            path={PATH_SKILL}
            pending={pendingSkillsDevelopment || pending}
            failed={failedSkillsDevelopment}
            action={HAS_MENTORING && HAS_COURSES_READONLY ? (
              <SortSelector
                  variant="skill_dev"
                  value={method}
                  onChange={setMethod}
                  disabled={disabled || pendingSkillsDevelopment ? true : undefined}
                  values={SKILLS_DEVELOPMENT_METHODS}
              />
            ) : undefined}
            pinned={includes(pinned, DA_SKILLS_DEVELOPMENT)}
            onPin={disabled ? undefined : handlePinSkillsDevelopment}
            pinning={pinning}
            exportEndpoint={API_DA_SKILLS_DEVELOPMENT_EXPORT}
            exportParams={skillsGrowthDevExportParams}
            exportDisabled={exportDisabled || pendingSkillsDevelopment || failedSkillsDevelopment ||
              size(skillsDevelopment?.skills) < 1}
        />
      ) : undefined}
      {chartId === DA_UPSKILLING_VELOCITY && DA_TABS?.upskilling_velocity ? (
        <TopChart
            variant="upskilling_velocity"
            hrbp
            layout="full"
            // info="hr.dashboard.upskilling_velocity.info"
            withInfoButton
            data={upskilling?.results}
            totalEmployees={upskilling?.total_employees}
            path={PATH_SKILL}
            pending={pendingUpskilling || pending}
            failed={failedUpskilling}
            optionalParams={employeeSkillsParams}
            pinned={includes(pinned, DA_UPSKILLING_VELOCITY)}
            onPin={disabled ? undefined : handlePinUpskilling}
            pinning={pinning}
            // TODO: enable export: exportEndpoint={API_DA_UPSKILLING_VELOCITY_EXPORT}
            // exportParams={upskillingExportParams}
            // exportDisabled={exportDisabled || pendingUpskilling || failedUpskilling || size(upskilling?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_EFFECTIVE_COURSES && HAS_COURSES_READONLY && DA_TABS?.most_effective_courses ? (
        <TopChart
            variant="effective_courses"
            hrbp
            layout="full"
            info="hr.dashboard.effective_courses.info"
            withInfoButton
            data={effectiveCourses?.results}
            totalEmployees={effectiveCourses?.total_employees}
            pending={pendingEffectiveCourses || pending}
            failed={failedEffectiveCourses}
            pinned={includes(pinned, DA_EFFECTIVE_COURSES)}
            onPin={disabled ? undefined : handlePinEffectiveCourses}
            pinning={pinning}
            exportEndpoint={API_DA_EFFECTIVE_COURSES_EXPORT}
            exportParams={effectiveCoursesExportParams}
            exportDisabled={exportDisabled || pendingEffectiveCourses || failedEffectiveCourses ||
              size(effectiveCourses?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_COURSE_COMPLETION && HAS_COURSES_READONLY && DA_TABS?.course_completion ? (
        <TopChart
            variant="course_completion"
            hrbp
            layout="full"
            // info="hr.dashboard.course_completion.info"
            withInfoButton
            data={courseCompletion?.results}
            totalEmployees={courseCompletion?.total_employees}
            pending={pendingCourseCompletion || pending}
            failed={failedCourseCompletion}
            action={(
              <SortSelector
                  variant="course_completeion_order"
                  value={completionDirection}
                  onChange={setCompletionDirection}
                  disabled={disabled || pendingCourseCompletion ? true : undefined}
                  values={COURSE_COMPLETION_DIRCTION}
              />
            )}
            pinned={includes(pinned, DA_COURSE_COMPLETION)}
            onPin={disabled ? undefined : handlePinCourseCompletion}
            pinning={pinning}
            exportEndpoint={API_DA_COURSE_COMPLETION_EXPORT}
            exportParams={courseCompletionExportParams}
            exportDisabled={exportDisabled || pendingCourseCompletion || failedCourseCompletion ||
              size(courseCompletion?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_EFFECTIVE_ADVISORS && HAS_MENTORING && DA_TABS?.most_effective_advisors ? (
        <TopChart
            variant="effective_advisors"
            hrbp
            layout="full"
            // info="hr.dashboard.effective_advisors.info"
            withInfoButton
            data={effectiveAdvisors?.results}
            totalEmployees={effectiveAdvisors?.total_employees}
            path={PATH_SKILL}
            pending={pendingEffectiveAdvisors || pending}
            failed={failedEffectiveAdvisors}
            pinned={includes(pinned, DA_EFFECTIVE_ADVISORS)}
            onPin={disabled ? undefined : handlePinEffectiveAdvisors}
            pinning={pinning}
            exportEndpoint={API_DA_EFFECTIVE_ADVISORS_EXPORT}
            exportParams={exportParams}
            exportDisabled={exportDisabled || pendingEffectiveAdvisors || failedEffectiveAdvisors ||
              size(effectiveAdvisors?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_SKILL_COURSE_MAPPING && HAS_COURSES_READONLY && DA_TABS?.skill_course_mapping ? (
        <TopChart
            variant={skillOrCourse === SkillOrCourse.skill_id ? 'skill_course_mapping' : 'course_skill_mapping'}
            hrbp
            layout="full"
            info="hr.dashboard.skill_course_mapping.info"
            withInfoButton
            data={skillCourse?.results}
            pending={pendingSkillCourse || pending}
            failed={failedSkillCourse}
            optionalParams={employeeSkillsParams}
            action={(
              <ScopeSelector
                  simple
                  scope={SKILL_OR_COURSE}
                  value={skillOrCourse}
                  onChange={setSkillOrCourse as (item: string) => void}
                  disabled={disabled || pendingSkillCourse ? true : undefined}
              />
            )}
            pinned={includes(pinned, DA_SKILL_COURSE_MAPPING)}
            onPin={disabled ? undefined : handlePinSkillCourse}
            pinning={pinning}
            exportEndpoint={API_DA_SKILL_COURSE_EXPORT}
            exportParams={skillCourseExportParams}
            exportDisabled={exportDisabled || pendingSkillCourse || failedSkillCourse || size(skillCourse?.results) < 1}
        />
      ) : undefined}
    </>
  );
};

DashboardAnalyticsSkills.propTypes = DashboardAnalyticsSkillsPropTypes;

export default memo(DashboardAnalyticsSkills);
