import {
  memo, useContext, useEffect, useCallback, useState, type FunctionComponent, type MouseEvent, useMemo
} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import countBy from 'lodash/countBy';
// Skillmore UI Components
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import AddSkillPopover from '@empathco/ui-components/src/widgets/AddSkillPopover';
// local imports
import { LookupItem } from '../models/lookupItem';
import { Skill } from '../models/skill';
import { getDataStatus, isDirtyData } from '../constants/dataStatuses';
import { MAX_SUGGESTED_SKILLS } from '../config/params';
import { DataContext } from '../context';
import SuggestSkillFooter from '../elements/SuggestSkillFooter';
import SkillsGrid from '../v3/SkillsGrid';
import SkillSearch from '../widgets/SkillSearch';

type RecommendedSkillsListProps = {
  supervisor?: boolean;
  uid?: string;
  employeeName?: string;
};

const RecommendedSkillsListPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string
};

const RecommendedSkillsList: FunctionComponent<RecommendedSkillsListProps> = ({
  supervisor = false,
  uid,
  employeeName = ''
}) => {
  const {
    dataStatus: { data: dataStatus, pending: pendingStatus, failed: failedStatus },
    recommendedSkills: { data: skills, pending, failed }, requireRecommendedSkills,
    suggestedSkillAdd: { pending: suggestSkillPending, failed: suggestSkillFailed }, addSuggestedSkill,
    suggestedSkillRemove: { pending: removeSkillPending, failed: removeSkillFailed }, removeSuggestedSkill
  } = useContext(DataContext);
  const dirty = !supervisor && isDirtyData(getDataStatus(dataStatus));

  useEffect(() => {
    requireRecommendedSkills?.();
  }, [uid, requireRecommendedSkills]);

  // Add Skill dialog
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);
  const disabled = pending || pendingStatus || failed || failedStatus || dirty || suggestSkillPending || removeSkillPending;

  const handleRemoveSkill = useCallback((skill: Skill) => {
    if (supervisor && uid) removeSuggestedSkill?.({ employee_id: uid, skill_id: skill.id });
  }, [supervisor, removeSuggestedSkill, uid]);

  const handleAddSkill = useCallback((skill: LookupItem) => {
    if (supervisor && uid && skill && skill.id) {
      setAnchorAddBtn(null);
      addSuggestedSkill?.({ employee_id: uid, skill_id: skill.id });
    }
  }, [supervisor, addSuggestedSkill, uid]);

  const { recommendedIds, suggestDisabled } = useMemo(() => supervisor && skills ? {
    recommendedIds: map(skills, 'id'),
    suggestDisabled: countBy(skills,
      ({ is_suggested_by_manager }) => Boolean(is_suggested_by_manager)
    ).true >= MAX_SUGGESTED_SKILLS
   } : {
     recommendedIds: null,
     suggestDisabled: true
  }, [skills, supervisor]);

  return (
    <>
      <SkillsGrid
          source="recommended"
          isEmployee={!supervisor}
          readOnly={supervisor}
          supervisor={supervisor}
          withRemoveSuggested={supervisor}
          onItemRemove={supervisor ? handleRemoveSkill : undefined}
          dirty={dirty}
          skills={skills}
          pending={pending || pendingStatus}
          failed={failed || failedStatus}
          disabled={suggestSkillPending || removeSkillPending}
      />
      {supervisor ? (
        <>
          <SuggestSkillFooter
              employeeName={employeeName}
              onClick={handleAddOpen}
              disabled={disabled}
              suggestDisabled={suggestDisabled}
              failed={failed || failedStatus}
          />
          <ActionFailedAlert
              message="skills.suggestion.add_error"
              open={suggestSkillFailed}
          />
          <ActionFailedAlert
              message="skills.suggestion.remove_error"
              open={removeSkillFailed}
          />
          <AddSkillPopover
              supervisor
              centered
              exclude={recommendedIds}
              anchorEl={anchorAddBtn}
              onAdd={handleAddSkill}
              onCancel={handleAddClose}
              disabled={disabled}
              title="skills.suggestion.popover_title"
              SkillSearchComponent={SkillSearch}
          />
        </>
      ) : undefined}
    </>
  );
};

RecommendedSkillsList.propTypes = RecommendedSkillsListPropTypes;

export default memo(RecommendedSkillsList);
