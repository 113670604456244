import { memo, useCallback, useMemo, useState, useContext, useLayoutEffect, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import join from 'lodash/join';
import split from 'lodash/split';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import without from 'lodash/without';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import toSafeInteger from 'lodash/toSafeInteger';
import { useQuery } from '@apollo/client';
// Skillmore UI Components
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
// local imports
import { DA_CHARTS_AVAILABILITY_QUERY } from '../graphql/DAChartsAvailability';
import { ChartsAvailability, DAChartsAvailabilityDocument } from '../graphql/types';
import useAnalyticsCharts from '../constants/dashboardAnalytics';
import { getSettingsStrValue } from '../helpers/context';
import { DataContext } from '../context';
import CategoryCard from '../elements/CategoryCard';
import DashboardAnalyticsBrowser from './DashboardAnalyticsBrowser';

type DashboardAnalyticsProps = {
  readonly supervisor?: boolean;
}

const DashboardAnalyticsPropTypes = {
  supervisor: PropTypes.bool
};

const DashboardAnalytics: FunctionComponent<DashboardAnalyticsProps> = ({
  supervisor = false
}) => {
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);

  // load Charts Availability
  const { pending, failed, results } = useQueryObject({
    data: undefined as unknown as ChartsAvailability,
    key: 'daChartsAvailability',
    query: useQuery(DA_CHARTS_AVAILABILITY_QUERY as typeof DAChartsAvailabilityDocument)
  });

  const settingsId = 'da_pinned_charts' as const;

  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsPinned = getSettingsStrValue(settings, settingsId);

  const [chartId, setChartId] = useState<number>();
  const handleCloseBrowser = useCallback(() => setChartId(undefined), []);

  const chartCategories = useAnalyticsCharts(results);

  const getPinned = useCallback((value?: string): number[] => {
    if (!value) return [];
    const pinnedArray = map(split(value, ','), toSafeInteger);
    return filter(pinnedArray, (id) => findIndex(chartCategories, { id }) >= 0);
  }, [chartCategories]);

  const [pinned, setPinned] = useState<number[]>(getPinned(settingsPinned));

  const handlePin = useCallback((id: number) => {
    const pinnedNew = includes(pinned, id) ? without(pinned, id) : sortBy([...pinned || [], id]);
    setPinned(pinnedNew);
    const value = join(pinnedNew, ',');
    if (settingsPinned !== value) updateSettings?.({ [settingsId]: value });
  }, [pinned, settingsPinned, updateSettings]);

  const charts = useMemo(() => sortBy(chartCategories, ({ id }) => !includes(pinned, id)), [pinned, chartCategories]);

  useLayoutEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) setPinned(getPinned(getSettingsStrValue(settings, settingsId)));
  }, [settingsLoaded, pendingSettingsUpdate, settings, getPinned]);

  return (
    <>
      <CardTitle title="breadcrumbs.dashboard" withDivider/>
      {((failed || failedSettings) && <FetchFailedAlert flat/>) ||
      ((pending || !settingsLoaded) && <LoadingPlaceholder flat/>) || (
        <CardDeck shady>
          {map(charts, (chart) => (
            <CategoryCard
                key={chart.id}
                category={chart}
                pinned={includes(pinned, chart.id)}
                onClick={setChartId}
            />
          ))}
        </CardDeck>
      )}
      {!failed && !pending && results ? (
        <DashboardAnalyticsBrowser
            supervisor={supervisor}
            chartCategories={chartCategories}
            openChart={chartId}
            onClose={handleCloseBrowser}
            pinned={pinned}
            onPin={handlePin}
            pinning={pendingSettingsUpdate ? true : undefined}
        />
      ) : undefined}
    </>
  );
};

DashboardAnalytics.propTypes = DashboardAnalyticsPropTypes;

export default memo(DashboardAnalytics);
