import { forwardRef, memo, useCallback, useEffect, useState, type Component, type MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';
// local imports
import SuggestedSkillIcon from '../v3/SuggestedSkillIcon';
// SCSS imports
import { footer, button, disabled as disabledLabel } from './SuggestSkillFooter.module.scss';

type SuggestSkillFooterProps = {
  employeeName: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  failed?: boolean | null;
  disabled?: boolean | null;
  suggestDisabled?: boolean | null;
  isOpen?: boolean;
}

const SuggestSkillFooterPropTypes = {
  employeeName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  failed: PropTypes.bool,
  disabled: PropTypes.bool,
  suggestDisabled: PropTypes.bool,
  // for Storybook only
  isOpen: PropTypes.bool
};

const SuggestSkillFooter = forwardRef<Component<BoxProps>, SuggestSkillFooterProps>(({
  employeeName,
  onClick,
  failed = false,
  disabled = false,
  suggestDisabled = false,
  isOpen = false
}, ref) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
  }, [isOpen]);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return failed ? null : (
    <CardSection ref={ref} dark className={footer}>
      {suggestDisabled && !disabled ? (
        <SimpleTooltip
            open={open}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            disableInteractive
            disableFocusListener
            placement="bottom"
            title={<FormattedMessage id="skills.suggestion.max_reached"/>}
        >
          <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={0.625}
              py={0.5}
          >
            <SuggestedSkillIcon
                active
                withoutTooltip
                disabled
            />
            <BoxTypography
                pl={1}
                color="action.disabled"
                className={disabledLabel}
            >
              <FormattedMessage id="skills.suggestion.button" values={{ name: employeeName }}/>
            </BoxTypography>
          </Box>
        </SimpleTooltip>
      ) : (
        <Button
            color="secondary"
            variant="text"
            size="small"
            startIcon={(
              <SuggestedSkillIcon
                  active
                  withoutTooltip
                  disabled={disabled || suggestDisabled}
              />
            )}
            onClick={onClick}
            disabled={disabled || suggestDisabled ? true : undefined}
            className={button}
        >
          <FormattedMessage id="skills.suggestion.button" values={{ name: employeeName }}/>
        </Button>
      )}
    </CardSection>
  );
});

SuggestSkillFooter.displayName = 'SuggestSkillFooter';

SuggestSkillFooter.propTypes = SuggestSkillFooterPropTypes;

export default memo(SuggestSkillFooter);
