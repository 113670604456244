import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// Skillmore UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { OPPORTUNITIES_QUERY } from '../graphql/Opportunities';
import { MY_OPPORTUNITIES_QUERY } from '../graphql/MyOpportunities';
import {
  Opportunity, MyOpportunity,
  OpportunitiesDocument, OpportunitiesQueryVariables,
  MyOpportunitiesDocument, MyOpportunitiesQueryVariables
} from '../graphql/types';
import { MAX_ITEMS } from '../config/params';

type OpportunitySearchProps = {
  value?: Opportunity | null;
  onChange: (skill: Opportunity | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
  isMy?: boolean;
}

const OpportunitySearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<Opportunity>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isMy: PropTypes.bool
};

const OpportunitySearch = forwardRef<HTMLDivElement, OpportunitySearchProps>(({
  value,
  onChange,
  disabled = false,
  fullWidth = false,
  isMy = false
}, ref) => {
  const opps = useQueryCounted({
    data: undefined as unknown as Opportunity,
    key: 'opportunities',
    lazyQuery: useLazyQuery(OPPORTUNITIES_QUERY as typeof OpportunitiesDocument)
  });

  const myOpps = useQueryCounted({
    data: undefined as unknown as MyOpportunity,
    key: 'myOpportunities',
    lazyQuery: useLazyQuery(MY_OPPORTUNITIES_QUERY as typeof MyOpportunitiesDocument)
  });

  const { query: getOpportunities, loading, failed, results, variables } = isMy ? myOpps : opps;

  const opportunities = useMemo(
    () => isMy ? map(results as MyOpportunity[], 'opportunity') : results as Opportunity[],
    [results, isMy]);

  const fetchParams: Partial<OpportunitiesQueryVariables & MyOpportunitiesQueryVariables> = useMemo(() => ({
    hide_archived: true,
    ...isMy ? {} : { published_only: true },
    limit: MAX_ITEMS
  }), [isMy]);

  const fetchEmployees = useCallback(
    (params: OpportunitiesQueryVariables & MyOpportunitiesQueryVariables) => getOpportunities?.({ variables: params }),
    [getOpportunities]);

  return (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type="opportunities"
        fetched={opportunities}
        pending={loading}
        failed={failed}
        params={variables as OpportunitiesQueryVariables & MyOpportunitiesQueryVariables}
        fetch={fetchEmployees}
        fetchParams={fetchParams}
        onChange={onChange}
        value={value}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  );
});

OpportunitySearch.displayName = 'OpportunitySearch';

OpportunitySearch.propTypes = OpportunitySearchPropTypes;

export default memo(OpportunitySearch);
