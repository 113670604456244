import { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
// Material Icon imports
import NavigateNext from '@mui/icons-material/NavigateNext';
// local imports
import { Breadcrumb, BreadcrumbItem } from '../types';
import TypographyLink from '../mixins/TypographyLink';
// SCSS imports
import { root, linkFix, fontSizeFix } from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  breadcrumbs?: BreadcrumbItem[];
};

const BreadcrumbsPropTypes = {
  // attributes
  breadcrumbs: PropTypes.array
};

const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(({
  breadcrumbs
}, ref) => {
  const { formatMessage } = useIntl();
  const label = useMemo(() => formatMessage({ id: 'breadcrumbs.aria_label' }), [formatMessage]);
  const lastIndex = size(breadcrumbs) - 1;
  return (
    <MuiBreadcrumbs
        ref={ref}
        className={root}
        color="inherit"
        separator={<NavigateNext fontSize="medium"/>}
        aria-label={label}
    >
      {map(breadcrumbs, (breadcrumb, index) => {
        const { title, link, values } = (isPlainObject(breadcrumb) ? breadcrumb : { title: breadcrumb }) as Breadcrumb;
        const isFirst = index === 0;
        const isNotLast = index < lastIndex;

        const variant = isFirst ? 'h1' : 'h4';
        const className = clsx({
          [linkFix]: isNotLast && link,
          [fontSizeFix]: lastIndex - index > 1
        });
        const text = isString(title) && size(title) >= 1
          ? formatMessage({ id: title, defaultMessage: title }, values)
          : title;

        return isNotLast && link ? (
          <TypographyLink
              key={index}
              variant={variant}
              className={className}
              to={link}
          >
            {text}
          </TypographyLink>
        ) : (
          <Typography
              key={index}
              variant={variant}
              className={className}
          >
            {text}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
});

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = BreadcrumbsPropTypes;

export default memo(Breadcrumbs);
