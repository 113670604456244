import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import isArray from 'lodash/isArray';
import toLower from 'lodash/toLower';
import replace from 'lodash/replace';
import trim from 'lodash/trim';
import size from 'lodash/size';
import join from 'lodash/join';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { type AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import type { RowInput, Styles, Table } from 'jspdf-autotable';
// Skillmore UI Components
import { EXPORT_PDF } from '@empathco/ui-components/src/models/exportFormat';
// local imports
import { Skill } from '../models/skill';
import { APIResponse } from '../models/apiResponse';
import { getSkillCurrentLevel, isInferred } from './models';

export type IJsPdf = typeof import('jspdf');
export type IJsPDF = typeof import('jspdf').jsPDF;
export type IJsPdfAutoTable = typeof import('jspdf-autotable');
export type IAutoTable = typeof import('jspdf-autotable').default;

export type Rows = RowInput[];
export type Headers = string[];
interface IDocWithAutoTable extends IJsPDF {
  lastAutoTable: Table;
}

// eslint-disable-next-line @typescript-eslint/init-declarations
let cachedJsPdf: IJsPdf | undefined;
// eslint-disable-next-line @typescript-eslint/init-declarations
let cachedJsPdfAutoTable: IJsPdfAutoTable | undefined;

const NEW_LINE = '\r\n';

export const CENTERED_BOLD_COLUMN: Partial<Styles> = {
  halign: 'center',
  fontStyle: 'bold'
} as const;

export const getSkillString = (skill: Skill) => `${getSkillCurrentLevel(skill)}${isInferred(skill) ? ' *' : ''}`;
export const getBooleanString = (value?: boolean | null) => value ? '=TRUE' : '=FALSE';
export const getInferredString = (skill: Skill) => getBooleanString(isInferred(skill));
export const getCodeNameFilename = (code: string, name: string) => toLower(join([code, replace(name, /\s+/ug, '_')], '_'));
export const getTitleFilename = (title: string) => toLower(replace(title, /[\s\W]+/ug, '_'));

function escapeCSV (value?: string | number | null): string {
  if (isFinite(value)) return toString(value);
  if (isString(value)) return `"${replace(replace(trim(value), '–', '-'), '"', '""')}"`;
  return '';
}

export const saveToCSV = (filename: string, headers: Headers, data: Rows) => {
  const blob = new Blob([
    join([
      join(map(headers, escapeCSV)),
      ...map(data, (row) => join(map(row, escapeCSV))),
      '' // last line break at the end of the CSV file
    ], NEW_LINE)
  ], { type: 'text/csv' });
  saveAs(blob, `${filename}.csv`);
};

// eslint-disable-next-line max-params
export function saveToPDF (
  JsPDF: IJsPDF,
  autoTable: IAutoTable,
  filename: string,
  headers: Headers,
  data: Rows,
  title: string,
  footer: string | string[],
  columnStyles?: { [key: string]: Partial<Styles> }
) {
  const doc = new JsPDF({
    orientation: 'landscape'
  });
  doc.text(title, 14, 15);
  autoTable(doc, {
    startY: 20,
    head: [headers],
    body: data,
    styles: {
      valign: 'middle',
      lineWidth: 0.33,
      lineColor: [255, 255, 255]
    },
    headStyles: {
      fillColor: [2, 148, 255], // <- themeOptions.palette.primary.main
      halign: 'center'
    },
    alternateRowStyles: {
      fillColor: [246, 247, 251] // <- themeOptions.palette.background.default
    },
    columnStyles
  });
  if (size(footer) >= 1) {
    let finalY = (doc as unknown as IDocWithAutoTable)?.lastAutoTable?.finalY;
    forEach(isArray(footer) ? footer : [footer], (text, index, arr) => {
      if (finalY) {
        doc.setFontSize(index === (arr.length - 1) ? 12 : 10);
        finalY += 12;
        doc.text(text, 14, finalY);
      }
    });
  }
  // TODO: page numbers?
  doc.save(`${filename}.pdf`);
}

export function downloadAndSave<T>(
  format: string,
  request: AxiosResponse<APIResponse<T>> | Promise<AxiosResponse<APIResponse<T>>>,
  save: (data: T[], JsPDF?: IJsPDF, autoTable?: IAutoTable) => void
): Promise<boolean | number> {
  return Promise.all([
    request,
    /* format === EXPORT_PDF ? cachedJsPdf || import('jspdf') : */ undefined,
    /* format === EXPORT_PDF ? cachedJsPdfAutoTable || import('jspdf-autotable') : */ undefined
  ])
  .then(([
    { status, data },
    jspdf,
    jspdfAutoTable
  ]: [
    AxiosResponse<APIResponse<T>>,
    IJsPdf | undefined,
    IJsPdfAutoTable | undefined
  ]) => {
    if (
      status !== 200 || !data || !data.results || (!data.job_id && size(data.results) < 1) ||
      (format === EXPORT_PDF && (!jspdf || !jspdfAutoTable))
    ) return false;
    if (data.job_id) return data.job_id;
    if (jspdf && !cachedJsPdf) cachedJsPdf = jspdf;
    if (jspdfAutoTable && !cachedJsPdfAutoTable) cachedJsPdfAutoTable = jspdfAutoTable;
    save(data.results, jspdf?.jsPDF, jspdfAutoTable?.default);
    return true;
  })
  .catch((_error) => false);
}
