import { memo, useEffect, useMemo, type FunctionComponent, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Skillmore UI Components
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { Job } from '../models/job';
import { getJobAnalyticsData, useMixpanel } from '../context/analytics';
import EmployeesMatchingJobList from './EmployeesMatchingJobList';
// SCSS imports
import { dialogContent } from './EmployeesMatchingJob.module.scss';

type EmployeesMatchingJobProps = {
  role: Job;
  isInternational?: boolean | null;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onExited: (node: HTMLElement) => void;
}

const EmployeesMatchingJobPropTypes = {
  // attributes
  role: PropTypes.object.isRequired as Validator<Job>,
  isInternational: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired
};

const EmployeesMatchingJob: FunctionComponent<EmployeesMatchingJobProps> = ({
  role,
  isInternational = false,
  isOpen = false,
  onClose,
  onExited
}) => {
  const mixpanel = useMixpanel();

  const transitionProps = useMemo(() => ({ onExited }), [onExited]);

  useEffect(() => {
    if (isOpen && role) mixpanel.track('JobCandidates', getJobAnalyticsData(role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mixpanel]); // do not track `role` changes

  return (
    <Dialog
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardTitle
          flex
          wrap
          title={(
            <Box mt={2.5} flexGrow="1" display="flex" alignItems="center">
              <FormattedMessage id="employees.job_candidates" values={{ role: role.title }}/>
            </Box>
          )}
          withDivider
      />
      <DialogContent className={dialogContent}>
        <EmployeesMatchingJobList
            role={role}
            isInternational={isInternational}
        />
      </DialogContent>
    </Dialog>
  );
};

EmployeesMatchingJob.propTypes = EmployeesMatchingJobPropTypes;

export default memo(EmployeesMatchingJob);
