import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
// Skillmore UI Components
import { bold } from '@empathco/ui-components/src/helpers/intl';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
// local imports
import useNonReducedUI from '../constants/managementLevel';
import { Job } from '../models/job';
// SCSS imports
import { cardRoot, titleRow, current, prevnext, circle } from './RoleSimpleCard.module.scss';

export const ROLE_SIMPLE_PREV = 'prev' as const;
export const ROLE_SIMPLE_CURRENT = 'current' as const;
export const ROLE_SIMPLE_NEXT = 'next' as const;

export const ROLES_SIMPLE = [
  ROLE_SIMPLE_PREV,
  ROLE_SIMPLE_CURRENT,
  ROLE_SIMPLE_NEXT
] as const;
export type RoleSmpleType = typeof ROLES_SIMPLE[number];

export const ROLE_SIMPLE_PREV_ID = 0 as const;
export const ROLE_SIMPLE_CURRENT_ID = 1 as const;
export const ROLE_SIMPLE_NEXT_ID = 2 as const;
export type RoleSmpleIdType = 0 | 1 | 2;

type RoleSimpleCardProps = {
  role: Job;
  path?: string | null;
  onClick?: (code: string, job?: Job) => void;
  variant?: RoleSmpleType;
  withoutMatchRate?: boolean;
  matchRate?: number | null;
};

const RoleSimpleCardPropTypes = {
  // attributes
  role: PropTypes.object.isRequired as Validator<Job>,
  path: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(ROLES_SIMPLE),
  withoutMatchRate: PropTypes.bool,
  matchRate: PropTypes.number
};

const RoleSimpleCard = forwardRef<HTMLDivElement, RoleSimpleCardProps>(({
  role,
  variant = ROLE_SIMPLE_CURRENT,
  path,
  onClick,
  withoutMatchRate = false,
  matchRate
}, ref) => {
  const { showNonReducedUI } = useNonReducedUI();

  const { code, is_index_display, title, match_rate, movement_rate, moved_employees } = role || {};
  const showMatchRate = !withoutMatchRate && showNonReducedUI(role);
  const isCurrent = variant === ROLE_SIMPLE_CURRENT;

  const handleClick = useCallback(() => {
    if (role?.code) onClick?.(role.code, role);
  }, [role, onClick]);

  const content = (
    <Box
        px={showMatchRate ? 2 : 5}
        py={1.5}
        display="flex"
        alignItems="center"
        position="relative"
        className={clsx({
          [current]: isCurrent,
          [prevnext]: variant === ROLE_SIMPLE_PREV || variant === ROLE_SIMPLE_NEXT
        })}
    >
      {variant !== ROLE_SIMPLE_NEXT && (
        <Box
            position="absolute"
            top="50%"
            borderRadius="50%"
            bgcolor="success.light"
            className={circle}
        />
      )}
      <Box
          flexGrow={1}
          flexBasis={0}
          pr={1}
          display="flex"
          flexDirection="column"
      >
        <Box
            color="secondary.main"
            className={titleRow}
        >
          {title}
        </Box>
        {!isCurrent && !isNil(movement_rate) ? (
          <BoxTypography pt={0.5} variant="body2">
            <FormattedMessage id="role.movement_rate" values={{ bold, rate: movement_rate / 100 }}/>
          </BoxTypography>
        ) : undefined}
        {!isCurrent && !isNil(moved_employees) ? (
          <BoxTypography variant="body2" color="greys.popupBorder">
            <FormattedMessage id="role.movement_employees" values={{ count: moved_employees }}/>
          </BoxTypography>
        ) : undefined}
      </Box>
      {showMatchRate ? (
        <Box display="flex" alignItems="center">
          <MatchIndicator value={toSafeInteger(isNil(matchRate) ? match_rate : matchRate)}/>
        </Box>
      ) : undefined}
    </Box>
  );

  return (
    <Card
        ref={ref}
        elevation={6}
        className={cardRoot}
    >
      {(onClick && (
        <CardActionArea onClick={handleClick}>
          {content}
        </CardActionArea>
      )) || (path && is_index_display && (
        <CardActionArea
            component={RouterLink}
            to={injectParams(path, { role_id: code })}
        >
          {content}
        </CardActionArea>
      )) || content}
    </Card>
  );
});

RoleSimpleCard.displayName = 'RoleSimpleCard';

RoleSimpleCard.propTypes = RoleSimpleCardPropTypes;

export default memo(RoleSimpleCard);
