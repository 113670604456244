import { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
// SCSS imports
import { label } from './PreferencesCheckbox.module.scss';

type PreferencesCheckboxProps = {
  id: string;
  title: string;
  value: boolean;
  onChange: (id: string) => void;
  disabled?: boolean | null;
}

const PreferencesCheckboxPropTypes = {
  // attributes
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const PreferencesCheckbox = forwardRef<HTMLButtonElement, PreferencesCheckboxProps>(({
  id,
  title,
  value,
  onChange,
  disabled = false
}, ref) => {
  const handleChange = useCallback(() => onChange(id), [id, onChange]);
  return (
    <>
      <CheckboxButton
          ref={ref}
          label={title}
          checked={value}
          onChange={handleChange}
          disabled={disabled ? true : undefined}
      />
      <Box
          pl={1.5}
          flexGrow={1}
          flexBasis={0}
          display="flex"
          alignItems="center"
          className={label}
      >
        {title}
      </Box>
    </>
  );
});

PreferencesCheckbox.displayName = 'PreferencesCheckbox';

PreferencesCheckbox.propTypes = PreferencesCheckboxPropTypes;

export default memo(PreferencesCheckbox);
