import { memo, useCallback, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import Slider from 'react-slick';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// Skillmore UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { SUCCESS_STORIES_QUERY } from '../graphql/SuccessStories';
import { SuccessStoriesDocument, SuccessStory } from '../graphql/types';
import { STORIES_AUTOPLAY_SPEED } from '../config/params';
import { SUCCESS_STORIES_OPTIONS } from '../helpers/graphql';
import CarouselArrow from '../elements/CarouselArrow';
import SuccessStoryCard from '../elements/SuccessStoryCard';

const EMPTY_STORIES: SuccessStory[] = [];

type SuccessStoriesProps = {
  reversePadding?: boolean;
}

const SuccessStoriesPropTypes = {
  reversePadding: PropTypes.bool
};

const SuccessStoriesPanel: FunctionComponent<SuccessStoriesProps> = ({
  reversePadding = false
}) => {
  const theme = useTheme();

  const [current, setCurrent] = useState(0);
  const onChange = useCallback((_currentSlide: number, nextSlide: number) => setCurrent(nextSlide), []);

  // load Success Stories
  const { loading, error, results } = useQueryCounted({
    data: undefined as unknown as SuccessStory,
    key: 'successStories',
    query: useQuery(SUCCESS_STORIES_QUERY as typeof SuccessStoriesDocument, SUCCESS_STORIES_OPTIONS)
  });
  const successStories = ((error || (!loading && size(results) < 1)) && EMPTY_STORIES) || (loading ? null : results);

  return (loading && (
    <Box
        pt={reversePadding ? 6 : 11}
        pb={reversePadding ? 11 : 6}
        flexGrow={1}
        display="flex"
        color="action.disabled"
        justifyContent="center"
    >
      <CircularProgress color="inherit" size="2.25rem"/>
    </Box>
  )) || (successStories && size(successStories) >= 1 ? (
    <>
      <BoxTypography variant="h2" align="center" pt={reversePadding ? 6 : 11}>
        <FormattedMessage id="board.stories"/>
      </BoxTypography>
      <Slider
          infinite
          dots
          adaptiveHeight
          autoplay
          autoplaySpeed={STORIES_AUTOPLAY_SPEED}
          slidesToScroll={1}
          centerMode
          centerPadding="17%"
          speed={theme.transitions.duration.complex}
          easing="ease-in-out"
          beforeChange={onChange}
          prevArrow={<CarouselArrow variant="prev"/>}
          nextArrow={<CarouselArrow variant="next"/>}
      >
        {map(successStories, (story, idx) => (
          <SuccessStoryCard
              key={idx}
              story={story}
              isCurrent={idx === current}
          />
        ))}
      </Slider>
      <Box pb={reversePadding ? 11 : 6}/>
    </>
  ) : null);
};

SuccessStoriesPanel.propTypes = SuccessStoriesPropTypes;

export default memo(SuccessStoriesPanel);
