import { forwardRef, memo, useCallback, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
// Skillmore UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { EMPLOYEE_SKILLS_SEARCH_QUERY } from '../graphql/EmployeeSkillsSearch';
import { SKILLS_SEARCH_QUERY } from '../graphql/SkillsSearch';
import {
  SimpleSkill, EmployeeSkill, SkillsSearchInput,
  SkillsSearchDocument, EmployeeSkillsSearchDocument
} from '../graphql/types';
import { ILookupItem, ILookupSkill } from '../models/lookupItem';
import { MAX_LOOKUP_OPTIONS } from '../config/params';
// SCSS imports
import { root } from './SkillSearch.module.scss';

export type SkillSearchProps = {
  supervisor?: boolean;
  limit?: number;
  exclude?: number[];
  withoutExisting?: boolean;
  value?: ILookupItem | null;
  onChange: (skill: ILookupSkill | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
  library?: boolean;
  short?: boolean;
  renderOption?: (option: ILookupSkill, state: AutocompleteRenderOptionState) => ReactNode;
}

const SkillSearchPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  limit: PropTypes.number,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  withoutExisting: PropTypes.bool,
  value: PropTypes.object as Validator<ILookupItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  library: PropTypes.bool,
  short: PropTypes.bool,
  renderOption: PropTypes.func
};

const SkillSearch = forwardRef<HTMLDivElement, SkillSearchProps>(({
  supervisor = false,
  limit,
  exclude,
  withoutExisting = false,
  value,
  onChange,
  disabled = false,
  fullWidth = false,
  library = false,
  short = false,
  renderOption
}, ref) => {
  // lazy load skills (common for supervisors; employee-specific for employees)
  const supervisorSkillsQuery = useQueryCounted({
    data: undefined as unknown as SimpleSkill,
    key: 'skillsSearch',
    lazyQuery: useLazyQuery(SKILLS_SEARCH_QUERY as typeof SkillsSearchDocument)
  });
  const employeeSkillsQuery = useQueryCounted({
    data: undefined as unknown as EmployeeSkill,
    key: 'employeeSkillsSearch',
    lazyQuery: useLazyQuery(EMPLOYEE_SKILLS_SEARCH_QUERY as typeof EmployeeSkillsSearchDocument)
  });
  const { query, pending, failed, variables, results } = supervisor ? supervisorSkillsQuery : employeeSkillsQuery;

  const fetchParams: SkillsSearchInput = useMemo(() => ({
    limit: limit || MAX_LOOKUP_OPTIONS,
    ...exclude ? { exclude_ids: exclude } : {},
    ...withoutExisting ? { exclude_existing_skills: true } : {}
  }), [exclude, limit, withoutExisting]);

  const fetchSkills = useCallback(
    (input: SkillsSearchInput) => query?.({ variables: { input, pathBuilder: pathBuilder as unknown as string } }),
    [query]);

  return query ? (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type={(library && 'skills_lib') || (short && 'skills') || 'skill_search'}
        className={root}
        fetched={results as ILookupSkill[]}
        pending={pending}
        failed={failed}
        params={variables?.input}
        fetch={fetchSkills}
        fetchParams={fetchParams}
        exclude={exclude}
        value={value as ILookupSkill}
        onChange={onChange}
        disabled={disabled}
        popupIcon={library || short ? <SearchIcon/> : undefined}
        renderOption={renderOption}
    />
  ) : null;
});

SkillSearch.displayName = 'SkillSearch';

SkillSearch.propTypes = SkillSearchPropTypes;

export default memo(SkillSearch);
