import { memo, useCallback, useContext, type FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
// Skillmore UI Components
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { getDataStatus, mustCompute, isComputing } from '../constants/dataStatuses';
import { DataContext } from '../context';
// SCSS imports
import { overlay } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { button } from './ComputeUpdatesOverlay.module.scss';

const ComputeUpdatesOverlay: FunctionComponent = () => {
  const {
    dataStatus: { data: dataStatus /* , pending, failed */ },
    dataStatusUpdate: { pending: updatePending, failed: updateFailed }, updateDataStatus
  } = useContext(DataContext);
  const status = getDataStatus(dataStatus);
  const compute = mustCompute(status);
  const computing = isComputing(status);
  const disabled = computing || Boolean(updatePending);

  const handleUpdate = useCallback(() => updateDataStatus?.(), [updateDataStatus]);

  return (
    <>
      {compute || computing ? (
        <CardActions className={overlay}>
          {computing ? <LinearProgress/> : (
            <Box pt={8} pb={2.25} px={2.25} display="flex" alignItems="center" justifyContent="center">
              <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleUpdate}
                  disabled={disabled}
                  startIcon={updatePending ? <CircularProgress size={18} color="inherit"/> : undefined}
                  className={button}
              >
                <FormattedMessage id="compute_updates.button"/>
              </Button>
            </Box>
          )}
        </CardActions>
      ) : undefined}
      <ActionFailedAlert
          message="compute_updates.failed"
          open={updateFailed}
      />
    </>
  );
};

export default memo(ComputeUpdatesOverlay);
