import { memo, useContext, useEffect, useMemo, useState, useCallback, type FunctionComponent } from 'react';
import toLower from 'lodash/toLower';
import { useParams } from 'react-router-dom';
// Skillmore UI Components
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { isNotContractor, isEmployee, isManager, hasHRBP, isAdmin } from '../models/user';
import useNonReducedUI from '../constants/managementLevel';
import { PATH_MY_JOBS } from '../config/paths';
import { getRoleId } from '../helpers/routerParams';
import { DataContext } from '../context';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import DataStatusPoller from '../v3/DataStatusPoller';
import RoleDetails from '../components/RoleDetails';
import RoleSkills from '../components/RoleSkills';
import RolePath from '../components/RolePath';

const RoleScreen: FunctionComponent = () => {
  const { showNonReducedUI } = useNonReducedUI();

  const role_id = getRoleId(useParams());

  const { content, user } = useVerifyUser({});
  const { role: { data: role, pending }, requireEmployeeRole } = useContext(DataContext);
  const nonReducedUI = isNotContractor(user);
  const hasSkillsAndPath = pending || showNonReducedUI(role);

  const isEmpl = isEmployee(user);
  const supervisor = isManager(user);
  const hrbp = hasHRBP(user);
  const isAdm = isAdmin(user);
  const notEmpl = !isEmpl && !supervisor;

  useEffect(() => {
    if (!content && role_id) requireEmployeeRole?.({ role_id, hrbp: notEmpl });
  }, [content, role_id, notEmpl, requireEmployeeRole]);

  const title = role?.title && toLower(role.code) === role_id ? role.title : undefined;

  const breadcrumbs = useMemo(() => [
    {
      title: 'breadcrumbs.roles_index',
      ...isEmpl // || (hrbp && HAS_HRP_JOBS) || admin
        ? { link: PATH_MY_JOBS } : {}
        // link: (isEmpl && PATH_MY_JOBS) || (hrbp && HAS_HRP_JOBS && PATH_HR_JOBS) || (admin && PATH_ADMIN_JOBS)
    },
    ...title ? [title] : []
  ], [title, isEmpl]);

  const [exporting, setExporting] = useState<boolean | null>(null);
  const handleExport = useCallback(() => {
    setExporting(true);
  }, []);
  const handleExportFinished = useCallback((success: boolean) => {
    setExporting(success ? null : false);
  }, []);
  const handleExportDismiss = useCallback(() => {
    setExporting(null);
  }, []);

  const withSettings = !user?.is_admin_only;

  return content || (
    <Screen
        withSettings={withSettings}
        breadcrumbs={breadcrumbs}
    >
      <RoleDetails reducedUI={!nonReducedUI} isEmployee={isEmpl} isAdm={isAdm} supervisor={supervisor} hrbp={hrbp}/>
      {hasSkillsAndPath ? (
        <RoleSkills
            reducedUI={!nonReducedUI}
            isEmployee={isEmpl}
            exporting={Boolean(exporting)}
            onExport={handleExport}
        />
      ) : undefined}
      {exporting ? <RoleSkills isEmployee onExportFinished={handleExportFinished}/> : undefined}
      {hasSkillsAndPath ? <RolePath isEmployee={isEmpl}/> : undefined}
      {isEmpl ? <DataStatusPoller/> : undefined}
      <ActionFailedAlert
          message="supervisor.export.failed"
          open={exporting === false}
          onDismiss={handleExportDismiss}
      />
    </Screen>
  );
};

export default memo(RoleScreen);
