import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN_PROGRESSES } from '../config/api';

export const DEV_PLAN_PROGRESSES_QUERY = gql`
  query DevPlanProgresses(
    $devplan_id: Int!
  ) {
    devplanProgresses(
      devplan_id: $devplan_id
    )
    @rest(
      type: "DevPlanProgressesPayload",
      path: "${API_DEV_PLAN_PROGRESSES}"
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        performance_rating
        years_on_job
        is_profile_accessible
        is_devplan_active
        initial_match_rate
        current_match_rate
        current_job {
          id
          title
          code
          location {
            id
            city
            state
            country
          }
        }
        location {
          id
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
          expected_level
          is_satisfied
        }
        completed_courses {
          id
          title
          code
          abbr
          kind
          level
          rating
          link
          level_growth_in_quarters
          external_url
          duration_value
          duration_unit
          certification_program
          video_url
          credit_hours
          is_self_placed
          description
          is_complete
          completed_at
          preferred_rating
          provider {
            id
            title
            code
          }
          covered_skills {
            id
            title
            abbr
            current_level
          }
        }
        completed_advisors {
          id
          code
          first_name
          last_name
          email
          is_complete
          confirmed_at
          active_mentor_count
          current_job {
            id
            title
            code
            location {
              id
              city
              state
              country
            }
          }
          location {
            id
            title
            city
            state
            country
          }
          advisory_skills {
            id
            title
            abbr
            current_level
          }
        }
        completed_opportunities {
          id
          opportunity_match_id
          title
          duration_unit
          duration_value
          description
          start_date
          status
          scope_manager_id
          onsite
          sidegig
          timezone_minutes
          published_at
          updated_at
          started_at
          archived_at
          timestamp @client
          location {
            id
            city
            state
            country
            title @client
          }
          owner {
            id
            code
            first_name
            last_name
            email
          }
          skills {
            id
            title
            abbr
            skill_proficiency_level
            status
          }
        }
        activities {
          id
          status
          created_at
          updated_at
          activity {
            id
            code
            name
            activity_type
            description
            activity_link
            development_area
            field_support
            store
            education_style
            owner {
              id
              code
              first_name
              last_name
              email
            }
          }
          skills {
            id
            abbr
            title
            skill_proficiency_level
          }
        }
      }
    }
  }
`;
