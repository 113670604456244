import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// Skillmore UI Components
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// SCSS imports
import { section } from './DashboardEmployeesCount.module.scss';

type DashboardEmployeesCountProps = {
  totalEmployees?: number | null;
};

const DashboardEmployeesCountPropTypes = {
  totalEmployees: PropTypes.number
};

const DashboardEmployeesCount = forwardRef<Component<BoxProps>, DashboardEmployeesCountProps>(({
  totalEmployees
}, ref) => (
  <CardSection ref={ref} compact className={section}>
    <BoxTypography
        variant="caption"
        color="info.caption"
        fontWeight={fontWeightMedium}
        pb={1}
    >
      {(isUndefined(totalEmployees) && '\u00A0') ||
      (isNull(totalEmployees) && <Skeleton variant="text" width="12rem"/>) || (
        <FormattedMessage
            id="hr.dashboard.employees_count"
            values={{ count: totalEmployees }}
        />
      )}
    </BoxTypography>
  </CardSection>
));

DashboardEmployeesCount.displayName = 'DashboardEmployeesCount';

DashboardEmployeesCount.propTypes = DashboardEmployeesCountPropTypes;

export default memo(DashboardEmployeesCount);
