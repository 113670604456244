import { memo, type FunctionComponent } from 'react';
// Skillmore UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
// local imports
import { getDelegator } from '../models/user';
import useCustomerSettings from '../config/customer';
import useVerifyUser from '../hooks/useVerifyUser';
import LeaderHeader from '../elements/LeaderHeader';
import Screen from '../v3/Screen';
import ManagerDashboard from '../components/ManagerDashboard';
import DashboardAnalytics from '../components/DashboardAnalytics';

const HRDashboardScreen: FunctionComponent = () => {
  const { DA_TABS } = useCustomerSettings();
  const { content, user } = useVerifyUser({ hr: true });
  const leader = getDelegator(user);
  return content || (
    <Screen withSettings>
      {leader ? <LeaderHeader leader={leader}/> : undefined}
      <ContentCard withoutTopMargin={Boolean(leader)}>
        {DA_TABS ? <DashboardAnalytics/> : <ManagerDashboard hrbp/>}
      </ContentCard>
    </Screen>
  );
};

export default memo(HRDashboardScreen);
