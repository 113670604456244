import { memo, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage, FormattedDate } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import LinearProgress from '@mui/material/LinearProgress';
// Material Icon imports
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
// Skillmore UI Components
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { shortDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { MyOpportunity, MyOpportunitiesSortOrder, MyOpportunityStatus, BookingStatus } from '../graphql/types';
import { MY_OPPORTUNITIES_ORDER, DEFAULT_OPPORTUNITIES_DIRECTION } from '../graphql/customTypes';
import { PATH_MY_OPPORTUNITY } from '../config/paths';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import { hasPagination, PaginationControlsComponent } from './PaginationControls';

type OpportunitiesTableProps = {
  opportunities?: MyOpportunity[];
  sortOrder?: MyOpportunitiesSortOrder | null;
  direction?: boolean | null;
  onSort: (sort?: MyOpportunitiesSortOrder, asc?: boolean | null) => void;
  pending?: boolean | null;
  savingId?: number;
  failed?: boolean | null;
  disabled?: boolean | null;
  notFoundMessage: string;
  filters?: ReactNode | ReactNode[];
  pagination?: ReactNode | ReactNode[];
  onSave?: (opp: MyOpportunity) => void;
};

const OpportunitiesTablePropTypes = {
  opportunities: PropTypes.array,
  sortOrder: PropTypes.string as Validator<MyOpportunitiesSortOrder>,
  direction: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  savingId: PropTypes.number,
  failed: PropTypes.bool,
  disabled: PropTypes.bool,
  notFoundMessage: PropTypes.string.isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  pagination: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  onSave: PropTypes.func
};

const OpportunitiesTable: FunctionComponent<OpportunitiesTableProps> = ({
  opportunities,
  sortOrder,
  direction,
  onSort,
  pending = false,
  savingId,
  failed = false,
  disabled = false,
  notFoundMessage,
  filters,
  pagination,
  onSave
}) => {
  const { getLocationStr } = useModels();
  const withPagination = hasPagination(pagination as PaginationControlsComponent);
  const loading = pending && !opportunities;
  const reloading = Boolean(pending && opportunities);

  const [
    sortByDefault, sortByMatchRate, sortByGrowthRate, sortByTitle, sortByOwnerName, sortByLocation, sortByPublishedAt,
    sortByDuration, sortByStartDate
  ] = useMemo(() => map(MY_OPPORTUNITIES_ORDER, (sorting) =>
    () => onSort(sorting, sortOrder === sorting ? !direction : DEFAULT_OPPORTUNITIES_DIRECTION[sorting])
  ), [sortOrder, direction, onSort]);

  const actionsDisabled = disabled || pending || !opportunities || size(opportunities) < 1;

  const titles = useMemo(() => map([
    { id: MyOpportunitiesSortOrder.default, handler: sortByDefault },
    { id: MyOpportunitiesSortOrder.title, handler: sortByTitle },
    { id: MyOpportunitiesSortOrder.owner_name, handler: sortByOwnerName },
    { id: MyOpportunitiesSortOrder.location, handler: sortByLocation },
    { id: MyOpportunitiesSortOrder.published_at, handler: sortByPublishedAt },
    { id: MyOpportunitiesSortOrder.duration, handler: sortByDuration },
    { id: MyOpportunitiesSortOrder.start_date, handler: sortByStartDate },
    { id: MyOpportunitiesSortOrder.match_rate, handler: sortByMatchRate },
    { id: MyOpportunitiesSortOrder.growth_rate, handler: sortByGrowthRate }
  ], ({ id, handler }) => {
    const label = (
      <FormattedMessage
          key={id}
          id="sort.opportunities.value"
          values={{ sort: id === MyOpportunitiesSortOrder.default ? 'status' : id }}
      />
    );
    return id && handler ? (
      <TableSortLabel
          key={id}
          direction={(sortOrder === id ? direction : DEFAULT_OPPORTUNITIES_DIRECTION[id]) ? 'asc' : 'desc'}
          active={sortOrder === id}
          onClick={handler}
          disabled={actionsDisabled || (id === MyOpportunitiesSortOrder.default && sortOrder === id)}
          IconComponent={SortArrow}
      >
        {label}
      </TableSortLabel>
    ) : label;
  }), [
    actionsDisabled, sortOrder, direction,
    sortByDefault, sortByMatchRate, sortByGrowthRate, sortByTitle, sortByOwnerName, sortByLocation, sortByPublishedAt,
    sortByDuration, sortByStartDate
  ]);

  const rows = useMemo(() => map(opportunities, (myOpp) => {
    const { id, is_new, employee_status, match_rate, growth_rate, opportunity, booking } = myOpp || {};
    const {
      title, published_at, duration_value, duration_unit, sidegig, start_date, onsite, timezone_minutes, location, owner
    } = opportunity || {};
    const { status: bookingStatus, employee_requested_at } = booking || {};
    const is_applied = Boolean(bookingStatus === BookingStatus.employee_requested ||
      (employee_requested_at && bookingStatus && bookingStatus !== BookingStatus.employee_rejected));
    const is_saved = employee_status === MyOpportunityStatus.shortlist;
    return {
      selected: false,
      values: [
        /* eslint-disable react/jsx-props-no-spreading, react/jsx-key */
        <Box display="flex" flexDirection="column" alignItems="center">
          <TargetIcon
              Icon={BookmarkBorderIcon}
              ActiveIcon={BookmarkIcon}
              label="opportunities.status.not_saved"
              activeLabel="opportunities.status.saved"
              active={is_saved}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={onSave ? () => onSave(myOpp) : undefined}
              disabled={disabled}
              pending={savingId && savingId === id ? true : undefined}
          />
          {is_applied ? (
            <TagLabel small variant="outlined" title="opportunities.applied"/>
          ) : undefined}
        </Box>,

        <>
          {is_new ? <SkillNewTag active/> : undefined}
          <StandardLink
              text={title}
              to={disabled ? undefined : injectParams(PATH_MY_OPPORTUNITY, { opp_id: id })}
          />
        </>,

        owner ? (
          <EmployeeName
              variant="inherit"
              employee={owner}
              manager
              disabled={disabled ? true : undefined}
          />
        ) : '—',

        <>
          {getLocationStr(location) || <FormattedMessage id="opportunities.not_specified"/>}
          <FormattedMessage
              id="opportunities.location_options"
              values={{ onsite, timezone: isSafeInteger(timezone_minutes) ? timezone_minutes : null, style: 'brackets' }}
          />
        </>,

        published_at ? <FormattedDate value={getJsDateFromISO(published_at)} {...shortDateOptions}/>
          : <FormattedMessage id="opportunities.not_specified"/>,

        <FormattedMessage
            id="opportunities.duration"
            values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: false }}
        />,

        start_date ? <FormattedDate value={getJsDateFromISO(start_date)} {...shortDateOptions}/>
          : <FormattedMessage id="opportunities.not_specified"/>,

        <Box display="flex" justifyContent="center" py={0.75}>
          <MatchIndicator value={toSafeInteger(match_rate)}/>
        </Box>,
        <Box display="flex" justifyContent="center" py={0.75}>
          <MatchIndicator value={toSafeInteger(growth_rate)} variant="planned" label="opportunities.growth_rate_value"/>
        </Box>

        /* eslint-enable react/jsx-props-no-spreading, react/jsx-key */
      ]
    };
  }), [disabled, savingId, opportunities, onSave, getLocationStr]);

  const content = (
    <>
      <CardSection>
        <DataTable
            tableSize="small"
            titles={titles}
            empty={notFoundMessage}
            data={rows}
            pending={loading}
            failed={failed}
            lastLeftAlignedTitle={6}
        />
      </CardSection>
      {withPagination ? (
        <CardSection flex bottom>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );

  return (
    <>
      {filters ? (
        <CardSection top flex>
          {filters}
        </CardSection>
      ) : undefined}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
    </>
  );
};

OpportunitiesTable.propTypes = OpportunitiesTablePropTypes;

export default memo(OpportunitiesTable);
