import {
  forwardRef, memo, useState, useCallback, useLayoutEffect, useMemo,
  type ChangeEvent, type KeyboardEvent
} from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
import ClearRounded from '@mui/icons-material/ClearRounded';
// local imports
import { isEmptyString } from '../helpers/strings';
// SCSS imports
import { root } from '../styles/modules/Lookup.module.scss';
import { clearButton } from './FilterSearch.module.scss';

type FilterSearchProps = {
  type: string;
  value: string;
  onChange: (id: string) => void;
  disabled?: boolean | null;
};

const FilterSearchPropTypes = {
  // attributes
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const FilterSearch = forwardRef<HTMLDivElement, FilterSearchProps>(({
  type,
  value,
  onChange,
  disabled = false
}, ref) => {
  const { formatMessage } = useIntl();

  const [text, setText] = useState(value);
  useLayoutEffect(() => setText(value), [value]);

  const handleChange = useCallback((
    event: ChangeEvent<{ name?: string; value: unknown; }>
  ) => setText(
    toString(event.target.value)
  ), []);

  const handleSearch = useCallback(() => {
    onChange(text);
  }, [text, onChange]);

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  const inputProps = useMemo(() => ({
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') (document?.activeElement as HTMLInputElement)?.blur();
    }
  }), []);

  return (
    <FormControl
        ref={ref}
        variant="outlined"
        size="small"
        disabled={disabled ? true : undefined}
        className={root}
    >
      <InputLabel htmlFor={`${type}-search`}>
        <FormattedMessage id={`filter.${type}.label`}/>
      </InputLabel>
      <OutlinedInput
          id={`${type}-search`}
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleSearch}
          inputProps={inputProps}
          endAdornment={
            <InputAdornment position="end">
              {!isEmptyString(value) && !isEmptyString(text) && value === text ? (
                <IconButton
                    aria-label={formatMessage({ id: 'lookup.text.clear' })}
                    color="inherit"
                    size="small"
                    edge="end"
                    onClick={handleClear}
                    disabled={disabled ? true : undefined}
                    className={clearButton}
                >
                  <ClearRounded/>
                </IconButton>
              ) : undefined}
              <IconButton
                  aria-label={formatMessage({ id: 'lookup.text.search' })}
                  color="primary"
                  size="large"
                  onClick={handleSearch}
                  disabled={disabled ? true : undefined}
              >
                <SearchIcon/>
              </IconButton>
            </InputAdornment>
          }
      />
    </FormControl>
  );
});

FilterSearch.displayName = 'FilterSearch';

FilterSearch.propTypes = FilterSearchPropTypes;

export default memo(FilterSearch);
