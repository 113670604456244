import { forwardRef, memo, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { REDEPLOYMENT_PLANS_QUERY } from '../graphql/RedeploymentPlans';
import { NEW_REDEPLOYMENT_PLAN } from '../graphql/NewRedeploymentPlan';
import { RedeploymentPlansQueryVariables, RedeploymentPlansDocument, NewRedeploymentPlanDocument } from '../graphql/types';
import { JobLookupItem, RedeploymentDetails } from '../graphql/customTypes';
import { getSelectedLeaderId } from '../models/user';
import { PATH_HR_REDEPLOYMENT } from '../config/paths';
import { GlobalContext } from '../context/global';
import LargeButton from '../elements/LargeButton';
import TbTeamCard from '../v3/TbTeamCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import AddJobDialog from '../widgets/AddJobDialog';
// SCSS imports
import { footer } from './RedeploymentPlansPanel.module.scss';

const componentProps: Partial<GetComponentProps<typeof TbTeamCard>> = {
  hrbp: true,
  variant: 'redeployment'
};

const RedeploymentPlansPanel = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { user: { data: user } } = useContext(GlobalContext);
  const leaderUid = useMemo(() => getSelectedLeaderId(user), [user]);

  // lazy load redeployment plans
  const { query: getRedeploymentPlans, pending, failed, count, results: plans, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as RedeploymentDetails,
    key: 'redeploymentPlans',
    lazyQuery: useLazyQuery(REDEPLOYMENT_PLANS_QUERY as typeof RedeploymentPlansDocument)
  });
  // new redeployment plan
  const { mutate: newPlan, loading: newPending, failed: newFailed } = useMutationMethod({
    key: 'newRedeploymentPlan',
    mutation: useMutation(NEW_REDEPLOYMENT_PLAN as typeof NewRedeploymentPlanDocument)
  });
  const disabled = pending || newPending;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();
  // Add Job dialog
  const [isOpen, setIsOpen] = useState(false);
  const handleAddOpen = useCallback(() => setIsOpen(true), []);
  const handleAddClose = useCallback(() => setIsOpen(false), []);

  const handleAddJob = useCallback((job?: JobLookupItem | null) => {
    setIsOpen(false);
    if (job?.id) newPlan?.({
      variables: { input: {
        job_id: job.id,
        title: formatMessage({ id: 'hr.redeployment.default_title' }, { title: job.title || '…' }),
        selected_leader_id: leaderUid
      } },
      update: (cache) => cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlans' }),
      onCompleted: (data) => {
        const plan_id = data?.newRedeploymentPlan?.id;
        if (plan_id) navigate(injectParams(PATH_HR_REDEPLOYMENT, { plan_id }));
      }
    });
  }, [navigate, leaderUid, newPlan, formatMessage]);

  useEffect(() => {
    if (pageSize && getRedeploymentPlans) {
      const variables: RedeploymentPlansQueryVariables = {
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getRedeploymentPlans({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, getRedeploymentPlans]); // ignoring `prevVars` changes

  return (
    <>
      <CardTitle title="hr.redeployment.title"/>
      <CardsGrid
          ref={ref}
          items={plans}
          variant="shady"
          withReloading
          pending={pending}
          failed={failed}
          disabled={disabled}
          blendNotFound
          blendPagination
          component={TbTeamCard}
          ComponentProps={componentProps}
          notFoundMessage="hr.redeployment.empty"
          pagination={(
            <PaginationControls
                settingsId="redeployment"
                loaded={Boolean(plans)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={pending || failed}
                totalMessage="hr.redeployment.pagination"
            />
          )}
      />
      <CardFooter className={footer}>
        <LargeButton
            title="hr.redeployment.build_new"
            onClick={handleAddOpen}
            disabled={disabled}
        />
      </CardFooter>
      <AddJobDialog
          redeployment
          isOpen={isOpen}
          onAdd={handleAddJob}
          onCancel={handleAddClose}
          disabled={newPending}
      />
      <ActionFailedAlert
          message="hr.redeployment.new_plan_error"
          open={newFailed}
      />
    </>
  );
});

RedeploymentPlansPanel.displayName = 'RedeploymentPlansPanel';

export default memo(RedeploymentPlansPanel);
