import { memo, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import transform from 'lodash/transform';
// Skillmore UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { OpportunitySkillStatus, SkillActivity, DevPlanTargetSkill } from '../graphql/types';
import { DevPlanAdvisor, DevPlanCourse, DevPlanOpportunity } from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import DevPlanAccordionSkill from '../elements/DevPlanAccordionSkill';

type SkillCachedData = {
  courses?: DevPlanCourse[];
  advisors?: DevPlanAdvisor[];
  opportunities?: DevPlanOpportunity[];
  activities?: SkillActivity[];
  satisfied?: boolean;
}

type DevPlanAccordionProps = {
  isMyPlan?: boolean;
  skills?: DevPlanTargetSkill[] | null;
  courses?: DevPlanCourse[];
  advisors?: DevPlanAdvisor[];
  opportunities?: DevPlanOpportunity[];
  activities?: SkillActivity[];
  pending?: boolean | null;
  failed?: boolean | null;
  disabled?: boolean;
  onCourse: (course: DevPlanCourse) => void;
  onAdvisor: (advisor: DevPlanAdvisor) => void;
  onOpportunity: (opportunity: DevPlanOpportunity) => void;
  onActivity: (activity: SkillActivity) => void;
  courseHighlighRating?: number;
  advisorHighlightCount?: number;
}

const DevPlanAccordionPropTypes = {
  isMyPlan: PropTypes.bool,
  skills: PropTypes.array,
  courses: PropTypes.array,
  advisors: PropTypes.array,
  opportunities: PropTypes.array,
  activities: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  disabled: PropTypes.bool,
  onCourse: PropTypes.func.isRequired,
  onAdvisor: PropTypes.func.isRequired,
  onOpportunity: PropTypes.func.isRequired,
  onActivity: PropTypes.func.isRequired,
  courseHighlighRating: PropTypes.number,
  advisorHighlightCount: PropTypes.number
};

const DevPlanAccordion: FunctionComponent<DevPlanAccordionProps> = ({
  isMyPlan = false,
  skills,
  courses,
  advisors,
  opportunities,
  activities,
  pending = false,
  failed = false,
  disabled = false,
  onCourse,
  onAdvisor,
  onOpportunity,
  onActivity,
  courseHighlighRating,
  advisorHighlightCount
}) => {
  const { HAS_MENTORING, HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  // const defaultSkillId = skills?.[0]?.id;

  const skillsData: Record<number, SkillCachedData> | null = useMemo(() => skills
    ? transform(skills, (result, { id, skill_proficiency_level }) => {
        const expectedLevel = skill_proficiency_level;
        let satisfied = !expectedLevel;
        const skillCourses = HAS_COURSES ? filter(courses, ({ covered_skills, is_selected }) => {
          const skl = find(covered_skills, ['id', id]);
          if (skl && is_selected && skl.current_level && expectedLevel && skl.current_level >= expectedLevel) satisfied = true;
          return Boolean(skl);
        }) : [];
        const skillAdvisors = HAS_MENTORING ? filter(advisors, ({ advisory_skills, is_selected }) => {
          const skl = find(advisory_skills, ['id', id]);
          if (skl && is_selected && expectedLevel && (skl.current_level || 0) >= expectedLevel) satisfied = true;
          return Boolean(skl);
        }) : [];
        const skillOpportunities = HAS_DEV_PLAN_OPPORTUNITIES ? filter(opportunities, ({ skills: oppSkills, is_selected }) => {
          const skl = find(oppSkills, { id, status: OpportunitySkillStatus.growth });
          if (skl && is_selected && expectedLevel && (skl.skill_proficiency_level || 0) >= expectedLevel) satisfied = true;
          return Boolean(skl);
        }) : [];
        const skillActivities = filter(activities, ({ skills: activitySkills, is_selected }) => {
          const skl = find(activitySkills, ['id', id]);
          if (skl && is_selected && expectedLevel && (skl.skill_proficiency_level || 0) >= expectedLevel) satisfied = true;
          return Boolean(skl);
        });
        result[id] = {
          courses: skillCourses,
          advisors: skillAdvisors,
          opportunities: skillOpportunities,
          activities: skillActivities,
          satisfied
        };
      }, {} as Record<number, SkillCachedData>)
    : null, [advisors, courses, opportunities, skills, activities, HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  return (failed && <FetchFailedAlert flat/>) || ((pending || !skillsData) && <LoadingPlaceholder flat/>) || (
    <>
      {map(skills, (skill) => (
        <DevPlanAccordionSkill
            key={skill.id}
            skill={skill}
            isMyPlan={isMyPlan}
            courses={(HAS_COURSES && skillsData?.[skill.id]?.courses) || []}
            advisors={(HAS_MENTORING && skillsData?.[skill.id]?.advisors) || []}
            opportunities={(HAS_DEV_PLAN_OPPORTUNITIES && skillsData?.[skill.id]?.opportunities) || []}
            activities={skillsData?.[skill.id]?.activities || []}
            satisfied={skillsData?.[skill.id]?.satisfied}
            disabled={disabled}
            onCourse={onCourse}
            onAdvisor={onAdvisor}
            onOpportunity={onOpportunity}
            onActivity={onActivity}
            defaultSelected // TODO: save open state during data reload? // ={defaultSkillId === skill.id}
            courseHighlighRating={courseHighlighRating}
            advisorHighlightCount={advisorHighlightCount}
        />
      ))}
    </>
  );
};

DevPlanAccordion.propTypes = DevPlanAccordionPropTypes;

export default memo(DevPlanAccordion);
