import { memo, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { TalentEmployeeObject, DevPlanEmployee } from '../graphql/customTypes';
import { GlobalContext } from '../context/global';
import CohortAvatar from '../elements/CohortAvatar';
import CardsGrid from '../v3/CardsGrid';
import EmployeeCard from '../v3/EmployeeCard';
// SCSS imports
import { shadyBg } from '@empathco/ui-components/src/styles/modules/ItemsGrid.module.scss';
import { titlePadding } from './DevPlanCohort.module.scss';

type DevPlanCohortProps = {
  title?: string | null;
  employees?: DevPlanEmployee[] | TalentEmployeeObject[] | null;
  withMatchRate?: boolean;
  disabled?: boolean | null;
  pending?: boolean | null;
  failed?: boolean | null;
}

const DevPlanCohortPropTypes = {
  title: PropTypes.string,
  employees: PropTypes.arrayOf(PropTypes.object) as Validator<DevPlanEmployee[] | TalentEmployeeObject[]>,
  withMatchRate: PropTypes.bool,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool
};

const DevPlanCohort: FunctionComponent<DevPlanCohortProps> = ({
  title,
  employees,
  withMatchRate = false,
  disabled = false,
  pending = false,
  failed = false
}) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    ...withMatchRate ? { matchRateVariant: 'planned' } : {},
    avatarVariant: 'grey',
    route: supvEmplPath,
    withDetailsPopup: true
  }), [supvEmplPath, withMatchRate]);

  return (
    <Box color="text.label" className={shadyBg}>
      <CardTitle
          title={(
            <Typography variant="subtitle1" component="span">
              <Box color="secondary.main" component="span">
                {title}
              </Box>
            </Typography>
          )}
          avatar={<CohortAvatar/>}
          className={(pending && !employees) || failed ? undefined : titlePadding}
      />
      <CardsGrid
          items={employees as TalentEmployeeObject[] | null}
          variant="white"
          withReloading
          notFoundMessage="hr.cohort.empty"
          withoutTopPadding
          blendFilters
          disabled={disabled}
          pending={pending}
          failed={failed}
          component={EmployeeCard}
          ComponentProps={componentProps}
      />
    </Box>
  );
};

DevPlanCohort.propTypes = DevPlanCohortPropTypes;

export default memo(DevPlanCohort);
