import {
  forwardRef, memo, useContext, useRef, useState, useEffect, useCallback, useMemo,
  type SyntheticEvent, type ElementType
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
// Material Icon imports
import Person from '@mui/icons-material/Person';
import ExpandMore from '@mui/icons-material/ExpandMore';
// Skillmore UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getInitials, getName } from '@empathco/ui-components/src/helpers/strings';
import { DEFAULT_MENU_RIGHT } from '@empathco/ui-components/src/helpers/menus';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
// local imports
import { isNotContractor, isEmployee, isLeaderOrHR } from '../models/user';
import useCustomerSettings from '../config/customer';
import usePathConfig, { PATH_MY_PREFERENCES, PATH_HR_DELEGATION, PATH_JOB } from '../config/paths';
import { removeAuthToken } from '../helpers/storage';
import { GlobalContext } from '../context/global';
// SCSS imports
import { caret, caretOpen } from '@empathco/ui-components/src/styles/modules/Toolbar.module.scss';
import { avatar, listItemText, listItemTypography, divider } from './UserDropdown.module.scss';

const primaryTypographyProps = {
  variant: 'subtitle2' as Variant,
  color: 'secondary',
  className: listItemTypography
};
const secondaryTypographyProps = { component: 'div' as ElementType };

type UserDropdownProps = {
  disableLogout?: boolean;
  active?: boolean;
}

const UserDropdownPropTypes = {
  disableLogout: PropTypes.bool,
  // for Storybook only
  active: PropTypes.bool
};

// eslint-disable-next-line complexity
const UserDropdown = forwardRef<HTMLButtonElement, UserDropdownProps>(({
  disableLogout = false,
  active = false
}, ref) => {
  const { HAS_LOGOUT } = useCustomerSettings();
  const { PATH_LOGOUT_LINK } = usePathConfig();

  const { user: { data: user, pending: userPending } } = useContext(GlobalContext);
  const theme = useTheme();

  const innerRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useCombinedRefs<HTMLButtonElement>(ref, innerRef);

  const [open, setOpen] = useState(false);

  const { first_name, last_name, current_job } = user || {};

  const nonReducedUI = isNotContractor(user);
  const isEmpl = isEmployee(user);
  const hrbpOrLeader = isLeaderOrHR(user);

  const [userInitials, firstName, lastName] = useMemo(
    () => [
      getInitials(first_name, last_name),
      getName(first_name),
      getName(last_name)
    ],
    [first_name, last_name]
  );

  // for Storybook only
  useEffect(() => {
    if (active) setOpen(true);
  }, [active]);

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

  const handleClose = useCallback((event: SyntheticEvent) => {
    if (innerRef.current && event && innerRef.current.contains(event.target as Node)) return;
    setOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    setOpen(false);
    removeAuthToken();
    window.location.assign(PATH_LOGOUT_LINK);
  }, [PATH_LOGOUT_LINK]);

  const pending = userPending || !user;

  const icon = (
    <Avatar className={avatar}>
      <Person color="primary" fontSize="inherit"/>
    </Avatar>
  );

  return (
    <>
      <IconButton
          ref={anchorRef}
          color="primary"
          size="large"
          aria-haspopup="true"
          disabled={pending}
          onClick={pending ? undefined : handleToggle}
      >
        {(pending && <CircularProgress size={theme.typography.h1.fontSize} color="inherit"/>) || (userInitials && (
          <Avatar className={avatar}>
            {userInitials}
          </Avatar>
        )) || icon}
        {!pending && (
          <ExpandMore
              color="primary"
              fontSize="large"
              className={clsx(caret, {
                [caretOpen]: open
              })}
          />
        )}
      </IconButton>
      <Menu
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...DEFAULT_MENU_RIGHT}
          anchorEl={innerRef.current}
          open={Boolean(open && !pending)}
          onClose={handleClose}
          variant="menu"
      >
        <ListItem>
          <ListItemIcon>
            {nonReducedUI ? icon : undefined}
          </ListItemIcon>
          <ListItemText
              primary={<FormattedMessage id="common.full_name" values={{ first: firstName, last: lastName }}/>}
              secondary={nonReducedUI && current_job ? (
                <TruncatedTextLink
                    onClick={current_job.code ? handleClose : undefined}
                    variant="subtitle2"
                    to={current_job.code ? injectParams(PATH_JOB, { role_id: current_job.code }) : undefined}
                    text={current_job.title}
                    maxLines={1}
                />
              ) : undefined}
              secondaryTypographyProps={secondaryTypographyProps}
          />
          {!nonReducedUI && <ListItemSecondaryAction/>}
        </ListItem>
        {isEmpl ? <Divider className={divider}/> : undefined}
        {isEmpl ? (
          <ListItemButton component={RouterLink} to={PATH_MY_PREFERENCES}>
            <ListItemIcon/>
            <ListItemText
                primary={<FormattedMessage id="header.preferences"/>}
                primaryTypographyProps={primaryTypographyProps}
                className={listItemText}
            />
          </ListItemButton>
        ) : undefined}
        {hrbpOrLeader ? <Divider className={divider}/> : undefined}
        {hrbpOrLeader ? (
          <ListItemButton component={RouterLink} to={PATH_HR_DELEGATION}>
            <ListItemIcon/>
            <ListItemText
                primary={<FormattedMessage id="header.delegation"/>}
                primaryTypographyProps={primaryTypographyProps}
                className={listItemText}
            />
          </ListItemButton>
        ) : undefined}
        {HAS_LOGOUT && !disableLogout ? <Divider className={divider}/> : undefined}
        {HAS_LOGOUT && !disableLogout ? (
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon/>
            <ListItemText
                primary={<FormattedMessage id="header.logout"/>}
                primaryTypographyProps={primaryTypographyProps}
                className={listItemText}
            />
          </ListItemButton>
        ) : undefined}
      </Menu>
    </>
  );
});

UserDropdown.displayName = 'UserDropdown';

UserDropdown.propTypes = UserDropdownPropTypes;

export default memo(UserDropdown);
