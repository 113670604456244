import { memo, useCallback, useEffect, useState, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
// Skillmore UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
// import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import { CONST_JOBS, toValidConst } from '../constants/constValues';
import { DA_SKILLS_INSIGHTS_INDEMAND_QUERY } from '../graphql/DASkillsInsightsInDemand';
import { DA_SKILLS_INSIGHTS_QUERY } from '../graphql/DASkillsInsights';
import { DA_JOBS_MOVEMENT_QUERY } from '../graphql/DAJobsMovement';
import { DA_HIRING_TRENDS_QUERY } from '../graphql/DAHiringTrends';
import { DA_JOB_RELATED_SKILLS_QUERY } from '../graphql/DAJobRelatedSkills';
import {
  JobInDemand, JobsMovementOrder, SkillType,
  DASkillsInsightsDocument, DASkillsInsightsQuery,
  DASkillsInsightsInDemandDocument, DASkillsInsightsInDemandQuery,
  DAJobsMovementDocument, DAJobsMovementQuery,
  DAHiringTrendsDocument, DAHiringTrendsQuery,
  DAJobRelatedSkillsDocument, DAJobRelatedSkillsQuery
} from '../graphql/types';
import { JOBS_MOVEMENT_ORDER, JOB_RELATED_SKILLS_ORDERS } from '../graphql/customTypes';
import {
  DA_FREQ_INDEMAND, DA_FREQ_SKILL_GAP, DA_HIRING_TRENDS, DA_JOBS_MOVEMENT, DA_JOB_RELATED_SKILLS
} from '../constants/dashboardAnalytics';
import useCustomerSettings from '../config/customer';
import {
  API_DA_HIRING_TRENDS_EXPORT, API_DA_JOBS_MOVEMENT_EXPORT, API_DA_JOB_RELATED_SKILLS_EXPORT,
  API_DA_SKILLS_INSIGHTS_EXPORT, API_DA_SKILLS_INSIGHTS_INDEMAND_EXPORT
} from '../config/api';
import { PATH_JOB, PATH_SKILL } from '../config/paths';
import { FilterValues } from '../hooks/useFilters';
import { GlobalContext } from '../context/global';
import ConstSelector from '../elements/ConstSelector';
import SortSelector from '../elements/SortSelector';
import TopChart, { TopChartProps } from '../widgets/TopChart';
// SCSS imports
import { durationSelector } from './DashboardAnalyticsJobs.module.scss';

type DashboardAnalyticsJobsProps = {
  chartId: number;
  skillType: SkillType;
  filters?: FilterValues;
  years: number;
  pending?: boolean | null;
  disabled?: boolean | null;
  onEmployeeCountChange?: (value: number | null | undefined) => void;
  pinned?: number[];
  onPin: (value: number) => void;
  pinning?: boolean;
}

const DashboardAnalyticsJobsPropTypes = {
  chartId: PropTypes.number.isRequired,
  skillType: PropTypes.string.isRequired as Validator<SkillType>,
  filters: PropTypes.object as Validator<FilterValues>,
  years: PropTypes.number.isRequired,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  onEmployeeCountChange: PropTypes.func,
  pinned: PropTypes.array,
  onPin: PropTypes.func.isRequired,
  pinning: PropTypes.bool
};

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const DashboardAnalyticsJobs: FunctionComponent<DashboardAnalyticsJobsProps> = ({
  chartId,
  skillType,
  filters,
  years,
  pending: parentPending = false,
  disabled = false,
  onEmployeeCountChange,
  pinned,
  onPin,
  pinning = false
}) => {
  const { DA_TABS, HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { fontsLoaded, token } = useContext(GlobalContext);
  const pending = parentPending || !fontsLoaded;

  // lazy load Skills Insights In-Demand data
  const {
    query: getSkillsInsightsInDemand, pending: pendingSkillsInsightsInDemand, failed: failedSkillsInsightsInDemand,
    results: skillsInsightsInDemand
  } = useQueryObject({
    data: undefined as unknown as DASkillsInsightsInDemandQuery['daSkillsInsightsInDemand'],
    key: 'daSkillsInsightsInDemand',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILLS_INSIGHTS_INDEMAND_QUERY as typeof DASkillsInsightsInDemandDocument)
  });

  // lazy load Skills Insights data
  const {
    query: getSkillsInsights, pending: pendingSkillsInsights, failed: failedSkillsInsights, results: skillsInsights
  } = useQueryObject({
    data: undefined as unknown as DASkillsInsightsQuery['daSkillsInsights'],
    key: 'daSkillsInsights',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_SKILLS_INSIGHTS_QUERY as typeof DASkillsInsightsDocument)
  });

  // lazy load Hiring Trends data
  const {
    query: getHiringTrends, pending: pendingHiringTrends, failed: failedHiringTrends, results: hiringTrends
  } = useQueryObject({
    data: undefined as unknown as DAHiringTrendsQuery['daHiringTrends'],
    key: 'daHiringTrends',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_HIRING_TRENDS_QUERY as typeof DAHiringTrendsDocument)
  });

  // lazy load Jobs Movement data
  const {
    query: getJobsMovement, pending: pendingJobsMovement, failed: failedJobsMovement, results: jobsMovement
  } = useQueryObject({
    data: undefined as unknown as DAJobsMovementQuery['daJobsMovement'],
    key: 'daJobsMovement',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_JOBS_MOVEMENT_QUERY as typeof DAJobsMovementDocument)
  });

  // lazy load Jobs Movement data
  const {
    query: getJobSkills, pending: pendingJobSkills, failed: failedJobSkills, results: jobSkills
  } = useQueryObject({
    data: undefined as unknown as DAJobRelatedSkillsQuery['daJobRelatedSkills'],
    key: 'daJobRelatedSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_JOB_RELATED_SKILLS_QUERY as typeof DAJobRelatedSkillsDocument)
  });

  // Skills Insights parameters:
  // const [transfersOnly, setTransfersOnly] = useState(false);
  // Hiring Trends parameters:
  const [jobType, setJobType] = useState(JOBS_MOVEMENT_ORDER[1]);
  // Jobs Movement parameters:
  const [jobOrder, setJobOrder] = useState(JOBS_MOVEMENT_ORDER[0]);
  const [jobTotal, setJobTotal] = useState<number>(CONST_JOBS[1]);
  // Job Related Skills parameters:
  const [jobSkillsOrder, setJobSkillsOrder] = useState(JOB_RELATED_SKILLS_ORDERS[0]);
  const [jobSkillsTotal, setJobSkillsTotal] = useState<number>(CONST_JOBS[1]);

  const handleJobTotalChange = useCallback((event: SelectChangeEvent<number>) =>
    setJobTotal(toValidConst(event?.target?.value, CONST_JOBS[1], CONST_JOBS)), []);
  const handleJobSkillsTotalChange = useCallback((event: SelectChangeEvent<number>) =>
    setJobSkillsTotal(toValidConst(event?.target?.value, CONST_JOBS[1], CONST_JOBS)), []);

  useEffect(() => {
    onEmployeeCountChange?.({
      [DA_FREQ_INDEMAND]: !failedSkillsInsightsInDemand && isNil(skillsInsightsInDemand?.total_employees)
        ? null : skillsInsightsInDemand?.total_employees,
      [DA_FREQ_SKILL_GAP]: !failedSkillsInsights && isNil(skillsInsights?.total_employees)
        ? null : skillsInsights?.total_employees,
      [DA_HIRING_TRENDS]: !failedHiringTrends && isNil(hiringTrends?.total_employees) ? null : hiringTrends?.total_employees,
      [DA_JOBS_MOVEMENT]: !failedJobsMovement && isNil(jobsMovement?.total_employees) ? null : jobsMovement?.total_employees,
      [DA_JOB_RELATED_SKILLS]: !failedJobSkills && isNil(jobSkills?.total_employees) ? null : jobSkills?.total_employees
    }[chartId]);
  }, [
    chartId,
    skillsInsightsInDemand?.total_employees, failedSkillsInsightsInDemand,
    skillsInsights?.total_employees, failedSkillsInsights,
    hiringTrends?.total_employees, failedHiringTrends,
    jobsMovement?.total_employees, failedJobsMovement,
    jobSkills?.total_employees, failedJobSkills,
    onEmployeeCountChange
  ]);

  const [
    skillsInsightsExportParams,
    hiringTrendsExportParams,
    jobsMovementExportParams,
    jobSkillsExportParams
  ] = useMemo(() => {
    if (!filters || !token || !isString(token)) return [null, null, null, null];
    const params: FilterValues & { token: string; } = { ...filters, token };
    return [
      // Skills Insights
      { ...params, /* ...transfersOnly ? { transfers_only: true } : {}, */ duration: 12 * years },
      // Hiring Trends
      { ...params,
        duration: 12 * years, ...jobType === JobsMovementOrder.target_count ? {} : { job_type: JobInDemand.most_indemand } },
      // Jobs Movement
      { ...params, job_order: jobOrder, job_total: jobTotal, duration: 12 * years },
      // Job Related Skills
      { ...params, job_order: jobSkillsOrder, job_total: jobSkillsTotal }
    ];
  }, [jobType, jobOrder, jobTotal, /* transfersOnly, */ jobSkillsOrder, jobSkillsTotal, years, filters, token]);


  // load Skills Insights In-Demand
  useEffect(() => {
    if (chartId === DA_FREQ_INDEMAND && DA_TABS?.frequent_indemand_targeted && filters) getSkillsInsightsInDemand?.({
      variables: {
        input: {
          ...filters,
          skill_type: skillType,
          // ...transfersOnly ? { transfers_only: true } : {},
          duration: 12 * years
        },
        pathBuilder: pathBuilder as unknown as string
      }
    });
  }, [chartId, filters, skillType, /* transfersOnly, */ years, getSkillsInsightsInDemand, DA_TABS?.frequent_indemand_targeted]);

  // load Skills Insights
  useEffect(() => {
    if (chartId === DA_FREQ_SKILL_GAP && DA_TABS?.frequent_skill_gap && filters) getSkillsInsights?.({
      variables: {
        input: {
          ...filters,
          // ...transfersOnly ? { transfers_only: true } : {},
          duration: 12 * years
        },
        pathBuilder: pathBuilder as unknown as string
      }
    });
  }, [chartId, filters, /* transfersOnly, */ years, getSkillsInsights, DA_TABS?.frequent_skill_gap]);

  // load Hiring Trends
  useEffect(() => {
    if (
      chartId === DA_HIRING_TRENDS && (DA_TABS?.hiring_trends_all || DA_TABS?.hiring_trends_internal) && filters
    ) getHiringTrends?.({ variables: {
      input: {
        ...filters,
        duration: 12 * years,
        job_type: jobType === JobsMovementOrder.target_count ? null : JobInDemand.most_indemand
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, jobType, years, getHiringTrends, DA_TABS?.hiring_trends_all, DA_TABS?.hiring_trends_internal]);

  // load Jobs Movement counts
  useEffect(() => {
    if (
      chartId === DA_JOBS_MOVEMENT && HAS_INDEMAND_SKILLS && DA_TABS?.jobs_movement && filters && jobOrder && jobTotal
    ) getJobsMovement?.({ variables: {
      input: {
        ...filters,
        job_order: jobOrder,
        job_total: jobTotal,
        duration: 12 * years
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, jobOrder, jobTotal, years, getJobsMovement, HAS_INDEMAND_SKILLS, DA_TABS?.jobs_movement]);

  // load Job Related Skills counts
  useEffect(() => {
    if (
      chartId === DA_JOB_RELATED_SKILLS && DA_TABS?.job_related_skills && filters && jobSkillsOrder && jobSkillsTotal
    ) getJobSkills?.({ variables: {
      input: {
        ...filters,
        job_order: jobSkillsOrder,
        job_total: jobSkillsTotal
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, jobSkillsOrder, jobSkillsTotal, getJobSkills, DA_TABS?.job_related_skills]);

  const optionalParams = useMemo(() => ({ years }), [years]);

  const handlePinSkillsInsightsInDemand = useCallback(() => onPin(DA_FREQ_INDEMAND), [onPin]);
  const handlePinSkillsInsights = useCallback(() => onPin(DA_FREQ_SKILL_GAP), [onPin]);
  const handlePinHiringTrends = useCallback(() => onPin(DA_HIRING_TRENDS), [onPin]);
  const handlePinJobsMovement = useCallback(() => onPin(DA_JOBS_MOVEMENT), [onPin]);
  const handlePinJobSkills = useCallback(() => onPin(DA_JOB_RELATED_SKILLS), [onPin]);

  const hiringOptionalParams: TopChartProps['optionalParams'] = useMemo(() => ({
    internalHiresOnly: !DA_TABS?.hiring_trends_all && DA_TABS?.hiring_trends_internal
  }), [DA_TABS]);

  const exportDisabled = disabled || pending || !filters;

  return (
    <>
      {chartId === DA_FREQ_INDEMAND && DA_TABS?.frequent_indemand_targeted ? (
        <TopChart
            variant="frequent_indemand_targeted"
            hrbp
            layout="full"
            info="hr.dashboard.frequent_indemand_targeted.info"
            withInfoButton
            data={skillsInsightsInDemand?.results}
            totalEmployees={skillsInsightsInDemand?.total_employees}
            pending={pendingSkillsInsightsInDemand || pending}
            failed={failedSkillsInsightsInDemand}
            path={PATH_SKILL}
            // TODO: enable when backend is ready:
            // action={(
            //   <OnOffSwitch
            //       label="hr.dashboard.transfers_only"
            //       value={transfersOnly}
            //       onChange={setTransfersOnly}
            //       disabled // ={disabled || pendingSkillsInsightsInDemand ? true : undefined}
            //   />
            // )}
            pinned={includes(pinned, DA_FREQ_INDEMAND)}
            onPin={disabled ? undefined : handlePinSkillsInsightsInDemand}
            pinning={pinning}
            exportEndpoint={API_DA_SKILLS_INSIGHTS_INDEMAND_EXPORT}
            exportParams={skillsInsightsExportParams}
            exportDisabled={exportDisabled || pendingSkillsInsightsInDemand || failedSkillsInsightsInDemand ||
              size(skillsInsightsInDemand?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_FREQ_SKILL_GAP && DA_TABS?.frequent_skill_gap ? (
        <TopChart
            variant="frequent_skill_gap"
            hrbp
            layout="full"
            info="hr.dashboard.frequent_skill_gap.info"
            withInfoButton
            data={skillsInsights?.results}
            totalEmployees={skillsInsights?.total_employees}
            pending={pendingSkillsInsights || pending}
            failed={failedSkillsInsights}
            path={PATH_SKILL}
            // TODO: enable when backend is ready
            // action={(
            //   <OnOffSwitch
            //       label="hr.dashboard.transfers_only"
            //       value={transfersOnly}
            //       onChange={setTransfersOnly}
            //       disabled // ={disabled || pendingSkillsInsights ? true : undefined}
            //   />
            // )}
            pinned={includes(pinned, DA_FREQ_SKILL_GAP)}
            onPin={disabled ? undefined : handlePinSkillsInsights}
            pinning={pinning}
            exportEndpoint={API_DA_SKILLS_INSIGHTS_EXPORT}
            exportParams={skillsInsightsExportParams}
            exportDisabled={exportDisabled || pendingSkillsInsights || failedSkillsInsights ||
              size(skillsInsights?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_HIRING_TRENDS && (DA_TABS?.hiring_trends_all || DA_TABS?.hiring_trends_internal) ? (
        <TopChart
            variant="hiring_trends"
            hrbp
            layout="full"
            info="hr.dashboard.hiring_trends.info"
            withInfoButton
            data={hiringTrends?.jobs}
            totalEmployees={hiringTrends?.total_employees}
            pending={pendingHiringTrends || pending}
            failed={failedHiringTrends}
            path={PATH_JOB}
            optionalParams={hiringOptionalParams}
            action={(
              <SortSelector
                  variant="jobs_order"
                  value={jobType}
                  onChange={setJobType}
                  disabled={disabled || pendingHiringTrends ? true : undefined}
                  values={JOBS_MOVEMENT_ORDER}
              />
            )}
            pinned={includes(pinned, DA_HIRING_TRENDS)}
            onPin={disabled ? undefined : handlePinHiringTrends}
            pinning={pinning}
            exportEndpoint={API_DA_HIRING_TRENDS_EXPORT}
            exportParams={hiringTrendsExportParams}
            exportDisabled={exportDisabled || pendingHiringTrends || failedHiringTrends || size(hiringTrends?.jobs) < 1}
        />
      ) : undefined}
      {chartId === DA_JOBS_MOVEMENT && HAS_INDEMAND_SKILLS && DA_TABS?.jobs_movement ? (
        <TopChart
            variant="jobs_movement"
            hrbp
            layout="full"
            info="hr.dashboard.jobs_movement.info"
            withInfoButton
            data={jobsMovement?.jobs}
            totalEmployees={jobsMovement?.total_employees}
            pending={pendingJobsMovement || pending}
            failed={failedJobsMovement}
            path={PATH_JOB}
            action={(
              <>
                <Box py={1}>
                  <SortSelector
                      variant="jobs_order"
                      value={jobOrder}
                      onChange={setJobOrder}
                      disabled={disabled || pendingJobsMovement ? true : undefined}
                      values={JOBS_MOVEMENT_ORDER}
                  />
                </Box>
                <Box py={1}>
                  <ConstSelector
                      variant="jobs"
                      value={jobTotal}
                      onChange={handleJobTotalChange}
                      disabled={disabled || pendingJobsMovement ? true : undefined}
                      className={durationSelector}
                  />
                </Box>
              </>
            )}
            pinned={includes(pinned, DA_JOBS_MOVEMENT)}
            onPin={disabled ? undefined : handlePinJobsMovement}
            pinning={pinning}
            exportEndpoint={API_DA_JOBS_MOVEMENT_EXPORT}
            exportParams={jobsMovementExportParams}
            exportDisabled={exportDisabled || pendingJobsMovement || failedJobsMovement || size(jobsMovement?.jobs) < 1}
        />
      ) : undefined}
      {chartId === DA_JOB_RELATED_SKILLS && DA_TABS?.job_related_skills ? (
        <TopChart
            variant="job_skills"
            hrbp
            layout="full"
            info="hr.dashboard.job_skills.info"
            withInfoButton
            data={jobSkills?.results}
            totalEmployees={jobSkills?.total_employees}
            pending={pendingJobSkills || pending}
            failed={failedJobSkills}
            optionalParams={optionalParams}
            path={PATH_JOB}
            action={(
              <>
                <Box py={1}>
                  <SortSelector
                      variant="job_skills_order"
                      value={jobSkillsOrder}
                      onChange={setJobSkillsOrder}
                      disabled={disabled || pendingJobSkills ? true : undefined}
                      values={JOB_RELATED_SKILLS_ORDERS}
                  />
                </Box>
                <Box py={1}>
                  <ConstSelector
                      variant="jobs"
                      value={jobSkillsTotal}
                      onChange={handleJobSkillsTotalChange}
                      disabled={disabled || pendingJobSkills ? true : undefined}
                      className={durationSelector}
                  />
                </Box>
              </>
            )}
            pinned={includes(pinned, DA_JOB_RELATED_SKILLS)}
            onPin={disabled ? undefined : handlePinJobSkills}
            pinning={pinning}
            exportEndpoint={API_DA_JOB_RELATED_SKILLS_EXPORT}
            exportParams={jobSkillsExportParams}
            exportDisabled={exportDisabled || pendingJobSkills || failedJobSkills || size(jobSkills?.results) < 1}
        />
      ) : undefined}
    </>
  );
};

DashboardAnalyticsJobs.propTypes = DashboardAnalyticsJobsPropTypes;

export default memo(DashboardAnalyticsJobs);
