import { type FunctionComponent, type ReactNode, type ForwardedRef } from 'react';
// import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
// Skillmore UI Components
import { type Identified } from '@empathco/ui-components/src/models/item';
import { type Values } from '@empathco/ui-components/src/helpers/intl';
import { simpleForwardRef } from '@empathco/ui-components/src/helpers/react';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { hasFilters, JobsIndexFiltersComponent } from '../v3/JobsIndexFilters';
import { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { topPadding, bottomPadding, shadyBg } from '@empathco/ui-components/src/styles/modules/ItemsGrid.module.scss';

export const CARDS_GRID_DARK = 'dark' as const;
export const CARDS_GRID_SHADY = 'shady' as const;
export const CARDS_GRID_WHITE = 'white' as const;
const CARDS_GRID_VARIANTS = [CARDS_GRID_DARK, CARDS_GRID_SHADY, CARDS_GRID_WHITE] as const;
type CardsGridVariant = typeof CARDS_GRID_VARIANTS[number];

// Generic React Component approach from:
// https://medium.com/weekly-webtips/typescript-generic-react-components-17c71a64150e
// https://github.com/janjakubnanista/generic-select-react-component

type CardsGridProps<T, P, C> = {
  readonly items?: T[] | null;
  readonly title?: ReactNode | ReactNode[];
  readonly values?: Values;
  readonly variant?: CardsGridVariant;
  readonly withReloading?: boolean;
  readonly withoutTopPadding?: boolean | null;
  readonly notFoundMessage?: ReactNode | ReactNode[] | string | null;
  readonly pending?: boolean | null;
  readonly failed?: boolean | null;
  readonly blendNotFound?: boolean;
  readonly blendFilters?: boolean;
  readonly blendPagination?: boolean;
  readonly filters?: ReactNode | ReactNode[];
  readonly pagination?: ReactNode | ReactNode[];
  readonly disabled?: boolean | null;
  readonly removingId?: number | null;
  readonly itemsPrefix?: ReactNode | ReactNode[];
  readonly itemsSuffix?: ReactNode | ReactNode[];
  readonly ComponentProps?: Partial<P>;
  readonly component: C;
};

// const CardsGridPropTypes = {
//   // attributes
//   items: PropTypes.array,
//   title: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]) as Validator<ReactNode | ReactNode[]>,
//   values: PropTypes.object,
//   variant: PropTypes.string,
//   withReloading: PropTypes.bool,
//   withoutTopPadding: PropTypes.bool,
//   notFoundMessage: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//     PropTypes.string
//   ]) as Validator<ReactNode | ReactNode[] | string>,
//   pending: PropTypes.bool,
//   failed: PropTypes.bool,
//   blendNotFound: PropTypes.bool,
//   blendFilters: PropTypes.bool,
//   blendPagination: PropTypes.bool,
//   filters: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]) as Validator<ReactNode | ReactNode[]>,
//   pagination: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]) as Validator<ReactNode | ReactNode[]>,
//   disabled: PropTypes.bool,
//   removingId: PropTypes.number,
//   itemsPrefix: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]) as Validator<ReactNode | ReactNode[]>,
//   itemsSuffix: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]) as Validator<ReactNode | ReactNode[]>,
//   component: PropTypes.object.isRequired as Validator<FunctionComponent>,
//   ComponentProps: PropTypes.object
// };

// eslint-disable-next-line complexity
const CardsGridRender = <
  T extends Identified,
  P,
  C extends FunctionComponent<P>
>({
  items,
  title,
  values,
  variant = CARDS_GRID_DARK,
  pending = false,
  failed = false,
  blendNotFound = false,
  blendFilters = false,
  blendPagination = false,
  filters,
  pagination,
  withReloading = false,
  withoutTopPadding = false,
  notFoundMessage,
  disabled = false,
  removingId,
  itemsPrefix,
  itemsSuffix,
  ComponentProps = {} as Partial<P>,
  component: Component
}: CardsGridProps<T, P, C>, ref: ForwardedRef<HTMLDivElement>) => {
  const withFilters = hasFilters(filters as JobsIndexFiltersComponent);
  const withPagination = hasPagination(pagination as PaginationControlsComponent);
  const loading = withReloading ? pending && !items : pending || !items;
  const reloading = Boolean(withReloading && pending && items);
  const filtersContent = withFilters ? (
    <CardSection
        top
        flex
        dark={blendFilters && variant === CARDS_GRID_DARK ? true : undefined}
        light={blendFilters && variant === CARDS_GRID_SHADY ? true : undefined}
    >
      {filters}
    </CardSection>
  ) : filters;
  const content = (
    <>
      {failed || loading || (size(items) < 1 && !itemsPrefix && !itemsSuffix) ? (
        <Box
            ref={ref}
            bgcolor={variant === CARDS_GRID_DARK &&
              ((withPagination && blendPagination && loading) || (withFilters && blendFilters && (loading || failed)))
              ? 'background.card' : undefined}
            className={clsx({
              [topPadding]: withFilters && (
                failed || (loading && (blendFilters || variant !== CARDS_GRID_SHADY)) || !withoutTopPadding
              ),
              [bottomPadding]: withPagination && failed && variant === CARDS_GRID_WHITE,
              [shadyBg]: variant === CARDS_GRID_SHADY &&
                ((withPagination && loading) || (withFilters && blendFilters && (loading || failed)))
            })}
        >
          {(failed && <FetchFailedAlert flat/>) ||
          (loading && <LoadingPlaceholder flat/>) ||
          (!isNil(notFoundMessage) && !isString(notFoundMessage) && notFoundMessage) ||
          (blendNotFound && variant === CARDS_GRID_SHADY && (
            <CardSection shady>
              <BoxTypography variant="body1" color="info.caption">
                <FormattedMessage id={(isString(notFoundMessage) && notFoundMessage) || 'employees.no_employees'}/>
              </BoxTypography>
            </CardSection>
          )) || (
            <>
              {withFilters && !blendFilters ? <Divider/> : undefined}
              <Box
                  bgcolor={variant === CARDS_GRID_DARK ? 'background.card' : undefined}
                  className={variant === CARDS_GRID_SHADY ? shadyBg : undefined}
              >
                <Alert severity="info" variant="standard">
                  <FormattedMessage
                      id={(isString(notFoundMessage) && notFoundMessage) || 'employees.no_employees'}
                      values={values}
                  />
                </Alert>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <CardDeck
            ref={ref}
            title={isString(title) && size(title) >= 1 ? (
              <Typography variant="subtitle1">
                <FormattedMessage id={title} values={values} defaultMessage={title}/>
              </Typography>
            ) : title}
            dark={variant === CARDS_GRID_DARK}
            shady={variant === CARDS_GRID_SHADY}
        >
          {itemsPrefix}
          {map(items, (item) => (
            <Component
                key={item.id}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...{
                  ...ComponentProps,
                  item,
                  disabled,
                  ...removingId && removingId === item.id ? { pending: true } : {}
                } as unknown as JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<C, P>}
            />
          ))}
          {itemsSuffix}
        </CardDeck>
      )}
      {withPagination ? (
        <CardSection
            flex
            bottom={!loading && (blendPagination || variant === CARDS_GRID_WHITE)}
            dark={blendPagination && variant !== CARDS_GRID_WHITE ? true : undefined}
        >
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );
  return (
    <>
      {filtersContent}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
    </>
  );
};

const CardsGrid = simpleForwardRef(CardsGridRender);

// CardsGrid.displayName = 'CardsGrid';

// CardsGrid.propTypes = CardsGridPropTypes;

export default CardsGrid;
