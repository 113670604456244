import { forwardRef, memo, useCallback, useMemo, useState, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';

type OpportunityDetailsCountProps = {
  id: string;
  icon: ReactNode;
  count?: number | null;
  link?: string;
};

const OpportunityDetailsCountPropTypes = {
  // attributes
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired as Validator<ReactNode>,
  count: PropTypes.number,
  link: PropTypes.string
};

const OpportunityDetailsCount = forwardRef<HTMLDivElement, OpportunityDetailsCountProps>(({
  id,
  count,
  icon,
  link
}, ref) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const values = useMemo(() => ({
    count: toSafeInteger(count),
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink to={link}>
        {mapChunks(chunks)}
      </StandardLink>
    )
  }), [count, link]);

  return (
    <SimpleTooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        placement="top"
        title={formatMessage({ id: `${id}.tooltip` })}
    >
      <Box ref={ref} pr={4} display="flex" alignItems="center">
        {icon}
        <FormattedMessage id={id} values={values}/>
      </Box>
    </SimpleTooltip>
  );
});

OpportunityDetailsCount.displayName = 'OpportunityDetailsCount';

OpportunityDetailsCount.propTypes = OpportunityDetailsCountPropTypes;

export default memo(OpportunityDetailsCount);
