import { memo, useCallback, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import { useMutation } from '@apollo/client';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { UPDATE_COHORT } from '../graphql/UpdateCohort';
import { DELETE_COHORT } from '../graphql/DeleteCohort';
import { UpdateCohortDocument, DeleteCohortDocument, CohortSkillStatus } from '../graphql/types';
import { CohortDetails, TalentEmployeeObject } from '../graphql/customTypes';
import { SKILL_LEVEL_REGULAR, SkillLevel } from '../models/skill';
import {
  PATH_SKILL, PATH_HR_COHORTS, PATH_HR_COHORT_EDIT, PATH_HR_DEV_PLAN, PATH_HR_DEV_PLAN_COHORT_NEW
} from '../config/paths';
import { getCorortSkillsHasLevels } from '../helpers/graphql';
import useExportCohort from '../hooks/useExportCohort';
import { GlobalContext } from '../context/global';
import SkillChip from '../elements/SkillChip';
import ReviewBar from '../elements/ReviewBar';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';
// SCSS imports
import { btn } from './SavedCohort.module.scss';

type SavedCohortPanelProps = {
  cohort?: CohortDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
}

const SavedCohortPanelPropTypes = {
  cohort: PropTypes.object as Validator<CohortDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool
};

const SavedCohort: FunctionComponent<SavedCohortPanelProps> = ({
  cohort,
  pending = false,
  failed = false
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { exportCohort, COHORT_CSV_COLUMNS } = useExportCohort();

  const { id: cohort_id, title: parentTitle, cohort_skills, employees, dev_plan } = cohort || {} as CohortDetails;
  const { id: devplan_id } = dev_plan || {};
  const hasLevels = useMemo(() => getCorortSkillsHasLevels(cohort_skills), [cohort_skills]);

  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const { mutate: updateCohort, loading: updatePending, failed: updateFailed } = useMutationMethod({
    key: 'updateCohort',
    mutation: useMutation(UPDATE_COHORT as typeof UpdateCohortDocument)
  });

  const { mutate: deleteCohort, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_COHORT as typeof DeleteCohortDocument)
  });

  const updateTitle = useCallback(
    (newTitle?: string) => updateCohort({
      variables: { cohort_id, input: { title: newTitle ? newTitle : '' } },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohorts' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohort' });
        if (devplan_id) cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplan' });
      }
    }), [cohort_id, devplan_id, updateCohort]
  );

  const deleteThisCohort = useCallback(
    () => deleteCohort?.({
      variables: { cohort_id },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohorts' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplans' });
      },
      onCompleted: () => navigate(PATH_HR_COHORTS)
    }),
    [cohort_id, deleteCohort, navigate]
  );

  const link = useMemo(() => injectParams(PATH_HR_COHORT_EDIT, { cohort_id }), [cohort_id]);

  const getExport = useCallback((format: ExportFormat, cohortTitle: string) => {
    if (!employees || !cohort_skills || !cohortTitle) return null;
    return exportCohort(
      format,
      cohortTitle,
      map(COHORT_CSV_COLUMNS, (id) => formatMessage({ id })),
      map(cohort_skills, 'skill'),
      employees as TalentEmployeeObject[]
    );
  }, [employees, cohort_skills, formatMessage, COHORT_CSV_COLUMNS, exportCohort]);

  const handleSkillClick = useCallback((id: number, abbr: string) => {
    if (id && abbr) navigate(injectParams(PATH_SKILL, { skill_id: abbr }));
  }, [navigate]);

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    avatarVariant: 'grey',
    route: supvEmplPath,
    withDetailsPopup: true
  }), [supvEmplPath]);

  const disabled = pending || updatePending || deletePending ? true : undefined;

  return (
    <>
      {failed || pending ? (
        <CardTitle title={failed ? 'hr.cohort.title.empty' : <Skeleton variant="text" width="12rem"/>}/>
      ) : (
        <ReviewBar
            titleLabel="hr.cohort.title.label"
            title={parentTitle}
            link={link}
            // pending // handled above
            // disabled // handled above
            editLabel="hr.cohort.edit"
            onRename={updateTitle}
            renameLabel="hr.cohort.rename"
            renamePending={updatePending}
            renameFailed={updateFailed}
            renameError="hr.cohort.rename_error"
            onDelete={deleteThisCohort}
            deleteLabel="hr.cohort.delete"
            deleteTitle="hr.cohort.delete_title"
            deleteQuestion="hr.cohort.delete_question"
            deletePending={deletePending}
            deleteFailed={deleteFailed}
            deleteError="hr.cohort.delete_error"
            onExport={getExport}
            exportDisabled={!employees || !cohort_skills}
        />
      )}
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
        <>
          <CardsGrid
              items={employees}
              notFoundMessage="hr.teambuilder.no_roster"
              variant="shady"
              withoutTopPadding
              blendFilters
              component={EmployeeCard}
              ComponentProps={componentProps}
          />
          <CardSection top>
            <BoxTypography pb={1.5} variant="subtitle1">
              <FormattedMessage id="hr.cohort.skills_criteria"/>
            </BoxTypography>
          </CardSection>
          <CardSection bottom>
            {map(cohort_skills, ({ skill, skill_proficiency_level, status }) => status === CohortSkillStatus.required ? (
              <SkillChip
                  key={skill.id}
                  plain
                  skill={skill}
                  level={hasLevels ? skill_proficiency_level as SkillLevel || SKILL_LEVEL_REGULAR : undefined}
                  disabled={disabled ? true : undefined}
                  onActivate={handleSkillClick}
              />
            ) : undefined)}
          </CardSection>
          <Divider light/>
          <CardSection>
            <Box
                display="flex"
                alignItems="flex-end"
            >
              <BoxTypography pr={2} variant="subtitle1">
                <FormattedMessage id="hr.dev_plan.title"/>
              </BoxTypography>
              <BoxTypography variant="body2" fontStyle="italic" color="misc.coursesLegend">
                {dev_plan?.title || <FormattedMessage id="hr.cohort.no_dev_plan"/>}
              </BoxTypography>
            </Box>
            <Box py={2} display="flex" alignItems="center" justifyContent="center">
              <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={disabled}
                  disableElevation
                  className={btn}
                  component={RouterLink}
                  to={devplan_id
                    ? injectParams(PATH_HR_DEV_PLAN, { plan_id: devplan_id })
                    : injectParams(PATH_HR_DEV_PLAN_COHORT_NEW, { cohort_id })}
              >
                <FormattedMessage id={devplan_id ? 'hr.cohort.view_dev_plan' : 'hr.cohort.launch_dev_plan'}/>
              </Button>
            </Box>
          </CardSection>
        </>
      )}
    </>
  );
};

SavedCohort.propTypes = SavedCohortPanelPropTypes;

export default memo(SavedCohort);
