import { forwardRef, memo, useCallback, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import clsx from 'clsx';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// Skillmore UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { JOB_SEARCH_QUERY } from '../graphql/JobSearch';
import { JobSearchDocument, JobSearchQueryVariables } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { MAX_LOOKUP_OPTIONS } from '../config/params';
// SCSS imports
import { root } from './JobSearch.module.scss';

export type JobSearchVariant = 'search' | 'target' | 'skills' | 'filter';

type JobSearchProps = {
  value?: JobLookupItem | null;
  onChange: (skill: JobLookupItem | null) => void;
  disabled?: boolean | null;
  exclude?: number[] | null;
  fullWidth?: boolean;
  withSkills?: boolean;
  variant?: JobSearchVariant;
  className?: string;
}

const JobSearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<JobLookupItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  fullWidth: PropTypes.bool,
  withSkills: PropTypes.bool,
  variant: PropTypes.string as Validator<JobSearchVariant>,
  className: PropTypes.string
};

const JobSearch = forwardRef<HTMLDivElement, JobSearchProps>(({
  value,
  onChange,
  disabled = false,
  exclude,
  fullWidth = false,
  withSkills = false,
  variant,
  className
}, ref) => {
  const [searching, setSearching] = useState(false);

  // job search query
  const { query: jobSearch, pending, failed, results, variables: params } = useQueryCounted({
    data: undefined as unknown as JobLookupItem,
    key: 'jobSearch',
    lazyQuery: useLazyQuery(JOB_SEARCH_QUERY as typeof JobSearchDocument)
  });
  const loading = searching ? pending : false;

  const handleJobSearch = useCallback((variables: JobSearchQueryVariables) => {
    setSearching(true);
    jobSearch?.({ variables: {
      search: value?.code ? replace(variables.search || '', ` (${toLower(value.code)})`, '') : variables.search,
      ...withSkills ? { with_skills_only: true } : {},
      limit: MAX_LOOKUP_OPTIONS
    } });
  }, [value, withSkills, jobSearch]);

  return (
    <Lookup
        ref={ref}
        withCodeOrAbbr
        fullWidth={fullWidth}
        type={variant ? `${variant}_job` : 'jobs'}
        className={clsx(root, className)}
        fetched={results}
        pending={loading}
        failed={failed}
        params={params}
        fetch={handleJobSearch}
        exclude={exclude}
        onChange={onChange}
        value={value}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  );
});

JobSearch.displayName = 'JobSearch';

JobSearch.propTypes = JobSearchPropTypes;

export default memo(JobSearch);
