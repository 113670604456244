import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import useDragAndDropItem, { type MoveItemFunction } from '@empathco/ui-components/src/hooks/useDragAndDropItem';
// local imports
import { Skill, SkillLevel } from '../models/skill';
import SkillChip from './SkillChip';

type SkillDraggableChipProps = {
  skill: Skill;
  level?: SkillLevel | null;
  moveSkill?: MoveItemFunction;
  onActivate?: (id: number, abbr: string) => void;
  onDelete?: (id: number) => void;
  disabled?: boolean;
};

const SkillDraggableChipPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  level: PropTypes.number as Validator<SkillLevel>,
  moveSkill: PropTypes.func,
  onActivate: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool
};

const SkillDraggableChip: FunctionComponent<SkillDraggableChipProps> = ({
  skill,
  level,
  onActivate,
  onDelete,
  moveSkill,
  disabled = false
}) => {
  const { ref, isDragging } = useDragAndDropItem(skill.id, 'skill', moveSkill, disabled);
  return (
    <Box m={1}>
      <SkillChip
          ref={ref}
          skill={skill}
          level={level}
          draggable
          onActivate={onActivate}
          onDelete={onDelete}
          disabled={disabled}
          isDragging={isDragging}
      />
    </Box>
  );
};

SkillDraggableChip.propTypes = SkillDraggableChipPropTypes;

export default memo(SkillDraggableChip);
