import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toLower from 'lodash/toLower';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { SkillActivity } from '../graphql/types';
import { getActivityIcon } from '../helpers/icons';
// SCSS imports
import { icon } from './ActivityCheckbox.module.scss';

type ActivityCheckboxProps = {
  activity?: SkillActivity | null;
  onChange?: (checked: boolean, id?: number) => void;
  onOpen?: (activity?: SkillActivity) => void;
  disabled?: boolean;
}

const ActivityCheckboxPropTypes = {
  activity: PropTypes.object as Validator<SkillActivity>,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  disabled: PropTypes.bool
};

const ActivityCheckbox = forwardRef<HTMLDivElement, ActivityCheckboxProps>(({
  activity,
  onChange,
  onOpen,
  disabled = false
}, ref) => {
  const { id, name, activity_type, is_selected } = activity || {};
  const ActivityIcon = getActivityIcon(activity_type);

  const handleOpen = useCallback(() => activity && onOpen?.(activity), [activity, onOpen]);

  return (
    <Box ref={ref}
        display="flex"
        alignItems="center"
    >
      <CheckboxButton
          id={id}
          label={name}
          checked={Boolean(is_selected)}
          onChange={onChange}
          disabled={disabled ? true : undefined}
      />
      <Box
          pl={1}
          pt={0.5}
      >
        <StandardLink variant="body2" bold onClick={disabled ? undefined : handleOpen}>
          {name}
        </StandardLink>
        <BoxTypography variant="body2" display="flex" alignItems="center">
          {ActivityIcon ? <ActivityIcon fontSize="inherit" color="inherit" className={icon}/> : undefined}
          <FormattedMessage id="skill.development.activity" values={{ type: toLower(activity_type || '') || null }}/>
        </BoxTypography>
      </Box>
    </Box>
  );
});

ActivityCheckbox.propTypes = ActivityCheckboxPropTypes;

ActivityCheckbox.displayName = 'ActivityCheckbox';

export default memo(ActivityCheckbox);
