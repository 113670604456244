import { forwardRef, memo, useMemo, useCallback, type ReactNode, type MouseEventHandler, type MouseEvent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import startsWith from 'lodash/startsWith';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// local imports
import { Values, ValuesPropType } from '../helpers/intl';
import LinkWithRoute from '../mixins/LinkWithRoute';
// SCSS imports
import { link, boldLink, darkLink, activeLink, disabledLink, disabledText } from './StandardLink.module.scss';

type StandardLinkProps = {
  children?: ReactNode | ReactNode[];
  text?: string;
  values?: Values;
  bold?: boolean;
  dark?: boolean;
  active?: boolean;
  inherit?: boolean;
  variant?: Variant | 'inherit';
  to?: string;
  toState?: object;
  href?: string | null;
  withoutTarget?: boolean;
  link?: string | null;
  onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
  className?: string;
};

const StandardLinkPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  // attributes
  text: PropTypes.string, // link text translated with React Intl
  values: ValuesPropType, // values for link text translation with React Intl
  bold: PropTypes.bool,
  dark: PropTypes.bool,
  active: PropTypes.bool,
  inherit: PropTypes.bool,
  variant: PropTypes.string as Validator<Variant | 'inherit'>,
  to: PropTypes.string, // React Router link
  toState: PropTypes.object, // React Router link state
  href: PropTypes.string, // static href
  withoutTarget: PropTypes.bool, // do not add `target="_blank"` for static href
  link: PropTypes.string, // dynamic href translated with React Intl
  onClick: PropTypes.func, // click handler function
  className: PropTypes.string
};

// eslint-disable-next-line complexity
const StandardLink = forwardRef<HTMLAnchorElement, StandardLinkProps>(({
  children,
  text,
  values,
  bold = false,
  dark = false,
  active = false,
  inherit = false,
  variant,
  to,
  toState,
  href: pureHref,
  withoutTarget = false,
  link: hrefLink,
  onClick,
  className,
  ...props
}, ref) => {
  const { formatMessage } = useIntl();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement & HTMLSpanElement>): void => {
      if (to || pureHref || hrefLink) event.stopPropagation();
      else event.preventDefault();
      if (onClick) onClick(event);
    },
    [hrefLink, pureHref, to, onClick]
  );

  const [translatedText, translatedHref] = useMemo(() => [
    text && formatMessage({ id: text, defaultMessage: text }, values),
    hrefLink && formatMessage({ id: hrefLink, defaultMessage: hrefLink })
  ], [formatMessage, hrefLink, text, values]);

  if (!children && !translatedText) return null;

  const content = children || translatedText;
  const href = pureHref || translatedHref;

  const disabled = !to && !href && !onClick;

  const clsName = clsx(className, {
    [boldLink]: bold,
    [link]: !bold,
    [activeLink]: active,
    [darkLink]: dark,
    [disabledLink]: !dark && !active && disabled,
    [disabledText]: disabled
  });

  return (disabled && (
    <Typography
        ref={ref}
        className={clsName}
        variant={variant || 'inherit'}
        component="span"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
      {content}
    </Typography>
  )) || (to && (
    <LinkWithRoute
        ref={ref}
        color={active || dark || inherit ? 'inherit' : 'secondary'}
        to={to}
        state={toState}
        variant={variant}
        onClick={handleClick}
        className={clsName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
      {content}
    </LinkWithRoute>
  )) || (
    <Link
        ref={ref}
        color={active || dark || inherit ? 'inherit' : 'secondary'}
        underline="hover"
        href={href || '#'}
        onClick={handleClick}
        target={href && !withoutTarget && !startsWith(href, 'mailto:') ? '_blank' : undefined}
        rel={href ? 'noopener noreferrer' : undefined}
        variant={variant}
        className={clsName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
      {content}
    </Link>
  );
});

StandardLink.displayName = 'StandardLink';

StandardLink.propTypes = StandardLinkPropTypes;

export default memo(StandardLink);
