import { Fragment, memo, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// Skillmore UI Components
import { mapChunks, type Values } from '@empathco/ui-components/src/helpers/intl';
import { defaultDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { INotification, NotificationKind } from '../models/notification';
import useCustomerSettings from '../config/customer';
import { PATH_HR, PATH_JOB, PATH_MY_JOBS, PATH_SKILL, PATH_MY_SKILLS, PATH_MY_SKILLS_MENTORSHIP } from '../config/paths';
import { RouterLinkState } from '../helpers/routerParams';
// SCSS imports
import { timestamp } from './Notification.module.scss';

const inferredSkillsState: RouterLinkState = { viewInferredSkills: true };

type NotificationProps = {
  readonly notification: INotification;
  readonly onClick?: () => void;
};

const NotificationPropTypes = {
  // attributes
  notification: PropTypes.object.isRequired as Validator<INotification>
};

const Notification: FunctionComponent<NotificationProps> = ({
  notification
}) => {
  const { getEmployeeContactUrl } = useCustomerSettings();
  const { kind, created_at, details, description } = notification || {};

  // eslint-disable-next-line max-statements, complexity
  const values: Values | null = useMemo(() => {
    if (details) {
      switch (kind) {
        case NotificationKind.job_targeted: {
          const { object, target } = details as INotification<NotificationKind.job_targeted>['details'];
          if (object) return {
            action: target,
            job: <StandardLink to={injectParams(PATH_JOB, { role_id: object.code })} text={object.title}/>
          };
          break;
        }
        case NotificationKind.skill_targeted: {
          const { object, target } = details as INotification<NotificationKind.skill_targeted>['details'];
          if (object) return {
            action: target,
            skill: <StandardLink to={injectParams(PATH_SKILL, { skill_id: object.code })} text={object.title}/>
          };
          break;
        }
        case NotificationKind.new_inferred_skills:
        case NotificationKind.new_suggested_skills: {
          const { objects } = details as (
            INotification<NotificationKind.new_inferred_skills>['details'] |
            INotification<NotificationKind.new_suggested_skills>['details']
          );
          if (size(objects) >= 1) return {
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink
                  to={PATH_MY_SKILLS}
                  toState={kind === NotificationKind.new_inferred_skills ? inferredSkillsState : undefined}
              >
                {mapChunks(chunks)}
              </StandardLink>
            ),
            skills: (
              <>
                {map(objects, ({ id, code, title }, idx) => (
                  <Fragment key={id}>
                    <StandardLink to={injectParams(PATH_SKILL, { skill_id: code })} text={title}/>
                    {idx + 1 < objects.length ? ', ' : undefined}
                  </Fragment>
                ))}
              </>
            )
          };
          break;
        }
        case NotificationKind.new_suggested_skill_by_manager: {
          const { object } = details as INotification<NotificationKind.new_suggested_skill_by_manager>['details'];
          if (object) return {
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink to={PATH_MY_SKILLS}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            skill: <StandardLink to={injectParams(PATH_SKILL, { skill_id: object.code })} text={object.title}/>
          };
          break;
        }
        case NotificationKind.new_job_matches: {
          return {
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink to={PATH_MY_JOBS}>
                {mapChunks(chunks)}
              </StandardLink>
            )
          };
          break;
        }
        case NotificationKind.new_open_reqs_for_target_job: {
          const { object } = details as INotification<NotificationKind.new_suggested_skill_by_manager>['details'];
          if (object) return {
            job: <StandardLink to={injectParams(PATH_JOB, { role_id: object.code })} text={object.title}/>
          };
          break;
        }
        case NotificationKind.delegation_created:
        case NotificationKind.delegation_updated: {
          const { object } = details as (
            INotification<NotificationKind.delegation_created>['details'] |
            INotification<NotificationKind.delegation_updated>['details']
          );
          const { hrbp_id, hrbp_title, hrbp_email } = details as INotification<NotificationKind.delegation_updated>['details'];
          if (object) return {
            code: object.code,
            title: object.title,
            hrbpCode: hrbp_id,
            hrbpTitle: hrbp_title,
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink to={PATH_HR}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            leader: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink href={getEmployeeContactUrl(object.code, object.email)}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            hrbp: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink href={getEmployeeContactUrl(hrbp_id, hrbp_email)}>
                {mapChunks(chunks)}
              </StandardLink>
            )
          };
          break;
        }
        case NotificationKind.accept_mentorship: {
          const {
            mentor_id, mentor_title, mentor_email, object
          } = details as INotification<NotificationKind.accept_mentorship>['details'];
          if (object) return {
            br: <br/>,
            title: mentor_title,
            code: mentor_id,
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink to={PATH_MY_SKILLS_MENTORSHIP}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            mentor: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink href={getEmployeeContactUrl(mentor_id, mentor_email)}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            skill: <StandardLink to={injectParams(PATH_SKILL, { skill_id: object.code })} text={object.title}/>
          };
          break;
        }
        case NotificationKind.new_advisee: {
          const {
            advisee_id, advisee_title, advisee_email, object
          } = details as INotification<NotificationKind.new_advisee>['details'];
          if (object) return {
            br: <br/>,
            title: advisee_title,
            code: advisee_id,
            // eslint-disable-next-line react/no-unstable-nested-components
            link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink to={PATH_MY_SKILLS_MENTORSHIP}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            advisee: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
              <StandardLink href={getEmployeeContactUrl(advisee_id, advisee_email)}>
                {mapChunks(chunks)}
              </StandardLink>
            ),
            skill: <StandardLink to={injectParams(PATH_SKILL, { skill_id: object.code })} text={object.title}/>
          };
          break;
        }
        default: break;
      }
    }
    return null;
  }, [details, kind, getEmployeeContactUrl]);

  return (
    <>
      <Box pt={1.5} className={timestamp}>
        {created_at ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <FormattedDate value={getJsDateFromISO(created_at)} {...defaultDateOptions}/>
        ) : undefined}
      </Box>
      <Box py={1.5} pb={2}>
        {values ? (
          <FormattedMessage
              id={`notifications.${kind}`}
              values={values}
              defaultMessage={description}
          />
        ) : description || ''}
      </Box>
      <Box py={1.5}>
        <Divider/>
      </Box>
    </>
  );
};

Notification.propTypes = NotificationPropTypes;

export default memo(Notification);
