import { forwardRef, memo, type Component } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// Skillmore UI Components
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { DevPlanTargetSkill } from '../graphql/types';
import { DevPlanTarget } from '../graphql/customTypes';
import { SkillLevel } from '../models/skill';
import { CategoryChipLeftTransparent } from '../elements/CategoryChip';
import SkillChip from '../elements/SkillChip';

type SkillsSelectorProps = {
  caption: string;
  target: DevPlanTarget | null;
  targetTitle?: string;
  targetGroupId: number;
  targetedSkills?: DevPlanTargetSkill[] | null;
  satisfiedSkills?: Record<string, boolean> | null;
  selectedSkills?: Record<string, boolean> | null;
  disabled?: boolean;
  pending?: boolean;
  onTargetClick?: () => void;
  onTargetChange: () => void;
  onSelect: (id: number, abbr: string) => void;
}

const SkillsSelectorPropTypes = {
  caption: PropTypes.string.isRequired,
  target: PropTypes.string as Validator<DevPlanTarget>,
  targetTitle: PropTypes.string,
  targetGroupId: PropTypes.number.isRequired,
  targetedSkills: PropTypes.array as Validator<DevPlanTargetSkill[]>,
  satisfiedSkills: PropTypes.object as Validator<Record<string, boolean>>,
  selectedSkills: PropTypes.object as Validator<Record<string, boolean>>,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  onTargetClick: PropTypes.func,
  onTargetChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

// eslint-disable-next-line complexity
const SkillsSelector = forwardRef<Component<BoxProps>, SkillsSelectorProps>(({
  caption,
  target,
  targetTitle,
  targetGroupId,
  targetedSkills,
  satisfiedSkills,
  selectedSkills,
  disabled = false,
  pending = false,
  onTargetClick,
  onTargetChange,
  onSelect
}, ref) => (
  <>
    <CardSection ref={ref} shady top={pending}>
      {target ? (
        <Box pb={1}>
          <Typography variant="h4" component="span">
            <FormattedMessage
                id="hr.dev_plan.target"
                values={{
                  target,
                  title: (
                    <CategoryChipLeftTransparent
                        groupId={targetGroupId}
                        groupType="job"
                        label={targetTitle}
                        clickable={Boolean(onTargetClick)}
                        onClick={onTargetClick}
                        onDelete={onTargetChange}
                        disabled={disabled || pending}
                    />
                  )
                }}
            />
          </Typography>
          <Button
              color="primary"
              variant="outlined"
              disabled={disabled || pending}
              startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
              onClick={onTargetChange}
          >
            <FormattedMessage id={caption}/>
          </Button>
        </Box>
      ) : undefined}
      {pending ? undefined : map(targetedSkills, (skill) => (
        <SkillChip
            key={skill.id}
            skill={skill}
            level={skill.skill_proficiency_level as SkillLevel}
            satisfied={satisfiedSkills?.[skill.id]}
            active={selectedSkills?.[skill.id]}
            onActivate={onSelect}
            disabled={disabled}
        />
      ))}
    </CardSection>
    {pending ? <LoadingPlaceholder flat/> : undefined}
    <Divider/>
  </>
));

SkillsSelector.displayName = 'SkillsSelector';

SkillsSelector.propTypes = SkillsSelectorPropTypes;

export default memo(SkillsSelector);
