import { memo, useReducer, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import CourseProviderSvgLogo, {
  COURSE_PROVIDER_IDS, type CourseProviderId
} from '@empathco/ui-components/src/elements/CourseProviderSvgLogo';
// local imports
import { CourseProvider } from '../models/courseProvider';
import useCustomerSettings from '../config/customer';
import { toggleReducer } from '../helpers/reducers';
// Media and SCSS imports
import { text, empty, internal } from './CourseProviderLogo.module.scss';

const companyLogoUrl = `/customers/${window.CUSTOMER ? `${window.CUSTOMER}/` : ''}logo.svg`;

type CourseProviderLogoProps = {
  provider?: CourseProvider | null;
  logo?: string;
};

const CourseProviderLogoPropTypes = {
  provider: PropTypes.object as Validator<CourseProvider>,
  logo: PropTypes.string
};

const CourseProviderLogo: FunctionComponent<CourseProviderLogoProps> = ({
  provider,
  logo = companyLogoUrl
}) => {
  const { HAS_INTERNAL_COURSE_LOGO } = useCustomerSettings();

  const [error, toggleError] = useReducer(toggleReducer, false);

  // Course Providers with SVG logos
  if (provider?.code && COURSE_PROVIDER_IDS.includes(provider.code as CourseProviderId)) return (
    <CourseProviderSvgLogo provider={provider?.code as CourseProviderId}/>
  );

  switch (provider?.code) {
    // Customer's internal Course Provider:
    case 'internal':
    // case null:
    // case undefined:
      return HAS_INTERNAL_COURSE_LOGO && logo && !error
        ? <img src={logo} className={internal} onError={toggleError}/>
        : <div className={empty}/>;

    // other Course Providers (text logo):
    default: return provider?.title ? (
      <Box className={text}>
        <TruncatedTextLink variant="inherit" plain color="text.label" text={provider?.title} length={20}/>
      </Box>
    ) : <div className={empty}/>;
  }
};

CourseProviderLogo.propTypes = CourseProviderLogoPropTypes;

export default memo(CourseProviderLogo);
