import { memo, useReducer, useMemo, type FunctionComponent, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import endsWith from 'lodash/endsWith';
import { useLazyQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// Skillmore UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import { EMPLOYEE_ROLE_QUERY } from '../graphql/EmployeeRole';
import { EmployeeRole, EmployeeRoleDocument } from '../graphql/types';
import useCustomerSettings from '../config/customer';
import {
  PATH_MY_SKILLS, PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_ACTIVITIES,
  PATH_MY_SKILLS_IN_DEMAND, PATH_MY_SKILLS_IN_DEMAND_LEARNING,
  PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP, PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES,
  PATH_MY_SKILLS_CURRENT_ROLE, PATH_MY_SKILLS_CURRENT_ROLE_LEARNING,
  PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP, PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES,
  PATH_SUPERVISOR_EMPLOYEE, PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_ACTIVITIES,
  PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_LEARNING,
  PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES,
  PATH_SUPERVISOR_CURRENT_ROLE, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING,
  PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES
} from '../config/paths';
import useNonReducedUI from '../constants/managementLevel';
import { toggleReducer } from '../helpers/reducers';
import { GlobalContext } from '../context/global';
import RecommendedSkillsList from './RecommendedSkillsList';
import InDemandSkillsList from './InDemandSkillsList';
import EmployeeSkillsBrowser from './EmployeeSkillsBrowser';
import CurrentRoleSkills from './CurrentRoleSkills';

type EmployeeSkillsRecommendedProps = {
  supervisor?: boolean;
  reducedUI?: boolean;
  uid?: string;
  employeeName?: string;
  employeeJobCode?: string;
  userOrgId?: number;
  onOpen?: () => void;
  onClose?: () => void;
  savedPathname?: string;
}

const EmployeeSkillsRecommendedPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  reducedUI: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  employeeJobCode: PropTypes.string,
  userOrgId: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  savedPathname: PropTypes.string
};

// eslint-disable-next-line complexity, max-statements
const EmployeeSkillsRecommended: FunctionComponent<EmployeeSkillsRecommendedProps> = ({
  supervisor = false,
  reducedUI = false,
  uid,
  employeeName = '',
  employeeJobCode,
  userOrgId,
  onOpen,
  onClose,
  savedPathname
}) => {
  const { HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();

  const { user: { data: user } } = useContext(GlobalContext);

  // lazy load job role
  const { query: getRole, pending, failed, results: role } = useQueryObject({
    data: undefined as unknown as EmployeeRole,
    key: 'employeeRole',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_ROLE_QUERY as typeof EmployeeRoleDocument)
  });

  const [popupOpen, togglePopupOpen] = useReducer(toggleReducer, false);

  const role_id = (supervisor ? employeeJobCode : user?.current_job?.code) || undefined;
  const hasRoleSkills = role_id && (pending || !role || (showNonReducedUI(role) && (
    size(role.skills_with_gap) + size(role.skills) >= 1
  )));

  useEffect(() => {
    if (role_id) getRole?.({ variables: {
      role_id,
      ...uid ? { selected_employee_id: uid } : {}
    } });
  }, [role_id, uid, getRole]);

  const browseButton = (
    <Button
        variant="outlined"
        color="primary"
        onClick={onOpen || togglePopupOpen}
    >
      <FormattedMessage id="skills.browse.button"/>
    </Button>
  );

  const browser = (
    <EmployeeSkillsBrowser
        reducedUI={reducedUI}
        uid={uid}
        supervisor={supervisor}
        isOpen={onOpen ? Boolean(savedPathname) : popupOpen}
        onClose={onClose || togglePopupOpen}
    />
  );

  const [values, helpValues] = useMemo(() => [
    supervisor ? {
      name: employeeName,
      endsWithS: endsWith(employeeName, 's')
    } : undefined,
    { supervisor }
  ], [supervisor, employeeName]);

  const recommendedAction = useMemo(() => (
    <InfoButton
        help={supervisor ? 'help.skills_recommendations_supv' : 'help.skills_recommendations'}
        placement="top"
        enablePortal
    />
  ), [supervisor]);

  // eslint-disable-next-line complexity
  const items = useMemo(() => {
    // recommended skills
    const tab11 = supervisor
      ? injectParams(PATH_SUPERVISOR_EMPLOYEE, { employee_id: uid })
      : PATH_MY_SKILLS;
    const tab12 = supervisor
      ? injectParams(PATH_SUPERVISOR_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_LEARNING;
    const tab13 = supervisor
      ? injectParams(PATH_SUPERVISOR_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_MENTORSHIP;
    const tab14 = supervisor
      ? injectParams(PATH_SUPERVISOR_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_ACTIVITIES;
    // in-demand skills
    const tab21 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND;
    const tab22 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_LEARNING;
    const tab23 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP;
    const tab24 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES;
    // current job skills
    const tab31 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE;
    const tab32 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_LEARNING;
    const tab33 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP;
    const tab34 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES;
    return [
      {
        title: supervisor ? 'skills.employees_recommendations.title' : 'skills.recommendations.title',
        values,
        link: {
          [tab11]: tab11,
          [tab21]: tab11,
          [tab31]: tab11,
          ...HAS_COURSES_READONLY ? {
            [tab12]: tab12,
            [tab22]: tab12,
            [tab32]: tab12
          } : {},
          ...HAS_MENTORING ? {
            [tab13]: tab13,
            [tab23]: tab13,
            [tab33]: tab13
          } : {},
          [tab14]: tab14,
          [tab24]: tab14,
          [tab34]: tab14
        },
        action: recommendedAction
      },
      ...HAS_INDEMAND_SKILLS ? [
        {
          title: supervisor ? 'skills.employees_in_demand.title' : 'skills.in_demand.title',
          values,
          link: {
            [tab11]: tab21,
            [tab21]: tab21,
            [tab31]: tab21,
            ...HAS_COURSES_READONLY ? {
              [tab12]: tab22,
              [tab22]: tab22,
              [tab32]: tab22
            } : {},
            ...HAS_MENTORING ? {
              [tab13]: tab23,
              [tab23]: tab23,
              [tab33]: tab23
            } : {},
            [tab14]: tab24,
            [tab24]: tab24,
            [tab34]: tab24
          },
          action: (
            <InfoButton
                help="help.in_demand_skills"
                values={helpValues}
                placement="top"
                enablePortal
            />
          )
        }
      ] : [],
      ...hasRoleSkills ? [
        {
          title: supervisor ? 'skills.employees_current_job.title' : 'skills.current_job.title',
          values,
          link: {
            [tab11]: tab31,
            [tab21]: tab31,
            [tab31]: tab31,
            ...HAS_COURSES_READONLY ? {
              [tab12]: tab32,
              [tab22]: tab32,
              [tab32]: tab32
          } : {},
            ...HAS_MENTORING ? {
              [tab13]: tab33,
              [tab23]: tab33,
              [tab33]: tab33
            } : {},
            [tab14]: tab34,
            [tab24]: tab34,
            [tab34]: tab34
          }
        }
      ] : []
    ];
  }, [
    supervisor, uid, values, helpValues, recommendedAction, hasRoleSkills,
    HAS_INDEMAND_SKILLS, HAS_COURSES_READONLY, HAS_MENTORING
  ]);

  return reducedUI ? (
    <>
      <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={1.5}
          pt={supervisor ? 2 : undefined}
      >
        {browseButton}
      </Box>
      {browser}
    </>
  ) : (
    <>
      <ContentCard>
        {items.length >= 2 ? (
          <CardTabbar
              savedPathname={savedPathname}
              withoutScroll
              items={items}
              wideAction // 12rem
              action={browseButton}
          >
            <RecommendedSkillsList uid={uid} supervisor={supervisor} employeeName={employeeName}/>
            {HAS_INDEMAND_SKILLS ? <InDemandSkillsList uid={uid} supervisor={supervisor} userOrgId={userOrgId}/> : undefined}
            {hasRoleSkills ? (
              <CurrentRoleSkills
                  roleId={role_id}
                  fullName={employeeName}
                  role={role}
                  pending={pending}
                  failed={failed}
                  supervisor={supervisor}
              />
            ) : undefined}
          </CardTabbar>
        ) : (
          <>
            <CardTitle
                title={supervisor ? 'skills.employees_recommendations.title' : 'skills.recommendations.title'}
                subheader={recommendedAction}
                values={values}
                action={browseButton}
                withDivider
            />
            <RecommendedSkillsList uid={uid} supervisor={supervisor} employeeName={employeeName}/>
          </>
        )}
      </ContentCard>
      {browser}
    </>
  );
};

EmployeeSkillsRecommended.propTypes = EmployeeSkillsRecommendedPropTypes;

export default memo(EmployeeSkillsRecommended);
