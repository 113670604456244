import { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import isSafeInteger from 'lodash/isSafeInteger';
import toString from 'lodash/toString';
import toSafeInteger from 'lodash/toSafeInteger';
import { useSearchParams } from 'react-router-dom';
// Skillmore UI Components
import { getIds, getStringifiedIds, isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { JobCategory, SortDirection } from '../graphql/types';
import { JOB_CATEGORIES } from '../graphql/customTypes';
import { EmployeeManagementLevel } from '../constants/managementLevel';
import { ROLE_SCOPES, RoleScope } from '../constants/scopes';
import useCustomerSettings from '../config/customer';
import { TalentFinderFilterValues } from '../context/supervisor';

export type FilterValues = TalentFinderFilterValues & {
  location_id?: number | null;
  supervisory_jobs_only?: boolean | null;
  open_reqs_only?: boolean | null;
  scope?: RoleScope | null;
  job_category?: JobCategory | null;
  skill_ids?: number[] | null;
  management_level?: EmployeeManagementLevel | null;
  management_level_min?: EmployeeManagementLevel | null;
  management_level_max?: EmployeeManagementLevel | null;
  by_sso?: boolean | null;
  search?: string | null;
  // sorting
  sort_by?: string | null;
  direction?: SortDirection | null;
  // pagination
  page_size?: number | null;
  current_page?: number | null;
};

export type SearchValues = {
  country?: string;
  state?: string;
  location?: string;
  manager?: string;
  direct?: '0' | '1';
  org?: string;
  job?: string;
  level?: string;
  level1?: string;
  levels?: string;
  rated?: '0' | '1';
  supervisory?: '0' | '1';
  openreqs?: '0' | '1';
  sso?: '0' | '1';
  scope?: string | null;
  category?: string;
  skills?: string;
  search?: string;
  sort?: string | null;
  dir?: 'asc' | 'desc' | null;
  // pagination
  size?: string;
  page?: string;
};

function useFilters(
  sortValues?: readonly string[]
) {
  const { MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST } = useCustomerSettings();

  const [urlSearchParams, setSearchParams] = useSearchParams() as unknown as
    [URLSearchParams, (nextInit?: (params: Partial<SearchValues>) => Partial<SearchValues>) => void];
  const searchParams = useMemo(() => Object.fromEntries(urlSearchParams) as Partial<SearchValues>, [urlSearchParams]);

  // eslint-disable-next-line complexity
  const updateSearchParams = useCallback((params?: Partial<FilterValues> | null) => {
    const jobLevel = isSafeInteger(params?.management_level)
      ? toSafeInteger(params?.management_level)
      : MANAGEMENT_LEVEL_FIRST - 1;
    const jobLevel0 = isSafeInteger(params?.management_level_min)
      ? toSafeInteger(params?.management_level_min)
      : MANAGEMENT_LEVEL_FIRST - 1;
    const jobLevel1 = isSafeInteger(params?.management_level_max)
      ? toSafeInteger(params?.management_level_max)
      : MANAGEMENT_LEVEL_FIRST - 1;
    const withJobLevel = (jobLevel >= MANAGEMENT_LEVEL_FIRST) && (jobLevel <= MANAGEMENT_LEVEL_LAST);
    const withJobLevel0 = (jobLevel0 >= MANAGEMENT_LEVEL_FIRST) && (jobLevel0 <= MANAGEMENT_LEVEL_LAST);
    const withJobLevel1 = (jobLevel1 >= MANAGEMENT_LEVEL_FIRST) && (jobLevel1 <= MANAGEMENT_LEVEL_LAST);
    const omitKeys = [
      // ints
      ...isNull(params?.country_id) || params?.country_id === 0 ? ['country'] : [],
      ...isNull(params?.state_id) || params?.state_id === 0 ? ['state'] : [],
      ...isNull(params?.location_id) || params?.location_id === 0 ? ['location'] : [],
      ...isNull(params?.org_id) || params?.org_id === 0 ? ['org'] : [],
      ...isNull(params?.job_id) || params?.job_id === 0 ? ['job'] : [],
      ...isNull(params?.page_size) || params?.page_size === 0 ? ['size'] : [],
      ...isNull(params?.current_page) || params?.current_page === 0 ? ['page'] : [],
      // management level
      ...(isNull(params?.management_level) || params?.management_level === (MANAGEMENT_LEVEL_FIRST - 1)) &&
        (isNull(params?.management_level_min) || params?.management_level_min === (MANAGEMENT_LEVEL_FIRST - 1))
        ? ['level'] : [],
      ...isNull(params?.management_level_max) || params?.management_level_max === (MANAGEMENT_LEVEL_FIRST - 1)
        ? ['level1'] : [],
      // strings
      ...isNull(params?.manager_id) || params?.manager_id === '0' ? ['manager'] : [], // TODO: check if '0' is valid
      ...isNull(params?.scope) ? ['scope'] : [],
      ...isNull(params?.job_category) ? ['category'] : [],
      ...isNull(params?.search) ? ['search'] : [],
      ...isNull(params?.sort_by) ? ['sort'] : [],
      ...isNull(params?.direction) ? ['dir'] : [],
      // booleans
      ...isNull(params?.direct_reports_only) ? ['direct'] : [],
      ...isNull(params?.employee_rated_only) ? ['rated'] : [],
      ...isNull(params?.supervisory_jobs_only) ? ['supervisory'] : [],
      ...isNull(params?.open_reqs_only) ? ['openreqs'] : [],
      ...isNull(params?.by_sso) ? ['sso'] : [],
      // int arrays
      ...isNull(params?.job_levels) || (params?.job_levels && size(params.job_levels) < 1) ? ['levels'] : [],
      ...isNull(params?.skill_ids) || (params?.skill_ids && size(params.skill_ids) < 1) ? ['skills'] : []
    ];
    // eslint-disable-next-line complexity
    setSearchParams((prevParams) => ({
      ...omitBy(size(omitKeys) >= 1 ? omit(prevParams, omitKeys) : prevParams, isFunction),
      // ints
      ...params?.country_id ? { country: toString(params.country_id) } : {},
      ...params?.state_id ? { state: toString(params.state_id) } : {},
      ...params?.location_id ? { location: toString(params.location_id) } : {},
      ...params?.org_id ? { org: toString(params.org_id) } : {},
      ...params?.job_id ? { job: toString(params.job_id) } : {},
      ...params?.page_size ? { size: toString(params.page_size) } : {},
      ...params?.current_page ? { page: toString(params.current_page) } : {},
      // management level
      ...withJobLevel ? { level: toString(jobLevel) } : {},
      ...withJobLevel0 ? { level: toString(jobLevel0) } : {},
      ...withJobLevel1 ? { level1: toString(jobLevel1) } : {},
      // strings
      ...isEmptyString(params?.manager_id) || params?.manager_id === '0' ? {} : { manager: params?.manager_id || '' },
      ...isEmptyString(params?.scope) ? {} : { scope: params?.scope || '' },
      ...isEmptyString(params?.job_category) ? {} : { category: params?.job_category || '' },
      ...isEmptyString(params?.search) ? {} : { search: params?.search || '' },
      ...isEmptyString(params?.sort_by) || (sortValues && !sortValues.includes(params?.sort_by || ''))
        ? {} : { sort: params?.sort_by || '' },
      ...isEmptyString(params?.direction) ? {} : {
        dir: params?.direction === SortDirection.descending ? 'desc' as const : 'asc' as const
      },
      // booleans
      ...isBoolean(params?.direct_reports_only) ? { direct: params.direct_reports_only ? '1' as const : '0' as const } : {},
      ...isBoolean(params?.employee_rated_only) ? { rated: params.employee_rated_only ? '1' as const : '0' as const } : {},
      ...isBoolean(params?.supervisory_jobs_only)
        ? { supervisory: params.supervisory_jobs_only ? '1' as const : '0' as const } : {},
      ...isBoolean(params?.open_reqs_only) ? { openreqs: params.open_reqs_only ? '1' as const : '0' as const } : {},
      ...isBoolean(params?.by_sso) ? { sso: params.by_sso ? '1' as const : '0' as const } : {},
      // int arrays
      ...size(params?.job_levels) >= 1 ? { levels: getStringifiedIds(params?.job_levels) } : {},
      ...size(params?.skill_ids) >= 1 ? { skills: getStringifiedIds(params?.skill_ids) } : {}
    }));
  }, [sortValues, setSearchParams, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST]);

  // eslint-disable-next-line complexity
  const defaultFilters = useMemo(() => {
    const jobLevel = toSafeInteger(searchParams.level);
    const jobLevel1 = toSafeInteger(searchParams.level1);
    const withoutJobLevel = isEmptyString(searchParams.level) || (jobLevel < MANAGEMENT_LEVEL_FIRST) ||
      (jobLevel > MANAGEMENT_LEVEL_LAST);
    const withoutJobLevel1 = isEmptyString(searchParams.level1) || (jobLevel1 < MANAGEMENT_LEVEL_FIRST) ||
      (jobLevel1 > MANAGEMENT_LEVEL_LAST);
    const filterValues: FilterValues = {
      // ints
      ...searchParams.country ? { country_id: toSafeInteger(searchParams.country) } : {},
      ...searchParams.state ? { state_id: toSafeInteger(searchParams.state) } : {},
      ...searchParams.location ? { location_id: toSafeInteger(searchParams.location) } : {},
      ...searchParams.org ? { org_id: toSafeInteger(searchParams.org) } : {},
      ...searchParams.job ? { job_id: toSafeInteger(searchParams.job) } : {},
      ...searchParams.size ? { page_size: toSafeInteger(searchParams.size) } : {},
      ...searchParams.page ? { current_page: toSafeInteger(searchParams.page) } : {},
      // management level
      ...withoutJobLevel ? {} : { management_level: jobLevel },
      ...withoutJobLevel1 ? {} : { management_level_max: jobLevel1 },
      // strings
      ...searchParams.manager ? { manager_id: searchParams.manager } : {},
      ...searchParams.scope && ROLE_SCOPES.includes(searchParams.scope as RoleScope)
        ? { scope: searchParams.scope as RoleScope } : {},
      ...searchParams.category && JOB_CATEGORIES.includes(searchParams.category as JobCategory)
        ? { job_category: searchParams.category as JobCategory } : {},
      ...searchParams.search ? { search: searchParams.search } : {},
      ...searchParams.sort && (!sortValues || sortValues.includes(searchParams.sort))
        ? { sort_by: searchParams.sort } : {},
      ...(searchParams.dir === 'asc' && { direction: SortDirection.ascending }) ||
        (searchParams.dir === 'desc' && { direction: SortDirection.descending }) || {},
      // booleans
      ...isEmptyString(searchParams.direct) ? {} : { direct_reports_only: searchParams.direct === '1' },
      ...isEmptyString(searchParams.rated) ? {} : { employee_rated_only: searchParams.rated === '1' },
      ...isEmptyString(searchParams.supervisory) ? {} : { supervisory_jobs_only: searchParams.supervisory === '1' },
      ...isEmptyString(searchParams.openreqs) ? {} : { open_reqs_only: searchParams.openreqs === '1' },
      ...isEmptyString(searchParams.sso) ? {} : { by_sso: searchParams.sso === '1' },
      // int arrays
      ...searchParams.levels ? { job_levels: getIds(searchParams.levels) } : {},
      ...searchParams.skills ? { skill_ids: getIds(searchParams.skills) } : {}
    };
    return size(filterValues) >= 1 ? filterValues : undefined;
  }, [searchParams, sortValues, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST]);

  return useMemo(() => ({
    defaultFilters,
    updateSearchParams
  }), [defaultFilters, updateSearchParams]);
}

export default useFilters;
