import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Skillmore UI Components
import useDragAndDropItem, { type MoveItemFunction } from '@empathco/ui-components/src/hooks/useDragAndDropItem';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { Employee } from '../models/employee';
import EmployeeCard, { AvatarVariant, MatchRateVariant } from './EmployeeCard';

type EmployeeDraggableCardProps = {
  item: Employee;
  moveEmployee: MoveItemFunction;
  withSkills?: boolean | null;
  matchRateVariant?: MatchRateVariant;
  avatarVariant?: AvatarVariant;
  route?: string | null;
  disabled?: boolean | null;
  pending?: boolean | null;
  onAction?: (id: number) => void;
  actionLabel?: string;
  withZoomInSkills?: boolean;
};

const EmployeeDraggableCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<Employee>,
  moveEmployee: PropTypes.func.isRequired,
  withSkills: PropTypes.bool,
  matchRateVariant: PropTypes.string as Validator<MatchRateVariant>,
  avatarVariant: PropTypes.string as Validator<AvatarVariant>,
  route: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  onAction: PropTypes.func,
  actionLabel: PropTypes.string,
  withZoomInSkills: PropTypes.bool
};

const EmployeeDraggableCard = forwardRef<HTMLDivElement, EmployeeDraggableCardProps>(({
  item,
  moveEmployee,
  withSkills = false,
  matchRateVariant,
  avatarVariant,
  route,
  disabled = false,
  pending = false,
  onAction,
  actionLabel,
  withZoomInSkills = false
}, parentRef) => {
  const { ref, isDragging } = useDragAndDropItem(item.id, 'employee', moveEmployee, disabled);
  const cardRef = useCombinedRefs<HTMLDivElement>(parentRef, ref);
  return (
    <EmployeeCard
        ref={cardRef}
        item={item}
        draggable
        isDragging={isDragging}
        withSkills={withSkills}
        matchRateVariant={matchRateVariant}
        avatarVariant={avatarVariant}
        route={route}
        disabled={disabled}
        pending={pending}
        onAction={onAction}
        actionLabel={actionLabel}
        withZoomInSkills={withZoomInSkills}
    />
  );
});

EmployeeDraggableCard.displayName = 'EmployeeDraggableCard';

EmployeeDraggableCard.propTypes = EmployeeDraggableCardPropTypes;

export default memo(EmployeeDraggableCard);
