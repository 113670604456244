import { memo, type ReactNode, useCallback, useContext, useEffect, useState, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import endsWith from 'lodash/endsWith';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { EMPLOYEE_ADVISORS_QUERY } from '../graphql/EmployeeAdvisors';
import { EMPLOYEE_ADVISEES_QUERY } from '../graphql/EmployeeAdvisees';
import { UPDATE_EMPLOYEE_ADVISOR } from '../graphql/UpdateEmployeeAdvisor';
import { DELETE_EMPLOYEE_ADVISOR } from '../graphql/DeleteEmployeeAdvisor';
import { DELETE_EMPLOYEE_ADVISEE } from '../graphql/DeleteEmployeeAdvisee';
import {
  SkillAdvisor, EmployeeAdvisorStatus,
  EmployeeAdvisorsDocument, EmployeeAdvisorsQueryVariables,
  EmployeeAdviseesDocument, EmployeeAdviseesQueryVariables,
  UpdateEmployeeAdvisorDocument, DeleteEmployeeAdvisorDocument, DeleteEmployeeAdviseeDocument
} from '../graphql/types';
import { EmployeeAdvisee } from '../graphql/customTypes';
import { PATH_MY_PREFERENCES, PATH_SUPERVISOR_EMPLOYEE } from '../config/paths';
import { DataContext } from '../context';
import PaginationControls from '../v3/PaginationControls';
import EmployeeCard from '../v3/EmployeeCard';
import SkillsGrid from '../v3/SkillsGrid';
import CardsGrid from '../v3/CardsGrid';
// SCSS imports
import { subtitle } from './Mentorship.module.scss';

type MentorshipProps = {
  supervisor?: boolean;
  uid?: string;
  employeeName?: string;
};

const MentorshipPropTypes = {
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const Mentorship: FunctionComponent<MentorshipProps> = ({
  supervisor = false,
  uid,
  employeeName = ''
}) => {
  const {
    mentorship: { data: mentorship, pending: pendingMentorship, failed: failedMentorship }, requireMentorship,
    preferences: { data: preferences, pending: pendingPreferences, failed: failedPreferences }, requirePreferences
  } = useContext(DataContext);

  const {
    query: getAdvisors, pending: pendingAdvisors, failed: failedAdvisors,
    count: advisorsCount, results: advisors, variables: prevVarsAdvisors
  } = useQueryCounted({
    data: undefined as unknown as SkillAdvisor,
    key: 'employeeAdvisors',
    lazyQuery: useLazyQuery(EMPLOYEE_ADVISORS_QUERY as typeof EmployeeAdvisorsDocument)
  });

  const {
    query: getAdvisees, pending: pendingAdvisees, failed: failedAdvisees,
    count: adviseesCount, results: advisees, variables: prevVarsAdvisees
  } = useQueryCounted({
    data: undefined as unknown as EmployeeAdvisee,
    key: 'employeeAdvisees',
    lazyQuery: useLazyQuery(EMPLOYEE_ADVISEES_QUERY as typeof EmployeeAdviseesDocument)
  });

  const { mutate: updateAdvisor, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_EMPLOYEE_ADVISOR as typeof UpdateEmployeeAdvisorDocument)
  });
  const { mutate: deleteAdvisor, loading: deleteAdvisorPending, failed: deleteAdvisorFailed } = useMutationMethod({
    mutation: useMutation(DELETE_EMPLOYEE_ADVISOR as typeof DeleteEmployeeAdvisorDocument)
  });
  const { mutate: deleteAdvisee, loading: deleteAdviseePending, failed: deleteAdviseeFailed } = useMutationMethod({
    mutation: useMutation(DELETE_EMPLOYEE_ADVISEE as typeof DeleteEmployeeAdviseeDocument)
  });

  const [currentPageAdvisors, setCurrentPageAdvisors] = useState(1);
  const [pageSizeAdvisors, setPageSizeAdvisors] = useState<number>();

  const [currentPageAdvisees, setCurrentPageAdvisees] = useState(1);
  const [pageSizeAdvisees, setPageSizeAdvisees] = useState<number>();

  const [pendingId, setPendingId] = useState<number>();

  useEffect(() => {
    if (supervisor) requireMentorship?.();
  }, [uid, supervisor, requireMentorship]);

  useEffect(() => {
    if (!supervisor) requirePreferences?.();
  }, [requirePreferences, supervisor]);

  const { mentor_others, skills_i_mentor } = preferences || {};
  const count = size(supervisor ? mentorship : (mentor_others && skills_i_mentor) || undefined);

  const handleCompleteAdvisor = useCallback((advisor: SkillAdvisor) => {
    const { id: advisor_id } = advisor;
    if (!advisor_id) return;
    setPendingId(advisor_id);
    updateAdvisor({
      variables: { advisor_id, input: { status: EmployeeAdvisorStatus.completed } },
      // TODO: optimistic response
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeAdvisors' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkillAdvisors' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
      },
      onCompleted: () => setPendingId(undefined),
      onError: () => setPendingId(undefined)
    });
  }, [updateAdvisor]);

  const handleDeleteAdvisor = useCallback((advisor: SkillAdvisor) => {
    if (advisor?.id) deleteAdvisor({
      variables: { advisor_id: advisor.id },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeAdvisors' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkillAdvisors' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
      }
    });
  }, [deleteAdvisor]);

  const handleDeleteAdvisee = useCallback((advisee: EmployeeAdvisee) => {
    if (advisee?.id) deleteAdvisee({
      variables: { advisee_id: advisee.id },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeAdvisees' });
      }
    });
  }, [deleteAdvisee]);

  useEffect(() => {
    if (pageSizeAdvisors && getAdvisors) {
      const variables: EmployeeAdvisorsQueryVariables = {
        ...supervisor && uid ? { selected_employee_id: toLower(uid) } : {},
        limit: pageSizeAdvisors
      };
      let curPage = currentPageAdvisors;
      if (paramsDiffer(prevVarsAdvisors, variables)) {
        curPage = 1;
        setCurrentPageAdvisors(1);
      }
      variables.offset = pageSizeAdvisors * (curPage - 1);
      getAdvisors({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageAdvisors, pageSizeAdvisors, supervisor, uid, getAdvisors]); // ignoring `prevVarsAdvisors` changes

  useEffect(() => {
    if (count >= 1 && pageSizeAdvisees && getAdvisees) {
      const variables: EmployeeAdviseesQueryVariables = {
        ...supervisor && uid ? { selected_employee_id: toLower(uid) } : {},
        limit: pageSizeAdvisees
      };
      let curPage = currentPageAdvisees;
      if (paramsDiffer(prevVarsAdvisees, variables)) {
        curPage = 1;
        setCurrentPageAdvisees(1);
      }
      variables.offset = pageSizeAdvisees * (curPage - 1);
      getAdvisees({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentPageAdvisees, pageSizeAdvisees, supervisor, uid, getAdvisees]); // ignoring `prevVarsAdvisees` changes

  const pending = supervisor ? pendingMentorship : pendingPreferences;
  const failed = supervisor ? failedMentorship : failedPreferences;

  const advisorComponentProps: Partial<GetComponentProps<typeof EmployeeCard>> | undefined = useMemo(() => advisors ? {
    avatarVariant: 'advisor',
    withSkills: true,
    withCompletedState: true,
    ...supervisor ? {
      route: PATH_SUPERVISOR_EMPLOYEE
    } : {
      onRemove: handleDeleteAdvisor,
      onComplete: handleCompleteAdvisor,
      onCompletePendingId: updatePending ? pendingId : undefined
    }
  } : undefined, [pendingId, updatePending, advisors, supervisor, handleDeleteAdvisor, handleCompleteAdvisor]);

  const adviseeComponentProps: Partial<GetComponentProps<typeof EmployeeCard>> | undefined = useMemo(() => advisees ? {
    avatarVariant: 'advisee',
    withSkills: true,
    withCompletedState: true,
    ...supervisor ? {
      route: PATH_SUPERVISOR_EMPLOYEE
    } : {
      onRemove: handleDeleteAdvisee
    }
  } : undefined, [advisees, supervisor, handleDeleteAdvisee]);

  const values = useMemo(() => ({
    supervisor: Boolean(supervisor),
    name: employeeName,
    endsWithS: endsWith(employeeName, 's')
  }), [supervisor, employeeName]);

  const link = useCallback((chunks?: ReactNode | ReactNode[] | null): ReactNode => (
    <StandardLink to={PATH_MY_PREFERENCES}>
      {mapChunks(chunks)}
    </StandardLink>
  ), []);

  const message = (
    <FormattedMessage
        id={
          (supervisor && 'mentorship.employee') ||
          (mentor_others && 'mentorship.prompt') ||
          'mentorship.opted_out'
        }
        values={{
          count,
          name: employeeName,
          link
        }}
    />
  );

  const disabled = pendingAdvisors || failedAdvisors || deleteAdvisorPending || deleteAdviseePending;

  return (pending && <LoadingPlaceholder flat/>) || (failed && <FetchFailedAlert flat/>) || (
    <>
      <CardSection>
        <Typography variant="h4">
          <FormattedMessage id="skill.advisors.subtitle" values={values}/>
        </Typography>
      </CardSection>
      <CardsGrid
          variant="shady"
          items={advisors}
          pending={pendingAdvisors}
          failed={failedAdvisors}
          blendPagination
          pagination={(
            <PaginationControls
                settingsId={supervisor ? 'supv_advisors' : 'advisors'}
                loaded={Boolean(advisors)}
                pending={pendingAdvisors}
                loading={pendingAdvisors}
                total={advisorsCount}
                currentPage={currentPageAdvisors}
                onPageSelected={setCurrentPageAdvisors}
                onPageSize={setPageSizeAdvisors}
                disabled={disabled}
                totalMessage="skill.advisors.pagination"
            />
          )}
          notFoundMessage={advisors ? 'skill.advisors.empty' : undefined}
          values={values}
          // withoutTopPadding
          component={EmployeeCard}
          ComponentProps={advisorComponentProps}
      />
      {supervisor && count >= 1 ? (
        <SkillsGrid
            source="mentoring"
            isEmployee={!supervisor}
            readOnly={supervisor}
            supervisor={supervisor}
            filters={message}
            skills={mentorship}
            pending={pending}
            failed={failed}
        />
      ) : undefined}
      <CardSection className={subtitle}>
        <BoxTypography variant="h4" pr={1}>
          <FormattedMessage id="skill.advisees.subtitle" values={values}/>
        </BoxTypography>
        {supervisor && count >= 1 ? undefined : (
          <BoxTypography variant="body1" pl={1} flex="1 1 0" textAlign="right">
            {message}
          </BoxTypography>
        )}
      </CardSection>
      {count >= 1 ? (
        <CardsGrid
            variant="shady"
            items={advisees}
            pending={pendingAdvisees}
            failed={failedAdvisees}
            blendPagination
            pagination={(
              <PaginationControls
                  settingsId={supervisor ? 'supv_advisees' : 'advisees'}
                  loaded={Boolean(advisees)}
                  pending={pendingAdvisees}
                  loading={pendingAdvisees}
                  total={adviseesCount}
                  currentPage={currentPageAdvisees}
                  onPageSelected={setCurrentPageAdvisees}
                  onPageSize={setPageSizeAdvisees}
                  disabled={disabled}
                  totalMessage="skill.advisees.pagination"
              />
            )}
            notFoundMessage={advisees ? 'skill.advisees.empty' : undefined}
            values={values}
            // withoutTopPadding
            component={EmployeeCard}
            ComponentProps={adviseeComponentProps}
        />
      ) : undefined}
      {supervisor ? undefined : (
        <>
          <ActionFailedAlert
              message="skill.advisors.update_error"
              open={updateFailed}
          />
          <ActionFailedAlert
              message="skill.advisors.delete_error"
              open={deleteAdvisorFailed}
          />
          <ActionFailedAlert
              message="skill.advisees.delete_error"
              open={deleteAdviseeFailed}
          />
        </>
      )}
    </>
  );
};

Mentorship.propTypes = MentorshipPropTypes;

export default memo(Mentorship);
