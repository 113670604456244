import { forwardRef, memo, type MouseEventHandler, type ReactNode, type ElementType } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import startsWith from 'lodash/startsWith';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { type BoxProps, type SystemProps } from '@mui/system/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid, { type GridProps } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// Material Icon imports
import DragIndicator from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
// SCSS imports
import {
  item, card, roleCard, bold, itemFull, itemRelative, smallCard, removeContainer, removeContainerSm, removeBtn, removeBtnSm,
  draggableCard, draggableCursor, dragging, selectBorder, selectBorderActive, shadyFooter
} from './ItemCard.module.scss';

type ItemCardProps = Omit<BoxProps, 'children' | 'className' | 'onClick'> &
  Omit<SystemProps, 'children' | 'className' | 'onClick'> & {
  children: ReactNode | ReactNode[];
  badge?: boolean;
  small?: boolean;
  smallItem?: boolean; // together with `header` and `footer` is used for Manager variant of OpportunityCard
  active?: boolean;
  light?: boolean;
  flat?: boolean;
  selected?: boolean;
  onSelect?: MouseEventHandler<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
  RemoveIcon?: ElementType;
  link?: string;
  href?: string;
  bottomPadding?: boolean;
  className?: string;
  cardClassName?: string;
  fullWidth?: boolean;
  header?: ReactNode | ReactNode[];
  footer?: ReactNode | ReactNode[];
  status?: ReactNode | ReactNode[];
  disabled?: boolean | null;
  draggable?: boolean;
  isDragging?: boolean;
  readonly sm?: GridProps['sm'] | null;
  readonly md?: GridProps['md'] | null;
  readonly lg?: GridProps['lg'] | null;
  readonly xl?: GridProps['xl'] | null;
};

const ItemCardPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]).isRequired as Validator<ReactNode | ReactNode[]>,
  // attributes
  badge: PropTypes.bool,
  small: PropTypes.bool,
  smallItem: PropTypes.bool,
  active: PropTypes.bool,
  light: PropTypes.bool,
  flat: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  RemoveIcon: PropTypes.elementType as Validator<ElementType>,
  link: PropTypes.string,
  href: PropTypes.string,
  bottomPadding: PropTypes.bool,
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]) as Validator<ReactNode | ReactNode[]>,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]) as Validator<ReactNode | ReactNode[]>,
  status: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]) as Validator<ReactNode | ReactNode[]>,
  disabled: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool
};

// eslint-disable-next-line complexity
const ItemCard = forwardRef<HTMLDivElement, ItemCardProps>(({
  children,
  badge = false,
  small = false,
  smallItem = false,
  active = false,
  light = false,
  flat = false,
  selected,
  onSelect,
  onClick,
  onRemoveClick,
  RemoveIcon,
  link,
  href,
  bottomPadding = false,
  className,
  cardClassName,
  fullWidth = false,
  header,
  footer,
  status,
  disabled = false,
  draggable = false,
  isDragging = false,
  sm,
  md,
  lg,
  xl,
  ...props
}, ref) => {
  const { formatMessage } = useIntl();

  const content = (
    <>
      {header ? (
        <Box
            px={smallItem ? 1.5 : 2.5}
            pt={smallItem ? 1.5 : 1.25}
            pb={smallItem ? 1 : 1.25}
            bgcolor={selected ? 'primary.main' : undefined}
            display="flex"
        >
          {header}
        </Box>
      ) : undefined}
      <Box
          pl={(header && (smallItem ? 1.5 : 2.5)) || ((small || smallItem) && 3.5) || 1.5}
          pr={(header && (smallItem ? 1.5 : 2.5)) || ((small || smallItem) && 4.5) || (light && 1) || (badge ? 0.5 : 1.5)}
          pt={(header && 0.5) || ((small || smallItem) && 2) || (light || badge ? 1.5 : 3)}
          pb={(header && smallItem && 1) || ((small || smallItem) && 2) || (bottomPadding ? 3 : 1.5)}
          color={(!isDragging && (
            (active && 'primary.contrastText') ||
            (light && 'text.label') ||
            'secondary.text'
          )) || undefined}
          bgcolor={(!isDragging && !onSelect && !isBoolean(selected) && (
            (active && 'primary.main') ||
            (light && 'background.paper') ||
            'background.card'
          )) || undefined}
          className={clsx(light ? roleCard : card, className, { [bold]: active })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
      >
        {children}
      </Box>
      {footer ? (
        <Box
            pl={smallItem ? 1.5 : 1.25}
            pr={smallItem ? 1.5 : 0.25}
            py={1}
            bgcolor={isDragging ? undefined : 'background.paper'}
            display="flex"
            flexDirection="column"
            className={smallItem ? shadyFooter : undefined}
        >
          {footer}
        </Box>
      ) : undefined}
    </>
  );

  const RemoveButtonIcon = RemoveIcon || CloseIcon;

  const cardContent = (
    <Card
        ref={small || draggable ? ref : undefined}
        elevation={flat || onSelect || isBoolean(selected) ? 0 : 9}
        className={clsx(cardClassName, {
          [smallCard]: small,
          [draggableCard]: draggable,
          [draggableCursor]: draggable && !disabled,
          [dragging]: isDragging,
          [selectBorder]: selected === false || (Boolean(onSelect) && !selected),
          [selectBorderActive]: selected === true
        })}
    >
      {!isDragging && onRemoveClick ? (
        <Box className={onSelect || header ? removeContainerSm : removeContainer}>
          <Button
              color="inherit"
              variant="outlined"
              aria-label={formatMessage({ id: 'common.button.remove' })}
              onClick={onRemoveClick}
              className={onSelect || header ? removeBtnSm : removeBtn}
              disabled={disabled ? true : undefined}
          >
            <RemoveButtonIcon fontSize={onSelect || header ? 'small' : undefined}/>
          </Button>
        </Box>
      ) : undefined}
      {(draggable && (
        <>
          <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={(disabled && 'action.disabled') || (isDragging ? undefined : 'secondary.main')}
              bgcolor={isDragging ? undefined : 'background.dragIcon'}
          >
            <DragIndicator/>
          </Box>
          <Box flex="1 1 0">
            {content}
          </Box>
        </>
      )) ||
      ((onSelect || onClick) && (
        <CardActionArea
            onClick={onSelect || onClick}
            disabled={disabled ? true : undefined}
        >
          {content}
        </CardActionArea>
      )) ||
      (link && (
        <CardActionArea
            component={RouterLink}
            to={link}
        >
          {content}
        </CardActionArea>
      )) || (href && (
        <CardActionArea
            component="a"
            href={href}
            target={startsWith(href, 'mailto:') ? undefined : '_blank'}
            rel="noopener noreferrer"
        >
          {content}
        </CardActionArea>
      )) || content}
    </Card>
  );

  return small ? (
    <>
      {cardContent}
      {status}
    </>
  ) : (
    <Grid
        ref={draggable ? undefined : ref}
        item
        xs={12}
        sm={fullWidth || isNull(sm) ? undefined : sm || 6}
        md={fullWidth || isNull(md) ? undefined : md || 4}
        lg={fullWidth || isNull(lg) ? undefined : lg || 3}
        xl={fullWidth || !xl ? undefined : xl}
        className={clsx({
          [itemFull]: fullWidth,
          [item]: !fullWidth,
          [itemRelative]: onRemoveClick
        })}
    >
      {cardContent}
      {status}
    </Grid>
  );
});

ItemCard.displayName = 'ItemCard';

ItemCard.propTypes = ItemCardPropTypes;

export default memo(ItemCard);
