import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Media and SCSS imports
import CourseraLogo from '../images/course-providers/coursera.svg?react';
import UdemyLogo from '../images/course-providers/udemy.svg?react';
import UoPxLogo from '../images/course-providers/uopx.svg?react';
import SkillsoftLogo from '../images/course-providers/skillsoft.svg?react';
import LinkedInLogo from '../images/course-providers/linkedin.svg?react';
import UdacityLogo from '../images/course-providers/udacity.svg?react';
import { uopx, coursera, udemy, skillsoft, linkedin, udacity } from './CourseProviderSvgLogo.module.scss';

export const COURSE_PROVIDER_IDS = ['uopx', 'coursera', 'udemy', 'percipio', 'linkedin', 'udacity'] as const;
export type CourseProviderId = typeof COURSE_PROVIDER_IDS[number];

type CourseProviderSvgLogoProps = {
  provider: CourseProviderId;
};

const CourseProviderSvgLogoPropTypes = {
  provider: PropTypes.string as Validator<CourseProviderId>
};

const CourseProviderSvgLogo: FunctionComponent<CourseProviderSvgLogoProps> = ({
  provider
}) => {
  switch (provider) {
    case 'uopx': return <UoPxLogo className={uopx}/>;
    case 'coursera': return <CourseraLogo className={coursera}/>;
    case 'udemy': return <UdemyLogo className={udemy}/>;
    case 'percipio': return <SkillsoftLogo className={skillsoft}/>;
    case 'linkedin': return <LinkedInLogo className={linkedin}/>;
    case 'udacity': return <UdacityLogo className={udacity}/>;
    default: return null;
  }
};

CourseProviderSvgLogo.propTypes = CourseProviderSvgLogoPropTypes;

export default memo(CourseProviderSvgLogo);
