import {
  memo, useCallback, useContext, useMemo, useState, useEffect, useLayoutEffect, useRef, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import toLower from 'lodash/toLower';
import toSafeInteger from 'lodash/toSafeInteger';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
// local imports
import { NEW_DEV_PLAN } from '../graphql/NewDevPlan';
import { NewDevPlanDocument } from '../graphql/types';
import useNonReducedUI from '../constants/managementLevel';
import { getDataStatus, isDirtyData } from '../constants/dataStatuses';
import { MatchRateObject } from '../models/matchRateObject';
import useCustomerSettings from '../config/customer';
import { PATH_MY_DEV_PLAN_EDITOR } from '../config/paths';
import { MAX_ITEMS } from '../config/params';
import { exportElemetToPDF } from '../helpers/pdf';
import useExport from '../hooks/useExport';
import { useMixpanel, currentEmployeeDetails } from '../context/analytics';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import SkillsGrid from '../v3/SkillsGrid';
import EmployeeNewDevPlanButtons from './EmployeeNewDevPlanButtons';
// SCSS imports
import { hidden, hidden2 } from './RoleSkills.module.scss';

type RoleSkillsProps = {
  reducedUI?: boolean | null;
  isEmployee?: boolean;
  exporting?: boolean;
  onExport?: () => void;
  onExportFinished?: (success: boolean) => void;
  // for Storybook only
  testNewDevPlan?: boolean | null;
}

const RoleSkillsPropTypes = {
  // attributes
  reducedUI: PropTypes.bool,
  isEmployee: PropTypes.bool,
  exporting: PropTypes.bool,
  onExport: PropTypes.func,
  onExportFinished: PropTypes.func,
  // for Storybook only
  testNewDevPlan: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const RoleSkills: FunctionComponent<RoleSkillsProps> = ({
  reducedUI = false,
  isEmployee = false,
  exporting = false,
  onExport,
  onExportFinished,
  testNewDevPlan = false
}) => {
  const { HAS_DEV_PLAN, HAS_EXPORT_MY_PLAN, HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();
  const mixpanel = useMixpanel();

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { user: { data: user } } = useContext(GlobalContext);
  const {
    dataStatus: { data: dataStatus },
    role: { data: role, pending, failed },
    matchRate: { data: matchRate, pending: pendingMatch, failed: failedMatch, params: paramsMatch }, requireMatchRate,
    skillsInDemand: { data: inDemandSkills, pending: pendingInDemand, failed: failedInDemand }, requireInDemandSkills
  } = useContext(DataContext);

  const { mutate: newDevPlan, loading: newPending, failed: newFailed } = useMutationMethod({
    mutation: useMutation(NEW_DEV_PLAN as typeof NewDevPlanDocument)
  });

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const refExportBtn = useRef<HTMLDivElement>(null);

  const roleNonReducedUI = showNonReducedUI(role);
  const dirty = isEmployee && roleNonReducedUI && isDirtyData(getDataStatus(dataStatus));

  const userOrgId = HAS_INDEMAND_SKILLS ? user?.org?.id : undefined;
  const { id, code: role_id, title, skills_with_gap, skills, match_rate: role_match_rate } = role || {};

  const inExportMode = Boolean(onExportFinished);
  const [doExport, setDoExport] = useState(inExportMode);
  useLayoutEffect(() => {
    if (inExportMode) setDoExport(true);
  }, [inExportMode]);

  const createNewDevPlan = useCallback(() => {
    if (isEmployee && id && user?.id) {
      newDevPlan({
        variables: { input: {
          employee_id: user.id,
          title: title
            ? formatMessage({ id: 'hr.dev_plan.devplan_title' }, { title })
            : formatMessage({ id: 'hr.dev_plan.default_devplan_title' }),
          job_id: id
        } },
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplans' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplans' });
          // If we include existing dev_plan data into employeeDetails:
          // if (employee) cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDetails' });
        },
        onCompleted: (data) => {
          const plan_id = data?.newDevPlan?.id;
          if (plan_id) navigate(injectParams(PATH_MY_DEV_PLAN_EDITOR, { plan_id }));
        }
      });
    }
  }, [id, title, user, isEmployee, newDevPlan, navigate, formatMessage]);

  const allSkills = useMemo(() => [...skills_with_gap || [], ...skills || []], [skills_with_gap, skills]);

  const canHaveSkillsGap = isEmployee && !reducedUI && roleNonReducedUI && !failed && !pending;
  const hasSkillsGap = canHaveSkillsGap && id && title && user?.id && size(skills_with_gap) >= 1;

  useEffect(() => {
    if (dirty && role_id) requireMatchRate?.({ role_id });
  }, [dirty, role_id, requireMatchRate]);

  useEffect(() => {
    if (inExportMode && userOrgId) requireInDemandSkills?.({
      org_id: userOrgId,
      limit: MAX_ITEMS
    });
  }, [inExportMode, userOrgId, requireInDemandSkills]);

  const { match_rate } = isEmployee && dirty && !pendingMatch && !failedMatch &&
  matchRate && paramsMatch && toLower(paramsMatch.role_id) === toLower(role_id)
    ? matchRate
    : {} as MatchRateObject;

  const getExport = useCallback((_format: ExportFormat, _token: string) => {
    const hasInDemandSkills = userOrgId && size(inDemandSkills) >= 1;
    mixpanel.track('MySkillsSummary', currentEmployeeDetails);
    return exportElemetToPDF(
      'my_skills_summary',
      [refExportBtn.current],
      formatMessage,
      [ref.current, hasInDemandSkills ? ref2.current : undefined],
      hasInDemandSkills ? uniqBy([...skills_with_gap || [], ...inDemandSkills || []], 'id') : skills_with_gap,
      title
    );
  }, [title, skills_with_gap, inDemandSkills, userOrgId, mixpanel, formatMessage]);

  const handleExportFinished = useCallback((success: boolean) => {
    if (success) mixpanel.track('MySkillsSummarySuccess', currentEmployeeDetails);
    onExportFinished?.(success);
  }, [onExportFinished, mixpanel]);

  const exp = useExport(getExport, handleExportFinished);

  const canExportMyPlan = HAS_EXPORT_MY_PLAN && user?.current_job?.id === id && canHaveSkillsGap && (
    (size(skills) + size(skills_with_gap)) >= 1 ||
    Boolean(userOrgId)
  );
  const doExportMyPlan = Boolean(doExport && (!dirty || !isNil(match_rate)) && !pendingMatch &&
    (!userOrgId || (Boolean(inDemandSkills) && !pendingInDemand && !failedInDemand)) &&
    exp.enabled && canExportMyPlan);
  const cancelExportMyPlan = (!canExportMyPlan && doExport && !pending && Boolean(role)) ||
    Boolean(userOrgId && failedInDemand);

  useLayoutEffect(() => {
    if (doExportMyPlan) {
      setDoExport(false);
      exp.handleExport('pdf');
    }
  }, [doExportMyPlan, exp]);

  useLayoutEffect(() => {
    if (cancelExportMyPlan) {
      setDoExport(false);
      onExportFinished?.(true);
    }
  }, [cancelExportMyPlan, onExportFinished]);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testNewDevPlan && id && user?.id) newDevPlan({ variables: { input: {
      title: formatMessage({ id: 'hr.dev_plan.default_devplan_title' }), job_id: id, employee_id: user.id
    } } });
  }, [testNewDevPlan, id, user, newDevPlan, formatMessage]);

  return (
    <>
      <ContentCard ref={ref} className={inExportMode ? hidden : undefined}>
        <CardTitle
            title="role_details.skills.title"
            compact={!pending && !failed ? true : undefined}
            action={
              <Box display="flex" alignItems="center">
                <Box
                    display="inline-flex"
                    pr={isEmployee && roleNonReducedUI && canExportMyPlan ? 5 : undefined}
                >
                  <SkillsGapLegend depersonalized={!isEmployee}/>
                </Box>
                {isEmployee && roleNonReducedUI ? (
                  <>
                    {canExportMyPlan ? (
                      <ExportButton
                          ref={refExportBtn}
                          pending={exporting /* || exp.failed === false */}
                          disabled={pending || pendingMatch || newPending || exporting || /* exp.failed === false || */
                            !exp.enabled}
                          onExport={onExport}
                          title="board.button.export"
                      />
                    ) : undefined}
                    <Box pl={4.5} display="inline-flex">
                      <MatchIndicator
                          value={toSafeInteger(isNil(match_rate) ? role_match_rate : match_rate)}
                          pending={dirty && isNil(match_rate) ? true : undefined}
                      />
                    </Box>
                  </>
                ) : undefined}
              </Box>
            }
        />
        <SkillsGrid
            small={inExportMode}
            source="job_skills"
            sourceId={id}
            plain={reducedUI ? true : undefined}
            withLink={reducedUI ? true : undefined}
            isEmployee={isEmployee}
            depersonalised={!isEmployee}
            withExpectedLevel
            skills={allSkills}
            pending={pending}
            disabled={newPending}
            failed={failed}
            // breakAfter={size(skills_with_gap)}
            pagination={HAS_DEV_PLAN && hasSkillsGap ? (
              <EmployeeNewDevPlanButtons
                  jobId={id}
                  title={title}
                  userId={user?.id}
                  pending={newPending}
                  failed={newFailed}
                  onClick={createNewDevPlan}
              />
            ) : undefined}
        />
      </ContentCard>
      {inExportMode && userOrgId && size(inDemandSkills) >= 1 ? (
        <ContentCard ref={ref2} className={hidden2}>
          <CardTitle title="skills.in_demand.title"/>
          <SkillsGrid isEmployee small skills={inDemandSkills}/>
        </ContentCard>
      ) : undefined}
      {/* <ActionFailedAlert
          message="supervisor.export.failed"
          open={exp.failed}
          onDismiss={exp.handleDismiss}
      /> */}
    </>
  );
};

RoleSkills.propTypes = RoleSkillsPropTypes;

export default memo(RoleSkills);
