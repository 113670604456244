import {
  forwardRef, memo, useState, useCallback, useMemo, type ReactNode // , useRef, useEffect, type SyntheticEvent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import trim from 'lodash/trim';
import isArray from 'lodash/isArray';
// import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { type TooltipProps } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
// import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
// local imports
import { ExportFormat, EXPORT_CSV /* , EXPORT_PDF */ } from '../models/exportFormat';
import { getStandardLink } from '../helpers/values';
// import { DEFAULT_MENU_RIGHT } from '../helpers/menus';
import { getStringifiedIds } from '../helpers/strings';
// import { useCombinedRefs } from '../hooks/useCombinedRefs';
import SimpleTooltip from './SimpleTooltip';
// SCSS imports
// import { caret, caretOpen } from '../styles/modules/Toolbar.module.scss';
// import { dropdownButton } from './ExportButton.module.scss';

export type ExportParams = Record<string, boolean | string | number | number[]>;

type ExportButtonProps = {
  title?: string;
  disabled?: boolean | null;
  pending?: boolean | null;
  tooMuchData?: boolean | null;
  tootipPlacement?: TooltipProps['placement'];
  params?: ExportParams | null;
  endpoint?: string;
  onExport?: (format: ExportFormat) => void;
  // for Storybook only
  // active?: boolean;
}

const ExportButtonPropTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  tooMuchData: PropTypes.bool,
  tootipPlacement: PropTypes.string as Validator<TooltipProps['placement']>,
  params: PropTypes.object as Validator<ExportParams>,
  endpoint: PropTypes.string,
  onExport: PropTypes.func
  // for Storybook only
  // active: PropTypes.bool
};

const ExportButton = forwardRef<HTMLDivElement, ExportButtonProps>(({
  title,
  disabled = false,
  pending = false,
  tooMuchData = false,
  tootipPlacement = 'top',
  params,
  endpoint,
  onExport
  // active = false
}, ref) => {
  const { formatMessage } = useIntl();
  // const innerRef = useRef<HTMLDivElement>(null);
  // const anchorRef = useCombinedRefs(ref, innerRef);

  // const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // for Storybook only
  // useEffect(() => {
  //   if (active) setOpen(true);
  // }, [active]);

  // const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

  // const handleClose = useCallback((event: SyntheticEvent) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target as Node)) return;
  //   setOpen(false);
  // }, []);

  const handleTooltipClose = useCallback(() => setTooltipOpen(false), []);
  const handleTooltipOpen = useCallback(() => setTooltipOpen(true), []);

  // const handlePDF = useCallback(() => {
  //   setOpen(false);
  //   if (onExport) onExport(EXPORT_PDF);
  // }, [onExport]);

  const handleCSV = useCallback(() => {
    // setOpen(false);
    onExport?.(EXPORT_CSV);
  }, [onExport]);

  // const isOpen = open && !pending && !disabled && !tooMuchData;

  const button = (
    <ButtonGroup
        ref={ref} // anchorRef
        variant="contained"
        color="primary"
        size="small"
        // aria-label="split button"
        disabled={disabled || pending || tooMuchData || (!onExport && (!endpoint || !params))}
    >
      <Button
          color="primary"
          variant="contained"
          onClick={endpoint && params ? undefined : handleCSV}
          type={endpoint && params ? 'submit' : undefined}
          startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
      >
        <Box pl={pending ? undefined : 2.25} pr={2.25 /* px={0.5} */} py={0.5 /* 0.25 */} component="span">
          <FormattedMessage id={title || 'toolbar.button.export'} defaultMessage={title}/>
        </Box>
      </Button>
      {/* <Button
          color="primary"
          // eslint-disable-next-line no-undefined
          aria-controls={isOpen ? 'split-button-menu' : undefined}
          // eslint-disable-next-line no-undefined
          aria-expanded={isOpen ? 'true' : undefined}
          aria-label={formatMessage({ id: 'toolbar.button.export' })}
          aria-haspopup="menu"
          onClick={handleToggle}
          className={dropdownButton}
      >
        <ArrowDropDown
            className={clsx(caret, {
              [caretOpen]: isOpen
            })}
        />
      </Button> */}
    </ButtonGroup>
  );

  const [values, hasLink] = useMemo(() => {
    if (!tooMuchData) return [undefined, false];
    const link = trim(formatMessage({ id: 'toolbar.button.too_much_data.link', defaultMessage: ' ' }));
    return [
      {
        link: link
          ? getStandardLink({ href: link })
          : (chunks?: ReactNode | ReactNode[] | null): ReactNode => chunks,
        br: <br/>
      },
      Boolean(link)
    ];
  }, [tooMuchData, formatMessage]);

  return (
    <>
      {(tooMuchData && (
        <SimpleTooltip
            open={tooltipOpen}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            disableInteractive={hasLink ? undefined : true}
            disableFocusListener
            placement={tootipPlacement}
            title={<FormattedMessage id="toolbar.button.too_much_data" values={values}/>}
        >
          {button}
        </SimpleTooltip>
      )) || (endpoint && params && (
        <form method="POST" action={endpoint}>
          {map(params, (value, key) => value ? (
            <input
                key={key}
                type="hidden"
                name={key}
                // eslint-disable-next-line no-nested-ternary
                value={value === true ? 'true' : isArray(value) ? getStringifiedIds(value) : value}
            />
          ) : undefined)}
          {button}
        </form>
      )) || button}
      {/* <Menu
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...DEFAULT_MENU_RIGHT}
          anchorEl={anchorRef.current}
          open={isOpen}
          onClose={handleClose}
      >
        {map([
          { option: 'toolbar.button.export_csv', handler: handleCSV },
          { option: 'toolbar.button.export_pdf', handler: handlePDF }
        ], ({ option, handler }) => (
          <MenuItem key={option} onClick={handler}>
            <Typography variant="inherit">
              <FormattedMessage id={option}/>
            </Typography>
          </MenuItem>
        ))}
      </Menu> */}
    </>
  );
});

ExportButton.displayName = 'ExportButton';

ExportButton.propTypes = ExportButtonPropTypes;

export default memo(ExportButton);
