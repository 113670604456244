import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import ReactEChartsCore from 'echarts-for-react/lib/core';
// import { type Opts } from 'echarts-for-react/lib/types';
import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import {
  LineChart,
  BarChart,
  PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  GraphChart
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from 'echarts/charts';
import {
  // GridSimpleComponent,
  GridComponent,
  PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  // MarkAreaComponent,
  LegendComponent
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  // DatasetComponent,
} from 'echarts/components';
// import { UniversalTransition } from 'echarts/features';
import {
  // Uncomment for rendering as SVG:
  // SVGRenderer,
  CanvasRenderer
} from 'echarts/renderers';
// SCSS imports
import { chart } from './Chart.module.scss';

// const opts: Opts = { renderer: 'svg' };

echarts.use([
  // components:
  TitleComponent,
  TooltipComponent,
  GraphicComponent,
  GridComponent,
  PolarComponent,
  MarkPointComponent,
  MarkLineComponent,
  LegendComponent,
  // charts:
  LineChart,
  BarChart,
  PieChart,
  // RadarChart,
  GraphChart,
  // features:
  // UniversalTransition
  // renderers:
  // SVGRenderer,
  CanvasRenderer
]);

export type EventHandlers = Record<string, Function>;

type ChartProps = {
  className?: string;
  option?: EChartsOption;
  onEvents?: EventHandlers;
};

const ChartPropTypes = {
  // attributes
  className: PropTypes.string,
  option: PropTypes.object as Validator<EChartsOption>,
  onEvents: PropTypes.object as Validator<EventHandlers>
};

const Chart = forwardRef<ReactEChartsCore, ChartProps>(({
  className,
  option,
  onEvents
}, ref) => option ? (
  <ReactEChartsCore
      ref={ref}
      echarts={echarts}
      option={option}
      onEvents={onEvents}
      className={clsx(chart, className)}
      notMerge
      lazyUpdate
      theme="light"
      // onChartReady={onChartReady}
      // Uncomment this and SVGRenderer for rendering as SVG:
      // opts={opts}
  />
) : null);

Chart.displayName = 'Chart';

Chart.propTypes = ChartPropTypes;

export default memo(Chart);
