import { forwardRef, memo, useCallback, useMemo, type MouseEvent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import PlayArrow from '@mui/icons-material/PlayArrow';
// SCSS imports
import { item, arrowDown, normal, shaded } from './ChainOfCommandItem.module.scss';

type ChainOfCommandItemProps = {
  id: string;
  title: string;
  level: number;
  onExpand?: (id: string) => void;
  expanded?: boolean;
  expandable?: boolean | null;
  expandableItem?: boolean;
  isLast?: boolean;
};

const ChainOfCommandItemPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  onExpand: PropTypes.func,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  expandableItem: PropTypes.bool,
  isLast: PropTypes.bool
};

const ChainOfCommandItem = forwardRef<HTMLLIElement, ChainOfCommandItemProps>(({
  id,
  title,
  level,
  onExpand,
  expanded = false,
  expandable = false,
  expandableItem = false,
  isLast = false
}, ref) => {
  const { formatMessage } = useIntl();

  const expandLabel = useMemo(
    () => formatMessage({ id: 'filter.coc.expanded' }, { expanded, title }),
    [expanded, title, formatMessage]);

  const handleExpand = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    onExpand?.(id);
  }, [onExpand, id]);

  const icon = (
    <PlayArrow
        className={clsx({
          [arrowDown]: expanded,
          [shaded]: !expandableItem,
          [normal]: expandable && expandableItem
        })}
    />
  );

  return (
    <Box
        ref={ref}
        pl={2 * level}
        pr={5}
        pb={isLast ? 0.5 : undefined}
        aria-label={expandable && expandableItem ? expandLabel : undefined}
        className={item}
    >
      {expandable ? (
        <>
          <IconButton
              size="small"
              aria-expanded={expandableItem ? expanded : undefined}
              aria-hidden={expandableItem ? undefined : true}
              disabled={!expandableItem || !onExpand}
              onClick={expandableItem && onExpand ? handleExpand : undefined}
          >
            {icon}
          </IconButton>
          {expandable && expandableItem ? (
            <Box aria-hidden component="span">
              {title}
            </Box>
          ) : title}
        </>
      ) : (
        <>
          {icon}
          {title}
        </>
      )}
    </Box>
  );
});

ChainOfCommandItem.displayName = 'ChainOfCommandItem';

ChainOfCommandItem.propTypes = ChainOfCommandItemPropTypes;

export default memo(ChainOfCommandItem);
