import { forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
// Material UI imports
import Divider from '@mui/material/Divider';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import { type MoveItemFunction } from '@empathco/ui-components/src/hooks/useDragAndDropItem';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { hasDelegationOnly, getDefaultLeaderId, getRootLeaderId, hasLeadershipOnly } from '../models/user';
import { FilterValues } from '../hooks/useFilters';
import { GlobalContext } from '../context/global';
import { SupervisorContext, TbBestMatchesParams, TbBestMatchesFilterValues } from '../context/supervisor';
import EmployeeDropTarget from '../elements/EmployeeDropTarget';
import EmployeeDraggableCard from '../v3/EmployeeDraggableCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import DashboardFilters from '../v3/DashboardFilters';

type TbBestMatchesProps = {
  teamId?: number;
  moveEmployee?: MoveItemFunction;
  disabled?: boolean | null;
  dragging?: boolean;
};

const TbBestMatchesPropTypes = {
  teamId: PropTypes.number,
  moveEmployee: PropTypes.func,
  disabled: PropTypes.bool,
  dragging: PropTypes.bool
};

const TbBestMatches = forwardRef<HTMLDivElement, TbBestMatchesProps>(({
  teamId,
  moveEmployee,
  disabled: parentDisabled = false,
  dragging = false
}, ref) => {
  const { user: { data: user }, paths: { supvEmplPath } } = useContext(GlobalContext);
  const {
    tbBestMatches: { data: employees, count, pending: employeesPending, failed: employeesFailed, params },
    requireTbBestMatches
  } = useContext(SupervisorContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [filters, setFilters] = useState<TbBestMatchesFilterValues>();

  const [rootUid, uid, delegate, withDirectReports] = useMemo(() => [
    getRootLeaderId(user),
    getDefaultLeaderId(user),
    hasDelegationOnly(user),
    hasLeadershipOnly(user)
  ], [user]);

  useEffect(() => {
    if (requireTbBestMatches && teamId && filters && !isNil(pageSize)) {
      const newParams: TbBestMatchesParams = {
        team_id: teamId,
        ...omit(filters, 'job_levels'),
        ...size(filters.job_levels) >= 1 ? { job_levels: filters.job_levels } : {},
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(params, newParams)) {
        curPage = 1;
        setCurrentPage(1);
      }
      requireTbBestMatches({
        ...newParams,
        offset: pageSize * (curPage - 1)
       });
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, filters, currentPage, pageSize, requireTbBestMatches]); // ignoring `params` changes

  const componentProps: Partial<GetComponentProps<typeof EmployeeDraggableCard>> = useMemo(() => ({
    avatarVariant: 'grey',
    route: supvEmplPath,
    withZoomInSkills: true,
    moveEmployee
  }), [moveEmployee, supvEmplPath]);

  const pending = !teamId || !filters || employeesPending || !employees || isNil(pageSize);
  const failed = employeesFailed;
  const disabled = parentDisabled || pending || failed;

  return (
    <>
      <CardSection top>
        <BoxTypography pb={1.5} variant="subtitle1">
          <FormattedMessage id="hr.teambuilder.best_matches"/>
        </BoxTypography>
        <Divider/>
      </CardSection>
      <CardsGrid
          ref={ref}
          items={employees}
          notFoundMessage="hr.teambuilder.no_matches"
          variant="white"
          pending={pending}
          failed={failed}
          disabled={disabled}
          withoutTopPadding
          blendFilters
          component={EmployeeDraggableCard}
          ComponentProps={componentProps}
          filters={
            <DashboardFilters
                settingsId="tb_matches"
                onChange={setFilters as (params: FilterValues) => void}
                withLevels
                withSort
                withReset
                uid={uid}
                rootUid={rootUid}
                delegate={delegate}
                withDirectReports={withDirectReports}
                disabled={disabled}
            />
          }
          itemsPrefix={dragging ? <EmployeeDropTarget active/> : undefined}
          pagination={
            <PaginationControls
                settingsId="tb_matches"
                loaded={Boolean(employees)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={disabled}
            />
          }
      />
    </>
  );
});

TbBestMatches.displayName = 'TbBestMatches';

TbBestMatches.propTypes = TbBestMatchesPropTypes;

export default memo(TbBestMatches);
