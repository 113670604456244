import { forwardRef, memo, useState, useCallback, useMemo, type Ref, type MouseEvent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// Material Icon imports
import InformationVariant from 'mdi-material-ui/InformationVariant';
// local imports
import { commonFormattingValues, Values, ValuesPropType } from '../helpers/intl';
import StandardLink from './StandardLink';
// SCSS imports
import { avatar, smaller, enabled, muted, iconButton } from './InfoButton.module.scss';

type InfoButtonProps = {
  help: string;
  values?: Values;
  text?: string;
  textVariant?: Variant | 'inherit';
  disabled?: boolean | null;
  small?: boolean | null;
  placement?: TooltipProps['placement'];
  enablePortal?: boolean | null;
  // for Storybook only
  isOpen?: boolean;
}

const InfoButtonPropTypes = {
  help: PropTypes.string.isRequired,
  values: ValuesPropType,
  text: PropTypes.string,
  textVariant: PropTypes.string as Validator<Variant | 'inherit'>,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  placement: PropTypes.string as Validator<TooltipProps['placement']>,
  enablePortal: PropTypes.bool,
  // for Storybook only
  isOpen: PropTypes.bool
};

const InfoButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, InfoButtonProps>(({
  help,
  values,
  text,
  textVariant,
  disabled = false,
  small = false,
  placement = 'top',
  enablePortal = false,
  isOpen = false
}, ref) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(isOpen);

  const toggleTooltip = useCallback((
    event?: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>
  ) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const vals = useMemo(() => values && size(values) >= 1
    ? { ...commonFormattingValues, ...values } : commonFormattingValues, [values]);

  return (
    // <ClickAwayListener onClickAway={handleTooltipClose}>
    <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        arrow
        // disableFocusListener
        disableHoverListener
        placement={placement}
        title={<FormattedMessage id={help} defaultMessage={help} values={vals}/>}
        PopperProps={enablePortal ? undefined : { disablePortal: true }}
    >
      {text ? (
        <StandardLink
            ref={ref as Ref<HTMLAnchorElement>}
            variant={small ? 'body2' : textVariant}
            text={text}
            onClick={disabled ? undefined : toggleTooltip}
        />
      ) : (
        <IconButton
            ref={ref as Ref<HTMLButtonElement>}
            size={small ? 'small' : 'medium'}
            disabled={disabled ? true : undefined}
            color="primary"
            aria-label={formatMessage({ id: 'common.button.info' })}
            onClick={toggleTooltip}
            className={iconButton}
        >
          <Avatar
              variant="circular"
              className={`${disabled ? muted : enabled} ${small ? smaller : avatar}`}
          >
            <InformationVariant fontSize="inherit"/>
          </Avatar>
        </IconButton>
      )}
    </Tooltip>
    // </ClickAwayListener>
  );
});

InfoButton.displayName = 'InfoButton';

InfoButton.propTypes = InfoButtonPropTypes;

export default memo(InfoButton);
