import toSafeInteger from 'lodash/toSafeInteger';
import { type Params } from 'react-router-dom';
// Skillmore UI Components
import { sanitizeRouteParam } from '@empathco/ui-components/src/helpers/route';

export const toSafeIntId = (id: string | null) => id ? toSafeInteger(id) : null;

export const getEmployeeId = (params: Readonly<Params<string>>) => sanitizeRouteParam(params?.employee_id);
export const getRoleId = (params: Readonly<Params<string>>) => sanitizeRouteParam(params?.role_id);
export const getSkillId = (params: Readonly<Params<string>>) => sanitizeRouteParam(params?.skill_id);
export const getCohortId = (params: Readonly<Params<string>>) => toSafeIntId(sanitizeRouteParam(params?.cohort_id));
export const getPlanId = (params: Readonly<Params<string>>) => toSafeIntId(sanitizeRouteParam(params?.plan_id));
export const getTeamId = (params: Readonly<Params<string>>) => toSafeIntId(sanitizeRouteParam(params?.team_id));
export const getOpportunityId = (params: Readonly<Params<string>>) => toSafeIntId(sanitizeRouteParam(params?.opp_id));
export const getReportId = (params: Readonly<Params<string>>) => toSafeIntId(sanitizeRouteParam(params?.report_id));
export const getReportType = (params: Readonly<Params<string>>) => sanitizeRouteParam(params?.report_type);

export interface RouterLinkState {
  fromDashboard?: boolean;
  viewInferredSkills?: boolean;
  viewDevPlans?: boolean;
}
