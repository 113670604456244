import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// Skillmore UI Components
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
// SCSS imports
import { item, avatar, box } from './EmployeeDropTarget.module.scss';

type EmployeeDropTargetProps = {
  active?: boolean;
}

const EmployeeDropTargetPropTypes = {
  active: PropTypes.bool
};

const EmployeeDropTarget = forwardRef<HTMLDivElement, EmployeeDropTargetProps>(({
  active = false
}, ref) => (
  <Grid
      ref={ref}
      item
      xs={12}
      md={6}
      lg={4}
      className={item}
  >
    <Box
        minHeight="6.5rem"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={active ? box : avatar}
    >
      {!active && <AccountCircleAlt fontSize="large"/>}
    </Box>
  </Grid>
));

EmployeeDropTarget.displayName = 'EmployeeDropTarget';

EmployeeDropTarget.propTypes = EmployeeDropTargetPropTypes;

export default memo(EmployeeDropTarget);
