import { forwardRef, memo, useCallback, useEffect, useState, useMemo, type SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { withStyles } from 'tss-react/mui';
import { type Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
// Skillmore UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
// local imports
import { MIN_MATCH_RATE, MAX_MATCH_RATE } from '../config/params';
import useCustomerSettings from '../config/customer';
import PlainValueLabel from './PlainValueLabel';

const components = { ValueLabel: PlainValueLabel };

const CustomSlider = withStyles(Slider, (theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    forcedColorAdjust: 'none'
  },
  track: {
    color: theme.palette.greys.matchRate
  },
  disabled: {
    '& .MuiSlider-rail': {
      backgroundColor: `${theme.palette.grey['400']} !important`
    }
  },
  rail: {
    backgroundColor: theme.palette.primary.main
  }
}));

type MatchRateSliderProps = {
  value?: number;
  onChange: (value?: number) => void;
  disabled?: boolean;
};

const MatchRateSliderPropTypes = {
  // attributes
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const MatchRateSlider = forwardRef<HTMLSpanElement, MatchRateSliderProps>(({
  value,
  onChange,
  disabled = false
}, ref) => {
  const { formatNumber } = useIntl();
  const { DEFAULT_MATCH_RATE } = useCustomerSettings();

  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const getValueText = useCallback(
    (val: number): string => formatNumber(val / 100, percentageOptions),
    [formatNumber]
  );

  const marks = useMemo(() => [
    { value: MIN_MATCH_RATE, label: getValueText(MIN_MATCH_RATE) },
    { value: MAX_MATCH_RATE, label: getValueText(MAX_MATCH_RATE) }
  ], [getValueText]);

  const handleChangeCommitted = useCallback((
    _event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => onChange(
    isArray(newValue) ? head(newValue) : newValue
  ), [onChange]);

  const handleChange = useCallback((_event: Event, newValue: number | number[], _activeThumb: number) => setSliderValue(
    isArray(newValue) ? head(newValue) : newValue
  ), []);

  return (
    <Box flexGrow={1} display="flex" alignItems="center">
      <Box pr={2} pb={2} color="text.label">
        <Typography variant="body1" component="span">
          <FormattedMessage id="common.match_rate"/>
        </Typography>
      </Box>
      <CustomSlider
          ref={ref}
          min={MIN_MATCH_RATE}
          max={MAX_MATCH_RATE}
          value={sliderValue || DEFAULT_MATCH_RATE}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          disabled={disabled || isNil(value)}
          valueLabelDisplay="on"
          aria-labelledby="match-rate-slider"
          getAriaValueText={getValueText}
          valueLabelFormat={getValueText}
          components={components}
          marks={marks}
      />
    </Box>
  );
});

MatchRateSlider.displayName = 'MatchRateSlider';

MatchRateSlider.propTypes = MatchRateSliderPropTypes;

export default memo(MatchRateSlider);
