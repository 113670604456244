import {
  memo, useCallback, useState,
  type FunctionComponent, type Ref, type Component, type MouseEvent, type Dispatch, type SetStateAction
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Skillmore UI Components
import { type Values } from '@empathco/ui-components/src/helpers/intl';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import AlertBox from '@empathco/ui-components/src/mixins/AlertBox';
import useDnD from '@empathco/ui-components/src/hooks/useDnD';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import AddSkillPopover from '@empathco/ui-components/src/widgets/AddSkillPopover';
// local imports
import { LookupItem } from '../models/lookupItem';
import { Skill } from '../models/skill';
import { DraggableType } from '../constants/draggableTypes';
import { SkillGroup } from '../context/persistent';
import TeamSelectedSkills from '../widgets/TeamSelectedSkills';
import TeamSkills from '../widgets/TeamSkills';
import AddSkillsDialog from '../widgets/AddSkillsDialog';
import SkillSearch from '../widgets/SkillSearch';
// SCSS imports
import { sectionHeader } from './SkillsDnDSelector.module.scss';

type SkillsStateType = Record<number, Skill>;

export const SKILL_SELECTOR_JOB = 'job' as const;
export const SKILL_SELECTOR_TALENT = 'talent' as const;

export const SKILL_SELECTOR = [
  SKILL_SELECTOR_JOB,
  SKILL_SELECTOR_TALENT
] as const;
export type SkillSelectorType = typeof SKILL_SELECTOR[number];

type SkillsDnDSelectorProps = {
  variant: SkillSelectorType;
  allSkills: Skill[]; // union of top/current skills + added skills
  skillIds: number[]; // selected skill ids
  setSkillIds: Dispatch<SetStateAction<number[]>>;
  userAddedSkillIds?: number[];
  onAddSkill?: (skill: Skill) => void;
  onAddSkillGroup?: (skillGroup: SkillGroup) => void;
  onDeleteSkill?: (id: number) => void;
  onAddedSkillsChange?: (addedSkillIds: number[]) => void;
  onSelectionChange: (skillIds: number[]) => void;
  onClick?: (id: number, abbr: string) => void;
  onReset?: () => void;
  withLevels?: boolean;
  disabledSkills?: boolean | null;
  disabledSelected?: boolean | null;
  disabledAddSkill?: boolean | null;
  excludeJobIds?: number[] | null;
  excludeOrgIds?: number[] | null;
  title?: string;
  subtitle?: string;
  emptyMessage?: string;
  warning?: string;
  values?: Values;
}

const SkillsDnDSelectorPropTypes = {
  variant: PropTypes.oneOf(SKILL_SELECTOR).isRequired,
  allSkills: PropTypes.array.isRequired,
  skillIds: PropTypes.array.isRequired,
  setSkillIds: PropTypes.func.isRequired,
  userAddedSkillIds: PropTypes.array,
  onAddSkill: PropTypes.func,
  onAddSkillGroup: PropTypes.func,
  onDeleteSkill: PropTypes.func,
  onAddedSkillsChange: PropTypes.func,
  onSelectionChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onReset: PropTypes.func,
  withLevels: PropTypes.bool,
  disabledSkills: PropTypes.bool,
  disabledSelected: PropTypes.bool,
  disabledAddSkill: PropTypes.bool,
  excludeJobIds: PropTypes.array,
  excludeOrgIds: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  emptyMessage: PropTypes.string,
  warning: PropTypes.string,
  values: PropTypes.object as Validator<Values>
};

// eslint-disable-next-line complexity
const SkillsDnDSelector: FunctionComponent<SkillsDnDSelectorProps> = ({
  variant,
  allSkills,
  skillIds,
  setSkillIds,
  userAddedSkillIds,
  onAddSkill,
  onAddSkillGroup,
  onDeleteSkill,
  onAddedSkillsChange,
  onSelectionChange,
  onClick,
  onReset,
  withLevels = false,
  disabledSkills = false,
  disabledSelected = false,
  disabledAddSkill = false,
  excludeJobIds,
  excludeOrgIds,
  title,
  subtitle,
  emptyMessage,
  warning,
  values
}) => {
  const {
    items: skills,
    addedIds,
    availableIds,
    excludeIds,
    addItem,
    deleteItem,
    moveItem,
    unselectItem,
    resetItems,
    // Drag and Drop state
    isDragging,
    drop,
    isActive,
    selectedDrop
  } = useDnD<Skill, DraggableType>({
    accept: 'skill',
    allItems: allSkills,
    ids: skillIds,
    setIds: setSkillIds,
    userAddedIds: userAddedSkillIds,
    onAdd: onAddSkill,
    onDelete: onDeleteSkill,
    onAddedItemsChange: onAddedSkillsChange,
    onSelectionChange,
    onReset,
    disabled: disabledSelected
  });

  // Add Skill dialog
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);

  const handleAddSkill = useCallback((skl?: LookupItem | Skill | null) => {
    addItem(skl as Skill);
    setAnchorAddBtn(null);
  }, [addItem]);

  const handleAddSkillGroup = useCallback((sklGrp?: SkillGroup | null) => {
    if (sklGrp) onAddSkillGroup?.(sklGrp);
    setAnchorAddBtn(null);
  }, [onAddSkillGroup]);

  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  return skills ? (
    <>
      <CardSection
          ref={drop as Ref<Component<BoxProps>>}
          shady
      >
        {variant === SKILL_SELECTOR_TALENT && size(availableIds) >= 1 ? (
          <BoxTypography variant="body2" color="text.secondary" className={sectionHeader}>
            <FormattedMessage id={title} defaultMessage={title} values={values}/>
          </BoxTypography>
        ) : undefined}
        <TeamSkills
            variant={(variant === SKILL_SELECTOR_JOB && 'simple') ||
              (variant === SKILL_SELECTOR_TALENT && 'uniform') ||
              'normal'}
            withLevels={withLevels}
            skillIds={availableIds}
            skills={skills as SkillsStateType}
            addedSkillIds={addedIds}
            onClick={onClick}
            onDelete={deleteItem}
            moveSkill={moveItem}
            onAddSkill={handleAddOpen}
            disabled={disabledSkills}
            pending={disabledAddSkill}
            dragging={isDragging}
            emptyMessage={emptyMessage}
        />
        {variant === SKILL_SELECTOR_TALENT ? undefined : (
          <>
            {warning ? (
              <AlertBox severity="error" variant="standard" elevation={9} mt={2.5}>
                <Typography variant="subtitle2">
                  <FormattedMessage id={warning} defaultMessage={warning} values={values}/>
                </Typography>
              </AlertBox>
            ) : undefined}
            {title || subtitle ? (
              <Box
                  display="flex"
                  alignItems="center"
                  pt={warning ? 2.5 : 5}
                  pb={1.75}
              >
                {title ? (
                  <BoxTypography pr={2.5} variant="subtitle1">
                    <FormattedMessage id={title} defaultMessage={title} values={values}/>
                  </BoxTypography>
                ) : undefined}
                {subtitle ? (
                  <BoxTypography flexGrow={1} variant="subtitle2" fontStyle="italic">
                    <FormattedMessage id={subtitle} defaultMessage={subtitle} values={values}/>
                  </BoxTypography>
                ) : undefined}
              </Box>
            ) : undefined}
            <TeamSelectedSkills
                ref={selectedDrop}
                variant={variant === SKILL_SELECTOR_JOB ? 'simple' : 'normal'}
                withLevels={withLevels}
                skillIds={skillIds}
                skills={skills as SkillsStateType}
                moveSkill={moveItem}
                onClick={onClick}
                onDelete={unselectItem}
                onReset={onReset ? resetItems : undefined}
                disabled={disabledSelected}
                isActive={isActive}
            />
          </>
        )}
      </CardSection>
      {variant === SKILL_SELECTOR_TALENT ? (
        <>
          {size(allSkills) >= 1 && (
            <CardSection
                ref={selectedDrop as Ref<Component<BoxProps>>}
                shady
            >
              <BoxTypography variant="body2" color="text.secondary" className={sectionHeader}>
                <FormattedMessage id={subtitle} defaultMessage={subtitle} values={values}/>
              </BoxTypography>
              <TeamSelectedSkills
                  variant="uniform"
                  withLevels={withLevels}
                  skillIds={skillIds}
                  skills={skills as SkillsStateType}
                  moveSkill={moveItem}
                  onClick={onClick}
                  onDelete={deleteItem}
                  disabled={disabledSelected}
                  isActive={isActive}
              />
            </CardSection>
          )}
          <AddSkillsDialog
              anchorEl={anchorAddBtn}
              exclude={excludeIds}
              excludeJobs={excludeJobIds}
              excludeOrgs={excludeOrgIds}
              onAdd={handleAddSkill}
              onAddGroup={handleAddSkillGroup}
              onCancel={handleAddClose}
              disabled={disabledSkills || disabledSelected}
          />
        </>
      ) : (
        <AddSkillPopover
            supervisor
            exclude={excludeIds}
            anchorEl={anchorAddBtn}
            onAdd={handleAddSkill}
            onCancel={handleAddClose}
            disabled={disabledSkills || disabledSelected}
            SkillSearchComponent={SkillSearch}
        />
      )}
    </>
  ) : null;
};

SkillsDnDSelector.propTypes = SkillsDnDSelectorPropTypes;

export default memo(SkillsDnDSelector);
