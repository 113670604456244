import { memo, useState, useContext, useEffect, useCallback, useMemo, type FunctionComponent, type MouseEvent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import findIndex from 'lodash/findIndex';
import transform from 'lodash/transform';
// Skillmore UI Components
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import AddSkillPopover from '@empathco/ui-components/src/widgets/AddSkillPopover';
// local imports
import { LookupItem } from '../models/lookupItem';
import { Skill } from '../models/skill';
import { STEP_TARGET_ID } from '../constants/builder';
import { mustCompute, isDirtyData } from '../constants/dataStatuses';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import useStatusPoller from '../hooks/useStatusPoller';
import SelectableSkills from '../v3/SelectableSkills';
import BuilderSubheader from '../elements/BuilderSubheader';
import BuilderFooter from '../elements/BuilderFooter';
import SkillSearch from '../widgets/SkillSearch';

const getAddedSkills = (
  targeted: Skill[] | null | undefined,
  required: Skill[],
  recommended: Skill[] | null | undefined
): number[] => union(
  map(targeted, 'id'),
  map(required, 'id'),
  map(recommended, 'id')
);

type BuilderTargetsStepProps = {
  onNext: (confirmed: boolean) => void;
}

const BuilderTargetsStepPropTypes = {
  // attributes
  onNext: PropTypes.func.isRequired
};

// eslint-disable-next-line complexity, max-statements
const BuilderTargetsStep: FunctionComponent<BuilderTargetsStepProps> = ({
  onNext
}) => {
  const { status, pending: pendingStatus, failed: failedStatus } = useStatusPoller(null);
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    dataStatusUpdate: { pending: statusPending, failed: statusFailed }, updateDataStatus,
    role: { data: role, pending: rolePending, failed: roleFailed }, requireEmployeeRole,
    targetSkills: { data: targetSkills, pending: skillsPending, failed: skillsFailed }, requireTargetSkills,
    recommendedSkills: { data: recommendedSkills, pending: recommendedPending, failed: recommendedFailed },
    requireRecommendedSkills,
    targetSkillsUpdate: { pending: updatePending, failed: updateFailed }, updateTargetSkills
  } = useContext(DataContext);
  const compute = mustCompute(status);
  const dirty = isDirtyData(status);

  const roleId = user?.current_job?.code || undefined;
  const needsRole = Boolean(roleId);
  const { skills_with_gap } = (needsRole && role) || {};

  const skillsWithGap = useMemo(() => skills_with_gap ? map(skills_with_gap, (skill) => ({
    ...skill,
    is_target: true
  })) : [], [skills_with_gap]);

  const [skills, setSkills] = useState<Skill[] | null>(null);
  const [added, setAdded] = useState<number[]>(getAddedSkills(targetSkills, skillsWithGap, recommendedSkills));
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);

  const loading = (needsRole && rolePending) || skillsPending || recommendedPending || pendingStatus || statusPending ||
    (needsRole && !role) || !targetSkills || !recommendedSkills || !skills || !status || dirty;
  const failed = (needsRole && roleFailed) || skillsFailed || recommendedFailed || failedStatus || statusFailed;
  const disabled = loading || failed || updatePending || !updateTargetSkills;

  useEffect(() => {
    if (roleId) requireEmployeeRole?.({ role_id: roleId });
  }, [roleId, requireEmployeeRole]);

  useEffect(() => {
    requireTargetSkills?.();
  }, [requireTargetSkills]);

  useEffect(() => {
    requireRecommendedSkills?.();
  }, [requireRecommendedSkills]);

  useEffect(() => {
    if (compute) updateDataStatus?.();
  }, [compute, updateDataStatus]);

  useEffect(() => {
    if (!targetSkills || !recommendedSkills) {
      setSkills(null);
      setAdded(getAddedSkills(targetSkills, skillsWithGap, recommendedSkills));
      setAnchorAddBtn(null);
    } else {
      setSkills(uniqBy([...targetSkills, ...skillsWithGap, ...recommendedSkills], 'id'));
      setAdded(getAddedSkills(targetSkills, skillsWithGap, recommendedSkills));
      setAnchorAddBtn(null);
    }
  }, [targetSkills, skillsWithGap, recommendedSkills]);

  const selectSkill = useCallback((skill: Skill) => {
    const index = findIndex(skills, ['id', skill.id]);
    const foundSkill = index >= 0 ? (skills as Skill[])[index] : null;
    if (foundSkill) {
      const updatedSkills = [...skills as Skill[]];
      updatedSkills[index] = {
        ...skill,
        is_target: !foundSkill.is_target
      };
      setSkills(updatedSkills);
    }
  }, [skills]);

  const handleAddSkill = useCallback((skl: LookupItem) => {
    const updatedSkills = [...skills || []];
    const idx = findIndex(skills, ['id', skl.id]);
    if (idx < 0) {
      updatedSkills.push({ ...skl as Skill, is_target: true });
    } else {
      updatedSkills[idx] = { ...(skills as Skill[])[idx], is_target: true };
    }
    setSkills(updatedSkills);
    setAdded((prevAdded) => union(prevAdded, [skl.id]));
    setAnchorAddBtn(null);
  }, [skills]);

  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  const handleSkip = useCallback(() => onNext(false), [onNext]);

  const handleSubmitTargets = useCallback(() => updateTargetSkills?.({
    onSuccess: () => onNext(true),
    onboarding_step: STEP_TARGET_ID,
    targets: transform(skills || [], (targets, skill) => {
      const { id, is_target } = skill;
      if (is_target) targets.push(id);
    }, [] as number[])
  }), [onNext, skills, updateTargetSkills]);

  return (
    <>
      <BuilderSubheader
          user={user}
          title="builder.step4.subheader"
          info="builder.step4.info"
          disabled={disabled}
          onAdd={handleAddOpen}
      />
      <SelectableSkills
          notFoundMessage="builder.step4.empty"
          skills={skills}
          failed={failed}
          pending={loading}
          onClick={selectSkill}
          disabled={disabled}
      />
      <BuilderFooter
          disabledPrev={loading || updatePending ? true : undefined}
          disabledNext={disabled}
          pendingNext={updatePending ? true : undefined}
          onPrev={handleSkip}
          onNext={handleSubmitTargets}
      />
      <ActionFailedAlert
          message="edit_skills.submit_error"
          open={updateFailed}
      />
      <AddSkillPopover
          anchorEl={anchorAddBtn}
          exclude={added}
          onAdd={handleAddSkill}
          onCancel={handleAddClose}
          disabled={disabled}
          SkillSearchComponent={SkillSearch}
      />
    </>
  );
};

BuilderTargetsStep.propTypes = BuilderTargetsStepPropTypes;

export default memo(BuilderTargetsStep);
