import { forwardRef, memo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// Skillmore UI Components
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { AdminJob } from '../graphql/types';
import { Job } from '../models/job';
import useModels from '../helpers/models';
import JobLevel from '../elements/JobLevel';
import OpenReqsLink from '../v3/OpenReqsLink';
import JobDetailsItem from '../v3/JobDetailsItem';

type JobDetailsBandProps = {
  role: Job | AdminJob;
  admin?: boolean;
}

const JobDetailsBandPropTypes = {
  // attributes
  role: PropTypes.object.isRequired as Validator<Job | AdminJob>,
  admin: PropTypes.bool
};

const JobDetailsBand = forwardRef<Component<BoxProps>, JobDetailsBandProps>(({
  role,
  admin = false
}, ref) => {
  const { getLocationStr, isInternational } = useModels();
  const { code, location } = role || {};
  const { supervisory } = (role || {}) as Job;
  const { is_supervisory } = (role || {}) as AdminJob;
  const { country } = location || {};
  return (
    <CardSection ref={ref} dark compact flex>
      <Box
          py={2}
          flexGrow={1}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
      >
        <JobDetailsItem
            prompt={<FormattedMessage id="role_details.level"/>}
            value={<JobLevel role={role}/>}
            divider
        />
        <JobDetailsItem
            prompt={<FormattedMessage id="role_details.job_key"/>}
            value={code}
            divider
        />
        <JobDetailsItem
            prompt={<FormattedMessage id="role_details.location"/>}
            value={(admin && getLocationStr(location)) ||
              (isInternational(country) && <FormattedMessage id="role_details.intl_location"/>) ||
              country || '—'}
            divider
        />
        <JobDetailsItem
            prompt={<FormattedMessage id="role_details.supervisory"/>}
            value={<FormattedMessage id={supervisory || is_supervisory ? 'common.yes' : 'common.no'}/>}
            divider
        />
        <JobDetailsItem
            prompt={<FormattedMessage id="role_details.open_reqs"/>}
            value={(
              <OpenReqsLink
                  admin={admin}
                  plain
                  role={role}
                  withOpenReqsPopup
                  withoutMoreButton
              />
            )}
        />
      </Box>
    </CardSection>
  );
});

JobDetailsBand.displayName = 'JobDetailsBand';

JobDetailsBand.propTypes = JobDetailsBandPropTypes;

export default memo(JobDetailsBand);
