import { memo, useEffect, useContext, useCallback, useState, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import isSafeInteger from 'lodash/isSafeInteger';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';
import toSafeInteger from 'lodash/toSafeInteger';
import toLower from 'lodash/toLower';
import { useParams } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
// Skillmore UI Components
import { bold, mapChunks } from '@empathco/ui-components/src/helpers/intl';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
// local imports
import { Job } from '../models/job';
import useNonReducedUI from '../constants/managementLevel';
import useCustomerSettings from '../config/customer';
import { PATH_MY_JOBS } from '../config/paths';
import { getRoleId } from '../helpers/routerParams';
import useModels from '../helpers/models';
import { SupervisorContext } from '../context/supervisor';
import { DataContext } from '../context';
import RoleDescription from '../v3/RoleDescription';
import JobDetailsBand from '../v3/JobDetailsBand';
import GeoZonePayrange from '../v3/GeoZonePayrange';
import TargetSwitch from '../v3/TargetSwitch';
import EmployeesPopup from './EmployeesPopup';
import EmployeesMatchingJob from './EmployeesMatchingJob';
// SCSS imports
import { infoCard, subheader, bonusLine, targetLimitMsg } from './RoleDetails.module.scss';

const zeroBonusRegexp = /^0*%$/u;

type RoleDetailsProps = {
  reducedUI?: boolean;
  isEmployee?: boolean;
  isAdm?: boolean;
  hrbp?: boolean;
  supervisor?: boolean;
}

const RoleDetailsPropTypes = {
  reducedUI: PropTypes.bool,
  isEmployee: PropTypes.bool,
  isAdm: PropTypes.bool,
  hrbp: PropTypes.bool,
  supervisor: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const RoleDetails: FunctionComponent<RoleDetailsProps> = ({
  reducedUI = false,
  isEmployee = false,
  isAdm = false,
  hrbp = false,
  supervisor = false
}) => {
  const {
    FIND_YOUR_GEO_ZONE_URL, HAS_CANDIDATES, HAS_COMPENSATION, INTERNATIONAL_SALARY_URL, isFeatureReleased
  } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();
  const { isInternational } = useModels();

  const role_id = getRoleId(useParams());
  const {
    role: { data: role, pending, failed },
    targetRoleUpdate: { pending: targetPending, failed: targetFailed, params: targetParams }, updateTargetRole,
    talentData: { data: talentData, pending: talentDataPending, params: talentDataParams }, requireTalentData
  } = useContext(DataContext);
  const {
    jobCandidatesCounts: { data: counts, pending: pendingCounts, failed: failedCounts, params: countsParams },
    requireJobCandidatesCounts
  } = useContext(SupervisorContext);
  const { code, title, description, is_target, bonus, regions, location } = role || {};
  const roleNonReducedUI = hrbp || showNonReducedUI(role);
  const isTarget = Boolean(is_target);
  const isIntl = isInternational(location?.country);
  const canViewCadidates = HAS_CANDIDATES && hrbp;

  const [connectToOpen, setConnectToOpen] = useState(false);
  const [matchingPopupOpen, setMatchingPopupOpen] = useState(false);
  const [matchingPopupMounted, setMatchingPopupMounted] = useState(false);
  const [targetMessageOpen, setTargetMessageOpen] = useState(false);
  const [targetMessageMounted, setTargetMessageMounted] = useState(false);

  const { total: matchedCount } = useMemo(
    () => counts && countsParams?.role_id === toLower(code)
      ? counts
      : { total: 0 },
    [counts, countsParams?.role_id, code]
  );

  const { close_match_employees: closeMatch, hires, same_job_employees: count } = useMemo(
    () => talentData && talentDataParams?.role_id === toLower(code)
      ? talentData
      : {},
    [talentData, talentDataParams?.role_id, code]
  );

  useEffect(() => {
    setConnectToOpen(false);
    setMatchingPopupOpen(false);
  }, [role_id]);

  useEffect(() => {
    if (HAS_CANDIDATES && canViewCadidates && role_id) requireJobCandidatesCounts?.({ role_id });
  }, [requireJobCandidatesCounts, role_id, canViewCadidates, HAS_CANDIDATES]);

  useEffect(() => {
    if (role_id && roleNonReducedUI) requireTalentData?.({ role_id });
  }, [role_id, roleNonReducedUI, requireTalentData]);

  useEffect(() => {
    if (targetFailed && targetParams?.limitReached) {
      setTargetMessageOpen(true);
      setTargetMessageMounted(true);
    }
  }, [targetFailed, targetParams?.limitReached]);

  const bonusValue = useMemo(
    () => (isSafeInteger(bonus) || (isString(bonus) && bonus.indexOf('%') === -1)) && toSafeInteger(bonus) > 0
      // eslint-disable-next-line react/style-prop-object
      ? <FormattedNumber value={toNumber(bonus)} style="currency" currency="USD" minimumFractionDigits={0}/>
      : (!zeroBonusRegexp.test(toString(bonus)) && bonus) || 0,
    [bonus]
  );

  const targetMessageValues = useMemo(() => isEmployee ? {
    br: <br/>,
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink to={PATH_MY_JOBS}>
        {mapChunks(chunks)}
      </StandardLink>
    )
  } : undefined, [isEmployee]);

  const switchTargetRole = useCallback((value: boolean) => {
    if (code && Boolean(value) !== isTarget) updateTargetRole?.({ role_id: code, is_target: value });
  }, [code, isTarget, updateTargetRole]);

  const handleConnectToOpen = useCallback(() => setConnectToOpen(true), []);
  const handleConnectToClose = useCallback(() => setConnectToOpen(false), []);

  const handleMatchingPopupOpen = useCallback(() => {
    setMatchingPopupOpen(true);
    setMatchingPopupMounted(true);
  }, []);

  const handleMatchingPopupClose = useCallback(() => setMatchingPopupOpen(false), []);
  const handleMatchingPopupExited = useCallback(() => setMatchingPopupMounted(false), []);

  const targetMessageCancel = useCallback(() => setTargetMessageOpen(false), []);
  const targetMessageExited = useCallback(() => setTargetMessageMounted(false), []);

  const loading = pending || !role;

  return (
    <ContentCard>
      <CardTitle
          title={
            (failed && 'role.default_role_name') ||
            (loading && <Skeleton variant="text" width="12rem"/>) ||
            title || 'role.default_role_name'
          }
          disabled={failed || loading}
          subheader={!failed && !loading && isEmployee ? (
            <TargetIcon
                // button
                active={isTarget}
                tooltip={isTarget && !targetPending ? 'common.target_role' : undefined}
                tooltipPlacement="right"
                // onClick={makeTargetRole}
                // disabled={pending}
                pending={targetPending}
            />
          ) : undefined}
          action={!failed && !loading && isEmployee ? (
            <TargetSwitch
                variant="role"
                value={isTarget}
                onChange={switchTargetRole}
                disabled={targetPending}
                pendingValue={targetPending ? !isTarget : undefined}
            />
          ) : undefined}
      />
      {(failed && <FetchFailedAlert/>) ||
      (loading && <LoadingPlaceholder/>) || (
        <>
          <JobDetailsBand role={role as Job}/>
          <CardSection>
            <Grid container spacing={6}>
              <Grid item xs={12} md={7} lg={8}>
                <Box pb={2.5} className={subheader}>
                  <FormattedMessage id="role.description"/>
                </Box>
                <RoleDescription description={description}/>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                {HAS_COMPENSATION ? (
                  <Box mb={3}>
                    <Card className={infoCard}>
                      {FIND_YOUR_GEO_ZONE_URL || (isIntl && INTERNATIONAL_SALARY_URL) ? (
                        <>
                          <Box pb={1} className={subheader}>
                            <FormattedMessage id="role_details.compensation"/>
                          </Box>
                          <StandardLink
                              bold
                              href={isIntl ? INTERNATIONAL_SALARY_URL : FIND_YOUR_GEO_ZONE_URL}
                              text={isIntl ? 'role_details.intl_salary_ranges' : 'role_details.find_your_geo_zone'}
                          />
                        </>
                      ) : undefined}
                      {!isIntl && size(regions) >= 1 ? (
                        <Box pt={2}>
                          <GeoZonePayrange ranges={isFeatureReleased('salary_ranges') ? regions : undefined}/>
                        </Box>
                      ) : undefined}
                      <Box pt={2} pb={1} className={bonusLine}>
                        <FormattedMessage id="role_details.bonus" values={{ bonus: bonusValue, bold }}/>
                      </Box>
                    </Card>
                  </Box>
                ) : undefined}
                {roleNonReducedUI ? (
                  <Card className={infoCard}>
                    <Box>
                      {talentDataPending ? <Skeleton variant="text" width="12rem" height="1.4rem"/> : (
                        <StandardLink
                            bold
                            variant="body1"
                            onClick={!count || count < 1 ? undefined : handleConnectToOpen}
                        >
                          <FormattedMessage id="role_details.connect" values={{ count: toSafeInteger(count) }}/>
                        </StandardLink>
                      )}
                    </Box>
                    {isNil(hires) ? undefined : (
                      <BoxTypography pt={1} variant="body1">
                        <FormattedMessage id="role_details.hires" values={{ hires, bold }}/>
                      </BoxTypography>
                    )}
                    {isNil(closeMatch) ? undefined : (
                      <Box pt={1} display="flex" alignItems="center">
                        <Typography variant="body1">
                          <FormattedMessage id="role_details.close_match_employees" values={{ closeMatch, bold }}/>
                        </Typography>
                        <InfoButton
                            small
                            help="role_details.close_match_employees.info"
                            placement="bottom-end"
                        />
                      </Box>
                    )}
                    {HAS_CANDIDATES && canViewCadidates ? (
                      <Box pt={1}>
                        {pendingCounts ? <Skeleton variant="text" width="12rem" height="1.4rem"/> : (
                          <StandardLink
                              bold
                              variant="body1"
                              onClick={failedCounts || toSafeInteger(matchedCount) < 1
                                ? undefined
                                : handleMatchingPopupOpen}
                          >
                            <FormattedMessage
                                id="role_details.matching_reqs_count"
                                values={{ count: toSafeInteger(matchedCount), bold }}
                            />
                          </StandardLink>
                        )}
                      </Box>
                    ) : undefined}
                  </Card>
                ) : undefined}
              </Grid>
            </Grid>
          </CardSection>
          {isEmployee && targetMessageMounted ? (
            <ConfirmDialog
                maxWidth="md"
                open={targetMessageOpen}
                text={(
                  <Box pr={2} pt={2.5} pb={2} component="span" display="block" className={targetLimitMsg}>
                    <FormattedMessage
                        id="role_details.target_limit"
                        values={targetMessageValues}
                    />
                  </Box>
                )}
                onCancel={targetMessageCancel}
                onExited={targetMessageExited}
            />
          ) : undefined}
          {isEmployee && !targetParams?.limitReached ? (
            <ActionFailedAlert
                message="role_details.target_update_error"
                open={targetFailed}
            />
          ) : undefined}
          <EmployeesPopup
              hrbp={hrbp || isAdm}
              role={role}
              supervisor={supervisor}
              isInternational={isIntl}
              reducedUI={reducedUI}
              isOpen={connectToOpen}
              onClose={handleConnectToClose}
          />
          {HAS_CANDIDATES && canViewCadidates && role && matchingPopupMounted ? (
            <EmployeesMatchingJob
                role={role}
                isInternational={hrbp || isAdm || isIntl}
                isOpen={matchingPopupOpen}
                onClose={handleMatchingPopupClose}
                onExited={handleMatchingPopupExited}
            />
          ) : undefined}
        </>
      )}
    </ContentCard>
  );
};

RoleDetails.propTypes = RoleDetailsPropTypes;

export default memo(RoleDetails);
