import {
  memo, useCallback, useEffect, useLayoutEffect, useState, useMemo,
  type FunctionComponent, type ChangeEvent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import transform from 'lodash/transform';
import toString from 'lodash/toString';
import { useLazyQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import TextareaAutosize from '@mui/material/TextareaAutosize';
// Material Icon imports
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
// Skillmore UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
import SkillLevelGauge from '@empathco/ui-components/src/elements/SkillLevelGauge';
import CopyToClipboardButton from '@empathco/ui-components/src/elements/CopyToClipboardButton';
// local imports
import { MY_OPPORTUNITY_QUERY } from '../graphql/MyOpportunity';
import {
  BookingStatus, MyOpportunityDocument, MyOpportunityStatus, OpportunitySkillStatus, OpportunityStatus
} from '../graphql/types';
import { MyOpportunity } from '../graphql/customTypes';
import { SKILL_LEVEL_FIRST, SKILL_LEVEL_MIN, Skill, SkillLevel } from '../models/skill';
import { PATH_MY_OPPORTUNITY } from '../config/paths';
import { getSkillCurrentLevel, getSkillCurrentLevelOnly } from '../helpers/models';
import { SkillLevelData } from '../context/dataContext';
import SkillLevelDialog from './SkillLevelDialog';
import OpportunityDetailsContent from './OpportunityDetailsContent';
// SCSS imports
import { topPanel, textArea, saveBtn, shareBtn, applyBtn } from './MyOpportunityDialog.module.scss';

type MyOpportunityDialogProps = {
  isOpen: boolean;
  opportunityId?: number | null;
  opportunity?: MyOpportunity;
  pending?: boolean | null;
  disabled?: boolean | null;
  savePending?: boolean;
  applyPending?: boolean;
  onSave: (opp?: MyOpportunity) => void;
  onApply: (opp?: MyOpportunity) => void;
  onEnd: (opp?: MyOpportunity, feeedback?: string, skills?: SkillLevelData[]) => void;
  onClose: () => void;
}

const MyOpportunityDialogPropTypes = {
  // attributes
  isOpen: PropTypes.bool.isRequired,
  opportunityId: PropTypes.number,
  opportunity: PropTypes.object as Validator<MyOpportunity>,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  savePending: PropTypes.bool,
  applyPending: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const MyOpportunityDialog: FunctionComponent<MyOpportunityDialogProps> = ({
  isOpen,
  opportunityId,
  opportunity: myOpp,
  pending: parentPending,
  disabled: parentDisabled,
  savePending,
  applyPending,
  onSave,
  onApply,
  onEnd,
  onClose
}) => {
  const { formatMessage } = useIntl();

  // lazy load my opportunity
  const { query: getMyOpportunity, pending, failed, results } = useQueryObject({
    data: undefined as unknown as MyOpportunity,
    key: 'myOpportunity',
    flatResults: true,
    lazyQuery: useLazyQuery(MY_OPPORTUNITY_QUERY as typeof MyOpportunityDocument)
  });

  const needsLazyLoad = isOpen && Boolean(opportunityId) && !parentPending && !myOpp;
  const loaded = needsLazyLoad ? !pending && Boolean(results) : !parentPending && Boolean(myOpp);
  const myOpportunity = needsLazyLoad ? results : myOpp;
  const { employee_status, booking, opportunity, is_new, match_rate, growth_rate, employee_skills } = myOpportunity || {};
  const { status } = booking || {};
  const { status: oppStatus, skills } = opportunity || {};

  const isSaved = employee_status === MyOpportunityStatus.shortlist;
  const isBooked = status === BookingStatus.confirmed;
  const isStarted = isBooked && oppStatus === OpportunityStatus.started;

  const canApply = loaded && (!status || status === BookingStatus.employee_rejected);
  const canCancel = loaded && (status === BookingStatus.employee_requested);
  const canSaveUnsave = !isStarted;
  const canEnd = loaded && isStarted;

  const isApplied = !canEnd && status === BookingStatus.employee_requested;

  const allGrowthSkills = useMemo(() => {
    if (!isOpen || !canEnd) return undefined;
    const allSkills = transform(skills || [], (result, skill) => {
      const existingSkill = find(employee_skills, ['id', skill.id]) as Skill;
      const emplSkill: Skill = {
        id: skill.id,
        abbr: skill.abbr,
        title: skill.title,
        current_level: isNil(existingSkill?.current_level) ? null : existingSkill?.current_level || SKILL_LEVEL_MIN,
        inferred_level: existingSkill?.inferred_level,
        is_inference_newer: Boolean(existingSkill?.is_inference_newer),
        level: existingSkill ? getSkillCurrentLevel(existingSkill) : SKILL_LEVEL_MIN, // original proficiency level
        skill_proficiency_level: existingSkill ? getSkillCurrentLevelOnly(existingSkill) : null, // original current-only level
        target_level: skill.skill_proficiency_level as SkillLevel, // planned level: const
        expected_level: skill.skill_proficiency_level as SkillLevel // planned level: cleared on user confirmation
      };

      const skl = find(result, ['id', skill.id]);
      if (skl && (skl.target_level || SKILL_LEVEL_MIN) < (emplSkill.target_level || SKILL_LEVEL_MIN)) {
        skl.target_level = emplSkill.target_level;
        skl.expected_level = emplSkill.target_level;
      } else if (
        skill.status === OpportunitySkillStatus.growth ||
        (emplSkill.level || SKILL_LEVEL_MIN) < (emplSkill.target_level || SKILL_LEVEL_MIN)
      ) {
        result.push(emplSkill);
      }
    }, [] as Skill[]);

    return sortBy(allSkills, ({ target_level, level, title }) => `${9 - (target_level || 0)}:${9 - (level || 0)}:${title}`);
  }, [skills, employee_skills, isOpen, canEnd]);

  const [growthSkills, setGrowthSkills] = useState(allGrowthSkills);
  const [feedback, setFeedback] = useState('');

  const disabled = parentDisabled || !loaded || savePending || applyPending;

  const [mounted, setMounted] = useState(isOpen);
  useLayoutEffect(() => {
    if (isOpen) {
      setMounted(true);
      setFeedback('');
    }
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  useLayoutEffect(() => {
    setGrowthSkills(allGrowthSkills);
  }, [allGrowthSkills]);

  const handleFeedbackChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setFeedback(toString(event.target.value));
  }, []);

  const [openSkill, setOpenSkill] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

  const handleSkillClick = useCallback((skl: Skill) => {
    setOpenSkill(true);
    setSelectedSkill(skl);
  }, []);
  const handleSkillClose = useCallback(() => {
    setOpenSkill(false);
  }, []);
  const handleSkillExited = useCallback(() => {
    setSelectedSkill(null);
  }, []);
  const handleLevelChange = useCallback((level: SkillLevel) => {
    if (selectedSkill) {
      const { id } = selectedSkill;
      setGrowthSkills((prevGrowthSkills) => map(prevGrowthSkills, (skill) => skill.id === id ? {
        ...skill,
        current_level: level,
        is_inference_newer: false,
        expected_level: null
      } : skill));
    }
    setOpenSkill(false);
  }, [selectedSkill]);

  const titles = useMemo(() => [
    /* eslint-disable react/jsx-key */
    <FormattedMessage id="opportunities.booking.growth.name"/>,
    <FormattedMessage id="opportunities.booking.growth.level"/>,
    <FormattedMessage id="opportunities.booking.growth.growth"/>
    /* eslint-enable react/jsx-key */
  ], []);

  const rows = useMemo(() => map(growthSkills, (skill) => {
    const plannedLevel = skill.target_level || SKILL_LEVEL_MIN; // `target_level` is planned level (const)
    const selected = plannedLevel >= SKILL_LEVEL_FIRST &&
      // original proficiency level is less than planned level
      (skill.level || SKILL_LEVEL_MIN) < plannedLevel;
    return {
      selected,
      values: [
        /* eslint-disable react/jsx-key */
        <SkillName
            maxLines={1}
            skill={skill}
            onClick={handleSkillClick}
            disabled={disabled}
        />,
        <SkillName
            skill={skill}
            onClick={handleSkillClick}
            disabled={disabled}
        >
          <SkillLevelGauge
              selected={selected}
              level={getSkillCurrentLevelOnly(skill as Skill)}
              inferredLevel={skill.inferred_level}
              isInferenceNewer={skill.is_inference_newer}
              withoutText
          />
        </SkillName>,
        <SkillLevelGauge
            selected={selected}
            level={plannedLevel}
            withoutText
        />
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [growthSkills, handleSkillClick, disabled]);

  useEffect(() => {
    if (needsLazyLoad && opportunityId) getMyOpportunity?.({ variables: { opportunity_id: opportunityId } });
  }, [needsLazyLoad, opportunityId, getMyOpportunity]);

  const url = useMemo(() => {
    if (!opportunityId || canEnd) return undefined;
    try {
      const urlObj = new URL(injectParams(PATH_MY_OPPORTUNITY, { opp_id: opportunityId }), window.location.href);
      return urlObj.href;
    } catch (_err) {
      return undefined;
    }
  }, [opportunityId, canEnd]);

  const handleSave = useCallback(() => onSave?.(myOpportunity), [myOpportunity, onSave]);
  const handleApply = useCallback(() => onApply?.(myOpportunity), [myOpportunity, onApply]);
  const handleEnd = useCallback(() => {
    const newSkills = transform(growthSkills || [], (result, skl) => {
      const { id: skill_id, skill_proficiency_level } = skl;
      const level = getSkillCurrentLevel(skl);
      // original current-only level is `null` or not equal to currently selected 'current proficiency level'
      if (isNil(skill_proficiency_level) || skill_proficiency_level !== level) {
        result.push({ skill_id, level });
      }
    }, [] as SkillLevelData[]);
    onEnd?.(
      myOpportunity,
      isEmptyString(feedback) ? undefined : feedback,
      size(newSkills) >= 1 ? newSkills : undefined
    );
  }, [myOpportunity, growthSkills, feedback, onEnd]);

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={disabled ? undefined : onClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose} disabled={disabled}/>
        <CardSection top className={topPanel}>
          <TargetIcon
              Icon={BookmarkBorderIcon}
              ActiveIcon={BookmarkIcon}
              label="opportunities.status.not_saved"
              activeLabel="opportunities.status.saved"
              active={isSaved}
              onClick={canSaveUnsave ? handleSave : undefined}
              disabled={disabled}
              pending={savePending}
              className={saveBtn}
          />
          {url ? (
            <CopyToClipboardButton
                text={url}
                message="opportunities.copied_to_clipboard"
                srLabel="opportunities.button.copy_to_clipboard"
                disabled={!loaded || (needsLazyLoad && failed)}
                className={shareBtn}
            />
          ) : undefined}
        </CardSection>
        {(needsLazyLoad && failed && <FetchFailedAlert flat/>) ||
        (!loaded && <LoadingPlaceholder flat/>) || (
          <OpportunityDetailsContent
              opportunity={opportunity}
              withoutSkills={canEnd}
              isNew={is_new}
              matchRate={match_rate}
              growthRate={growth_rate}
              disabled={disabled}
          />
        )}
        {canEnd ? (
          <CardSection top>
            {size(growthSkills) >= 1 ? (
              <Box pb={3}>
                <BoxTypography variant="h4" pb={2.25}>
                  <FormattedMessage id="opportunities.booking.growth.title"/>
                </BoxTypography>
                <DataTable
                    titles={titles}
                    empty="skills.no_skills_found"
                    data={rows}
                    lastLeftAlignedTitle={2}
                />
              </Box>
            ) : undefined}
            <TextareaAutosize
                minRows={4}
                placeholder={formatMessage({ id: 'opportunities.booking.feedback.placeholder' })}
                value={feedback}
                onChange={handleFeedbackChange}
                disabled={disabled}
                className={textArea}
            />
          </CardSection>
        ) : undefined}
        {canApply || canCancel || canEnd ? (
          <CardFooter>
            <Button
                color="primary"
                variant={isApplied ? 'outlined' : 'contained'}
                disableElevation
                disabled={disabled || (!canEnd && (!canApply || isApplied)) ? true : undefined}
                startIcon={applyPending && !isApplied ? <CircularProgress size={18} color="inherit"/> : undefined}
                onClick={canEnd ? handleEnd : handleApply}
                className={isApplied ? undefined : applyBtn}
            >
              <FormattedMessage
                  id={(canEnd && 'opportunities.booking.button.end') ||
                    (isApplied && 'opportunities.button.applied') ||
                    'opportunities.button.apply'}
              />
            </Button>
            {isApplied && canCancel ? (
              <Button
                  color="primary"
                  variant="text"
                  disabled={disabled}
                  startIcon={applyPending && isApplied ? <CircularProgress size={18} color="inherit"/> : undefined}
                  onClick={handleApply}
              >
                <FormattedMessage id="opportunities.button.remove"/>
              </Button>
            ) : undefined}
          </CardFooter>
        ) : loaded && (!needsLazyLoad || !failed) && <Box py={2}/>}
      </Dialog>
      {selectedSkill ? (
        <SkillLevelDialog
            isOpen={openSkill}
            skill={selectedSkill}
            defaultLevel={selectedSkill.expected_level || undefined} // `expected_level` is planned level (until confirmed)
            manualOnly
            onUpdate={handleLevelChange}
            onCancel={handleSkillClose}
            onExited={handleSkillExited}
            disabled={disabled}
        />
      ) : undefined}
    </>
  ) : null;
};

MyOpportunityDialog.propTypes = MyOpportunityDialogPropTypes;

export default memo(MyOpportunityDialog);
