/* eslint-disable max-lines */
import { useCallback, useMemo } from 'react';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
// import omit from 'lodash/omit';
import size from 'lodash/size';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import forEach from 'lodash/forEach';
import transform from 'lodash/transform';
import findIndex from 'lodash/findIndex';
import indexOf from 'lodash/indexOf';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import isUndefined from 'lodash/isUndefined';
import isPlainObject from 'lodash/isPlainObject';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';
import { useIntl } from 'react-intl';
// Skillmore UI Components
import { CHART_VIEW, TILES_VIEW, TABLE_VIEW, type ViewType } from '@empathco/ui-components/src/elements/ViewSwitch';
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { SKILL_LEVEL_MIN, SKILL_LEVEL_MAX, SKILL_LEVEL_FIRST, SKILL_LEVEL_TO_MENTOR, SkillLevel, Skill } from '../models/skill';
import { Job } from '../models/job';
import { Course } from '../models/course';
import { DevPlan } from '../models/devPlan';
import { LookupItem } from '../models/lookupItem';
import { Preferences } from '../models/preferences';
import { Settings } from '../models/settings';
import { User } from '../models/user';
import { EmployeeManagementLevel } from '../constants/managementLevel';
import { ILookupStringItem, Location, Manager, SkillWithLevel } from '../graphql/types';
import useCustomerSettings from '../config/customer';
import { MIN_MATCH_RATE, MAX_MATCH_RATE } from '../config/params';
import {
  AddSkillParams, UpdatePreferencesParams, UpdateTargetSkillParams, UpdateTargetRoleParams, UpdateCourseParams
} from '../context/dataContext';

export const isInferred = (skill?: Skill | SkillWithLevel | null) => {
  const { actual_level } = skill as Skill || {};
  const { current_level, inferred_level, is_inference_newer } = skill || {};
  return (is_inference_newer || (isNil(actual_level) && isNil(current_level))) &&
    !isNil(inferred_level) && inferred_level >= SKILL_LEVEL_FIRST;
};

export const getSkillCurrentLevelOnly = (skill?: Skill | SkillWithLevel | null): SkillLevel | undefined => {
  const { actual_level } = skill as Skill || {};
  const { current_level, inferred_level, is_inference_newer } = skill || {};
  if (is_inference_newer && !isNil(inferred_level) && inferred_level >= SKILL_LEVEL_FIRST) return undefined;
  if (!isNil(current_level)) return toSafeInteger(current_level) as SkillLevel;
  if (!isNil(actual_level)) return toSafeInteger(actual_level) as SkillLevel;
  return undefined;
};

export const getSkillCurrentLevel = (skill?: Skill | SkillWithLevel | null): SkillLevel => {
  const { inferred_level } = skill || {};
  if (isInferred(skill)) return toSafeInteger(inferred_level) as SkillLevel;
  const level = getSkillCurrentLevelOnly(skill);
  if (!isUndefined(level)) return level;
  return isSafeInteger(inferred_level) ? inferred_level as SkillLevel : SKILL_LEVEL_MIN;
};

export const hasCurrentLevel = (skill?: Skill | SkillWithLevel | null) => {
  const { actual_level } = skill as Skill || {};
  const { current_level, inferred_level, is_inference_newer } = skill || {};
  return (!isNil(current_level) || !isNil(actual_level)) && (
    !is_inference_newer || isNil(inferred_level) || inferred_level < SKILL_LEVEL_FIRST
  );
};

const ACTUAL_LEVEL_KEYS = ['current_level', 'actual_level'] as const;
const INFERRED_LEVEL_KEYS = ['inferred_level'] as const;
const TARGET_LEVEL_KEYS = ['expected_level'] as const;
const SKILL_LEVEL_KEYS = [...ACTUAL_LEVEL_KEYS, ...TARGET_LEVEL_KEYS, ...INFERRED_LEVEL_KEYS] as const;
const JOB_LEVEL_KEYS = ['management_level'] as const;
type SkillLevelKey = typeof SKILL_LEVEL_KEYS[number];
type JobLevelKey = typeof JOB_LEVEL_KEYS[number];
type TargetKey = 'is_target';

// Unused as of now:
// const USER_SKILL_LEVEL_KEYS = [...ACTUAL_LEVEL_KEYS, ...INFERRED_LEVEL_KEYS, 'is_inference_newer'] as const;
// export const depersonaliseSkill = (skill: Skill): Skill => omit(skill, USER_SKILL_LEVEL_KEYS);

const getIsMyAddon = (level?: number | null) => ({
  is_my: toSafeInteger(level) >= SKILL_LEVEL_FIRST
});

type EntityTargeted = Partial<Record<TargetKey, boolean | null | undefined>>;

const getIsTargetAddon = (is_target?: boolean | null): EntityTargeted => isNil(is_target) ? {} : {
  is_target: Boolean(is_target)
};

export const PREF_SKILL_FIELDS = ['id', 'title', 'abbr'] as const;

export const TARGET_SKILL_FIELDS = [
  'id', 'abbr', 'title', 'type', 'abbr', 'group', // 'ple_link',
  'current_level', 'actual_level', 'inferred_level',
  'is_in_demand', 'is_inference', 'is_mentoring', 'is_my', 'is_new', 'is_target'
] as const;

// Levels sanitization

export const sanitizeLevel = (level?: number | null): SkillLevel => {
  const lvl = toSafeInteger(level);
  if (lvl < SKILL_LEVEL_MIN) return SKILL_LEVEL_MIN;
  if (lvl > SKILL_LEVEL_MAX) return SKILL_LEVEL_MAX;
  return lvl as SkillLevel;
};

export const sanitizeJobLevel = (level?: number | null, minLevel = 0, maxLevel = 0): EmployeeManagementLevel | undefined => {
  if (isNil(level)) return undefined;
  const lvl = toSafeInteger(level);
  if (lvl < minLevel) return minLevel as EmployeeManagementLevel;
  if (maxLevel && lvl > maxLevel) return maxLevel as EmployeeManagementLevel;
  return lvl as EmployeeManagementLevel;
};

type EntityWithLevel = Partial<Record<SkillLevelKey | JobLevelKey, number | null | undefined>>;

type UpdatedStatus = {
  updated: boolean;
}

const sanitizeLevelValue = (
  key: SkillLevelKey | JobLevelKey,
  entity: EntityWithLevel,
  updated: UpdatedStatus,
  isJob: boolean
) => {
  let level = entity[key];
  if (isNil(level)) return;
  level = isJob ? sanitizeJobLevel(level) : sanitizeLevel(level);
  if (entity[key] !== level) {
    updated.updated = true;
    entity[key] = level;
  }
};

const sanitizeLevelValues = <T>(
  entity: T | null,
  actualKeys: readonly (SkillLevelKey | JobLevelKey)[],
  isJob: boolean
) => {
  if (!entity) return entity;
  const newEntity = { ...entity } as T;
  const updated: UpdatedStatus = { updated: false };
  forEach(actualKeys, (key) => sanitizeLevelValue(key, newEntity as EntityWithLevel, updated, isJob));
  return updated.updated ? newEntity : entity;
};

export const sanitizeSkillLevel = (entity: Skill | null = null) => sanitizeLevelValues(entity, SKILL_LEVEL_KEYS, false);

export const sanitizeRoleLevel = (entity: Job | null = null) => sanitizeLevelValues(entity, JOB_LEVEL_KEYS, true);

// MENTORING UPDATES

export const updateCachedSkillIsMentoring = (skill?: Skill | null, params?: UpdatePreferencesParams | null) => {
  if (
    !skill || !params || !isPlainObject(skill) || !isPlainObject(params) ||
    isNil(skill.is_mentoring) || !isArray(params.skills_i_mentor)
  ) {
    return skill;
  }
  const newIsMentoring = indexOf(params.skills_i_mentor, skill.id) >= 0;
  return newIsMentoring === Boolean(skill.is_mentoring) ? skill : {
    ...skill,
    is_mentoring: newIsMentoring
  };
};

// Unused for now, may be needed in the future:
// export const updateSkillsIsMentoring = (skills, params) => {
//   if (!isArray(skills) || !isPlainObject(params) || !isArray(params.skills_i_mentor)) return skills;
//   const { skills_i_mentor } = params;
//   const { skills: newSkills } = transform(skills, (result, skill, index) => {
//     if (!isPlainObject(skill) || isNil(skill.is_mentoring)) return;
//     const newIsMentoring = indexOf(skills_i_mentor, skill.id) >= 0;
//     if (newIsMentoring === Boolean(skill.is_mentoring)) return;
//     if (result.skills === skills) result.skills = [...skills];
//     result.skills[index] = {
//       ...skill,
//       is_mentoring: newIsMentoring
//     };
//   }, { skills });
//   return newSkills;
// };

interface SkillLevelUpdateParams extends Partial<AddSkillParams> {
  id?: number;
  skill_id?: number;
  level: SkillLevel;
  is_opt_in_mentor?: boolean;
  is_target?: boolean;
}

// TARGET UPDATES

export const updateCachedIsTarget = <T extends (Skill | Job)>(entity: T, is_target?: boolean | null): T => {
  const addon = getIsTargetAddon(Boolean(is_target));
  // eslint-disable-next-line consistent-return
  const { entity: newEntity } = transform(addon, (result, value, key) => {
    const prevIsTarget = result.entity[key as TargetKey];
    if ((isNull(prevIsTarget) || isBoolean(prevIsTarget)) && Boolean(prevIsTarget) !== Boolean(value)) {
      if (result.entity === entity) {
        result.entity = { ...result.entity, [key]: value };
      } else {
        result.entity[key as TargetKey] = value;
      }
    }
  }, { entity });
  return newEntity;
};

type ITargetUpdateParams = Omit<Partial<UpdateTargetSkillParams> & Partial<UpdateTargetRoleParams>, 'skill_id'>;
interface TargetUpdateParams extends ITargetUpdateParams {
  id?: number | string;
  job_id?: number;
  role_id?: string;
  skill_id?: number | string;
  is_target?: boolean;
}

const updateCachedEntityIsTarget = <T extends (Skill | Job)>(
  entity?: T | null,
  payload?: TargetUpdateParams | null
) => {
  if (!entity || !payload) return entity;
  const { id, job_id, role_id, skill_id, is_target } = payload;
  const entityId = id || job_id || role_id || skill_id;
  if (!entityId || (
    entityId !== entity.id &&
    entityId !== (entity as Job).code &&
    entityId !== (entity as Skill).abbr
  )) return entity;
  return updateCachedIsTarget(entity, is_target);
};

const updateCachedEntitiesIsTarget = <T extends (Skill | Job)>(
  entities?: T[] | null,
  payload?: TargetUpdateParams | null,
  thereCanBeOnlyOne = false
) => {
  if (!entities || !payload) return entities;
  const { id, job_id, role_id, skill_id, is_target } = payload;
  const entityId = id || job_id || role_id || skill_id;
  if (!entityId) return entities;
  if (thereCanBeOnlyOne && is_target) {
    return transform(entities, (result, entity, index) => {
      result[index] = updateCachedIsTarget(entity,
        entity.id === entityId ||
        (entity as Job).code === entityId ||
        (entity as Skill).abbr === entityId
      );
    }, [] as T[]);
  }
  const idx = findIndex(entities, ['id', entityId]);
  if (idx < 0) return entities;
  const newEntities = [...entities];
  newEntities[idx] = updateCachedIsTarget(newEntities[idx], is_target);
  return newEntities;
};

// Skill/Job
export const updateCachedSkillIsTarget = (
  skill?: Skill | null, payload?: TargetUpdateParams | null
) => updateCachedEntityIsTarget(skill, payload);
export const updateCachedRoleIsTarget = (
  job?: Job | null, payload?: TargetUpdateParams | null
) => updateCachedEntityIsTarget(job, payload);

// Skills/Jobs collection
export const updateCachedSkillsIsTarget = (
  skills?: Skill[] | null, payload?: TargetUpdateParams | null
) => updateCachedEntitiesIsTarget(skills, payload, false);
export const updateCachedRolesIsTarget = (
  jobs?: Job[] | null, payload?: TargetUpdateParams | null
) => updateCachedEntitiesIsTarget(jobs, payload, false);

export const updateCachedTargetSkills = (
  targetSkills?: Skill[] | null,
  params?: TargetUpdateParams | null,
  payload?: Skill | null
) => {
  if (!targetSkills) return targetSkills;
  const { skill_id, is_target } = params || {};
  if (!skill_id) return targetSkills;
  const isTarget = findIndex(targetSkills, ['id', skill_id]) >= 0;
  if (is_target) return isTarget || !isPlainObject(payload) ? targetSkills : [...targetSkills, payload];
  return isTarget ? reject(targetSkills, ['id', skill_id]) : targetSkills;
};

export const updateCachedSettings = (settings?: Settings | null, params?: Settings | null) => {
  if (!isPlainObject(settings) || !isPlainObject(params)) return settings;
  return { ...settings, ...params };
};

// COURSES & DEV PLAN

export const getCourseMaxLevel = (
  currentLevel?: SkillLevel | null,
  growth?: (number | null)[] | null,
  courses?: Course[] | null,
  selected?: Record<number, boolean> | null
): SkillLevel => {
  if (!courses) return 0;
  const curLevel = toSafeInteger(currentLevel);
  const allowedLevels = transform(courses, (result, { id, level }) => {
    if (!selected || selected[id]) result[level] = true; // allow next level courses
  }, transform(growth || [], (result, value, index) => {
    if (value && value >= 1) result[index + 1] = true; // allow next level courses
  }, [
    curLevel === 0, // allow level=1 courses
    curLevel === 1, // allow level=2 courses
    curLevel === 2, // allow level=3 courses
    curLevel === 3 //  allow level=4 courses
  ]));
  const idx = indexOf(allowedLevels, false, curLevel >= 1 ? curLevel : 0);
  return idx < 0 ? SKILL_LEVEL_MAX : idx as SkillLevel;
};

export const updateSkillCourses = (devPlan?: DevPlan | null, params?: UpdateCourseParams | null) => devPlan && params &&
  isArray(params.course_ids) && isArray(devPlan.suggested_courses)
  ? {
    ...devPlan,
    suggested_courses: map(devPlan.suggested_courses, (course) => {
      const is_selected = indexOf(params.course_ids, course.id) >= 0;
      return course.is_selected === is_selected ? course : { ...course, is_selected };
    })
  }
  : devPlan;

// Misc.

export const sanitizeMatchRate = (matchRate?: number | null) => {
  if (isNil(matchRate)) return undefined;
  const rate = toSafeInteger(matchRate);
  if (rate < MIN_MATCH_RATE) return MIN_MATCH_RATE;
  if (rate > MAX_MATCH_RATE) return MAX_MATCH_RATE;
  return rate;
};

export const sanitizeLookup = (id?: number | null, items?: LookupItem[] | null) => {
  if (
    isNil(id) ||
    !isSafeInteger(id) || id < 1 ||
    (isArray(items) && findIndex(items, ['id', id]) < 0)
  ) return 0;
  return id;
};

export const sanitizeLookupString = <T = string>(
  id?: string | null,
  items?: ILookupStringItem[] | null,
  defaultValue?: T
): string | T => {
  if (
    isNil(id) ||
    isEmptyString(id) ||
    (isArray(items) && findIndex(items, ['id', id]) < 0)
  ) return isUndefined(defaultValue) ? '0' : defaultValue;
  return id;
};

export const sanitizeSkillsView = (
  view?: string | null
): ViewType => view === TILES_VIEW || view === TABLE_VIEW ? view : TILES_VIEW;

export const generateGetLocationStr = (
  getCounty: (location?: Partial<Location> | null) => string | null | undefined
) => (location?: Partial<Location> | null): string | null | undefined => {
  const county = getCounty(location);
  const { city, country } = location || {};
  const cityStr = city ? startCase(toLower(city)) : undefined;
  return county && cityStr ? `${cityStr}, ${county}` : cityStr || county || country;
}

const NO_MANAGER: Manager = { id: '0', title: '' };
const EMPTY_MANAGER: Manager = { id: '', title: '' };

const getValidManager = (managers: Manager[], uid: string, allowEmpty?: boolean | null): Manager | undefined => {
  if (isNil(uid) || isEmptyString(uid) || !isArray(managers)) return undefined;
  if (uid === '0' || (allowEmpty && managers[0]?.topId === uid)) return {
    id: uid,
    title: '',
    org_id: managers[0].topOrgId
  };
  return find(managers, ['id', uid]) || NO_MANAGER;
};

export const getDefaultManager = (
  uid?: string | null,
  managers?: Manager[] | null,
  allowEmpty?: boolean | null,
  settingsUid?: string | null
): Manager | undefined => {
  if (managers && size(managers) >= 1) return (settingsUid && getValidManager(managers, settingsUid, allowEmpty)) ||
    (uid && getValidManager(managers, uid, allowEmpty)) ||
    NO_MANAGER;
  return managers ? EMPTY_MANAGER : undefined;
};

function useModels() {
  const { formatMessage } = useIntl();
  const { DOMESTIC_COUNTRIES, USER_TAGS, HAS_JOBS_CHART, HAS_MENTORING } = useCustomerSettings();

  // local
  const getIsMentoringAddon = useCallback((is_opt_in_mentor?: boolean | null) =>
    !HAS_MENTORING || isNil(is_opt_in_mentor) ? {} : { is_mentoring: Boolean(is_opt_in_mentor) },
    [HAS_MENTORING]);

  // LEVEL UPDATES

  // local
  const updateLevel = useCallback((
    entity: Skill,
    newLevel: SkillLevel,
    keys: readonly SkillLevelKey[],
    addons: Record<string, unknown> = {}
  ) => {
    if (!entity) return entity;
    let newEntity: Skill | null = null;
    // if mentoring or target has changed, update skill regardless of the level change
    const { is_mentoring, is_target } = addons || {};
    if (
      entity.is_inference_newer ||
      (HAS_MENTORING && !isNil(is_mentoring) && (isNil(entity.is_mentoring) || entity.is_mentoring !== is_mentoring)) ||
      (!isNil(is_target) && (isNil(entity.is_target) || entity.is_target !== is_target))
    ) {
      newEntity = {
        ...entity,
        ...addons || {},
        ...entity.is_inference_newer ? { is_inference_newer: false } : {}
      } as Skill;
    }
    // otherwise update only if level has changed
    forEach(keys, (key) => {
      const prevLevel = entity[key];
      if (isNull(prevLevel) || isSafeInteger(prevLevel)) {
        if (newEntity) {
          newEntity[key] = newLevel;
        } else {
          newEntity = { ...entity, ...addons || {}, [key]: newLevel };
        }
      }
    });
    return newEntity || entity;
  }, [HAS_MENTORING]);

  // local
  const updateSkillLevels = useCallback((
    keys: readonly SkillLevelKey[],
    skill?: Skill | null,
    payload?: SkillLevelUpdateParams | null,
    setIsMy = false
  ) => {
    if (!skill || !payload) return skill;
    const { id, skill_id, level, is_opt_in_mentor, is_target } = payload;
    if ((id || skill_id) !== skill.id) return skill;
    return updateLevel(skill, level, keys, {
      ...setIsMy ? getIsMyAddon(level) : {},
      ...getIsTargetAddon(is_target),
      ...getIsMentoringAddon(is_opt_in_mentor)
    });
  }, [getIsMentoringAddon, updateLevel]);

  // local
  const updateSkillsLevels = useCallback((
    keys: readonly SkillLevelKey[],
    skills?: Skill[] | null,
    payload?: SkillLevelUpdateParams | null,
    setIsMy = false
  ) => {
    if (!skills || !payload) return skills;
    const { id, skill_id, level, is_opt_in_mentor, is_target } = payload;
    const idx = findIndex(skills, ['id', id || skill_id]);
    if (idx < 0) return skills;
    const newSkills = [...skills];
    newSkills[idx] = updateLevel(newSkills[idx], level, keys, {
      ...setIsMy ? getIsMyAddon(level) : {},
      ...getIsTargetAddon(is_target),
      ...getIsMentoringAddon(is_opt_in_mentor)
    });
    return newSkills;
  }, [getIsMentoringAddon, updateLevel]);

  // Skill
  const updateCachedSkillLevels = useCallback((skill?: Skill | null, payload?: SkillLevelUpdateParams | null) =>
    updateSkillLevels(ACTUAL_LEVEL_KEYS, skill, payload, true), [updateSkillLevels]);
  // const updateSkillTargetLevels = (skill, payload) =>
  //   updateSkillLevels(TARGET_LEVEL_KEYS, skill, payload, false);

  // Skills collection
  const updateCachedSkillsLevels = useCallback((skills?: Skill[] | null, payload?: SkillLevelUpdateParams | null) =>
    updateSkillsLevels(ACTUAL_LEVEL_KEYS, skills, payload, true), [updateSkillsLevels]);
  // const updateSkillsTargetLevels = (skills, payload) =>
  //   updateSkillsLevels(TARGET_LEVEL_KEYS, skills, payload, false);

  const updateCachedRoleSkills = useCallback((role?: Job | null, payload?: SkillLevelUpdateParams | null) => {
    if (!role || (!isArray(role.skills_with_gap) && !isArray(role.skills))) return role;
    const skills_with_gap = updateCachedSkillsLevels(role.skills_with_gap, payload);
    const skills = updateCachedSkillsLevels(role.skills, payload);
    if (skills_with_gap === role.skills_with_gap && skills === role.skills) return role;
    const allSkills = sortBy([...skills_with_gap || [], ...skills || []], (skill) => -getSkillCurrentLevel(skill));
    return {
      ...role,
      skills_with_gap: filter(allSkills, (skill) => getSkillCurrentLevel(skill) < toSafeInteger(skill.expected_level)),
      skills: filter(allSkills, (skill) => getSkillCurrentLevel(skill) >= toSafeInteger(skill.expected_level))
    };
  }, [updateCachedSkillsLevels]);

  const updateCachedSkillGaps = useCallback((skills?: Skill[] | null, payload?: SkillLevelUpdateParams | null) =>
    isArray(skills) ? filter(
      updateCachedSkillsLevels(skills, payload),
      (skill) => getSkillCurrentLevel(skill) < toSafeInteger(skill.expected_level)
    ) : skills, [updateCachedSkillsLevels]);

  const sanitizeJobsView = useCallback((view?: string | null, supervisor: boolean | null = false): ViewType =>
    (HAS_JOBS_CHART && !supervisor) || view !== CHART_VIEW ? (view as ViewType) || TILES_VIEW : TILES_VIEW,
    [HAS_JOBS_CHART]);

  const getDomesticCountryId = useCallback((countries?: LookupItem[] | null) => find(countries,
    ({ title }) => indexOf(DOMESTIC_COUNTRIES, title) >= 0
  )?.id, [DOMESTIC_COUNTRIES]);

  const isDomesticCountryId = useCallback((id?: number | null, countries?: LookupItem[] | null) => indexOf(DOMESTIC_COUNTRIES,
    (find(countries, ['id', id]) || {} as LookupItem).title
  ) >= 0, [DOMESTIC_COUNTRIES]);

  const isInternational = useCallback((country?: string | null) =>
    !country || indexOf(DOMESTIC_COUNTRIES, country) < 0,
    [DOMESTIC_COUNTRIES]);

  const getUserTags = useCallback((user?: User | null) => transform(USER_TAGS, (result, tagDef, tag) => {
    result[tag] = false;
    if (tagDef.states) {
      const userState = user?.location?.state || user?.current_job?.location?.state;
      if (userState) {
        const userStateLC = toLower(userState);
        if (find(tagDef.states, (state) => toLower(state) === userStateLC)) result[tag] = true;
      }
    }
  }, {} as Record<string, boolean>), [USER_TAGS]);

  // local
  const getCounty = useCallback((location?: Partial<Location> | null): string | null | undefined => {
    const { state, country } = location || {};
    return country && indexOf(DOMESTIC_COUNTRIES, country) < 0 ? country : state;
  }, [DOMESTIC_COUNTRIES]);

  const getLocationStr = useMemo(() => generateGetLocationStr(getCounty), [getCounty]);

  const getEmployeeLocationStr = useCallback((
    jobLocation?: Partial<Location> | null,
    residenceLocation?: Partial<Location> | null,
  ): string | null | undefined => {
    const jobLocationStr = getLocationStr(jobLocation);
    const resLocationStr = getLocationStr(residenceLocation);
    return resLocationStr && jobLocationStr !== resLocationStr
      ? formatMessage({ id: 'employee.location_with_residence' }, { job: jobLocationStr || null, residence: resLocationStr })
      : jobLocationStr;
  }, [getLocationStr, formatMessage]);

  // PREFERENCES

  const updateCachedPreferences = useCallback((preferences?: Preferences | null, params?: UpdatePreferencesParams | null) => {
    if (!preferences || !params || !isPlainObject(preferences) || !isPlainObject(params)) return preferences;
    const allSkills = HAS_MENTORING && isArray(params.skills_i_mentor) ? [
      ...preferences.skills_i_mentor || [],
      ...preferences.skills_i_can_mentor || []
    ] : null;
    return {
      ...preferences,
      ...params,
      ...HAS_MENTORING && allSkills ? {
        skills_i_mentor: filter(allSkills, ({ id }) => indexOf(params.skills_i_mentor, id) >= 0),
        skills_i_can_mentor: filter(allSkills, ({ id }) => indexOf(params.skills_i_mentor, id) < 0)
      } : {}
    };
  }, [HAS_MENTORING]);

  const updateCachedPreferencesSkills = useCallback((
    preferences?: Preferences | null,
    params?: SkillLevelUpdateParams | null,
    payload?: Skill | null
  ): Preferences | null | undefined => {
    if (!preferences || !params || !isPlainObject(preferences) || !isPlainObject(params)) return preferences;
    const { skill_id, level, is_opt_in_mentor } = params;
    if (!HAS_MENTORING || level < SKILL_LEVEL_TO_MENTOR) return preferences;
    const toRemove = is_opt_in_mentor ? 'skills_i_can_mentor' as const : 'skills_i_mentor' as const;
    const toAdd = is_opt_in_mentor ? 'skills_i_mentor' as const : 'skills_i_can_mentor' as const;
    const removeIdx = findIndex(preferences[toRemove], ['id', skill_id]);
    const skill = payload || (removeIdx >= 0 ? preferences[toRemove]?.[removeIdx] : null);
    return {
      ...preferences,
      [toRemove]: removeIdx < 0 ? preferences[toRemove]
        : reject(preferences[toRemove], ['id', skill_id]),
      [toAdd]: !skill || findIndex(preferences[toAdd], ['id', skill_id]) >= 0 ? preferences[toAdd]
        : [...preferences[toAdd] || [], skill]
    };
  }, [HAS_MENTORING]);

  return useMemo(() => ({
    updateCachedSkillLevels,
    // updateSkillTargetLevels,
    updateCachedSkillsLevels,
    // updateSkillsTargetLevels,
    updateCachedRoleSkills,
    updateCachedSkillGaps,
    sanitizeJobsView,
    getDomesticCountryId,
    isDomesticCountryId,
    isInternational,
    getUserTags,
    // getCounty,
    getLocationStr,
    getEmployeeLocationStr,
    updateCachedPreferences,
    updateCachedPreferencesSkills
  }), [
    getDomesticCountryId, getLocationStr, getEmployeeLocationStr,
    isDomesticCountryId, isInternational, getUserTags, sanitizeJobsView, updateCachedPreferences,
    updateCachedPreferencesSkills, updateCachedRoleSkills, updateCachedSkillGaps, updateCachedSkillLevels,
    updateCachedSkillsLevels // , updateSkillTargetLevels, updateSkillsTargetLevels, getCounty
  ]);
}

export default useModels;
