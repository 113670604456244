import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// Skillmore UI Components
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
// local imports
import { DEV_PLAN_QUERY } from '../graphql/DevPlan';
import { EMPLOYEE_DETAILS_QUERY } from '../graphql/EmployeeDetails';
import { DevPlanDocument, EmployeeDetailsDocument } from '../graphql/types';
import { DevPlanDetails, TalentEmployeeDetails } from '../graphql/customTypes';
import { getPlanId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import DevPlanEditor from '../panels/DevPlanEditor';

// eslint-disable-next-line complexity
const EmployeeDevPlanEditorScreen: FunctionComponent = () => {
  const plan_id = getPlanId(useParams());

  const { content, user } = useVerifyUser({ employee: true });
  const employee_id = plan_id ? undefined : user?.id;

  // lazy load dev plan
  const { query: getDevPlan, pending: pendingDevPlan, failed: failedDevPlan, results: devplan } = useQueryObject({
    data: undefined as unknown as DevPlanDetails,
    key: 'devplan',
    flatResults: true,
    lazyQuery: useLazyQuery(DEV_PLAN_QUERY as typeof DevPlanDocument)
  });

  // lazy load employee
  const { query: getEmployee, pending: pendingEmployee, failed: failedEmployee, results: employee } = useQueryObject({
    data: undefined as unknown as TalentEmployeeDetails,
    key: 'employeeDetails',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_DETAILS_QUERY as typeof EmployeeDetailsDocument)
  });

  useEffect(() => {
    if (!content && plan_id) getDevPlan?.({ variables: { devplan_id: plan_id } });
  }, [content, plan_id, getDevPlan]);

  useEffect(() => {
    if (!content && employee_id) getEmployee?.({ variables: { employee_id } });
  }, [content, employee_id, getEmployee]);

  const loadedPlan = Boolean(plan_id) && plan_id === devplan?.id;
  const loadedEmployee = Boolean(employee_id) && employee_id === employee?.id;
  const failed = (plan_id && failedDevPlan) || (employee_id && failedEmployee) ? true : undefined;

  const breadcrumbs = useMemo(() => (loadedPlan && [{ title: devplan.title }]) ||
    (loadedEmployee && [{ title: 'dev_plans.new' }]) || undefined, [loadedPlan, loadedEmployee, devplan]);

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      <ContentCard>
        {!loadedPlan && !loadedEmployee && !failed ? <LoadingPlaceholder flat/> : (
          <DevPlanEditor
              isMyPlan
              devplan={loadedPlan ? devplan : null}
              employee={loadedEmployee ? employee : null}
              pending={(plan_id && (pendingDevPlan || plan_id !== devplan?.id)) ||
                (employee_id && (pendingEmployee || employee_id !== employee?.id))
                ? true : undefined}
              failed={failed}
          />
        )}
      </ContentCard>
    </Screen>
  );
};

export default memo(EmployeeDevPlanEditorScreen);
