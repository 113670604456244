import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import endsWith from 'lodash/endsWith';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import PhoneInTalk from '@empathco/ui-components/src/icons/PhoneInTalk';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { TeamWithSkill } from '../models/teamWithSkill';
import useCustomerSettings from '../config/customer';
// SCSS imports
import { nameContainer, nameRow } from './EmployeeTeamCard.module.scss';

type EmployeeTeamCardProps = {
  item: TeamWithSkill;
}

const EmployeeTeamCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<TeamWithSkill>
};

const EmployeeTeamCard = forwardRef<HTMLDivElement, EmployeeTeamCardProps>(({
  item
}, ref) => {
  const { getEmployeeContactUrl } = useCustomerSettings();
  const { full_name: name, email, employees_with_skill, all_employees, code } = item || {};
  return (
    <ItemCard
        ref={ref}
        bottomPadding
        light
        href={getEmployeeContactUrl(code, email)}
    >
      <Box display="flex">
        <Box pr={1} pt={0.25}>
          <PhoneInTalk fontSize="large" color="primary"/>
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" className={nameContainer}>
            <Box color="secondary.main" className={nameRow}>
              <FormattedMessage
                  id="teamcard.name"
                  values={{
                    name,
                    endsWithS: endsWith(name, 's')
                  }}
              />
            </Box>
          </Box>
          <BoxTypography
              color="text.primary"
              pt={0.75}
              variant="body1"
          >
            <FormattedMessage
                id="teamcard.employee_count"
                values={{ employees_with_skill, all_employees }}
            />
          </BoxTypography>
        </Box>
      </Box>
    </ItemCard>
  );
});

EmployeeTeamCard.displayName = 'EmployeeTeamCard';

EmployeeTeamCard.propTypes = EmployeeTeamCardPropTypes;

export default memo(EmployeeTeamCard);
