import { type Dispatch } from 'react';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import toString from 'lodash/toString';
import isSafeInteger from 'lodash/isSafeInteger';
import { type AxiosResponse } from 'axios';
// Skillmore UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { APIActionResponse } from '../models/apiResponse';
import { axiosInstance as axios, getApiAddSuggestedSkill, getApiRemoveSuggestedSkill } from '../config/api';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { Token, Online } from './global';
import { DataActions, SuggestedSkillParams } from './dataContext';

export const suggestedSkillAction = (
  token: Token,
  operation: 'ADD' | 'REMOV',
  online: Online,
  dispatch: Dispatch<DataActions>,
  pending: boolean | null
) => async ({ employee_id, skill_id }: SuggestedSkillParams) => {
  if (
    !token || pending || !isSafeInteger(skill_id) || skill_id < 1 ||
    isEmptyString(employee_id) || trim(toString(employee_id)) === '0'
  ) return;
  const params = optimizeParams({
    employee_id,
    skill_id
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: `SUGGESTED_SKILL_${operation}ING`,
      params
    } as DataActions);
    const { status, data } = await axios.request<
      Omit<SuggestedSkillParams, 'employee_id'>,
      AxiosResponse<APIActionResponse>
    >({
      method: 'POST',
      url: operation === 'ADD'
        ? getApiAddSuggestedSkill(employee_id)
        : getApiRemoveSuggestedSkill(employee_id),
      data: omit(params, 'employee_id'),
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: `SUGGESTED_SKILL_${operation}ED`,
      payload: true,
      params
    } as DataActions);
  } catch (error) {
    dispatch({
      type: `SUGGESTED_SKILL_${operation}ED`,
      payload: false,
      params
    } as DataActions);
  }
};
