import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_EMPLOYEE } from '../config/api';

export const ADMIN_EMPLOYEE_QUERY = gql`
  query AdminEmployee(
    $employee_id: Int!
  ) {
    adminEmployee(
      employee_id: $employee_id
    )
    @rest(
      type: "AdminEmployee",
      path: "${API_ADMIN_EMPLOYEE}"
    ) {
      id
      code
      first_name
      last_name
      email
      is_countable
      current_job {
        id
        code
        title
        location {
          id
          title
          city
          state
          country
          # country_id
          # state_id
        }
      }
      manager {
        id
        code
        first_name
        last_name
        email
      }
      profile
      location {
        id
        title
        city
        state
        country
        # country_id
        # state_id
      }
      by_sso
      management_level
      is_contractor
      org_unit {
        id
        title
      }
      org {
        id
        title
      }
      years_on_job
      performance_rating
      is_esau_untouchable
      email
      is_inference_target
      skills {
        id
        title
        abbr
        current_level
        inferred_level
        is_inference_newer
      }
    }
  }
`;
