import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import { type TooltipProps } from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
// Material Icon imports
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// local imports
import SimpleTooltip from './SimpleTooltip';
// SCSS imports
import { avatar, hidden, avatarSmall, hiddenSmall } from './SkillTrendIcon.module.scss';

type SkillTrendIconProps = {
  active?: boolean | null;
  small?: boolean;
  placement?: TooltipProps['placement'];
  className?: string;
  isOpen?: boolean;
}

const SkillTrendIconPropTypes = {
  active: PropTypes.bool,
  small: PropTypes.bool,
  placement: PropTypes.string as Validator<TooltipProps['placement']>,
  className: PropTypes.string,
  // for Storybook only
  isOpen: PropTypes.bool
};

const SkillTrendIcon = forwardRef<HTMLDivElement, SkillTrendIconProps>(({
  active = false,
  small = false,
  placement = 'right',
  className,
  isOpen = false
}, ref) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const tooltip = useMemo(() => formatMessage({ id: 'common.skills.in_demand' }), [formatMessage]);

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
  }, [isOpen]);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const classNameFull = clsx(className, {
    [avatar]: !small && active,
    [hidden]: !small && !active,
    [avatarSmall]: small && active,
    [hiddenSmall]: small && !active
  });

  const icon = active ? (
    <Avatar
        ref={ref}
        variant="circular"
        aria-hidden={active ? undefined : 'true'}
        className={classNameFull}
    >
      <TrendingUpIcon fontSize="inherit"/>
    </Avatar>
  ) : <Box ref={ref} aria-hidden className={classNameFull}/>;

  return (
    <>
      {active ? (
        <SimpleTooltip
            aria-hidden
            open={open}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            disableInteractive
            disableFocusListener
            placement={placement}
            title={tooltip}
        >
          {icon}
        </SimpleTooltip>
      ) : icon}
      {active ? (
        <Box sx={visuallyHidden}>
          {tooltip}
        </Box>
      ) : undefined}
    </>
  );
});

SkillTrendIcon.displayName = 'SkillTrendIcon';

SkillTrendIcon.propTypes = SkillTrendIconPropTypes;

export default memo(SkillTrendIcon);
