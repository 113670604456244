import { type Dispatch } from 'react';
import trim from 'lodash/trim';
import size from 'lodash/size';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import toString from 'lodash/toString';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isSafeInteger from 'lodash/isSafeInteger';
import { type AxiosResponse, type AxiosError } from 'axios';
// Skillmore UI Components
import { getStringifiedIds, isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX } from '../models/skill';
import { Employee } from '../models/employee';
import { ContextEntityWithCount, ContextObject } from '../models/contextEntity';
import { APIActionResponse } from '../models/apiResponse';
import { isValidSortParam } from '../constants/tbBestMatchesSort';
import {
  axiosInstance as axios,
  API_TEAM_BUILDER_TEAMS, getApiTeamBuilderMatches, getApiTeamBuilderTeam,
  getApiTeamBuilderJobs, getApiTeamBuilderJob,
  getApiTeamBuilderSkills, getApiTeamBuilderSkill, getApiTeamBuilderSkillsReset,
  getApiTeamBuilderEmployees, getApiTeamBuilderEmployee
} from '../config/api';
import { fetchFactory } from '../helpers/actions';
import { TbTeam } from '../models/tbTeam';
import { getRequestHeaders, locationParams, optimizeParams } from '../helpers/context';
import { FeedbackParams } from './commonParams';
import { Token, Online } from './global';
import {
  SupervisorActions,
  TbTeamsParams, TB_TEAMS_FETCH,
  TbAddTeamParams,
  TbTeamParams, TB_TEAM_FETCH,
  TbUpdateTeamParams, TbDeleteTeamParams,
  TbBestMatchesParams, TB_MATCHES_FETCH,
  TbTeamAddJobParams, TbTeamJobParams,
  TbTeamAddSkillParams, TbTeamUpdateSkillParams, TbTeamSkillParams, TbTeamResetSkillsParams,
  TbTeamAddEmployeeParams, TbTeamEmployeeParams, TeamBuilderAction
} from './supervisor';

export const updateCachedTbTeam = (team: TbTeam | null, params: TbUpdateTeamParams) =>
  !params?.team_id || !team?.id || team.id !== params.team_id ? team : { ...team, title: params.title } as TbTeam;

export const addEmployeeToCachedTbTeam = (team: TbTeam | null, params: TbTeamAddEmployeeParams) => {
  if (!params?.team_id || !team?.id || team.id !== params.team_id) return team;
  return {
    ...team,
    employees: [...team.employees || [], params.employee]
  } as TbTeam;
};

export const deleteEmployeeFromCachedTbTeam = (team: TbTeam | null, params: TbTeamEmployeeParams) => {
  if (
    !params?.team_id || !team?.id || team.id !== params.team_id ||
    !team.employees || size(team.employees) <= 0 || findIndex(team.employees, ['id', params.employee_id]) < 0
  ) return team;
  return {
    ...team,
    employees: reject(team.employees, ['id', params.employee_id])
  } as TbTeam;
};

export const requireTbTeams = (
  token: Token,
  online: Online,
  unauthenticate: (() => void) | undefined,
  dispatch: Dispatch<SupervisorActions>,
  tbTeams: ContextEntityWithCount<TbTeam, TbTeamsParams>
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ offset, limit }) => ({
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null,
    limit: limit && isSafeInteger(limit) && limit >= 1 ? limit : null
  }),
  type: TB_TEAMS_FETCH,
  entity: tbTeams,
  withCount: true,
  api: API_TEAM_BUILDER_TEAMS
});

export const requireTbTeam = (
  token: Token,
  online: Online,
  unauthenticate: (() => void) | undefined,
  dispatch: Dispatch<SupervisorActions>,
  tbTeam: ContextObject<TbTeam, TbTeamParams>
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ['team_id'],
  type: TB_TEAM_FETCH,
  entity: tbTeam,
  validator: ({ team_id }) => Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderTeam(team_id),
  results: '',
  dropParams: ['team_id']
});

export const requireTbBestMatches = (
  token: Token,
  online: Online,
  unauthenticate: (() => void) | undefined,
  dispatch: Dispatch<SupervisorActions>,
  tbBestMatches: ContextEntityWithCount<Employee, TbBestMatchesParams>
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ team_id, manager_id, country_id, state_id, job_levels, sort_by, offset, limit }) => ({
    team_id,
    ...locationParams(country_id, state_id),
    job_levels: job_levels && isArray(job_levels) && size(job_levels) >= 1 ? job_levels : null,
    manager_id: isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? null : manager_id,
    sort_by: isValidSortParam(toString(sort_by)) ? sort_by : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null,
    limit: limit && isSafeInteger(limit) && limit >= 1 ? limit : null
  } as TbBestMatchesParams),
  type: TB_MATCHES_FETCH,
  entity: tbBestMatches,
  withCount: true,
  validator: ({ team_id }) => Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderMatches(team_id),
  dropParams: (params) => ({
    ...omit(params, 'team_id'),
    ...params.job_levels ? { job_levels: getStringifiedIds(params.job_levels) } : {}
  })
});

const teamBuilderAction = <P extends (TbTeamParams & FeedbackParams) | TbAddTeamParams, T = APIActionResponse>({
  token,
  operation,
  validator,
  api,
  method,
  dropParams,
  online,
  dispatch,
  pending,
  feedback
}: {
  token: Token;
  operation: TeamBuilderAction;
  validator?: (params: P) => boolean;
  api: string | ((params: P) => string);
  method: 'POST' | 'PATCH' | 'DELETE';
  dropParams?: string[];
  online: Online;
  dispatch: Dispatch<SupervisorActions>;
  pending: boolean | null;
  feedback?: (feedback: T) => unknown;
  // eslint-disable-next-line complexity
}) => async (callParams: P) => {
  if (!token || pending || (validator && !validator(callParams))) return;
  const params = optimizeParams(
    callParams.onSuccess
      ? omit(callParams, ['onSuccess'])
      : callParams,
    online
  ) as P;
  try {
    if (!online) throw new Error();
    dispatch({
      type: `${operation}ING` as const,
      params
    } as SupervisorActions);
    const { status, data } = await axios.request<P, AxiosResponse<T>>({
      method,
      url: isFunction(api) ? api(params) : api,
      data: isArray(dropParams) ? omit(params, dropParams) : params,
      headers: getRequestHeaders(token)
    }) || {};
    if (
      (method === 'POST' && status !== 200 && status !== 201) ||
      (method === 'PATCH' && status !== 200) ||
      (method === 'DELETE' && status !== 200 && status !== 204)
    ) throw new Error();
    (callParams as FeedbackParams).onSuccess?.((data && feedback?.(data)) || undefined);
    dispatch({
      type: `${operation}ED` as const,
      payload: true,
      params
    } as SupervisorActions);
  } catch (error) {
    dispatch({
      type: `${operation}ED` as const,
      payload: false,
      params: (error as AxiosError)?.response?.status === 400 ? { ...params, badRequest: true } as P : params
    } as SupervisorActions);
  }
};

export const tbAddTeam = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbAddTeamParams, TbTeam>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_ADD',
  method: 'POST',
  api: API_TEAM_BUILDER_TEAMS,
  feedback: (data: TbTeam) => ({ team_id: data.id })
});

export const tbUpdateTeam = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbUpdateTeamParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_UPDAT',
  method: 'PATCH',
  validator: ({ team_id, title }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    !isEmptyString(title),
  api: ({ team_id }) => getApiTeamBuilderTeam(team_id),
  dropParams: ['team_id']
});

export const tbDeleteTeam = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbDeleteTeamParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_DELET',
  method: 'DELETE',
  validator: ({ team_id }) => Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderTeam(team_id),
  dropParams: ['team_id']
});

export const tbTeamAddJob = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamAddJobParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_JOB_ADD',
  method: 'POST',
  validator: ({ team_id, job_id }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(job_id) && isSafeInteger(job_id) && job_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderJobs(team_id),
  dropParams: ['team_id', 'job']
});

export const tbTeamDeleteJob = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamJobParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_JOB_DELET',
  method: 'DELETE',
  validator: ({ team_id, job_id }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(job_id) && isSafeInteger(job_id) && job_id >= 1,
  api: ({ team_id, job_id }) => getApiTeamBuilderJob(team_id, job_id),
  dropParams: ['team_id', 'job_id']
});

export const tbTeamAddSkill = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamAddSkillParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_SKILL_ADD',
  method: 'POST',
  validator: ({ team_id, skill_id, skill_proficiency_level }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(skill_id) && isSafeInteger(skill_id) && skill_id >= 1 &&
    skill_proficiency_level >= SKILL_LEVEL_FIRST && skill_proficiency_level <= SKILL_LEVEL_MAX,
  api: ({ team_id }) => getApiTeamBuilderSkills(team_id),
  dropParams: ['team_id', 'skill']
});

export const tbTeamUpdateSkill = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamUpdateSkillParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_SKILL_UPDAT',
  method: 'PATCH',
  validator: ({ team_id, skill_id, skill_proficiency_level }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(skill_id) && isSafeInteger(skill_id) && skill_id >= 1 &&
    skill_proficiency_level >= SKILL_LEVEL_FIRST && skill_proficiency_level <= SKILL_LEVEL_MAX,
  api: ({ team_id, skill_id }) => getApiTeamBuilderSkill(team_id, skill_id),
  dropParams: ['team_id', 'skill_id']
});

export const tbTeamDeleteSkill = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamSkillParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_SKILL_DELET',
  method: 'DELETE',
  validator: ({ team_id, skill_id, skill_proficiency_level }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(skill_id) && isSafeInteger(skill_id) && skill_id >= 1 &&
    skill_proficiency_level >= SKILL_LEVEL_FIRST && skill_proficiency_level <= SKILL_LEVEL_MAX,
  api: ({ team_id, skill_id }) => getApiTeamBuilderSkill(team_id, skill_id),
  dropParams: ['team_id', 'skill_id']
});

export const tbTeamResetSkills = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamResetSkillsParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_SKILLS_CLEAR',
  method: 'POST',
  validator: ({ team_id }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderSkillsReset(team_id),
  dropParams: ['team_id']
});

export const tbTeamAddEmployee = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamAddEmployeeParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_EMPLOYEE_ADD',
  method: 'POST',
  validator: ({ team_id, employee_id }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(employee_id) && isSafeInteger(employee_id) && employee_id >= 1,
  api: ({ team_id }) => getApiTeamBuilderEmployees(team_id),
  dropParams: ['team_id', 'employee']
});

export const tbTeamDeleteEmployee = (
  token: Token,
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  pending: boolean | null
) => teamBuilderAction<TbTeamEmployeeParams>({
  token,
  online,
  dispatch,
  pending,
  operation: 'TB_TEAM_EMPLOYEE_DELET',
  method: 'DELETE',
  validator: ({ team_id, employee_id }) =>
    Boolean(team_id) && isSafeInteger(team_id) && team_id >= 1 &&
    Boolean(employee_id) && isSafeInteger(employee_id) && employee_id >= 1,
  api: ({ team_id, employee_id }) => getApiTeamBuilderEmployee(team_id, employee_id),
  dropParams: ['team_id', 'employee_id']
});
