import { forwardRef, memo, useCallback, useEffect, useState, useContext } from 'react';
import isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { JobLookupItem } from '../graphql/customTypes';
import { Skill, SkillLevel, SKILL_LEVEL_FIRST } from '../models/skill';
import { PATH_HR_TEAM_BUILDER_TEAM } from '../config/paths';
import { SupervisorContext, TbAddTeamFeedback } from '../context/supervisor';
import LargeButton from '../elements/LargeButton';
import TbTeamCard from '../v3/TbTeamCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import AddJobDialog from '../widgets/AddJobDialog';
// SCSS imports
import { footer } from './TeamBuilder.module.scss';

const componentProps: Partial<GetComponentProps<typeof TbTeamCard>> = {
  hrbp: true
};

const TeamBuilder = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const navigate = useNavigate();
  const {
    tbTeams: { data: teams, count, pending, failed }, requireTbTeams,
    tbTeamAdd: { pending: addPending, failed: addFailed }, tbAddTeam,
    tbTeamSkillAdd: { pending: skillPending /* , failed: skillFailed */ }, tbTeamAddSkill
  } = useContext(SupervisorContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();
  // Add Job dialog
  const [isOpen, setIsOpen] = useState(false);

  const handleAddOpen = useCallback(() => setIsOpen(true), []);
  const handleAddClose = useCallback(() => setIsOpen(false), []);

  const handleAddJob = useCallback((job?: JobLookupItem | null) => {
    setIsOpen(false);
    if (job?.id) tbAddTeam?.({
      job_id: job.id,
      onSuccess: (data?: TbAddTeamFeedback) => {
        const { team_id } = data || {};
        if (team_id) navigate(injectParams(PATH_HR_TEAM_BUILDER_TEAM, { team_id }));
      }
    });
  }, [navigate, tbAddTeam]);

  const handleAddSkill = useCallback((skill: Skill, level: SkillLevel) => {
    setIsOpen(false);
    if (skill?.id && level >= SKILL_LEVEL_FIRST) tbAddTeam?.({
      onSuccess: (data?: TbAddTeamFeedback) => {
        const { team_id } = data || {};
        if (team_id) {
          navigate(injectParams(PATH_HR_TEAM_BUILDER_TEAM, { team_id }));
          tbTeamAddSkill?.({
            team_id,
            skill_id: skill.id,
            skill_proficiency_level: level,
            skill
            // Error message for this action is supposed to be rendered in `TbTeamItems` by
            // `TeamBuilderTeam` component on the next screen.
            // If there is any problem with that, we may uncomment `ActionFailedAlert` below,
            // uncomment `I_AddingSkillFailed` story and move redirection to `onSuccess` handler:
            // onSuccess: () => navigate(...)
          });
        }
      }
    });
  }, [navigate, tbAddTeam, tbTeamAddSkill]);

  useEffect(() => {
    if (!isNil(pageSize)) requireTbTeams?.({
      limit: pageSize,
      offset: pageSize * (currentPage - 1)
    });
  }, [currentPage, pageSize, requireTbTeams]);

  return (
    <>
      <CardSection>
        <BoxTypography variant="subtitle1">
          <FormattedMessage id="hr.teambuilder.teams"/>
        </BoxTypography>
      </CardSection>
      <CardsGrid
          ref={ref}
          items={teams}
          variant="shady"
          pending={pending || !teams}
          failed={failed}
          disabled={addPending || skillPending}
          blendNotFound
          blendPagination
          component={TbTeamCard}
          ComponentProps={componentProps}
          notFoundMessage="hr.teambuilder.no_teams"
          pagination={(
            <PaginationControls
                settingsId="tb_teams"
                loaded={Boolean(teams)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={pending || failed}
                totalMessage="hr.teambuilder.pagination"
            />
          )}
      />
      <CardFooter className={footer}>
        <LargeButton
            title="hr.teambuilder.new_team"
            variant="cohort"
            onClick={handleAddOpen}
            disabled={failed || addPending || skillPending}
        />
      </CardFooter>
      <AddJobDialog
          isOpen={isOpen}
          onAdd={handleAddJob}
          onAddSkill={handleAddSkill}
          onCancel={handleAddClose}
          disabled={addPending || skillPending}
      />
      <ActionFailedAlert
          message="hr.teambuilder.new_team_error"
          open={addFailed}
      />
      {/* <ActionFailedAlert
          message="hr.teambuilder.skills_add_error"
          open={skillFailed}
      /> */}
    </>
  );
});

TeamBuilder.displayName = 'TeamBuilder';

export default memo(TeamBuilder);
