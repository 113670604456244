import { memo, useCallback, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import toSafeInteger from 'lodash/toSafeInteger';
import { useMutation } from '@apollo/client';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import EditIcon from '@mui/icons-material/Edit';
import ManIcon from '@mui/icons-material/Man';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
// Skillmore UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
// local imports
import { UPDATE_OPPORTUNITY } from '../graphql/UpdateOpportunity';
import { BookingStatus, Opportunity, OpportunityStatus, UpdateOpportunityDocument } from '../graphql/types';
import {
  PATH_SUPERVISOR_OPPORTUNITY, PATH_SUPERVISOR_OPPORTUNITY_EDIT,
  PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS, PATH_SUPERVISOR_OPPORTUNITY_MATCHES
} from '../config/paths';
import OpportunityDetailsCount from '../elements/OpportunityDetailsCount';
import OpportunityDetailsContent from '../widgets/OpportunityDetailsContent';
import OpportunityBookingsDialog from '../widgets/OpportunityBookingsDialog';
// SCSS imports
import { icon1, icon2, icon3, icon4, icon5 } from './OpportunityReviewPanel.module.scss';

const buttonSx = { ml: 4 };

type OpportunityReviewPanelProps = {
  opportunityId?: number | null;
  opportunity?: Opportunity | null;
  pending?: boolean | null;
  failed?: boolean | null;
  // for Storybook only:
  testAction?: boolean;
}

const OpportunityReviewPanelPropsTypes = {
  opportunityId: PropTypes.number,
  opportunity: PropTypes.object as Validator<Opportunity | null>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  // for Storybook only:
  testAction: PropTypes.bool
};

// eslint-disable-next-line complexity
const OpportunityReviewPanel: FunctionComponent<OpportunityReviewPanelProps> = ({
  opportunityId,
  opportunity,
  pending,
  failed,
  testAction
}) => {
  const navigate = useNavigate();

  const { mutate: updateOpportunity, loading: updatePending, failed: updateFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunity', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY as typeof UpdateOpportunityDocument)
  });

  const { id, status, bookings, total_matches, shortlisted } = opportunity || {};

  const [requested_count, booked_count, applicants_count] = useMemo(() => {
    let requested = 0;
    let booked = 0;
    let applicants = 0;
    forEach(bookings, ({ status: bookingStatus, count }) => {
      if (bookingStatus === BookingStatus.manager_requested) requested = toSafeInteger(count);
      else if (bookingStatus === BookingStatus.confirmed) booked = toSafeInteger(count);
      else if (bookingStatus === BookingStatus.employee_requested) applicants = toSafeInteger(count);
    });
    return [requested, booked, applicants];
  }, [bookings]);

  const isBooked = useMemo(
    () => status === OpportunityStatus.published && findIndex(bookings, ['status', BookingStatus.confirmed]) >= 0,
    [status, bookings]
  );
  const canView = status && status !== OpportunityStatus.draft;
  const canManage = status === OpportunityStatus.published && !isBooked;

  const handlePublish = useCallback(() => {
    if (id) updateOpportunity?.({
      variables: {
        opportunity_id: id,
        input: { status: OpportunityStatus.published }
      },
      // TODO: optimistic response
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
      }
    });
  }, [id, updateOpportunity]);

  const [matchesLink, bookingsLink] = useMemo(() => [
    (id && injectParams(PATH_SUPERVISOR_OPPORTUNITY_MATCHES, { opp_id: id })) || undefined,
    (id && injectParams(PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS, { opp_id: id })) || undefined
  ], [id]);
  const hasBookingDialog = (canManage && Boolean(matchesLink)) || (canView && Boolean(bookingsLink));

  const handleClose = useCallback(() => {
    navigate(injectParams(PATH_SUPERVISOR_OPPORTUNITY, { opp_id: id }));
  }, [id, navigate]);

  const disabled = updatePending ? true : undefined;

  const canEdit = id && (
    status === OpportunityStatus.draft ||
    status === OpportunityStatus.published
  );
  const canPublish = id && status === OpportunityStatus.draft;

  useEffect(() => {
    if (testAction) updateOpportunity?.({ variables: { opportunity_id: 1, input: { status: OpportunityStatus.published } } });
  }, [testAction, updateOpportunity]);

  return (opportunityId && failed && <FetchFailedAlert flat/>) ||
  ((pending || !opportunity) && <LoadingPlaceholder flat/>) || (
    <>
      <CardSection top>
        <TagLabel
            small
            variant={status === OpportunityStatus.deleted ? 'inactive'
              : (isBooked && 'active') || status || OpportunityStatus.draft}
            title={isBooked ? 'opportunities.status.booked' : undefined}
        />
      </CardSection>
      <OpportunityDetailsContent
          supervisor
          opportunity={opportunity}
          disabled={disabled}
          actions={canEdit || canPublish ? (
            <>
              {canEdit ? (
                <Button
                    color="primary"
                    variant="text"
                    disableElevation
                    disabled={disabled}
                    component={RouterLink}
                    startIcon={<EditIcon/>}
                    to={injectParams(PATH_SUPERVISOR_OPPORTUNITY_EDIT, { opp_id: id })}
                    sx={buttonSx}
                >
                  <FormattedMessage id="opportunities.button.edit"/>
                </Button>
              ) : undefined}
              {canPublish ? (
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={disabled}
                    startIcon={updatePending ? <CircularProgress size={18} color="inherit"/> : undefined}
                    onClick={handlePublish}
                    sx={buttonSx}
                >
                  <FormattedMessage id="opportunities.button.publish"/>
                </Button>
              ) : undefined}
            </>
          ) : undefined}
      />
      {status && status !== OpportunityStatus.draft ? (
        <CardSection bottom>
          <Divider/>
          <BoxTypography variant="h4" pt={2.5} pb={1.5}>
            <FormattedMessage id="opportunities.matches.title"/>
          </BoxTypography>
          <BoxTypography variant="subtitle2" pb={2} display="flex" alignItems="center" flexWrap="wrap">
            {[
              {
                msgId: 'opportunities.matches.total',
                icon: <ManIcon fontSize="large" className={icon1}/>,
                count: total_matches,
                link: canManage ? matchesLink : undefined
              },
              {
                msgId: 'opportunities.matches.applicants',
                icon: <EmojiPeopleIcon fontSize="large" className={icon2}/>,
                count: applicants_count,
                link: canView ? bookingsLink : undefined
              },
              {
                msgId: 'opportunities.matches.shortlisted',
                icon: <ManIcon fontSize="large" className={icon3}/>,
                count: shortlisted,
                link: canView ? bookingsLink : undefined
              },
              {
                msgId: 'opportunities.matches.requested',
                icon: <ManIcon fontSize="large" className={icon4}/>,
                count: requested_count,
                link: canView ? bookingsLink : undefined
              },
              {
                msgId: 'opportunities.matches.booked',
                icon: <ManIcon fontSize="large" className={icon5}/>,
                count: booked_count,
                link: canView ? bookingsLink : undefined
                // TODO: booked employee name => contact link or open Booking popup
              }
            ].map(({ msgId, icon, count, link }) => (
              <OpportunityDetailsCount key={msgId} id={msgId} icon={icon} count={count} link={link}/>
            ))}
          </BoxTypography>
        </CardSection>
      ) : undefined}
      {hasBookingDialog ? (
        <CardFooter withDivider>
          {canManage && matchesLink ? (
            <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                component={RouterLink}
                to={matchesLink}
            >
              <FormattedMessage id="opportunities.button.matches"/>
            </Button>
          ) : undefined}
          {canManage && matchesLink && canView && bookingsLink ? <Box width="8%"/> : undefined}
          {canView && bookingsLink ? (
            <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                component={RouterLink}
                to={bookingsLink}
            >
              <FormattedMessage
                  id={canManage ? 'opportunities.button.bookings' : 'opportunities.button.bookings_readonly'}
              />
            </Button>
          ) : undefined}
        </CardFooter>
      ) : undefined}
      <ActionFailedAlert
          message="opportunities.publish.error"
          open={updateFailed}
      />
      {hasBookingDialog ? (
        <OpportunityBookingsDialog
            opportunity={opportunity}
            onClose={handleClose}
            readOnly={!canManage}
        />
      ) : undefined}
    </>
  );
};

OpportunityReviewPanel.propTypes = OpportunityReviewPanelPropsTypes;

export default memo(OpportunityReviewPanel);
