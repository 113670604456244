// polyfill imports
import 'react-app-polyfill/stable';
import 'vite/modulepreload-polyfill';
// React imports
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import includes from 'lodash/includes';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
// Material UI imports
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// Skillmore UI Components
import { themeOptions } from '@empathco/ui-components/src/styles/themeOptions';
import { nonce } from '@empathco/ui-components/src/config/contentSecurityPolicy';
import SvgGradients from '@empathco/ui-components/src/common/SvgGradients';
// local imports
import LocalizationProvider from './config/LocalizationProvider';
import App from './App';
// SCSS imports
import './styles/index.scss';

const cache = createCache({
  key: 'css',
  prepend: true,
  nonce
});

const tssCache = createCache({
  key: 'tss',
  nonce
});

const theme = createTheme(themeOptions);

// Suppress EvalError exceptions reporting to Sentry
window.onunhandledrejection = (event) => {
  if (!includes(event?.reason, 'unsafe-eval')) throw event;
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <CookiesProvider>
        <LocalizationProvider>
          <CacheProvider value={cache}>
            <TssCacheProvider value={tssCache}>
              <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
                <SvgGradients/>
              </ThemeProvider>
            </TssCacheProvider>
          </CacheProvider>
        </LocalizationProvider>
      </CookiesProvider>
    </StrictMode>
  );
}

// TODO: implement Service Worker & other PWA features with https://github.com/vite-pwa/vite-plugin-pwa
// TODO: implement Web Vitas with https://github.com/daliborgogic/vite-vitals
