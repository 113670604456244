import { forwardRef, memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
// local imports
import { DEV_PLANS_QUERY } from '../graphql/DevPlans';
import { DevPlansDocument, DevPlansQueryVariables } from '../graphql/types';
import { DevPlan, EmployeeSearchItem } from '../graphql/customTypes';
import { PATH_HR_COHORTS, PATH_HR_DEV_PLAN_EMPLOYEE_NEW } from '../config/paths';
import LargeButton from '../elements/LargeButton';
import TbTeamCard from '../v3/TbTeamCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import AddEmployeeDialog from '../widgets/AddEmployeeDialog';
// SCSS imports
import { footer } from './DevPlansPanel.module.scss';

const componentProps: Partial<GetComponentProps<typeof TbTeamCard>> = {
  hrbp: true,
  variant: 'devplan'
};

const values = { br: <br/> };

type DevPlansPanelProps = {
  // for Storybook only
  testPending?: boolean;
}

const DevPlansPanelPropTypes = {
  testPending: PropTypes.bool
};

const DevPlansPanel = forwardRef<HTMLDivElement, DevPlansPanelProps>(({
  testPending
}, ref) => {
  const navigate = useNavigate();

  // lazy load dev plans
  const {
    query: getDevPlans, pending: pendingDevPlans, failed, count, results: devplans, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as DevPlan,
    key: 'devplans',
    lazyQuery: useLazyQuery(DEV_PLANS_QUERY as typeof DevPlansDocument)
  });
  const pending = pendingDevPlans || testPending;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  // Employee dialog
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (pageSize && getDevPlans) {
      const variables: DevPlansQueryVariables = {
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getDevPlans({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, getDevPlans]); // ignoring `prevVars` changes

  const handleNewEmployeeDevPlan = useCallback((employee?: EmployeeSearchItem) => {
    if (employee?.id) navigate(injectParams(PATH_HR_DEV_PLAN_EMPLOYEE_NEW, { employee_id: employee?.id }));
  }, [navigate]);

  return (
    <>
      <CardsGrid
          ref={ref}
          items={devplans}
          variant="shady"
          withReloading
          pending={pending}
          failed={failed}
          // disabled={...}
          blendNotFound
          blendPagination
          component={TbTeamCard}
          ComponentProps={componentProps}
          notFoundMessage="hr.dev_plans.empty"
          pagination={(
            <PaginationControls
                settingsId="tb_devplans"
                loaded={Boolean(devplans)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={pending || failed}
                totalMessage="hr.dev_plans.pagination"
            />
          )}
      />
      <CardFooter className={footer}>
        <LargeButton
            title="hr.dev_plans.view_cohorts"
            variant="cohort"
            link={PATH_HR_COHORTS}
            disabled={pending}
        />
        <Box width="11.5%"/>
        <LargeButton
            title="hr.dev_plans.new_employee_dev_plan"
            values={values}
            variant="devplan"
            onClick={handleOpen}
            disabled={pending}
        />
      </CardFooter>
      <AddEmployeeDialog
          isOpen={isOpen}
          onAdd={handleNewEmployeeDevPlan}
          onCancel={handleClose}
      />
    </>
  );
});

DevPlansPanel.displayName = 'DevPlansPanel';

DevPlansPanel.propTypes = DevPlansPanelPropTypes;

export default memo(DevPlansPanel);
