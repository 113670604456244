import { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sum from 'lodash/sum';
import size from 'lodash/size';
import slice from 'lodash/slice';
import { LinearGradient } from 'echarts/lib/export/api/graphic';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
// Skillmore UI Components
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { setEChartInstanceOption } from '@empathco/ui-components/src/helpers/echarts';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { GlobalEChartsStyles } from '../config/params';
import Chart from '../elements/Chart';
// SCSS imports
import { chart } from './TalentFinderChart.module.scss';

type TalentFinderChartProps = {
  counts: number[];
}

const TalentFinderChartPropTypes = {
  // attributes
  counts: PropTypes.array.isRequired
};

const TalentFinderChart = forwardRef<EChartsReactCore, TalentFinderChartProps>(({
  counts
}, ref) => {
  const theme = useTheme();
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const totalDesired = size(counts) - 1;

  const [values, total] = useMemo(() => [
    [
      counts[0],
      totalDesired > 1 ? sum(slice(counts, 1, totalDesired)) : 0,
      totalDesired > 0 ? counts[totalDesired] : 0
    ],
    sum(counts)
  ], [counts, totalDesired]);

  const labels = useMemo(() => [
    formatMessage({ id: 'hr.talentfinder.employees.required' }),
    formatMessage({ id: 'hr.talentfinder.employees.some' }),
    formatMessage({ id: 'hr.talentfinder.employees.all' }),
    formatMessage({ id: 'hr.talentfinder.total' }, { count: total }),
    formatNumber(total)
  ], [total, formatMessage, formatNumber]);

  const colors = useMemo(() => [
    new LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: theme.palette.chart.series1s },
      { offset: 1, color: theme.palette.chart.series1e }
    ]),
    new LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: theme.palette.chart.series2e },
      { offset: 1, color: theme.palette.chart.series2s }
    ]),
    new LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: theme.palette.chart.series3e },
      { offset: 1, color: theme.palette.chart.series3s }
    ])
  ], [theme.palette.chart]);

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    setEChartInstanceOption(echartInstance, {
      ...GlobalEChartsStyles,
      title: [
        {
          text: labels[3],
          right: spacing(0.25),
          bottom: spacing(1),
          textStyle: {
            fontSize: 15,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.info.caption
          }
        },
        {
          text: labels[4],
          textAlign: 'center',
          textVerticalAlign: 'middle',
          left: '49.5%',
          top: '43%',
          textStyle: {
            fontSize: 25,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.greys.popupBorder
          }
        }
      ],
      series: {
        silent: true,
        name: 'talents',
        type: 'pie',
        clockwise: false,
        radius: ['55%', '56%'],
        center: ['50%', '44%'],
        avoidLabelOverlap: true,
        itemStyle: {
          borderWidth: spacing(1.625),
          borderRadius: spacing(1.625)
        },
        label: {
          show: total >= 1,
          color: theme.palette.text.primary,
          width: spacing(21),
          overflow: 'break',
          distanceToLabelLine: spacing(2),
          formatter: ({ name, value }: { name: string; value: number; }) =>
            `{count|${formatNumber(value)}}\n\n{label|${name}}`,
          rich: {
            count: {
              fontSize: 25,
              fontWeight: theme.typography.fontWeightRegular,
              lineHeight: 40
            },
            label: {
              fontSize: 15,
              fontWeight: theme.typography.fontWeightRegular,
              lineHeight: 20
            }
          }
        },
        labelLayout: () => totalDesired <= 0 ? {
          // eslint-disable-next-line id-length
          x: '61%',
          // eslint-disable-next-line id-length
          y: '27%',
          align: 'left'
        } : {},
        labelLine: { show: false },
        data: [
          ...map(values, (value, idx) => value < 1 ? {} : {
            value,
            name: labels[idx],
            itemStyle: {
              color: colors[idx],
              borderColor: colors[idx]
            }
          }),
          total < 1 ? {
            value: 1,
            itemStyle: {
              color: 'transparent',
              borderColor: theme.palette.action.disabledBackground
            }
          } : {}
        ]
      }
    });
  }, [values, total, totalDesired, labels, colors, theme, formatNumber]);

  return <Chart ref={chartRef} option={GlobalEChartsStyles} className={chart}/>;
});

TalentFinderChart.displayName = 'TalentFinderChart';

TalentFinderChart.propTypes = TalentFinderChartPropTypes;

export default memo(TalentFinderChart);
