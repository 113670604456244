import { memo, useState, useContext, useEffect, useCallback, type FunctionComponent, type MouseEvent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import transform from 'lodash/transform';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import { useApolloClient } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// Skillmore UI Components
import { getCurrentSeconds } from '@empathco/ui-components/src/helpers/datetime';
import SkillsLegend from '@empathco/ui-components/src/elements/SkillsLegend';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { Skill, SkillLevel, SKILL_LEVEL_FIRST } from '../models/skill';
import { STEP_CURRENT_JOB, STEP_TARGET, STEP_SCOPE, STEP_ADDITION } from '../constants/builder';
import useCustomerSettings from '../config/customer';
import { API_MY_RESUME_STATUS } from '../config/api';
import { PREF_SKILL_FIELDS, getSkillCurrentLevel /* , getSkillCurrentLevelOnly */ } from '../helpers/models';
import useStatusPoller from '../hooks/useStatusPoller';
import { SkillLevelData } from '../context/dataContext';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import BuilderSubheader from '../elements/BuilderSubheader';
import BuilderFooter from '../elements/BuilderFooter';
import SkillsGrid from '../v3/SkillsGrid';
import AddSkillDialog from '../widgets/AddSkillDialog';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

type BuilderInferredStepProps = {
  step: number;
  onNext: (confirmed: boolean) => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  disabled?: boolean;
  resumeUploading?: boolean;
  // for Storybook only
  skipResumeCheck?: boolean;
  testResume?: boolean;
}

const BuilderInferredStepPropTypes = {
  // attributes
  step: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
  disabled: PropTypes.bool,
  resumeUploading: PropTypes.bool,
  // for Storybook only
  skipResumeCheck: PropTypes.bool,
  testResume: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const BuilderInferredStep: FunctionComponent<BuilderInferredStepProps> = ({
  step,
  onNext,
  onUploadStart,
  onUploadEnd,
  disabled: parentDisabled = false,
  resumeUploading = false,
  skipResumeCheck = false,
  testResume
}) => {
  const { HAS_MENTORING, HAS_RESUME_UPLOADER } = useCustomerSettings();
  const { cache: apolloCache } = useApolloClient();

  const { user: { data: user } } = useContext(GlobalContext);
  const {
    editableSks: { data: editableSks, pending, failed: failedSks }, requireEditableSkills,
    preferences: { pending: preferencesPending, failed: preferencesFailed },
    requirePreferences, // required by AddSkillDialog -> EditSkillLevel
    skillsUpdate: { pending: updatePending, failed: updateFailed }, updateSkills,
    skillUpdate: { pending: updateSkillPending, failed: updateSkillFailed, params: updateSkillParams }, updateSkill
  } = useContext(DataContext);
  const scope = step >= STEP_CURRENT_JOB && step < STEP_TARGET ? STEP_SCOPE[step - 1] : null;

  const [skills, setSkills] = useState<Skill[] | null>(null);
  const [exclude, setExclude] = useState<number[]>(map(editableSks, 'id'));
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);

  const [resumeJobId, setResumeJobId] = useState(step === STEP_ADDITION ? getCurrentSeconds() : undefined);
  const {
    data: resumeStatus, computed: resumeComputed, computing: resumeComputing,
    pending: resumeStatusPending, failed: resumeJobFailed
  } = useStatusPoller(step === STEP_ADDITION && !skipResumeCheck ? resumeJobId : undefined, API_MY_RESUME_STATUS, true);
  const resumeStatusEmpty = resumeStatus && isEmpty(resumeStatus) && resumeComputing;
  const resumeJobPending = (!resumeStatusEmpty && resumeComputing) || resumeStatusPending;
  const resumePending = HAS_RESUME_UPLOADER && step === STEP_ADDITION && (resumeJobPending || testResume)
    ? true : undefined;

  const loading = pending || !editableSks || !skills || (step === STEP_ADDITION && preferencesPending);
  const failed = failedSks || (step === STEP_ADDITION && preferencesFailed);
  const disabled = parentDisabled || loading || failed || updatePending || updateSkillPending ||
    resumeUploading || resumePending || !updateSkills || !updateSkill;

  useEffect(() => {
    if (scope) requireEditableSkills?.({ inferred: true, scope });
  }, [scope, requireEditableSkills]);

  useEffect(() => {
    if (HAS_RESUME_UPLOADER && scope && !resumeJobPending && resumeComputed) {
      requireEditableSkills?.({ inferred: true, scope, forced: true });
      setResumeJobId(undefined);
    }
  }, [resumeComputed, resumeJobPending, scope, requireEditableSkills, HAS_RESUME_UPLOADER]);

  useEffect(() => {
    if (resumeStatusEmpty) setResumeJobId(undefined);
  }, [resumeStatusEmpty]);

  useEffect(() => {
    if (step === STEP_ADDITION) requirePreferences?.();
  }, [step, requirePreferences]);

  useEffect(() => {
    if (!editableSks) {
      setSkills(null);
      setExclude([]);
      setAnchorAddBtn(null);
    } else if (editableSks && (!skills || skills !== editableSks)) {
      const updatedSkills = uniqBy([...skills || [], ...editableSks], 'id');
      setSkills(updatedSkills);
      setExclude(map(updatedSkills, 'id'));
      setAnchorAddBtn(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableSks]); // do not need `skills` dependency, because we should do nothing here if it updates

  const updateLevel = useCallback((id: number, level: SkillLevel, mentoring: boolean, isTarget?: boolean) => {
    const index = findIndex(skills, ['id', id]);
    const skill = index >= 0 ? (skills as Skill[])[index] : null;
    const { actual_level, current_level, is_mentoring, is_target, is_inference_newer } = skill || {};
    if (skill && (
      is_inference_newer ||
      actual_level !== level ||
      current_level !== level ||
      (HAS_MENTORING && is_mentoring !== mentoring) ||
      (isBoolean(isTarget) && is_target !== isTarget)
    )) {
      const updatedSkills = [...skills as Skill[]];
      updatedSkills[index] = {
        ...skill,
        is_inference_newer: false,
        current_level: level,
        actual_level: level,
        ...HAS_MENTORING ? { is_mentoring: mentoring } : {},
        is_my: level >= SKILL_LEVEL_FIRST,
        ...isBoolean(isTarget) ? { is_target: isTarget } : {}
      };
      // update skill right away (do not wait for Skip or Confirm All button click)
      updateSkill?.({
        apolloCache,
        skill_id: id,
        level,
        ...HAS_MENTORING ? { is_opt_in_mentor: mentoring } : {},
        ...isBoolean(isTarget) ? { is_target: isTarget } : {},
        source: 'profile_builder'
      });
      setSkills(updatedSkills);
    }
  }, [skills, updateSkill, apolloCache, HAS_MENTORING]);

  const handleAddSkill = useCallback((skl: Skill, level: SkillLevel, mentoring: boolean, is_target?: boolean) => {
    if (findIndex(skills, ['id', skl.id]) < 0) {
      if (level >= SKILL_LEVEL_FIRST) {
        // add new skill with specified level
        setSkills([
          ...skills || [],
          {
            ...skl,
            is_inference_newer: false,
            current_level: level,
            actual_level: level,
            ...HAS_MENTORING ? { is_mentoring: mentoring } : {},
            ...isBoolean(is_target) ? { is_target } : {},
            is_my: true
          }
        ]);
        setExclude((prevAdded) => uniq([...prevAdded, skl.id]));
        // save added skill right away (do not wait for Skip or Confirm All button click)
        updateSkill?.({
          apolloCache,
          skill_id: skl.id,
          level,
          ...HAS_MENTORING ? { is_opt_in_mentor: mentoring } : {},
          ...isBoolean(is_target) ? { is_target } : {},
          source: 'profile_builder',
          skill: pick(skl, PREF_SKILL_FIELDS)
        });
      }
    } else updateLevel(skl.id, level, mentoring, is_target);
    setAnchorAddBtn(null);
  }, [skills, updateLevel, updateSkill, apolloCache, HAS_MENTORING]);

  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  const handleLevelUpdates = useCallback((skip: boolean) => {
    if (skip) {
      onNext(false);
      return;
    }
    if (scope) {
      const updatedLevels = transform(skills as Skill[], (levels, skill) => {
        const { id, is_mentoring, is_target } = skill;
        const level = getSkillCurrentLevel(skill);
        levels.push({
          skill_id: id,
          level,
          ...HAS_MENTORING ? { is_opt_in_mentor: Boolean(is_mentoring) } : {},
          ...isBoolean(is_target) ? { is_target } : {}
        });
      }, [] as SkillLevelData[]);

      if (size(updatedLevels) < 1) onNext(true);
      else updateSkills?.({
        apolloCache,
        onSuccess: () => onNext(true),
        is_onboarding: true,
        onboarding_step: scope,
        levels: updatedLevels
      });
    }
  }, [skills, scope, updateSkills, onNext, apolloCache, HAS_MENTORING]);

  const handleSkip = useCallback(() => handleLevelUpdates(true), [handleLevelUpdates]);
  const handleConfirm = useCallback(() => handleLevelUpdates(false), [handleLevelUpdates]);

  const handleUpload = useCallback(() => {
    setResumeJobId(getCurrentSeconds());
  }, []);

  const skillsCount = size(skills);

  const content = (
    <SkillsGrid
        isEmployee
        source="profile_builder"
        notFoundMessage={step !== STEP_ADDITION ||
          user?.has_current_job_skills || user?.has_in_demand_skills ? `builder.step${step}.empty` : undefined}
        skills={skills}
        failed={failed}
        pending={loading}
        plain
        removeIcon="all"
        onItemUpdate={updateLevel}
        disabled={disabled}
        updatingSkillId={(updateSkillPending && updateSkillParams?.skill_id) || null}
        filters={!failed && !loading && skillsCount >= 1 ? <SkillsLegend/> : undefined}
    />
  );

  return (
    <>
      <BuilderSubheader
          key={step}
          user={user}
          title={`builder.step${step}.subheader`}
          info={`builder.step${step}.info`}
          infoLink={step === 2 ? 'builder.step2.help.button' : undefined}
          infoText={step === 2 ? 'builder.step2.help.text' : undefined}
          disabled={disabled}
          onAdd={step === STEP_ADDITION ? handleAddOpen : undefined}
          onUpload={HAS_RESUME_UPLOADER && step === STEP_ADDITION ? handleUpload : undefined}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          pending={loading}
          pendingResume={resumePending}
          testUploading={testResume === false ? true : undefined}
      />
      {HAS_RESUME_UPLOADER && ((resumePending && !loading) || resumeUploading) ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      <BuilderFooter
          nextCaption={((loading || skillsCount > 1) && 'builder.button.confirm_all') ||
            (skillsCount < 1 && step !== STEP_ADDITION && 'builder.button.next') || undefined}
          topPadding={loading || resumePending || failed ? true : undefined}
          disabledPrev={parentDisabled || loading || updatePending || updateSkillPending ? true : undefined}
          disabledNext={disabled}
          pendingNext={updatePending ? true : undefined}
          onPrev={handleSkip}
          onNext={handleConfirm}
      />
      <ActionFailedAlert
          message="edit_skills.submit_error"
          open={updateFailed}
      />
      <ActionFailedAlert
          message="skill.level_update_error"
          open={updateSkillFailed}
      />
      {step === STEP_ADDITION && (
        <>
          <AddSkillDialog
              plain
              anchorEl={anchorAddBtn}
              exclude={exclude}
              onAdd={handleAddSkill}
              onCancel={handleAddClose}
              disabled={disabled}
          />
          {HAS_RESUME_UPLOADER ? (
            <ActionFailedAlert
                open={resumeJobFailed}
                message="builder.resume.error_processing"
            />
          ) : undefined}
        </>
      )}
    </>
  );
};

BuilderInferredStep.propTypes = BuilderInferredStepPropTypes;

export default memo(BuilderInferredStep);
