import { forwardRef, memo, type MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import CloseIcon from '@mui/icons-material/Close';
import CloseThick from 'mdi-material-ui/CloseThick';
// SCSS imports
import { button, smallButton, avatar, smallAvatar, transparentAvatar, active, inactive } from './CloseIconButton.module.scss';

type CloseIconButtonProps = {
  small?: boolean;
  transparent?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const CloseIconButtonPropTypes = {
  small: PropTypes.bool,
  transparent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const CloseIconButton = forwardRef<HTMLButtonElement, CloseIconButtonProps>(({
  small = false,
  transparent = false,
  onClick,
  disabled = false
}, ref) => {
  const { formatMessage } = useIntl();
  return (
    <IconButton
        ref={ref}
        size="medium"
        aria-label={formatMessage({ id: 'common.button.close' })}
        className={small ? smallButton : button}
        onClick={onClick}
        disabled={disabled}
    >
      <Avatar
          variant="circular"
          className={clsx({
            [smallAvatar]: small,
            [transparentAvatar]: transparent,
            [avatar]: !small && !transparent,
            [active]: !disabled,
            [inactive]: disabled
          })}
      >
        {transparent
          ? <CloseIcon fontSize="inherit"/>
          : <CloseThick fontSize="inherit"/>}
      </Avatar>
    </IconButton>
  );
});

CloseIconButton.displayName = 'CloseIconButton';

CloseIconButton.propTypes = CloseIconButtonPropTypes;

export default memo(CloseIconButton);
