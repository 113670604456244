/* eslint-disable max-lines, complexity, max-lines-per-function */
import { useMemo } from 'react';
import { type RouteObject } from 'react-router-dom';
// Skillmore UI Components
import { type Breadcrumb, type BreadcrumbItem, DrawerItem } from '@empathco/ui-components/src/types';
// local imports
import useCustomerSettings from '../config/customer';

// ******************************************************************************
// IMPORTANT!!! When adding new path here it is REQUIRED to add this new path to:
// - App.jsx
// - ROUTES, DRAWER and BREADCRUMBS below in this file (paths.js)
// ******************************************************************************

export const PATH_LOGIN_LINK_EMAIL = '/login' as const; // email-based login screen
export const PATH_LOGIN_LINK_SSO = '/sso/saml2/login/' as const; // implemented by backend
export const PATH_LOGIN_LINK_DEFAULT = PATH_LOGIN_LINK_SSO;
export const PATH_LOGOUT_LINK_EMAIL = '/api/logout' as const;
export const PATH_LOGOUT_LINK_SSO = '/sso/logout/' as const;
export const PATH_LOGOUT_LINK_DEFAULT = PATH_LOGOUT_LINK_SSO;
export const PATH_SSO_SERVICE = '/accounts/profile/' as const; // external SSO URI

export const PATH_HOME = '/' as const;
export const PATH_SITE_ADMIN = '/admin/' as const; // TODO: change to new Django Admin URI (if any) when Admin UI is ready
export const PATH_LOGIN_SSO_EMAIL = '/auth_email/' as const;
export const PATH_LOGIN_SSO_SSO = '/auth_sso/' as const;
export const PATH_LOGIN_SSO_DEFAULT = PATH_LOGIN_SSO_SSO;
// HRBP UI
export const PATH_HR = '/hr' as const; // HR - Dashboard Analytics
export const PATH_HR_TEAMS = '/hr/teams' as const; // HR - My Team
// export const PATH_HR_JOBS = '/hr/jobs' as const; // HR - Jobs [Insights]
export const PATH_HR_TALENT_FINDER = '/hr/talentfinder' as const; // HR - Talent Finder
export const PATH_HR_COHORTS = '/hr/talentfinder/cohorts' as const; // HR - Saved Cohorts
export const PATH_HR_COHORT = '/hr/talentfinder/cohorts/:cohort_id' as const; // HR - Cohort
export const PATH_HR_COHORT_EDIT = '/hr/talentfinder/cohorts/:cohort_id/edit' as const; // HR - Cohort - Edit
export const PATH_HR_TEAM_BUILDER = '/hr/teambuilder' as const; // HR - Team Builder
export const PATH_HR_TEAM_BUILDER_TEAM = '/hr/teambuilder/:team_id/edit' as const; // HR - Team Builder - Team Edit
export const PATH_HR_TEAM_BUILDER_REVIEW = '/hr/teambuilder/:team_id' as const; // HR - Team Builder - Team Review
export const PATH_HR_DEV_PLANS = '/hr/devplanner' as const; // HR - Development Plans
export const PATH_HR_DEV_PLAN_COHORT_NEW = '/hr/devplanner/cohort/:cohort_id' as const; // HR - New Development Plan for Cohort
export const PATH_HR_DEV_PLAN_EMPLOYEE_NEW = '/hr/devplanner/employee/:employee_id' as const; // HR - New Dev Plan for Employee
export const PATH_HR_DEV_PLAN = '/hr/devplanner/:plan_id' as const; // HR - Development Plan Review
export const PATH_HR_DEV_PLAN_EDITOR = '/hr/devplanner/:plan_id/edit' as const; // HR - Development Plan Editor
export const PATH_HR_REDEPLOYMENTS = '/hr/redeployment' as const; // HR - Redeployment Plans
export const PATH_HR_REDEPLOYMENT = '/hr/redeployment/:plan_id' as const; // HR - Redeployment Plan
export const PATH_HR_DELEGATION = '/hr/delegation' as const; // HR - Delegation
// HR Admin UI
export const PATH_ADMIN_INDEMAND_SKILLS = '/hr/in-demand-skills/' as const;
// Admin UI
export const PATH_ADMIN = '/adm1n' as const; // TODO: change to '/admin' when Admin UI is ready
export const PATH_ADMIN_EMPLOYEES = PATH_ADMIN;
export const PATH_ADMIN_JOBS = `${PATH_ADMIN}/jobs` as const;
export const PATH_ADMIN_REPORTS = `${PATH_ADMIN}/reports` as const;
export const PATH_ADMIN_REPORT = `${PATH_ADMIN_REPORTS}/:report_type/:report_id` as const;
export const PATH_ADMIN_SETTINGS = `${PATH_ADMIN}/settings` as const;
export const PATH_ADMIN_USERS = `${PATH_ADMIN}/admins` as const;
export const PATH_ADMIN_SET_PASSWORD = `${PATH_ADMIN}/set_password` as const;
// Supervisor UI
export const PATH_SUPERVISOR = '/supervisor' as const; // My Team - Dashboard
export const PATH_SUPERVISOR_TEAM = '/supervisor/team' as const; // My Team - Team View
export const PATH_SUPERVISOR_TALENT_FINDER = '/supervisor/talentfinder' as const; // My Team - Talent Finder
export const PATH_SUPERVISOR_EMPLOYEE = '/supervisor/team/:employee_id' as const; // My Team - Employee Details
export const PATH_SUPERVISOR_LEARNING = '/supervisor/team/:employee_id/training' as const;
export const PATH_SUPERVISOR_MENTORSHIP = '/supervisor/team/:employee_id/advice' as const;
export const PATH_SUPERVISOR_ACTIVITIES = '/supervisor/team/:employee_id/activities' as const;
export const PATH_SUPERVISOR_IN_DEMAND = '/supervisor/team/:employee_id/in-demand' as const;
export const PATH_SUPERVISOR_IN_DEMAND_LEARNING = '/supervisor/team/:employee_id/in-demand/training' as const;
export const PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP = '/supervisor/team/:employee_id/in-demand/advice' as const;
export const PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES = '/supervisor/team/:employee_id/in-demand/activities' as const;
export const PATH_SUPERVISOR_CURRENT_ROLE = '/supervisor/team/:employee_id/current-role' as const;
export const PATH_SUPERVISOR_CURRENT_ROLE_LEARNING = '/supervisor/team/:employee_id/current-role/training' as const;
export const PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP = '/supervisor/team/:employee_id/current-role/advice' as const;
export const PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES = '/supervisor/team/:employee_id/current-role/activities' as const;
export const PATH_SUPERVISOR_OPPORTUNITIES = '/supervisor/opportunities' as const; // Opp. Marketplace - My Opportunity Posts
export const PATH_SUPERVISOR_OPPORTUNITY_NEW = '/supervisor/opportunities/new' as const; // Opp. Marketplace - New Opportunity
export const PATH_SUPERVISOR_OPPORTUNITY = '/supervisor/opportunities/:opp_id' as const; // Opp. Marketplace - Opportunity
export const PATH_SUPERVISOR_OPPORTUNITY_MATCHES = '/supervisor/opportunities/:opp_id/matches' as const; // Opp. Marketplace
export const PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS = '/supervisor/opportunities/:opp_id/bookings' as const; // Opp. Marketplace
export const PATH_SUPERVISOR_OPPORTUNITY_EDIT = '/supervisor/opportunities/:opp_id/edit' as const; // Opp. Mrktplc - Opp. Edit
// Employee UI
export const PATH_MY = '/my' as const; // Dashboard
export const PATH_MY_WELCOME = '/my/welcome' as const; // Welcome (first time only)
export const PATH_MY_SPLASH = '/hello' as const; // Splash Screen for users whose profiles are not supported yet
export const PATHS_MY_PROFILE_BUILDER = [
  // Profile Builder steps
  '/my/profile-builder/current-job' as const,
  '/my/profile-builder/in-demand' as const,
  '/my/profile-builder/additional' as const,
  '/my/profile-builder/target-skills' as const,
  '/my/profile-builder' as const
] as const;
export const PATH_MY_SKILLS = '/my/skills' as const; // Recommendations + Target Skills
export const PATH_MY_SKILLS_BROWSER = '/my/skills/browser' as const; // Skills Browser
export const PATH_MY_SKILLS_LEARNING = '/my/skills/training' as const; // Recommendations + Learning Progress
export const PATH_MY_SKILLS_MENTORSHIP = '/my/skills/advice' as const; // Recommendations + Mentorship
export const PATH_MY_SKILLS_ACTIVITIES = '/my/skills/activities' as const; // Recommendations + Activities
export const PATH_MY_SKILLS_IN_DEMAND = '/my/skills/in-demand' as const; // In-Demand + Target Skills
export const PATH_MY_SKILLS_IN_DEMAND_LEARNING = '/my/skills/in-demand/training' as const; // In-Demand + Learning Progress
export const PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP = '/my/skills/in-demand/advice' as const; // In-Demand + Mentorship
export const PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES = '/my/skills/in-demand/activities' as const; // In-Demand + Activities
export const PATH_MY_SKILLS_CURRENT_ROLE = '/my/skills/current-role' as const; // Current Role + Target Skills
export const PATH_MY_SKILLS_CURRENT_ROLE_LEARNING = '/my/skills/current-role/training' as const; // Current Role + Learning
export const PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP = '/my/skills/current-role/advice' as const; // Current Role + Mentorship
export const PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES = '/my/skills/current-role/activities' as const; // CurrentRole + Activities
export const PATH_MY_DEV_PLAN_NEW = '/my/skills/devplanner/new' as const; // New Dev Plan
export const PATH_MY_DEV_PLAN = '/my/skills/devplanner/:plan_id' as const; // Development Plan Review
export const PATH_MY_DEV_PLAN_EDITOR = '/my/skills/devplanner/:plan_id/edit' as const; // Development Plan Editor
export const PATH_MY_JOBS = '/my/jobs' as const; // Jobs
export const PATH_MY_OPPORTUNITIES = '/my/opportunities' as const; // Opportunity Marketplace - My Opportunities
export const PATH_MY_OPPORTUNITIES_BROWSE = '/my/opportunities/browse' as const; // Opportunity Marketplace - Browse
export const PATH_MY_OPPORTUNITY = '/my/opportunities/:opp_id' as const; // Opportunity Marketplace - Opportunity
export const PATH_MY_PREFERENCES = '/my/preferences' as const; // Preferences
export const PATH_MY_NOTIFICATIONS = '/my/notifications' as const; // Notifications

// Universal paths:
export const PATH_SKILL = '/skills/:skill_id' as const; // Skill Details
export const PATH_JOB = '/jobs/:role_id' as const; // Job Details - 'Most Common Job Moves' tab is open
export const PATH_JOB_TCP = '/jobs/:role_id/tcp' as const; // Job Details - 'Technical Career Path (TCP)' tab is open

// Deprecated paths:
export const PATH_MY_SKILL = '/my/skills/:skill_id' as const; // Skill Details
export const PATH_MY_JOB = '/my/jobs/:role_id' as const; // Job Details - 'Most Common Job Moves' tab is open
export const PATH_MY_JOB_TCP = '/my/jobs/:role_id/tcp' as const; // Job Details - 'Technical Career Path (TCP)' tab is open
export const PATH_SUPERVISOR_SKILL = '/supervisor/skills/:skill_id' as const; // My Team - Skill Details
export const PATH_HR_SKILL = '/hr/skills/:skill_id' as const; // HR - Skill Details
export const PATH_HR_JOB = '/hr/jobs/:role_id' as const; // HR - Job Details - Most Common Job Moves tab is open
export const PATH_HR_JOB_TCP = '/hr/jobs/:role_id/tcp' as const; // HR - Job - Technical Career Path (TCP) tab is open

export interface UserRoles {
  contractor?: boolean;
  employee?: boolean;
  leader?: boolean;
  supervisor?: boolean;
  hrbp?: boolean;
  admin?: boolean;
}

export interface CustomizedDrawerItem extends DrawerItem {
  visibleTo?: UserRoles;
  invisibleTo?: UserRoles;
}

export const HOME_ICON_ID = 'HOME_ICON';

export const getDrawerItems = ({
  HAS_MENTORING, HAS_INDEMAND_SKILLS,
  HAS_TALENT_FINDER, HAS_DEV_PLAN, HAS_REDEPLOYMENT, HAS_TEAM_BUILDER, HAS_INDEMAND_SKILLS_EDITOR,
  HELP_URL, HAS_COURSES_READONLY, HAS_OPPORTUNITIES, HAS_ADMIN_UI
}: Partial<ReturnType<typeof useCustomerSettings>>): CustomizedDrawerItem[] => [
  {
    text: 'drawer.my_skills',
    link: PATH_MY_SKILLS,
    paths: [
      PATH_SKILL,
      PATH_MY_SKILLS_BROWSER,
      PATH_MY_SKILLS_ACTIVITIES,
      PATH_MY_SKILLS_CURRENT_ROLE,
      PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES,
      ...HAS_INDEMAND_SKILLS ? [PATH_MY_SKILLS_IN_DEMAND, PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES] : [],
      ...HAS_COURSES_READONLY ? [PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_CURRENT_ROLE_LEARNING] : [],
      ...HAS_COURSES_READONLY && HAS_INDEMAND_SKILLS ? [PATH_MY_SKILLS_IN_DEMAND_LEARNING] : [],
      ...HAS_MENTORING ? [PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP] : [],
      ...HAS_MENTORING && HAS_INDEMAND_SKILLS ? [PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP] : [],
      ...HAS_DEV_PLAN ? [
        PATH_MY_DEV_PLAN_NEW,
        PATH_MY_DEV_PLAN,
        PATH_MY_DEV_PLAN_EDITOR
      ] : []
    ],
    visibleTo: {
      employee: true,
      leader: true
    }
  },
  {
    text: 'drawer.my_jobs',
    link: PATH_MY_JOBS,
    paths: [PATH_JOB, PATH_JOB_TCP],
    visibleTo: {
      employee: true
    }
  },
  ...HAS_TALENT_FINDER ? [
    {
      text: 'drawer.hr.talent_finder',
      link: PATH_HR_TALENT_FINDER,
      paths: [PATH_HR_COHORTS, PATH_HR_COHORT],
      visibleTo: {
        hrbp: true
      }
    },
    {
      text: 'drawer.hr.talent_finder',
      link: PATH_SUPERVISOR_TALENT_FINDER,
      visibleTo: {
        supervisor: true
      },
      invisibleTo: {
        hrbp: true
      }
    },
    ...HAS_DEV_PLAN ? [
      {
        text: 'drawer.hr.dev_plan',
        link: PATH_HR_DEV_PLANS,
        paths: [PATH_HR_DEV_PLAN, PATH_HR_DEV_PLAN_COHORT_NEW, PATH_HR_DEV_PLAN_EMPLOYEE_NEW, PATH_HR_DEV_PLAN_EDITOR],
        visibleTo: {
          supervisor: true,
          hrbp: true
        }
      }
    ] : []
  ] : [],
  {
    text: 'drawer.my_team',
    link: PATH_SUPERVISOR_TEAM,
    paths: [
      PATH_SUPERVISOR_EMPLOYEE,
      PATH_SUPERVISOR_ACTIVITIES,
      PATH_SUPERVISOR_CURRENT_ROLE,
      PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES,
      ...HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES] : [],
      ...HAS_COURSES_READONLY ? [PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING] : [],
      ...HAS_COURSES_READONLY && HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND_LEARNING] : [],
      ...HAS_MENTORING ? [PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP] : [],
      ...HAS_MENTORING && HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP] : []
    ],
    visibleTo: {
      supervisor: true
    },
    invisibleTo: HAS_TEAM_BUILDER ? {
      hrbp: true
    } : undefined
  },
  ...HAS_TEAM_BUILDER ? [
    {
      text: 'drawer.teams',
      link: PATH_HR_TEAMS,
      paths: [
        PATH_HR_TEAMS,
        PATH_HR_TEAM_BUILDER,
        PATH_HR_TEAM_BUILDER_TEAM,
        PATH_HR_TEAM_BUILDER_REVIEW,
        PATH_SUPERVISOR_EMPLOYEE,
        PATH_SUPERVISOR_ACTIVITIES,
        PATH_SUPERVISOR_CURRENT_ROLE,
        PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES,
        ...HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES] : [],
        ...HAS_COURSES_READONLY ? [PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING] : [],
        ...HAS_COURSES_READONLY && HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND_LEARNING] : [],
        ...HAS_MENTORING ? [PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP] : [],
        ...HAS_MENTORING && HAS_INDEMAND_SKILLS ? [PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP] : []
      ],
      visibleTo: {
        hrbp: true
      }
    }
  ] : [],
  ...HAS_REDEPLOYMENT ? [
    {
      text: 'drawer.hr.redeployment',
      link: PATH_HR_REDEPLOYMENTS,
      paths: [PATH_HR_REDEPLOYMENT],
      visibleTo: {
        hrbp: true
      }
    }
  ] : [],
  ...HAS_INDEMAND_SKILLS_EDITOR ? [
    {
      text: 'drawer.admin.in_demand_skills',
      link: PATH_ADMIN_INDEMAND_SKILLS,
      visibleTo: {
        admin: true
      }
    }
  ] : [],
  ...HAS_OPPORTUNITIES ? [
    {
      text: 'drawer.opportunities',
      link: PATH_SUPERVISOR_OPPORTUNITIES,
      paths: [
        PATH_SUPERVISOR_OPPORTUNITY_NEW,
        PATH_SUPERVISOR_OPPORTUNITY,
        PATH_SUPERVISOR_OPPORTUNITY_MATCHES,
        PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS,
        PATH_SUPERVISOR_OPPORTUNITY_EDIT,
        PATH_MY_OPPORTUNITIES,
        PATH_MY_OPPORTUNITIES_BROWSE,
        PATH_MY_OPPORTUNITY
      ],
      visibleTo: { supervisor: true },
      invisibleTo: { employee: true }
    },
    {
      text: 'drawer.opportunities',
      link: PATH_MY_OPPORTUNITIES_BROWSE,
      paths: [
        PATH_MY_OPPORTUNITIES,
        PATH_MY_OPPORTUNITY,
        PATH_SUPERVISOR_OPPORTUNITIES,
        PATH_SUPERVISOR_OPPORTUNITY_NEW,
        PATH_SUPERVISOR_OPPORTUNITY,
        PATH_SUPERVISOR_OPPORTUNITY_MATCHES,
        PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS,
        PATH_SUPERVISOR_OPPORTUNITY_EDIT
      ],
      visibleTo: { employee: true }
    }
  ] : [],
  {
    text: 'drawer.dashboard',
    link: PATH_SUPERVISOR,
    visibleTo: {
      supervisor: true
    },
    invisibleTo: {
      hrbp: true
    }
  },
  {
    text: 'drawer.dashboard',
    link: PATH_HR,
    // paths: [
    //   ...HAS_HRP_JOBS ? [PATH_HR_JOBS] : [],
    // ],
    visibleTo: {
      hrbp: true
    }
  },
  ...HAS_ADMIN_UI ? [
    {
      text: 'drawer.admin',
      link: PATH_ADMIN,
      paths: [
        PATH_ADMIN_EMPLOYEES,
        PATH_ADMIN_JOBS,
        PATH_ADMIN_REPORTS,
        PATH_ADMIN_REPORT,
        PATH_ADMIN_USERS,
        PATH_ADMIN_SETTINGS
      ],
      visibleTo: {
        admin: true
      }
    }
  ] : [],
  ...HELP_URL ? [
    {
      text: 'drawer.help',
      href: HELP_URL,
      invisibleTo: {
        contractor: true
      }
    }
  ] : [],
  {
    icon: HOME_ICON_ID
    // default link to user's `homePath` will be used
  }
];

// HRBP UI:
const HR: Breadcrumb[] = [{ title: 'breadcrumbs.dashboard', link: PATH_HR } as const];
const HR_TALENT_FINDER: Breadcrumb[] = [{ title: 'breadcrumbs.hr.talentfinder', link: PATH_HR_TALENT_FINDER } as const];
const HR_DEV_PLANS: Breadcrumb[] = [{ title: 'breadcrumbs.hr.devplans', link: PATH_HR_DEV_PLANS } as const];
const HR_REDEPLOYMENTS: Breadcrumb[] = [{ title: 'breadcrumbs.hr.redeployments', link: PATH_HR_REDEPLOYMENTS } as const];
const HR_TEAM_BUILDER: Breadcrumb[] = [{ title: 'breadcrumbs.hr.teambuilder', link: PATH_HR_TEAM_BUILDER } as const];
// Admin UI:
const ADMIN: Breadcrumb[] = [{ title: 'breadcrumbs.admin', link: PATH_ADMIN } as const];
// Manager UI:
const SUPERVISOR_TALENT_FINDER: Breadcrumb[] =
  [{ title: 'breadcrumbs.hr.talentfinder', link: PATH_SUPERVISOR_TALENT_FINDER } as const];
// Empoyee UI:
const MY_SKILLS: Breadcrumb[] = [{ title: 'breadcrumbs.skills', link: PATH_MY_SKILLS } as const];
const MY_ROLES: Breadcrumb[] = [{ title: 'breadcrumbs.roles_index', link: PATH_MY_JOBS } as const];

function usePathConfig() {
  const {
    // HAS_HRP_JOBS,
    HAS_MENTORING, HAS_INDEMAND_SKILLS, HAS_PROFILE_BUILDER,
    HAS_TALENT_FINDER, HAS_DEV_PLAN, HAS_REDEPLOYMENT, HAS_TEAM_BUILDER, HAS_INDEMAND_SKILLS_EDITOR,
    HELP_URL, HAS_MULTIPLE_SSO, HAS_LOGIN, HAS_COURSES_READONLY, HAS_OPPORTUNITIES, HAS_ADMIN_UI
  } = useCustomerSettings();

  const PATH_LOGIN_LINK = HAS_LOGIN || HAS_MULTIPLE_SSO
    ? PATH_LOGIN_LINK_EMAIL // email-based login screen (including multiple SSO providers)
    : PATH_LOGIN_LINK_SSO; // implemented by backend
  const PATH_LOGOUT_LINK = HAS_LOGIN ? PATH_LOGOUT_LINK_EMAIL : PATH_LOGOUT_LINK_SSO;
  const PATH_LOGIN_SSO = HAS_LOGIN ? PATH_LOGIN_SSO_EMAIL : PATH_LOGIN_SSO_SSO;

  const ROUTES: RouteObject[] = useMemo(() => [
    // Login Screens
    { path: PATH_LOGIN_SSO },
    { path: PATH_LOGIN_LINK_SSO },
    { path: PATH_LOGIN_LINK_EMAIL },
    // HR Screens
    { path: PATH_HR },
    // ...HAS_HRP_JOBS ? [{ path: PATH_HR_JOBS }] : [],
    ...HAS_TEAM_BUILDER ? [
      { path: PATH_HR_TEAMS },
      { path: PATH_HR_TEAM_BUILDER },
      { path: PATH_HR_TEAM_BUILDER_TEAM },
      { path: PATH_HR_TEAM_BUILDER_REVIEW }
    ] : [],
    { path: PATH_HR_DELEGATION },
    ...HAS_TALENT_FINDER ? [
      { path: PATH_HR_TALENT_FINDER },
      { path: PATH_SUPERVISOR_TALENT_FINDER },
      ...HAS_DEV_PLAN ? [
        { path: PATH_HR_COHORTS },
        { path: PATH_HR_COHORT },
        { path: PATH_HR_COHORT_EDIT },
        { path: PATH_HR_DEV_PLANS },
        { path: PATH_HR_DEV_PLAN_COHORT_NEW },
        { path: PATH_HR_DEV_PLAN_EMPLOYEE_NEW },
        { path: PATH_HR_DEV_PLAN },
        { path: PATH_HR_DEV_PLAN_EDITOR }
      ] : []
    ] : [],
    ...HAS_REDEPLOYMENT ? [
      { path: PATH_HR_REDEPLOYMENTS },
      { path: PATH_HR_REDEPLOYMENT }
    ] : [],
    ...HAS_INDEMAND_SKILLS_EDITOR ? [{ path: PATH_ADMIN_INDEMAND_SKILLS }] : [],
    // Admin Screens
    { path: PATH_ADMIN_EMPLOYEES },
    { path: PATH_ADMIN_JOBS },
    { path: PATH_ADMIN_REPORT },
    { path: PATH_ADMIN_REPORTS },
    { path: PATH_ADMIN_USERS },
    { path: PATH_ADMIN_SETTINGS },
    { path: PATH_ADMIN_SET_PASSWORD },
    // Supervisor Screens
    ...HAS_OPPORTUNITIES ? [
      { path: PATH_SUPERVISOR_OPPORTUNITIES },
      { path: PATH_SUPERVISOR_OPPORTUNITY_NEW },
      { path: PATH_SUPERVISOR_OPPORTUNITY },
      { path: PATH_SUPERVISOR_OPPORTUNITY_MATCHES },
      { path: PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS },
      { path: PATH_SUPERVISOR_OPPORTUNITY_EDIT }
    ] : [],
    ...HAS_INDEMAND_SKILLS ? [
      { path: PATH_SUPERVISOR_IN_DEMAND },
      { path: PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES },
      ...HAS_COURSES_READONLY ? [{ path: PATH_SUPERVISOR_IN_DEMAND_LEARNING }] : []
    ] : [],
    ...HAS_MENTORING ? [
      ...HAS_INDEMAND_SKILLS ? [{ path: PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP }] : [],
      { path: PATH_SUPERVISOR_MENTORSHIP },
      { path: PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP}
    ] : [],
    ...HAS_COURSES_READONLY ? [
      { path: PATH_SUPERVISOR_LEARNING },
      { path: PATH_SUPERVISOR_CURRENT_ROLE_LEARNING }
    ] : [],
    { path: PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES },
    { path: PATH_SUPERVISOR_ACTIVITIES },
    { path: PATH_SUPERVISOR_CURRENT_ROLE },
    { path: PATH_SUPERVISOR_EMPLOYEE },
    { path: PATH_SUPERVISOR_TEAM },
    { path: PATH_SUPERVISOR },
    // Employee Screens
    ...HAS_OPPORTUNITIES ? [
      { path: PATH_MY_OPPORTUNITIES },
      { path: PATH_MY_OPPORTUNITIES_BROWSE },
      { path: PATH_MY_OPPORTUNITY }
    ] : [],
    { path: PATH_JOB_TCP },
    { path: PATH_JOB },
    { path: PATH_MY_JOBS },
    ...HAS_PROFILE_BUILDER ? [
      ...PATHS_MY_PROFILE_BUILDER.map((path) => ({ path })),
      { path: PATH_MY_WELCOME }
    ] : [],
    { path: PATH_MY_SPLASH },
    { path: PATH_SKILL },
    ...HAS_INDEMAND_SKILLS ? [
      { path: PATH_MY_SKILLS_IN_DEMAND },
      { path: PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES },
      ...HAS_COURSES_READONLY ? [{ path: PATH_MY_SKILLS_IN_DEMAND_LEARNING }] : []
    ] : [],
    ...HAS_MENTORING ? [
      ...HAS_INDEMAND_SKILLS ? [{ path: PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP }] : [],
      { path: PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP },
      { path: PATH_MY_SKILLS_MENTORSHIP }
    ] : [],
    { path: PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES },
    { path: PATH_MY_SKILLS_CURRENT_ROLE },
    { path: PATH_MY_SKILLS_ACTIVITIES },
    ...HAS_COURSES_READONLY ? [
      { path: PATH_MY_SKILLS_CURRENT_ROLE_LEARNING },
      { path: PATH_MY_SKILLS_LEARNING },
    ] : [],
    { path: PATH_MY_SKILLS_BROWSER },
    { path: PATH_MY_SKILLS },
    ...HAS_DEV_PLAN ? [
      { path: PATH_MY_DEV_PLAN_NEW },
      { path: PATH_MY_DEV_PLAN },
      { path: PATH_MY_DEV_PLAN_EDITOR }
    ] : [],
    { path: PATH_MY_PREFERENCES },
    { path: PATH_MY_NOTIFICATIONS },
    { path: PATH_MY },
    // Home Screen
    { path: PATH_HOME }
  ], [
    HAS_COURSES_READONLY, HAS_DEV_PLAN, HAS_INDEMAND_SKILLS, HAS_INDEMAND_SKILLS_EDITOR, HAS_MENTORING,
    HAS_OPPORTUNITIES, HAS_PROFILE_BUILDER, HAS_REDEPLOYMENT, HAS_TALENT_FINDER, HAS_TEAM_BUILDER, PATH_LOGIN_SSO
  ]);

  const DRAWER: CustomizedDrawerItem[] = useMemo(() => getDrawerItems({
    HAS_ADMIN_UI, HAS_COURSES_READONLY, HAS_DEV_PLAN, HAS_INDEMAND_SKILLS, HAS_INDEMAND_SKILLS_EDITOR, HAS_MENTORING,
    HAS_OPPORTUNITIES, HAS_REDEPLOYMENT, HAS_TALENT_FINDER, HAS_TEAM_BUILDER, HELP_URL
  }), [
    HAS_ADMIN_UI, HAS_COURSES_READONLY, HAS_DEV_PLAN, HAS_INDEMAND_SKILLS, HAS_INDEMAND_SKILLS_EDITOR, HAS_MENTORING,
    HAS_OPPORTUNITIES, HAS_REDEPLOYMENT, HAS_TALENT_FINDER, HAS_TEAM_BUILDER, HELP_URL
  ]);

  const BREADCRUMBS: Record<string, BreadcrumbItem[]> = useMemo(() => ({
    [PATH_MY_SKILLS]: MY_SKILLS,
    [PATH_MY_SKILLS_ACTIVITIES]: MY_SKILLS,
    [PATH_MY_SKILLS_CURRENT_ROLE]: MY_SKILLS,
    [PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES]: MY_SKILLS,
    ...HAS_MENTORING ? {
      [PATH_MY_SKILLS_MENTORSHIP]: MY_SKILLS,
      [PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP]: MY_SKILLS,
      ...HAS_INDEMAND_SKILLS ? { [PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP]: MY_SKILLS } : {}
    } : {},
    [PATH_MY_SKILLS_BROWSER]: MY_SKILLS,
    ...HAS_COURSES_READONLY ? {
      [PATH_MY_SKILLS_LEARNING]: MY_SKILLS,
      [PATH_MY_SKILLS_CURRENT_ROLE_LEARNING]: MY_SKILLS
    } : {},
    ...HAS_INDEMAND_SKILLS ? {
      [PATH_MY_SKILLS_IN_DEMAND]: MY_SKILLS,
      [PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES]: MY_SKILLS,
      ...HAS_COURSES_READONLY ? { [PATH_MY_SKILLS_IN_DEMAND_LEARNING]: MY_SKILLS } : {}
    } : {},
    ...HAS_DEV_PLAN ? {
      [PATH_MY_DEV_PLAN_NEW]: MY_SKILLS,
      [PATH_MY_DEV_PLAN]: MY_SKILLS,
      [PATH_MY_DEV_PLAN_EDITOR]: MY_SKILLS
    } : {},
    [PATH_MY_JOBS]: MY_ROLES,
    [PATH_MY_PREFERENCES]: ['breadcrumbs.preferences' as const],
    [PATH_MY_NOTIFICATIONS]: ['breadcrumbs.notifications' as const],
    [PATH_SUPERVISOR]: [{ title: 'breadcrumbs.dashboard' } as const],
    [PATH_SUPERVISOR_TEAM]: [{ title: 'breadcrumbs.my_team' } as const],
    [PATH_HR]: HR,
    // ...HAS_HRP_JOBS ? { [PATH_HR_JOBS]: HR_JOBS } : {},
    ...HAS_TEAM_BUILDER ? {
      [PATH_HR_TEAM_BUILDER]: HR_TEAM_BUILDER,
      [PATH_HR_TEAM_BUILDER_TEAM]: HR_TEAM_BUILDER,
      [PATH_HR_TEAM_BUILDER_REVIEW]: HR_TEAM_BUILDER
    } : {},
    ...HAS_INDEMAND_SKILLS_EDITOR ? {
      [PATH_ADMIN_INDEMAND_SKILLS]: ['breadcrumbs.admin.in_demand_skills' as const]
    } : {},
    [PATH_ADMIN_EMPLOYEES]: ADMIN,
    [PATH_ADMIN_JOBS]: ADMIN,
    [PATH_ADMIN_REPORTS]: ADMIN,
    [PATH_ADMIN_REPORT]: ADMIN,
    [PATH_ADMIN_SETTINGS]: ADMIN,
    [PATH_ADMIN_USERS]: ADMIN,
    ...HAS_TALENT_FINDER ? {
      [PATH_HR_TALENT_FINDER]: HR_TALENT_FINDER,
      [PATH_SUPERVISOR_TALENT_FINDER]: SUPERVISOR_TALENT_FINDER,
      ...HAS_DEV_PLAN ? {
        [PATH_HR_COHORTS]: HR_TALENT_FINDER,
        [PATH_HR_COHORT]: HR_TALENT_FINDER,
        [PATH_HR_COHORT_EDIT]: HR_TALENT_FINDER,
        [PATH_HR_DEV_PLANS]: HR_DEV_PLANS,
        [PATH_HR_DEV_PLAN_COHORT_NEW]: HR_DEV_PLANS,
        [PATH_HR_DEV_PLAN_EMPLOYEE_NEW]: HR_DEV_PLANS,
        [PATH_HR_DEV_PLAN]: HR_DEV_PLANS,
        [PATH_HR_DEV_PLAN_EDITOR]: HR_DEV_PLANS
      } : {}
    } : {},
    ...HAS_REDEPLOYMENT ? {
      [PATH_HR_REDEPLOYMENTS]: HR_REDEPLOYMENTS,
      [PATH_HR_REDEPLOYMENT]: HR_REDEPLOYMENTS
    } : {}
  }), [
    HAS_COURSES_READONLY, HAS_DEV_PLAN, HAS_INDEMAND_SKILLS, HAS_INDEMAND_SKILLS_EDITOR, HAS_MENTORING, HAS_REDEPLOYMENT,
    HAS_TALENT_FINDER, HAS_TEAM_BUILDER
  ]);

  return useMemo(() => ({
    PATH_LOGIN_LINK,
    PATH_LOGOUT_LINK,
    PATH_LOGIN_SSO,
    ROUTES,
    DRAWER,
    BREADCRUMBS
  }), [BREADCRUMBS, DRAWER, PATH_LOGIN_LINK, PATH_LOGIN_SSO, PATH_LOGOUT_LINK, ROUTES]);
}

export default usePathConfig;
