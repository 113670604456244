import { forwardRef, memo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import round from 'lodash/round';
import toFinite from 'lodash/toFinite';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// Material Icon imports
import CheckRounded from '@mui/icons-material/CheckRounded';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { Course } from '../models/course';
import { DevPlanCourse } from '../graphql/customTypes';

type CourseFeaturesProps = {
  course?: Course | DevPlanCourse;
  devplan?: boolean;
}

const CourseFeaturesPropTypes = {
  course: PropTypes.object as Validator<Course | DevPlanCourse>,
  devplan: PropTypes.bool
};

const CourseFeatures = forwardRef<Component<BoxProps>, CourseFeaturesProps>(({
  course,
  devplan = false
}, ref) => {
  const {
    level, duration_value: duration, duration_unit: unit, certification_program, credit_hours, is_self_placed
  } = (course || {}) as Course;

  return (
    <Box
        ref={ref}
        minHeight="100%"
        display="flex"
        flexDirection="column"
    >
      {[
        ...certification_program ? [{ id: 'certification_program', text: certification_program }] : [],
        ...level ? [{ id: 'skill.courses.level_value', values: { level } }] : [],
        ...duration ? [{ id: 'skill.courses.duration_value', values: { duration: round(toFinite(duration)), unit } }] : [],
        { id: 'skill.courses.self_paced', values: { self: Boolean(is_self_placed) } },
        ...credit_hours ? [{ id: 'skill.courses.credit_hours', values: { hours: round(toFinite(credit_hours)) } }] : []
      ].map(({ id, text, values }) => (
        <Box
            key={id}
            py={0.25}
            display="flex"
            alignItems="center"
        >
          <CheckRounded color="primary" fontSize={devplan ? 'medium' : 'inherit'}/>
          <BoxTypography pl={1} variant="body2">
            {text || <FormattedMessage id={id} values={values}/>}
          </BoxTypography>
        </Box>
      ))}
    </Box>
  );
});

CourseFeatures.displayName = 'CourseFeatures';

CourseFeatures.propTypes = CourseFeaturesPropTypes;

export default memo(CourseFeatures);
